// @ts-nocheck
/**
 * External dependencies.
 */
import { useSelector, useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

/**
 * Internal dependencies.
 */
import ProjectSettings from "../ProjectSettings";
import Window from "./Window";
import EditPopup from "../EditPopup";
import { AddHardwareMenuRun } from "../EditPopup/EditRun/EditRunHardware";
import { AddHardwareMenuPost } from "../EditPopup/EditPost/EditPostHardware";
import AddHardwareMenuCanvas from "../AddHardwareMenu/Canvas";
import Note from "../Note";
import CanvasSettings from "../CanvasSettings";
import ShippingEstimate from "../ShippingEstimate";
import EditRunLength from "../EditRunLength";
import EditHandrailLength from "../EditHandrailLength";
import EditLineLength from "../EditLineLength";
import EditStairsLength from "../EditStairsLength";
import { useContext } from "react";
import { CustomersContext, InventoryContext } from "../../App";
import {
  getProjectAsJSON,
  getSequenceId,
  projectSave,
} from "../../redux/effects";
import { projectEstimateNumber } from "../../utils";
import { serviceUrl } from "../../environment";
import { getCustomerName } from "../../entities/utils";

function WindowManager({ tool }) {
  const totalState = useSelector((state) => state);
  const state = totalState.state.present;
  const windowZIndex = useSelector((state) => state.windowsZIndex);
  const windows = useSelector((state) => state.windows);

  const { customers } = useContext(CustomersContext);

  return (
    <div className="app__window-manager">
      {state.settingsOpen && (
        <Window
          render={ProjectSettings}
          zIndex={windowZIndex["project-settings"]}
        />
      )}
      {state.edit.type && (
        <Window
          render={EditPopup}
          edit={state.edit}
          zIndex={windowZIndex["edit-popup"]}
        />
      )}
      {windows.getIn(["save-override", "open"]) && (
        <Window
          render={SaveOverrideMenu}
          state={totalState}
          zIndex={windowZIndex["save-override"]}
        />
      )}
      {windows.getIn(["add-hardware-run", "open"]) && (
        <Window
          render={AddHardwareMenuRun}
          run={windows.getIn(["add-hardware-run", "run"])}
          currentProject={state.currentProject}
          zIndex={windowZIndex["add-run-hardware"]}
        />
      )}
      {windows.getIn(["add-hardware-post", "open"]) && (
        <Window
          render={AddHardwareMenuPost}
          post={windows.getIn(["add-hardware-post", "post"])}
          currentProject={state.currentProject}
          zIndex={windowZIndex["add-post-hardware"]}
        />
      )}
      {windows.getIn(["error-message", "open"]) && (
        <Window
          render={ErrorMessage}
          message={windows.getIn(["error-message", "message"])}
        />
      )}
      {windows.getIn(["edit-stairs-length", "open"]) && (
        <Window
          render={EditStairsLength}
          stairs={windows.getIn(["edit-stairs-length", "stairs"])}
          point={windows.getIn(["edit-stairs-length", "point"])}
          point-type={windows.getIn(["edit-stairs-length", "point-type"])}
          zIndex={windowZIndex["edit-stairs-length"]}
        />
      )}
      {windows.getIn(["edit-run-length", "open"]) && (
        <Window
          render={EditRunLength}
          run={windows.getIn(["edit-run-length", "run"])}
          point={windows.getIn(["edit-run-length", "point"])}
          zIndex={windowZIndex["edit-run-length"]}
        />
      )}
      {windows.getIn(["edit-handrail-length", "open"]) && (
        <Window
          render={EditHandrailLength}
          handrail={windows.getIn(["edit-handrail-length", "handrail"])}
          point={windows.getIn(["edit-handrail-length", "point"])}
          zIndex={windowZIndex["edit-handrail-length"]}
        />
      )}
      {windows.getIn(["edit-line-length", "open"]) && (
        <Window
          render={EditLineLength}
          shape={windows.getIn(["edit-line-length", "shape"])}
          point={windows.getIn(["edit-line-length", "point"])}
          zIndex={windowZIndex["edit-line-length"]}
        />
      )}
      {windows.getIn(["generate-pdf", "open"]) && (
        <Window
          render={PDFGenerating}
          customers={customers}
          message={windows.getIn(["generate-pdf", "message"])}
          download={windows.getIn(["generate-pdf", "download"])}
          type={windows.getIn(["generate-pdf", "type"])}
          zIndex={windowZIndex["generate-pdf"]}
        />
      )}
      {windows.getIn(["shipping-estimate", "open"]) && (
        <Window render={ShippingEstimate} />
      )}
      {windows.getIn(["add-hardware-canvas", "open"]) && (
        <Window render={AddHardwareMenuCanvas} state={state} />
      )}
      {windows.getIn(["export-sales-order-dialog", "open"]) && (
        <Window
          render={ExportToQBDialog}
          resolve={windows.getIn(["export-sales-order-dialog", "resolve"])}
          reject={windows.getIn(["export-sales-order-dialog", "reject"])}
        />
      )}
      {windows.getIn(["project-frozen-dialog", "open"]) && (
        <Window
          render={ProjectFrozenDialog}
          zIndex={windowZIndex["project-frozen-dialog"]}
        />
      )}
      {state.notes &&
        state.notes
          .entrySeq()
          .filter(([_, note]) => {
            return note.isEditing === true;
          })
          .map(([id, note]) => (
            <Note
              key={id}
              note={note}
              tool={tool}
              currentProject={state.currentProject}
            />
          ))}
      {state.canvasMenuOpen?.size && (
        <Window
          render={CanvasSettings}
          canvas={state.canvasMenuOpen}
          zIndex={windowZIndex["canvas-settings"]}
        />
      )}
      {windows.get("add-image-drop") && (
        <div
          style={{
            border: "dashed grey 4px",
            backgroundColor: "rgba(255,255,255,.8)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              left: 0,
              textAlign: "center",
              color: "grey",
              fontSize: 36,
            }}
          >
            <div>Drop image on canvas where you want to place it.</div>
          </div>
        </div>
      )}
    </div>
  );
}

function ErrorMessage({ message, setPosition, animation }) {
  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="error-message">
      <div className="error-message__message">{message}</div>
      <div className="error-message__footer">
        <button
          onClick={() => {
            setTimeout(() => {
              dispatch({ type: "window/error-message-close" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Okay
        </button>
      </div>
    </div>
  );
}

function ProjectFrozenDialog({ setPosition, animation }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.state.present);

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="error-message">
      <div className="error-message__message">
        Project is Frozen and cannot be edited. Unfreezing a project cannot be
        undone. New prices will load from current inventory.
      </div>
      <div className="error-message__footer">
        <button
          onClick={() => {
            setTimeout(() => {
              dispatch({ type: "window/close-project-frozen-dialog" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Okay
        </button>
        <button
          onClick={() => {
            setTimeout(() => {
              if (project.frozenProject.id) {
                const url = serviceUrl("deleteFrozenProject");
                fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ id: project.frozenProject.id }),
                })
                  .then((res) => {
                    if (!res || !res.ok) {
                      dispatch({
                        type: "window/error-message-open",
                        message: "An error occurred while unfreezing project",
                      });
                      console.error(res.json());
                      throw new Error(
                        "An error occurred while unfreezing project"
                      );
                    } else {
                      return res.json();
                    }
                  })
                  .then((_json) => {
                    dispatch({
                      type: "project/unfreeze-project",
                    });
                  })
                  .catch((error) => {
                    dispatch({
                      type: "window/error-message-open",
                      message: "An error occurred while unfreezing project",
                    });
                    console.error(error);
                  });
                dispatch({ type: "window/close-project-frozen-dialog" });
              }

              if (project?.frozenEstimate?.id) {
                const url = serviceUrl("deleteFrozenEstimate");
                fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ id: project?.frozenEstimate?.id }),
                })
                  .then((res) => {
                    if (!res || !res.ok) {
                      dispatch({
                        type: "window/error-message-open",
                        message: "An error occurred while unfreezing estimate",
                      });
                      console.error(res.json());
                      throw new Error(
                        "An error occurred while unfreezing estimate"
                      );
                    } else {
                      return res.json();
                    }
                  })
                  .then((_json) => {
                    dispatch({
                      type: "project/unfreeze-estimate",
                      project: project,
                    });
                  })
                  .catch((error) => {
                    dispatch({
                      type: "window/error-message-open",
                      message: "An error occurred while unfreezing estimate",
                    });
                    console.error(error);
                  });
              }
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Unfreeze Project (Cannot Be Undone)
        </button>
      </div>
    </div>
  );
}

function PDFGenerating({
  message,
  setPosition,
  animation,
  download,
  customers,
  type,
}) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.state.present);

  let customer = state.customer;

  const estimateNumber = projectEstimateNumber(state).replace("#", "");

  if (customer) {
    if (customers.has(customer)) {
      customer = customers.get(customer);
    }
  }

  let fullName = "";

  if (customer) {
    fullName = getCustomerName(customer);
  }

  const PDFName = `${fullName} ${type} ${estimateNumber}.zip`;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="error-message">
      <div className="error-message__message">{message}</div>
      <div className="error-message__footer">
        {download && (
          <a
            href={download}
            download={PDFName}
            onClick={() => {
              setTimeout(() => {
                dispatch({ type: "window/generate-pdf-close" });
              }, 0);
            }}
            className="error-message__button button button--primary"
          >
            Download
          </a>
        )}
        <button
          onClick={() => {
            setTimeout(() => {
              dispatch({ type: "window/generate-pdf-close" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Okay
        </button>
      </div>
    </div>
  );
}

function SaveOverrideMenu({ setPosition, animation, state }) {
  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div
      {...bind()}
      style={{ maxWidth: "800px" }}
      className="error-message qb-export-dialog"
    >
      <div className="error-message__message">
        Project was unable to save due to network or invalid sequence of edits.
        Are you sure you want to override the current save state? This action is
        not reversable. If it fails this message will pop up again.
      </div>
      <div className="error-message__footer">
        <button
          onClick={() => {
            setTimeout(() => {
              const json = getProjectAsJSON(state);
              const sequenceId = getSequenceId(state);

              json.sequenceId = sequenceId;

              projectSave(json, dispatch, null, true);

              dispatch({ type: "window/close-save-override" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Override Save State / Retry Save
        </button>
        <button
          onClick={() => {
            setTimeout(() => {
              dispatch({ type: "window/close-save-override" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function ExportToQBDialog({ resolve, setPosition, animation, reject }) {
  const dispatch = useDispatch();

  const inventory = useContext(InventoryContext);

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="error-message qb-export-dialog">
      <div className="error-message__message">
        Are you sure you want to export to QuickBooks? This process can take
        several minutes to complete.
        <div style={{ height: "10px" }}></div>
        <div>
          <strong>
            Leave the browser tab open while the process completes.
          </strong>
        </div>
        <div style={{ height: "10px" }}></div>
        <div>
          <strong>You can start another project in a new tab</strong>.
        </div>
      </div>
      <div className="error-message__footer">
        <button
          onClick={() => {
            setTimeout(() => {
              resolve();
              dispatch({ type: "window/export-sales-order-dialog-close" });
              dispatch({
                type: "project/export-to-quickbooks",
                inventory: inventory,
              });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Export to QuickBooks
        </button>
        <a
          target="_blank"
          href="/"
          className="error-message__button button button--primary"
        >
          Open New Tab
        </a>
        <button
          onClick={() => {
            setTimeout(() => {
              reject();
              dispatch({ type: "window/export-sales-order-dialog-close" });
            }, 0);
          }}
          className="error-message__button button button--primary"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default WindowManager;
