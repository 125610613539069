export type AppReducerState = { view: string; currentProject: string };
export type AppReducerAction =
  | { type: "app/project-view"; id: string }
  | { type: "app/projects-view" }
  | { type: "app/open-project"; id: string };

export function appReducer(
  appState: AppReducerState = { view: "login", currentProject: "" },
  action: AppReducerAction
): AppReducerState {
  switch (action.type) {
    case "app/project-view":
      return {
        ...appState,
        view: "single-project",
        currentProject: action.id,
      };
    case "app/projects-view":
      return {
        ...appState,
        view: "create-project",
        currentProject: "",
      };
    case "app/open-project":
      return {
        ...appState,
        view: "single-project",
        currentProject: action.id,
      };
    default:
      return appState;
  }
}
