import { SaltwaterLocationsSchema } from "./parsers/saltwaterLocationsParser";
import {
  getSaltwaterLocationsFromDB,
  saveSaltwaterLocationsInDB,
} from "./saltwaterLocationsDB";

import type { SaltwaterLocations } from "./parsers/saltwaterLocationsParser";

async function fetchSaltwaterLocations() {
  try {
    const saltwaterLocations = await getSaltwaterLocationsFromDB();
    if (saltwaterLocations) {
      return saltwaterLocations;
    } else {
      throw new Error();
    }
  } catch (error) {
    const response = await fetch("/data/saltwaterLocations.json");
    const json = await response.json();
    // const data = SaltwaterLocationsSchema.parse(json);
    // todo: re enable parsing potentially async.
    const data: SaltwaterLocations = json;
    await saveSaltwaterLocationsInDB(data);
    return data;
  }
}

export { fetchSaltwaterLocations };
