// @ts-nocheck
import { Map, OrderedMap, Record } from "immutable";

import { isCloseToValue, roundToHundreth } from ".";
import { cornerDistance } from "../draw";
import { Stairs } from "../entities";
import { getPosts } from "./getPosts";

export const Edge = Record({ from: "", to: "" });
export const GraphNode = Record({ node: null, type: "node", edges: Map() });

export function walkGraph(graph, startNode, filter = [], visitedNodes = Map()) {
  if (visitedNodes.has(startNode.node.id)) {
    return visitedNodes;
  }

  visitedNodes = visitedNodes.set(startNode.node.id, startNode);

  const edges = graph.getIn([startNode.node.id, "edges"]);

  if (edges && edges.size) {
    edges.forEach((edge) => {
      const node = graph.get(edge.to);

      if (!filter.length || filter.includes(node.type)) {
        visitedNodes = walkGraph(graph, node, filter, visitedNodes);
      }
    });
  }

  return visitedNodes;
}

export function getAllGraphs({
  runs,
  corners,
  gates,
  posts,
  stairs,
  handrails,
}) {
  let graph = Map();

  const nodes = Map()
    .merge(runs)
    .merge(gates)
    .merge(posts)
    .merge(stairs)
    .merge(handrails);

  nodes.forEach((node) => {
    if (graph.has(node.id)) {
      return;
    }

    const connectedNodes = getConnectedElements(
      node,
      corners,
      runs,
      gates,
      stairs,
      posts,
      handrails
    );
    graph = graph.merge(connectedNodes);
  });

  return graph;
}

export function nodesToObject(nodes, runs, gates, stairs, posts, handrails) {
  nodes.forEach((node) => {
    if (node.type === "run") {
      runs = runs.set(node.id, node);
    }

    if (node.type === "gate") {
      gates = gates.set(node.id, node);
    }

    if (node.type === "post") {
      posts = posts.set(node.id, node);
    }

    if (node.type === "stairs" || node.type === "landing") {
      stairs = stairs.set(node.id, node);
    }

    if (node.type === "handrail") {
      handrails = handrails.set(node.id, node);
    }
  });

  return {
    runs: runs,
    gates: gates,
    stairs: stairs,
    posts: posts,
    handrails: handrails,
  };
}

export function handleMoveTransformsOfGraph(
  nodes,
  transforms,
  runs,
  gates,
  stairs,
  posts,
  handrails
) {
  const { transformX1, transformY1, transformX2, transformY2 } = transforms;

  return nodes.map((node) => {
    if (node.type === "run") {
      node = runs.get(node.id);

      if (node.stairs) {
        node = transformRunStairs(node, transformX1, transformY1, runs);
        node = transformRunContinuousStairs(
          node,
          transformX1,
          transformY1,
          transformX1,
          transformY1
        );
      }

      node = node
        .set("x1", node.x1 + transformX1)
        .set("y1", node.y1 + transformY1)
        .set("x2", node.x2 + transformX2)
        .set("y2", node.y2 + transformY2);

      return node;
    }

    if (node.type === "post") {
      node = posts.get(node.id);
      node = node.set("x", node.x + transformX2).set("y", node.y + transformY2);

      return node;
    }

    if (node.type === "gate") {
      node = gates.get(node.id);
      node = node
        .set("x1", node.x1 + transformX1)
        .set("y1", node.y1 + transformY1)
        .set("x2", node.x2 + transformX2)
        .set("y2", node.y2 + transformY2);

      return node;
    }

    if (node.type === "stairs" || node.type === "landing") {
      node = stairs.get(node.id);
      node = node
        .set("x1", node.x1 + transformX1)
        .set("y1", node.y1 + transformY1)
        .set("x2", node.x2 + transformX2)
        .set("y2", node.y2 + transformY2);

      return node;
    }

    if (node.type === "handrail") {
      node = handrails.get(node.id);
      node = node
        .set("x1", node.x1 + transformX1)
        .set("y1", node.y1 + transformY1)
        .set("x2", node.x2 + transformX2)
        .set("y2", node.y2 + transformY2);

      if (node.hasIn(["p1", "x"])) {
        node = node
          .setIn(["p1", "x"], node.getIn(["p1", "x"]) + transformX1)
          .setIn(["p1", "y"], node.getIn(["p1", "y"]) + transformY1);

        if (node.hasIn(["p1", "matchingPoint", "x"])) {
          node = node.setIn(
            ["p1", "matchingPoint", "x"],
            node.getIn(["p1", "matchingPoint", "x"]) + transformX1
          );
          node = node.setIn(
            ["p1", "matchingPoint", "y"],
            node.getIn(["p1", "matchingPoint", "y"]) + transformY1
          );
        }
      }

      if (node.hasIn(["p2", "x"])) {
        node = node
          .setIn(["p2", "x"], node.getIn(["p2", "x"]) + transformX2)
          .setIn(["p2", "y"], node.getIn(["p2", "y"]) + transformY2);

        if (node.hasIn(["p2", "matchingPoint", "x"])) {
          node = node.setIn(
            ["p2", "matchingPoint", "x"],
            node.getIn(["p2", "matchingPoint", "x"]) + transformX2
          );
          node = node.setIn(
            ["p2", "matchingPoint", "y"],
            node.getIn(["p2", "matchingPoint", "y"]) + transformY2
          );
        }
      }

      return node;
    }

    return node;
  });
}

export function handleTransformsOfGraph(
  chains,
  root,
  transforms,
  runs,
  gates,
  stairs,
  posts,
  handrails
) {
  let { transformX1, transformY1, transformX2, transformY2 } = transforms;

  let newRuns = runs;
  let newGates = gates;
  let newStairs = stairs;
  let newPosts = posts;
  let newHandrails = handrails;

  // If the root is only attached to objects on its end.
  if (chains.start.size === 0 && chains.end.size !== 0) {
    transformX1 = transformX1 * 2;
    transformY1 = transformY1 * 2;

    if (root.type === "run") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);

      newRuns = newRuns.set(root.id, root);
    }

    if (root.type === "post") {
      root = root.set("x", root.x + transformX1);
      root = root.set("y", root.y + transformY1);

      newPosts = newPosts.set(root.id, root);
    }

    if (root.type === "handrail") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);

      if (root.hasIn(["p1", "x"])) {
        root = root.setIn(["p1", "x"], root.getIn(["p1", "x"]) + transformX1);
        root = root.setIn(["p1", "y"], root.getIn(["p1", "y"]) + transformY1);

        if (root.hasIn(["p1", "matchingPoint", "x"])) {
          root = root.setIn(
            ["p1", "matchingPoint", "x"],
            root.getIn(["p1", "matchingPoint", "x"]) + transformX1
          );
          root = root.setIn(
            ["p1", "matchingPoint", "y"],
            root.getIn(["p1", "matchingPoint", "y"]) + transformY1
          );
        }
      }

      newHandrails = newHandrails.set(root.id, root);
    }
  }

  // If the root is only attached to objects on its start.
  if (chains.end.size === 0 && chains.start.size !== 0) {
    transformX2 = transformX2 * 2;
    transformY2 = transformY2 * 2;

    if (root.type === "run") {
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      newRuns = newRuns.set(root.id, root);
    }

    if (root.type === "post") {
      root = root.set("x", root.x + transformX2);
      root = root.set("y", root.y + transformY2);

      newPosts = newPosts.set(root.id, root);
    }

    if (root.type === "handrail") {
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      if (root.hasIn(["p2", "x"])) {
        root = root.setIn(["p2", "x"], root.getIn(["p1", "x"]) + transformX2);
        root = root.setIn(["p2", "y"], root.getIn(["p1", "y"]) + transformY2);

        if (root.hasIn(["p2", "matchingPoint", "x"])) {
          root = root.setIn(
            ["p2", "matchingPoint", "x"],
            root.getIn(["p2", "matchingPoint", "x"]) + transformX2
          );
          root = root.setIn(
            ["p2", "matchingPoint", "y"],
            root.getIn(["p2", "matchingPoint", "y"]) + transformY2
          );
        }
      }

      newHandrails = newHandrails.set(root.id, root);
    }
  }

  // If the root is attached to objects on both ends.
  if (chains.end.size !== 0 && chains.start.size !== 0) {
    const startRuns = chains.start
      .filter((item) => item.type === "run" && root.id !== item.id)
      .map((run) => {
        run = run
          .set("y1", run.get("y1") + transformY1)
          .set("y2", run.get("y2") + transformY1)
          .set("x1", run.get("x1") + transformX1)
          .set("x2", run.get("x2") + transformX1);

        run = transformRunStairs(run, transformX1, transformY1);
        run = transformRunContinuousStairs(
          run,
          transformX1,
          transformY1,
          transformX1,
          transformY1
        );

        return run;
      });
    const startGates = chains.start
      .filter((item) => item.type === "gate" && root.id !== item.id)
      .map((theGate) => {
        theGate = theGate
          .set("y1", theGate.get("y1") + transformY1)
          .set("y2", theGate.get("y2") + transformY1)
          .set("x1", theGate.get("x1") + transformX1)
          .set("x2", theGate.get("x2") + transformX1);

        return theGate;
      });
    const startStairs = chains.start
      .filter(
        (item) =>
          (item.type === "stairs" || item.type === "landing") &&
          root.id !== item.id
      )
      .map((stairs) => {
        stairs = stairs
          .set("y1", stairs.get("y1") + transformY1)
          .set("y2", stairs.get("y2") + transformY1)
          .set("x1", stairs.get("x1") + transformX1)
          .set("x2", stairs.get("x2") + transformX1);

        return stairs;
      });
    const startPosts = chains.start
      .filter((item) => item.type === "post" && root.id !== item.id)
      .map((post) => {
        post = post.set("x", post.get("x") + transformX1);
        post = post.set("y", post.get("y") + transformY1);

        return post;
      });
    const startHandrails = chains.start
      .filter((item) => item.type === "handrail" && root.id !== item.id)
      .map((handrail) => {
        handrail = handrail
          .set("y1", handrail.get("y1") + transformY1)
          .set("y2", handrail.get("y2") + transformY1)
          .set("x1", handrail.get("x1") + transformX1)
          .set("x2", handrail.get("x2") + transformX1);

        if (handrail.hasIn(["p1", "x"])) {
          handrail = handrail
            .setIn(["p1", "x"], handrail.getIn(["p1", "x"]) + transformX1)
            .setIn(["p1", "y"], handrail.getIn(["p1", "y"]) + transformY1)
            .setIn(
              ["p1", "matchingPoint", "x"],
              handrail.getIn(["p1", "matchingPoint", "x"]) + transformX1
            )
            .setIn(
              ["p1", "matchingPoint", "y"],
              handrail.getIn(["p1", "matchingPoint", "y"]) + transformY1
            );
        }

        if (handrail.hasIn(["p2", "x"])) {
          handrail = handrail
            .setIn(["p2", "x"], handrail.getIn(["p2", "x"]) + transformX1)
            .setIn(["p2", "y"], handrail.getIn(["p2", "y"]) + transformY1)
            .setIn(
              ["p2", "matchingPoint", "x"],
              handrail.getIn(["p2", "matchingPoint", "x"]) + transformX1
            )
            .setIn(
              ["p2", "matchingPoint", "y"],
              handrail.getIn(["p2", "matchingPoint", "y"]) + transformY1
            );
        }
        return handrail;
      });
    const endRuns = chains.end
      .filter((item) => item.type === "run" && root.id !== item.id)
      .map((run) => {
        run = run
          .set("y1", run.get("y1") + transformY2)
          .set("y2", run.get("y2") + transformY2)
          .set("x1", run.get("x1") + transformX2)
          .set("x2", run.get("x2") + transformX2);

        run = transformRunStairs(run, transformX2, transformY2);
        run = transformRunContinuousStairs(
          run,
          transformX2,
          transformY2,
          transformX2,
          transformY2
        );

        return run;
      });
    const endGates = chains.end
      .filter((item) => item.type === "gate" && root.id !== item.id)
      .map((theGate) => {
        theGate = theGate
          .set("y1", theGate.get("y1") + transformY2)
          .set("y2", theGate.get("y2") + transformY2)
          .set("x1", theGate.get("x1") + transformX2)
          .set("x2", theGate.get("x2") + transformX2);

        return theGate;
      });
    const endStairs = chains.end
      .filter(
        (item) =>
          (item.type === "stairs" || item.type === "landing") &&
          root.id !== item.id
      )
      .map((stairs) => {
        stairs = stairs
          .set("y1", stairs.get("y1") + transformY2)
          .set("y2", stairs.get("y2") + transformY2)
          .set("x1", stairs.get("x1") + transformX2)
          .set("x2", stairs.get("x2") + transformX2);

        return stairs;
      });
    const endPosts = chains.end
      .filter((item) => item.type === "post" && root.id !== item.id)
      .map((post) => {
        post = post.set("x", post.get("x") + transformX2);
        post = post.set("y", post.get("y") + transformY2);

        return post;
      });
    const endHandrails = chains.end
      .filter((item) => item.type === "handrail" && root.id !== item.id)
      .map((handrail) => {
        handrail = handrail
          .set("y1", handrail.get("y1") + transformY2)
          .set("y2", handrail.get("y2") + transformY2)
          .set("x1", handrail.get("x1") + transformX2)
          .set("x2", handrail.get("x2") + transformX2);

        if (handrail.hasIn(["p1", "x"])) {
          handrail = handrail
            .setIn(["p1", "x"], handrail.getIn(["p1", "x"]) + transformX2)
            .setIn(["p1", "y"], handrail.getIn(["p1", "y"]) + transformY2)
            .setIn(
              ["p1", "matchingPoint", "x"],
              handrail.getIn(["p1", "matchingPoint", "x"]) + transformX2
            )
            .setIn(
              ["p1", "matchingPoint", "y"],
              handrail.getIn(["p1", "matchingPoint", "y"]) + transformY2
            );
        }

        if (handrail.hasIn(["p2", "x"])) {
          handrail = handrail
            .setIn(["p2", "x"], handrail.getIn(["p2", "x"]) + transformX2)
            .setIn(["p2", "y"], handrail.getIn(["p2", "y"]) + transformY2)
            .setIn(
              ["p2", "matchingPoint", "x"],
              handrail.getIn(["p2", "matchingPoint", "x"]) + transformX2
            )
            .setIn(
              ["p2", "matchingPoint", "y"],
              handrail.getIn(["p2", "matchingPoint", "y"]) + transformY2
            );
        }
        return handrail;
      });

    newRuns = newRuns.merge(startRuns).merge(endRuns);
    newGates = newGates.merge(startGates).merge(endGates);
    newStairs = newStairs.merge(startStairs).merge(endStairs);
    newPosts = newPosts.merge(startPosts).merge(endPosts);
    newHandrails = newHandrails.merge(startHandrails).merge(endHandrails);

    if (root.type === "run") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      newRuns = newRuns.set(root.id, root);
    }

    if (root.type === "gate") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      newGates = newGates.set(root.id, root);
    }

    if (root.type === "stairs" || root.type === "landing") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      newStairs = newStairs.set(root.id, root);
    }

    if (root.type === "post") {
      root = root.set("x", root.x + transformX1);
      root = root.set("y", root.y + transformY1);

      newPosts = newPosts.set(root.id, root);
    }

    if (root.type === "handrail") {
      root = root.set("x1", root.x1 + transformX1);
      root = root.set("y1", root.y1 + transformY1);
      root = root.set("x2", root.x2 + transformX2);
      root = root.set("y2", root.y2 + transformY2);

      newHandrails = newHandrails.set(root.id, root);
    }
  }

  return {
    newRuns: newRuns,
    newGates: newGates,
    newStairs: newStairs,
    newPosts: newPosts,
    newHandrails: newHandrails,
  };
}

export function transformRunContinuousStairs(
  run,
  transformX1,
  transformY1,
  transformX2,
  transformY2
) {
  if (run.stairs) {
    if (run.stairs.continuousStairs) {
      let continuousStairs = OrderedMap(run.stairs.continuousStairs).map(
        (stair) => Stairs(stair)
      );

      continuousStairs = continuousStairs.map((stair) => {
        stair = stair.set("x1", stair.x1 + transformX1);
        stair = stair.set("y1", stair.y1 + transformY1);
        stair = stair.set("x2", stair.x2 + transformX2);
        stair = stair.set("y2", stair.y2 + transformY2);

        return stair;
      });

      run = run.setIn(["stairs", "continuousStairs"], continuousStairs);
    }
  }

  return run;
}

export function transformRunStairs(run, transformX, transformY) {
  if (run.stairs) {
    run = run
      .setIn(
        ["stairs", "startPost", "x"],
        run.getIn(["stairs", "startPost", "x"]) + transformX
      )
      .setIn(
        ["stairs", "startPost", "y"],
        run.getIn(["stairs", "startPost", "y"]) + transformY
      )
      .setIn(
        ["stairs", "endPost", "x"],
        run.getIn(["stairs", "endPost", "x"]) + transformX
      )
      .setIn(
        ["stairs", "endPost", "y"],
        run.getIn(["stairs", "endPost", "y"]) + transformY
      )
      .setIn(
        ["stairs", "snapped", "x1"],
        run.getIn(["stairs", "snapped", "x1"]) + transformX
      )
      .setIn(
        ["stairs", "snapped", "y1"],
        run.getIn(["stairs", "snapped", "y1"]) + transformY
      )
      .setIn(
        ["stairs", "snapped", "x2"],
        run.getIn(["stairs", "snapped", "x2"]) + transformX
      )
      .setIn(
        ["stairs", "snapped", "y2"],
        run.getIn(["stairs", "snapped", "y2"]) + transformY
      )
      .setIn(
        ["stairs", "snapLineCorners", "TL", "x"],
        run.getIn(["stairs", "snapLineCorners", "TL", "x"]) + transformX
      )
      .setIn(
        ["stairs", "snapLineCorners", "TL", "y"],
        run.getIn(["stairs", "snapLineCorners", "TL", "y"]) + transformY
      )
      .setIn(
        ["stairs", "snapLineCorners", "TR", "x"],
        run.getIn(["stairs", "snapLineCorners", "TR", "x"]) + transformX
      )
      .setIn(
        ["stairs", "snapLineCorners", "TR", "y"],
        run.getIn(["stairs", "snapLineCorners", "TR", "y"]) + transformY
      )
      .setIn(
        ["stairs", "snapLineCorners", "BL", "x"],
        run.getIn(["stairs", "snapLineCorners", "BL", "x"]) + transformX
      )
      .setIn(
        ["stairs", "snapLineCorners", "BL", "y"],
        run.getIn(["stairs", "snapLineCorners", "BL", "y"]) + transformY
      )
      .setIn(
        ["stairs", "snapLineCorners", "BR", "x"],
        run.getIn(["stairs", "snapLineCorners", "BR", "x"]) + transformX
      )
      .setIn(
        ["stairs", "snapLineCorners", "BR", "y"],
        run.getIn(["stairs", "snapLineCorners", "BR", "y"]) + transformY
      );
  }

  return run;
}

/**
 * Gets the sub graphs of items attached to the run.
 * Runs have an end and a start which can attach to other runs or gates.
 * Runs can also be attached to stairs directly.
 */
export function getElementChainSplitFromRun(run, graph) {
  let start = Map(),
    end = Map(),
    stairs = Map();

  const node = graph.get(run.id);

  if (node) {
    const seen = Map({ [run.id]: true });

    start = getChainFromStartOfRun(node, graph, seen).chain;
    end = getChainFromEndOfRun(node, graph, seen).chain;
  }

  return {
    start,
    end,
    stairs,
  };
}

export function getElementChainSplitFromHandrail(handrail, graph) {
  let start = Map(),
    end = Map(),
    stairs = Map();

  const node = graph.get(handrail.id);

  if (node) {
    const seen = Map({ [handrail.id]: true });

    start = getChainFromStartOfRun(node, graph, seen).chain;
    end = getChainFromEndOfRun(node, graph, seen).chain;
  }

  return {
    start,
    end,
    stairs,
  };
}

export function getChainOfEntities(node, graph, seen, chain = Map()) {
  if (!node) {
    return { chain, seen };
  }

  if (node.type === "run") {
    const toRunStartEdge = node.getIn(["edges", "run-start-to-run"]);

    if (
      toRunStartEdge &&
      !seen.has(toRunStartEdge.to) &&
      graph.has(toRunStartEdge.to)
    ) {
      chain = chain.set(
        toRunStartEdge.to,
        graph.getIn([toRunStartEdge.to, "node"])
      );
      seen = seen.set(toRunStartEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunStartEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toRunEndEdge = node.getIn(["edges", "run-end-to-run"]);

    if (
      toRunEndEdge &&
      !seen.has(toRunEndEdge.to) &&
      graph.has(toRunEndEdge.to)
    ) {
      chain = chain.set(
        toRunEndEdge.to,
        graph.getIn([toRunEndEdge.to, "node"])
      );
      seen = seen.set(toRunEndEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEndEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toGateStartEdge = node.getIn(["edges", "run-start-to-gate"]);

    if (
      toGateStartEdge &&
      !seen.has(toGateStartEdge.to) &&
      graph.has(toGateStartEdge.to)
    ) {
      chain = chain.set(
        toGateStartEdge.to,
        graph.getIn([toGateStartEdge.to, "node"])
      );
      seen = seen.set(toGateStartEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toGateStartEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toGateEndEdge = node.getIn(["edges", "run-end-to-gate"]);

    if (
      toGateEndEdge &&
      !seen.has(toGateEndEdge.to) &&
      graph.has(toGateEndEdge.to)
    ) {
      chain = chain.set(
        toGateEndEdge.to,
        graph.getIn([toGateEndEdge.to, "node"])
      );
      seen = seen.set(toGateEndEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toGateEndEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toStairsEdge = node.getIn(["edges", "run-to-stairs"]);

    if (
      toStairsEdge &&
      !seen.has(toStairsEdge.to) &&
      graph.has(toStairsEdge.to)
    ) {
      chain = chain.set(
        toStairsEdge.to,
        graph.getIn([toStairsEdge.to, "node"])
      );
      seen = seen.set(toStairsEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toStairsEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toHandrailEdge = node.getIn(["edges", "run-to-automatic-handrail"]);

    // Handrails that are automatically drawn on run.
    if (
      toHandrailEdge &&
      !seen.has(toHandrailEdge.to) &&
      graph.has(toHandrailEdge.to)
    ) {
      chain = chain.set(
        toHandrailEdge.to,
        graph.getIn([toHandrailEdge.to, "node"])
      );
      seen = seen.set(toHandrailEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toHandrailEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    // Handrails that are manually drawn on run but both points are attached to run.
    node
      .get("edges")
      .filter((_edge, key) => {
        return key.startsWith("run-to-drawn-attached-handrail");
      })
      .forEach((handrailEdge) => {
        if (
          handrailEdge &&
          !seen.has(handrailEdge.to) &&
          graph.has(handrailEdge.to)
        ) {
          chain = chain.set(
            handrailEdge.to,
            graph.getIn([handrailEdge.to, "node"])
          );
          seen = seen.set(handrailEdge.to, true);

          const next = getChainOfEntities(
            graph.get(handrailEdge.to),
            graph,
            seen,
            chain
          );

          seen = next.seen;
          chain = next.chain;
        }
      });

    // Handrails that are drawn but attached at one point of the run.
    node
      .get("edges")
      .filter((_edge, key) => {
        return key.startsWith("run-to-drawn-handrail");
      })
      .forEach((handrailEdge) => {
        if (
          handrailEdge &&
          !seen.has(handrailEdge.to) &&
          graph.has(handrailEdge.to)
        ) {
          chain = chain.set(
            handrailEdge.to,
            graph.getIn([handrailEdge.to, "node"])
          );
          seen = seen.set(handrailEdge.to, true);

          const next = getChainOfEntities(
            graph.get(handrailEdge.to),
            graph,
            seen,
            chain
          );

          seen = next.seen;
          chain = next.chain;
        }
      });
  }

  if (node.type === "gate") {
    const toRunFromP1Edge = node.getIn(["edges", "gate-p1-to-run"]);

    if (
      toRunFromP1Edge &&
      !seen.has(toRunFromP1Edge.to) &&
      graph.has(toRunFromP1Edge.to)
    ) {
      chain = chain.set(
        toRunFromP1Edge.to,
        graph.getIn([toRunFromP1Edge.to, "node"])
      );
      seen = seen.set(toRunFromP1Edge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunFromP1Edge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toRunFromP2Edge = node.getIn(["edges", "gate-p2-to-run"]);

    if (
      toRunFromP2Edge &&
      !seen.has(toRunFromP2Edge.to) &&
      graph.has(toRunFromP2Edge.to)
    ) {
      chain = chain.set(
        toRunFromP2Edge.to,
        graph.getIn([toRunFromP2Edge.to, "node"])
      );
      seen = seen.set(toRunFromP2Edge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunFromP2Edge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toPostFromP1Edge = node.getIn(["edges", "gate-p1-to-post"]);

    if (
      toPostFromP1Edge &&
      !seen.has(toPostFromP1Edge.to) &&
      graph.has(toPostFromP1Edge.to)
    ) {
      chain = chain.set(
        toPostFromP1Edge.to,
        graph.getIn([toPostFromP1Edge.to, "node"])
      );
      seen = seen.set(toPostFromP1Edge.to, true);

      const next = getChainOfEntities(
        graph.get(toPostFromP1Edge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toPostFromP2Edge = node.getIn(["edges", "gate-p2-to-post"]);

    if (
      toPostFromP2Edge &&
      !seen.has(toPostFromP2Edge.to) &&
      graph.has(toPostFromP2Edge.to)
    ) {
      chain = chain.set(
        toPostFromP2Edge.to,
        graph.getIn([toPostFromP2Edge.to, "node"])
      );
      seen = seen.set(toPostFromP2Edge.to, true);

      const next = getChainOfEntities(
        graph.get(toPostFromP2Edge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  if (node.type === "stairs" || node.type === "landing") {
    const stairsToRunKeys = [
      "BL-TL-stairs-to-run",
      "TL-BL-stairs-to-run",
      "BR-TR-stairs-to-run",
      "TR-BR-stairs-to-run",
      "BL-BR-stairs-to-run",
      "BR-BL-stairs-to-run",
      "TL-TR-stairs-to-run",
      "TR-TL-stairs-to-run",
    ];

    stairsToRunKeys.forEach((key) => {
      const toRunEdge = node.getIn(["edges", key]);

      if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
        chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
        seen = seen.set(toRunEdge.to, true);

        const next = getChainOfEntities(
          graph.get(toRunEdge.to),
          graph,
          seen,
          chain
        );

        seen = next.seen;
        chain = next.chain;
      }
    });
  }

  if (node.type === "post") {
    const toGateEdges = node.getIn(["edges"]);

    toGateEdges.forEach((toGateEdge) => {
      if (toGateEdge && !seen.has(toGateEdge.to) && graph.has(toGateEdge.to)) {
        chain = chain.set(toGateEdge.to, graph.getIn([toGateEdge.to, "node"]));
        seen = seen.set(toGateEdge.to, true);

        const next = getChainOfEntities(
          graph.get(toGateEdge.to),
          graph,
          seen,
          chain
        );

        seen = next.seen;
        chain = next.chain;
      }
    });
  }

  if (node.type === "handrail") {
    const toRunEdge = node.getIn(["edges", "handrail-to-run"]);

    // Direct connection to run.
    if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
      chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
      seen = seen.set(toRunEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    // Connection to from start of handrail to run.
    const toRunFromStartEdge = node.getIn(["edges", "handrail-start-to-run"]);

    if (
      toRunFromStartEdge &&
      !seen.has(toRunFromStartEdge.to) &&
      graph.has(toRunFromStartEdge.to)
    ) {
      chain = chain.set(
        toRunFromStartEdge.to,
        graph.getIn([toRunFromStartEdge.to, "node"])
      );
      seen = seen.set(toRunFromStartEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunFromStartEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    // Connection from end of handrail to run.
    const toRunFromEndEdge = node.getIn(["edges", "handrail-end-to-run"]);

    if (
      toRunFromEndEdge &&
      !seen.has(toRunFromEndEdge.to) &&
      graph.has(toRunFromEndEdge.to)
    ) {
      chain = chain.set(
        toRunFromEndEdge.to,
        graph.getIn([toRunFromEndEdge.to, "node"])
      );
      seen = seen.set(toRunFromEndEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunFromEndEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    // Connection from start of handrail to other handrail.
    const toHandrailFromStartEdge = node.getIn([
      "edges",
      "handrail-start-to-handrail",
    ]);

    if (
      toHandrailFromStartEdge &&
      !seen.has(toHandrailFromStartEdge.to) &&
      graph.has(toHandrailFromStartEdge.to)
    ) {
      chain = chain.set(
        toHandrailFromStartEdge.to,
        graph.getIn([toHandrailFromStartEdge.to, "node"])
      );
      seen = seen.set(toHandrailFromStartEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toHandrailFromStartEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    // Connection from end of handrail to other handrail.
    const toHandrailFromEndEdge = node.getIn([
      "edges",
      "handrail-end-to-handrail",
    ]);

    if (
      toHandrailFromEndEdge &&
      !seen.has(toHandrailFromEndEdge.to) &&
      graph.has(toHandrailFromEndEdge.to)
    ) {
      chain = chain.set(
        toHandrailFromEndEdge.to,
        graph.getIn([toHandrailFromEndEdge.to, "node"])
      );
      seen = seen.set(toHandrailFromEndEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toHandrailFromEndEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  return {
    chain,
    seen,
  };
}

function getChainFromStartOfRun(node, graph, seen, chain = Map()) {
  if (node.type === "run") {
    const toRunEdge = node.getIn(["edges", "run-start-to-run"]);

    if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
      chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
      seen = seen.set(toRunEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toGateEdge = node.getIn(["edges", "run-start-to-gate"]);

    if (toGateEdge && !seen.has(toGateEdge.to) && graph.has(toGateEdge.to)) {
      chain = chain.set(toGateEdge.to, graph.getIn([toGateEdge.to, "node"]));
      seen = seen.set(toGateEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toGateEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  if (node.type === "handrail") {
    const toRunEdge = node.getIn(["edges", "handrail-start-to-run"]);

    if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
      chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
      seen = seen.set(toRunEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toHandrailEdge = node.getIn(["edges", "handrail-start-to-handrail"]);

    if (
      toHandrailEdge &&
      !seen.has(toHandrailEdge.to) &&
      graph.has(toHandrailEdge.to)
    ) {
      chain = chain.set(
        toHandrailEdge.to,
        graph.getIn([toHandrailEdge.to, "node"])
      );
      seen = seen.set(toHandrailEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toHandrailEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  return {
    chain,
    seen,
  };
}

function getChainFromEndOfRun(node, graph, seen, chain = Map()) {
  if (node.type === "run") {
    const toRunEdge = node.getIn(["edges", "run-end-to-run"]);

    if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
      chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
      seen = seen.set(toRunEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toGateEdge = node.getIn(["edges", "run-end-to-gate"]);

    if (toGateEdge && !seen.has(toGateEdge.to) && graph.has(toGateEdge.to)) {
      chain = chain.set(toGateEdge.to, graph.getIn([toGateEdge.to, "node"]));
      seen = seen.set(toGateEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toGateEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  if (node.type === "handrail") {
    const toRunEdge = node.getIn(["edges", "handrail-end-to-run"]);

    if (toRunEdge && !seen.has(toRunEdge.to) && graph.has(toRunEdge.to)) {
      chain = chain.set(toRunEdge.to, graph.getIn([toRunEdge.to, "node"]));
      seen = seen.set(toRunEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toRunEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }

    const toHandrailEdge = node.getIn(["edges", "handrail-end-to-handrail"]);

    if (
      toHandrailEdge &&
      !seen.has(toHandrailEdge.to) &&
      graph.has(toHandrailEdge.to)
    ) {
      chain = chain.set(
        toHandrailEdge.to,
        graph.getIn([toHandrailEdge.to, "node"])
      );
      seen = seen.set(toHandrailEdge.to, true);

      const next = getChainOfEntities(
        graph.get(toHandrailEdge.to),
        graph,
        seen,
        chain
      );

      seen = next.seen;
      chain = next.chain;
    }
  }

  return {
    chain,
    seen,
  };
}

export function getNodesOfGraph(graph) {
  return graph.map((node) => node.get("node"));
}

/**
 * Only grab runs that are connected to each other.
 *
 * This is used for getRunIndex().
 */
export function getConnectedRuns(runs, corners) {
  let groups = OrderedMap();
  let graph = OrderedMap();

  let index = 0;

  runs.forEach((run) => {
    if (!graph.has(run.id) && !groupHasKey(groups, run.id)) {
      graph = OrderedMap();
      graph = getConnectedRunsToRun(run, runs, corners, graph);

      groups = groups.set(index, graph);

      index++;
    }
  });

  return groups;
}

function groupHasKey(groups, key) {
  return groups.reduce((hasKey, map) => {
    if (map.has(key)) {
      hasKey = true;
    }

    return hasKey;
  }, false);
}

export function getConnectedRunsToRun(
  node,
  runs,
  corners,
  graph = OrderedMap()
) {
  if (!node || graph.has(node.id)) {
    return graph;
  }

  graph = graph.set(
    node.id,
    GraphNode({ node: node, type: node.type, edges: Map() })
  );

  if (node.type === "run") {
    const run = node;

    if (corners.length) {
      corners.forEach((corner) => {
        if (corner.points[run.id]) {
          Object.values(corner.points).forEach((point) => {
            if (point.id !== run.id) {
              const theRun = runs.get(point.id);
              const edgeKey = getRunToRunEdgeKey(run, corner);
              graph = graph.setIn(
                [run.id, "edges", edgeKey],
                Edge({ from: run.id, to: theRun.id })
              );

              if (!graph.has(theRun.id)) {
                graph = getConnectedRunsToRun(theRun, runs, corners, graph);
              }
            }
          });
        }
      });
    }
  }

  return graph;
}

export function sortGraphOfRuns(nodes, runs, corners) {
  let graph = OrderedMap();

  const endRun = nodes.find(
    (run) => run.edges.size === 1 || run.edges.size === 0
  );

  if (endRun) {
    graph = getConnectedRunsToRun(endRun.node, runs, corners, graph);
  }

  return graph;
}

export function walkAllGraphsWithStepFunction(
  graph,
  filter = [],
  runIndex = 0,
  visitedNodes = OrderedMap()
) {
  graph.forEach((node) => {
    visitedNodes = walkGraphWithStepFunction(
      graph,
      node,
      filter,
      runIndex,
      visitedNodes
    );
  });

  return visitedNodes;
}

export function walkGraphWithStepFunction(
  graph,
  startNode,
  filter = [],
  runIndex = 0,
  visitedNodes = OrderedMap()
) {
  if (visitedNodes.has(startNode.node.id)) {
    return visitedNodes;
  }

  visitedNodes = visitedNodes.set(startNode.node.id, {
    node: startNode.node,
    index: runIndex,
  });

  const edges = graph.getIn([startNode.node.id, "edges"]);

  if (edges && edges.size) {
    edges.forEach((edge) => {
      const node = graph.get(edge.to);

      if (!filter.length || filter.includes(node.type)) {
        if (!visitedNodes.has(edge.to)) {
          runIndex++;

          visitedNodes = walkGraphWithStepFunction(
            graph,
            node,
            filter,
            runIndex,
            visitedNodes
          );
        }
      }
    });
  }

  return visitedNodes;
}

export function getConnectedElements(
  node,
  corners,
  runs,
  gates,
  stairs,
  posts,
  handrails,
  graph = Map()
) {
  if (!node || graph.has(node.id)) {
    return graph;
  }

  graph = graph.set(
    node.id,
    GraphNode({ node: node, type: node.type, edges: Map() })
  );

  if (node.type === "run") {
    const run = node;

    if (run.stairs) {
      const theStairs = stairs.get(run.stairs.stairsIndex);

      graph = graph.setIn(
        [run.id, "edges", "run-to-stairs"],
        Edge({ from: run.id, to: theStairs.id })
      );

      if (!graph.has(theStairs.id)) {
        graph = getConnectedElements(
          theStairs,
          corners,
          runs,
          gates,
          stairs,
          posts,
          handrails,
          graph
        );
      }
    }

    if (corners.length) {
      corners.forEach((corner) => {
        if (corner.points[run.id]) {
          Object.values(corner.points).forEach((point) => {
            if (point.id !== run.id) {
              const theRun = runs.get(point.id);
              const edgeKey = getRunToRunEdgeKey(run, corner);
              graph = graph.setIn(
                [run.id, "edges", edgeKey],
                Edge({ from: run.id, to: theRun.id })
              );

              if (!graph.has(theRun.id)) {
                graph = getConnectedElements(
                  theRun,
                  corners,
                  runs,
                  gates,
                  stairs,
                  posts,
                  handrails,
                  graph
                );
              }
            }
          });
        }
      });
    }

    if (gates.size) {
      gates.forEach((gate) => {
        if (gate.p1.runIndex === run.id) {
          const runToGateEdgeKey = getRunToGateEdgeKey(gate, "p1");

          // Add run to gate connection.
          graph = graph.setIn(
            [run.id, "edges", runToGateEdgeKey],
            Edge({ from: run.id, to: gate.id })
          );

          if (!graph.has(gate.id)) {
            // Add gate.
            graph = getConnectedElements(
              gate,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }

        if (gate.p2.runIndex === run.id) {
          const runToGateEdgeKey = getRunToGateEdgeKey(gate, "p2");

          // Add run to gate connection.
          graph = graph.setIn(
            [run.id, "edges", runToGateEdgeKey],
            Edge({ from: run.id, to: gate.id })
          );

          if (!graph.has(gate.id)) {
            // Add gate.
            graph = getConnectedElements(
              gate,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }
      });
    }

    if (handrails.size) {
      handrails.forEach((handrail) => {
        if (handrail.run === run.id) {
          const runToHandrailEdgeKey = "run-to-automatic-handrail";

          // Add run to handrail connection.
          graph = graph.setIn(
            [run.id, "edges", runToHandrailEdgeKey],
            Edge({ from: run.id, to: handrail.id })
          );

          if (!graph.has(handrail.id)) {
            // Add handrail.
            graph = getConnectedElements(
              handrail,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }

        if (
          handrail.run === null &&
          handrail.p1.run === run.id &&
          handrail.p2.run === run.id
        ) {
          const runToHandrailEdgeKey = getRunDrawnAttachedHandrailKey(handrail);

          // Add run to handrail connection.
          graph = graph.setIn(
            [run.id, "edges", runToHandrailEdgeKey],
            Edge({ from: run.id, to: handrail.id })
          );

          if (!graph.has(handrail.id)) {
            // Add handrail.
            graph = getConnectedElements(
              handrail,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }

        if (
          handrail.run === null &&
          (handrail.p1.run === run.id || handrail.p2.run === run.id) &&
          handrail.p2.run !== handrail.p1.run
        ) {
          const runToHandrailEdgeKey = getRunDrawnHandrailKey(handrail);

          // Add run to handrail connection.
          graph = graph.setIn(
            [run.id, "edges", runToHandrailEdgeKey],
            Edge({ from: run.id, to: handrail.id })
          );

          if (!graph.has(handrail.id)) {
            // Add handrail.
            graph = getConnectedElements(
              handrail,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }
      });
    }
  }

  if (node.type === "gate") {
    const gate = node;

    if (gate.p1.runIndex) {
      const theRun = runs.get(gate.p1.runIndex);
      const gateEdgeKey = getGateEdgeKey("p1");

      graph = graph.setIn(
        [gate.id, "edges", gateEdgeKey],
        Edge({ from: gate.id, to: theRun.id })
      );

      if (theRun && !graph.has(theRun.id)) {
        graph = getConnectedElements(
          theRun,
          corners,
          runs,
          gates,
          stairs,
          posts,
          handrails,
          graph
        );
      }
    } else {
      if (gate.p1.postIndex) {
        const thePost = posts.get(gate.p1.postIndex);
        const gateEdgeKey = getGateToPostEdgeKey("p1");

        graph = graph.setIn(
          [gate.id, "edges", gateEdgeKey],
          Edge({ from: gate.id, to: thePost.id })
        );

        if (thePost && !graph.has(thePost.id)) {
          graph = getConnectedElements(
            thePost,
            corners,
            runs,
            gates,
            stairs,
            posts,
            handrails,
            graph
          );
        }
      }
    }

    if (gate.p2.runIndex) {
      const theRun = runs.get(gate.p2.runIndex);
      const gateEdgeKey = getGateEdgeKey("p2");

      graph = graph.setIn(
        [gate.id, "edges", gateEdgeKey],
        Edge({ from: gate.id, to: theRun.id })
      );

      if (theRun && !graph.has(theRun.id)) {
        graph = getConnectedElements(
          theRun,
          corners,
          runs,
          gates,
          stairs,
          posts,
          handrails,
          graph
        );
      }
    } else {
      if (gate.p2.postIndex) {
        const thePost = posts.get(gate.p2.postIndex);
        const gateEdgeKey = getGateToPostEdgeKey("p2");

        graph = graph.setIn(
          [gate.id, "edges", gateEdgeKey],
          Edge({ from: gate.id, to: thePost.id })
        );

        if (thePost && !graph.has(thePost.id)) {
          graph = getConnectedElements(
            thePost,
            corners,
            runs,
            gates,
            stairs,
            posts,
            handrails,
            graph
          );
        }
      }
    }
  }

  if (node.type === "post") {
    const post = node;

    if (gates.size) {
      gates.forEach((gate) => {
        if (gate.p1.postIndex === post.id) {
          const gateEdgeKey = getPostToGateEdgeKey("p1", gate);

          graph = graph.setIn(
            [post.id, "edges", gateEdgeKey],
            Edge({ from: post.id, to: gate.id })
          );

          if (!graph.has(gate.id)) {
            graph = getConnectedElements(
              gate,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }

        if (gate.p2.postIndex === post.id) {
          const gateEdgeKey = getPostToGateEdgeKey("p2", gate);

          graph = graph.setIn(
            [post.id, "edges", gateEdgeKey],
            Edge({ from: post.id, to: gate.id })
          );

          if (!graph.has(gate.id)) {
            graph = getConnectedElements(
              gate,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }
      });
    }
  }

  if (node.type === "stairs" || node.type === "landing") {
    const theStairs = node;

    if (runs.size) {
      runs.forEach((run) => {
        if (run.stairs && run.stairs.stairsIndex === theStairs.id) {
          const stairsEdgeKey = getStairsRunEdgeKey(run);

          graph = graph.setIn(
            [theStairs.id, "edges", stairsEdgeKey],
            Edge({ from: theStairs.id, to: run.id })
          );

          if (!graph.has(run.id)) {
            graph = getConnectedElements(
              run,
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        }
      });
    }

    if (stairs.size) {
      if (theStairs.stairsEdges.size) {
        theStairs.stairsEdges.forEach((stairsEdge, stairsEdgeKey) => {
          graph = graph.setIn(
            [theStairs.id, "edges", stairsEdgeKey],
            Edge({ from: theStairs.id, to: stairsEdge.to })
          );

          if (!graph.has(stairsEdge.to)) {
            graph = getConnectedElements(
              stairs.get(stairsEdge.to),
              corners,
              runs,
              gates,
              stairs,
              posts,
              handrails,
              graph
            );
          }
        });
      }
    }

    if (handrails.size) {
      handrails.forEach((handrail) => {
        if (!handrail.p1.run && !handrail.p2.run) {
          if (
            handrail.p1.stairsIndex &&
            handrail.p2.stairsIndex &&
            handrail.p1.stairsIndex === handrail.p2.stairsIndex
          ) {
            const startCorner = handrail.p1.cornerType;
            const endCorner = handrail.p2.cornerType;
            const handrailEdgeKey = `${startCorner}-${endCorner}-stairs-to-handrail`;

            graph = graph.setIn(
              [theStairs.id, "edges", handrailEdgeKey],
              Edge({ from: theStairs.id, to: handrail.id })
            );

            if (!graph.has(handrail.id)) {
              graph = getConnectedElements(
                handrail,
                corners,
                runs,
                gates,
                stairs,
                posts,
                handrails,
                graph
              );
            }
          }
        }
      });
    }
  }

  if (node.type === "handrail") {
    const handrail = node;

    if (handrail.run) {
      const theRun = runs.get(handrail.run);

      const runEdgeKey = "handrail-to-run";

      if (theRun) {
        graph = graph.setIn(
          [handrail.id, "edges", runEdgeKey],
          Edge({ from: handrail.id, to: theRun.id })
        );

        if (!graph.has(theRun.id)) {
          graph = getConnectedElements(
            theRun,
            corners,
            runs,
            gates,
            stairs,
            posts,
            handrails,
            graph
          );
        }
      }
    }

    // Attached handrail, handdrawn.
    if (
      handrail.run === null &&
      handrail.p1.run &&
      handrail.p2.run &&
      handrail.p1.run === handrail.p2.run
    ) {
      const theRun = runs.get(handrail.p1.run);

      const runEdgeKey = "handrail-to-run";

      graph = graph.setIn(
        [handrail.id, "edges", runEdgeKey],
        Edge({ from: handrail.id, to: theRun.id })
      );

      if (!graph.has(theRun.id)) {
        graph = getConnectedElements(
          theRun,
          corners,
          runs,
          gates,
          stairs,
          posts,
          handrails,
          graph
        );
      }
    }

    // Attached handrail but only on one end of handrail.
    if (handrail.run === null && handrail.p1.run !== handrail.p2.run) {
      const theRun1 = runs.get(handrail.p1.run);
      const theRun2 = runs.get(handrail.p2.run);

      if (theRun1) {
        const runEdgeKey = "handrail-start-to-run";

        graph = graph.setIn(
          [handrail.id, "edges", runEdgeKey],
          Edge({ from: handrail.id, to: theRun1.id })
        );

        if (!graph.has(theRun1.id)) {
          graph = getConnectedElements(
            theRun1,
            corners,
            runs,
            gates,
            stairs,
            posts,
            handrails,
            graph
          );
        }
      }

      if (theRun2) {
        const runEdgeKey = "handrail-end-to-run";

        graph = graph.setIn(
          [handrail.id, "edges", runEdgeKey],
          Edge({ from: handrail.id, to: theRun2.id })
        );

        if (!graph.has(theRun2.id)) {
          graph = getConnectedElements(
            theRun2,
            corners,
            runs,
            gates,
            stairs,
            posts,
            handrails,
            graph
          );
        }
      }

      if (handrails.size) {
        handrails.forEach((theHandrail) => {
          if (theHandrail.id !== handrail.id) {
            if (
              handrail.p1.run &&
              (handrail.p1.run === theHandrail.p1.run ||
                handrail.p1.run === theHandrail.p2.run)
            ) {
              // If p1 of the handrail is connected to the same post as the other handrail add edge.
              if (
                (handrail.p1.run === theHandrail.p1.run &&
                  handrail.p1.postIndex === theHandrail.p1.postIndex) ||
                (handrail.p1.run === theHandrail.p2.run &&
                  handrail.p1.postIndex === theHandrail.p2.postIndex)
              ) {
                const handrailEdgeKey = "handrail-start-to-handrail";

                graph = graph.setIn(
                  [handrail.id, "edges", handrailEdgeKey],
                  Edge({ from: handrail.id, to: theHandrail.id })
                );

                if (!graph.has(theHandrail.id)) {
                  graph = getConnectedElements(
                    theHandrail,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    graph
                  );
                }
              }
            }

            if (
              handrail.p2.run &&
              (handrail.p2.run === theHandrail.p1.run ||
                handrail.p2.run === theHandrail.p2.run)
            ) {
              // If p2 of the handrail is connected to the same post as the other handrail add edge.
              if (
                (handrail.p2.run === theHandrail.p1.run &&
                  handrail.p2.postIndex === theHandrail.p1.postIndex) ||
                (handrail.p2.run === theHandrail.p2.run &&
                  handrail.p2.postIndex === theHandrail.p2.postIndex)
              ) {
                const handrailEdgeKey = "handrail-end-to-handrail";

                graph = graph.setIn(
                  [handrail.id, "edges", handrailEdgeKey],
                  Edge({ from: handrail.id, to: theHandrail.id })
                );

                if (!graph.has(theHandrail.id)) {
                  graph = getConnectedElements(
                    theHandrail,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    graph
                  );
                }
              }
            }
          }
        });
      }
    }

    if (handrail.run === null && (!handrail.p1.run || !handrail.p2.run)) {
      // If the handrail could be connected to a free handrail.
      if (handrails.size) {
        handrails.forEach((theHandrail) => {
          if (theHandrail.id !== handrail.id) {
            if (
              theHandrail.run === null &&
              (!theHandrail.p1.run || !theHandrail.p2.run)
            ) {
              if (
                !handrail.p1.run &&
                ((isCloseToValue(handrail.x1, theHandrail.x1, 1) &&
                  isCloseToValue(handrail.y1, theHandrail.y1, 1)) ||
                  (isCloseToValue(handrail.x1, theHandrail.x2, 1) &&
                    isCloseToValue(handrail.y1, theHandrail.y2, 1)))
              ) {
                const handrailEdgeKey = "handrail-start-to-handrail";

                graph = graph.setIn(
                  [handrail.id, "edges", handrailEdgeKey],
                  Edge({ from: handrail.id, to: theHandrail.id })
                );

                if (!graph.has(theHandrail.id)) {
                  graph = getConnectedElements(
                    theHandrail,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    graph
                  );
                }
              }

              if (
                !handrail.p2.run &&
                ((isCloseToValue(handrail.x2, theHandrail.x1, 1) &&
                  isCloseToValue(handrail.y2, theHandrail.y1, 1)) ||
                  (isCloseToValue(handrail.x2, theHandrail.x2, 1) &&
                    isCloseToValue(handrail.y2, theHandrail.y2, 1)))
              ) {
                const handrailEdgeKey = "handrail-end-to-handrail";

                graph = graph.setIn(
                  [handrail.id, "edges", handrailEdgeKey],
                  Edge({ from: handrail.id, to: theHandrail.id })
                );

                if (!graph.has(theHandrail.id)) {
                  graph = getConnectedElements(
                    theHandrail,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    graph
                  );
                }
              }
            }
          }
        });
      }
    }

    // Check if attached to stairs.
    if (handrail.run === null && !handrail.p1.run && !handrail.p2.run) {
      if (handrail.p1.stairsIndex && handrail.p2.stairsIndex) {
        if (stairs.size) {
          stairs.forEach((theStairs) => {
            if (
              theStairs.id === handrail.p1.stairsIndex ||
              theStairs.id === handrail.p2.stairsIndex
            ) {
              const handrailEdgeKey = "handrail-to-stairs";

              graph = graph.setIn(
                [handrail.id, "edges", handrailEdgeKey],
                Edge({ from: handrail.id, to: theStairs.id })
              );

              if (!graph.has(theStairs.id)) {
                graph = getConnectedElements(
                  theStairs,
                  corners,
                  runs,
                  gates,
                  stairs,
                  posts,
                  handrails,
                  graph
                );
              }
            }
          });
        }
      }
    }

    // If automatically drawn check if the handrail is connected to other runs with handrails.
    if (handrail.run) {
      if (handrails.size) {
        handrails.forEach((theHandrail) => {
          if (corners.length) {
            corners
              .filter((corner) => {
                return corner.points[handrail.run];
              })
              .forEach((corner) => {
                Object.keys(corner.points).forEach((runIndex) => {
                  if (runIndex !== handrail.run) {
                    const connectingRun = runs.get(runIndex);

                    if (theHandrail.run === connectingRun.id) {
                      const handrailEdgeKey = getHandrailToHandrailEdgeKey(
                        handrail,
                        corner
                      );

                      graph = graph.setIn(
                        [handrail.id, "edges", handrailEdgeKey],
                        Edge({ from: handrail.id, to: theHandrail.id })
                      );

                      if (!graph.has(theHandrail.id)) {
                        graph = getConnectedElements(
                          theHandrail,
                          corners,
                          runs,
                          gates,
                          stairs,
                          posts,
                          handrails,
                          graph
                        );
                      }
                    }
                  }
                });
              });
          }
        });
      }
    }
  }

  return graph;
}

function getStairsRunEdgeKey(run) {
  if (!run.stairs) {
    return "no-stairs";
  }

  if (!run.stairs.keys) {
    return "no-stairs";
  }

  const { start, end } = run.stairs.keys;

  return `${start}-${end}-stairs-to-run`;
}

function getGateEdgeKey(post) {
  let type = "p1";
  if (post === "p1") {
    type = "p1";
  } else if (post === "p2") {
    type = "p2";
  }

  return `gate-${type}-to-run`;
}

function getRunDrawnHandrailKey(handrail) {
  return `run-to-drawn-handrail-${handrail.id}`;
}

function getRunDrawnAttachedHandrailKey(handrail) {
  return `run-to-drawn-attached-handrail-${handrail.id}`;
}

function getGateToPostEdgeKey(postType) {
  return `gate-${postType}-to-post`;
}

function getPostToGateEdgeKey(postType, gate) {
  return `post-${postType}-to-gate-${gate.id}}`;
}

function getRunToGateEdgeKey(gate, post) {
  let type = "start";

  // If start of run attachment.
  if (gate[post].postIndex === 0) {
    type = "start";
  } else if (gate[post].postIndex > 0) {
    type = "end";
  }

  return `run-${type}-to-gate`;
}

function getRunToRunEdgeKey(run, corner) {
  if (!run || !corner) {
    return "no-run";
  }

  let type = "start";

  const cornerPoint = corner.points[run.id];

  if (cornerPoint) {
    if (cornerPoint.type === "1") {
      type = "start";
    }

    if (cornerPoint.type === "2") {
      type = "end";
    }
  }

  return `run-${type}-to-run`;
}

function getHandrailToHandrailEdgeKey(handrail, corner) {
  if (!handrail || !corner) {
    return "no-handrail";
  }

  let type = "start";

  const cornerPoint = corner.points[handrail.run];

  if (cornerPoint) {
    if (cornerPoint.type === "1") {
      type = "start";
    }

    if (cornerPoint.type === "2") {
      type = "end";
    }
  }

  return `handrail-${type}-to-handrail`;
}

export function getRunGraph(
  run,
  corners,
  runs,
  gates,
  stairs,
  posts,
  graph = Map()
) {
  if (!run) {
    return graph;
  }

  graph = graph.set(run.id, run);

  // Add stairs.
  graph = addRunStairsToGraph(run, stairs, graph);

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[run.id]) {
        Object.values(corner.points).forEach((point) => {
          if (!graph.has(point.id) && point.id !== run.id) {
            const theRun = runs.get(point.id);

            graph = graph.set(point.id, theRun);

            // Add stairs.
            graph = addRunStairsToGraph(theRun, stairs, graph);

            graph = getRunGraph(
              theRun,
              corners,
              runs,
              gates,
              stairs,
              posts,
              graph
            );
          }
        });
      }
    });
  }

  if (gates.size) {
    gates.forEach((gate) => {
      if (gate.p1.runIndex === run.id || gate.p2.runIndex === run.id) {
        if (!graph.has(gate.id)) {
          if (gate.p1.runIndex === run.id) {
            const theRun = runs.get(gate.p2.runIndex);

            if (theRun && !graph.has(theRun.id)) {
              // Add run.
              graph = graph.set(theRun.id, theRun);

              // Add stairs.
              graph = addRunStairsToGraph(theRun, stairs, graph);

              // Add gate.
              graph = graph.set(gate.id, gate);

              graph = getRunGraph(
                theRun,
                corners,
                runs,
                gates,
                stairs,
                posts,
                graph
              );
            }
          }

          if (gate.p1.runIndex === null) {
            graph = graph.set(gate.p1.postIndex, posts.get(gate.p1.postIndex));
            graph = graph.set(gate.id, gate);

            // @TODO continue graph of multiple gates connected to post.
          }

          if (gate.p2.runIndex === run.id) {
            const theRun = runs.get(gate.p1.runIndex);

            if (theRun && !graph.has(theRun.id)) {
              // Add run.
              graph = graph.set(theRun.id, theRun);

              // Add stairs.
              graph = addRunStairsToGraph(theRun, stairs, graph);

              // Add gate.
              graph = graph.set(gate.id, gate);

              graph = getRunGraph(
                theRun,
                corners,
                runs,
                gates,
                stairs,
                posts,
                graph
              );
            }
          }

          if (gate.p2.runIndex === null) {
            graph = graph.set(gate.p2.postIndex, posts.get(gate.p2.postIndex));
            graph = graph.set(gate.id, gate);

            // @TODO continue graph of multiple gates connected to post.
          }
        }
      }
    });
  }

  return graph;
}

function addRunStairsToGraph(run, stairs, graph = Map()) {
  if (run.stairs) {
    if (stairs.has(run.stairs.stairsIndex)) {
      const theStairs = stairs.get(run.stairs.stairsIndex);

      graph = graph.set(theStairs.id, theStairs);
    }
  }

  return graph;
}

export function getGatePoints(gate, runs, posts, settings) {
  const runIndex1 = gate.p1.runIndex;

  const theRun1 = runs.get(runIndex1);

  const runIndex2 = gate.p2.runIndex;

  const theRun2 = runs.get(runIndex2);

  let startPost, endPost;

  if (theRun1) {
    const posts1 = getPosts(theRun1, settings);
    startPost = posts1[gate.p1.postIndex];
  }

  if (theRun2) {
    const posts2 = getPosts(theRun2, settings);
    endPost = posts2[gate.p2.postIndex];
  }

  if (!runIndex1) {
    startPost = posts.get(gate.p1.postIndex);
  }

  if (!runIndex2) {
    endPost = posts.get(gate.p2.postIndex);
  }

  return {
    startPost: startPost,
    endPost: endPost,
  };
}

export function getPostsFactoringInCorners(run, postSpacing, corners, state) {
  const posts = getPosts(run, state.settings, state);
  const runId = run.id;

  const { x1, y1, x2, y2 } = run;

  let startingCorner = null;
  let endingCorner = null;

  let singlePostEndingCorner = null;
  let singlePostStartingCorner = null;

  if (corners && corners.length) {
    const potentialCorners = corners
      .map((corner) => corner.runs.has(runId))
      .filter((included) => included);

    if (potentialCorners && potentialCorners.length) {
      startingCorner = corners.find((corner) => {
        return corner.runs.has(runId) && corner.points[runId].type === "1";
      });

      endingCorner = corners.find((corner) => {
        return corner.runs.has(runId) && corner.points[runId].type === "2";
      });
    }

    if (state) {
      const stateCorners = state.corners;

      const matchingStartCorner = stateCorners.find((value) => {
        // Type 1 is the ending run for the corner where the first post is what is removed.
        if (value.id[runId] && value.id[runId].type === "1") {
          return value.type && value.type === "single";
        }

        return false;
      });

      if (matchingStartCorner) {
        startingCorner = null;
        singlePostStartingCorner = matchingStartCorner;
      }

      const matchingEndCorner = stateCorners.find((value) => {
        // Type 1 is the ending run for the corner where the first post is what is removed.
        if (value.id[runId] && value.id[runId].type === "2") {
          return value.type && value.type === "single";
        }

        return false;
      });

      if (matchingEndCorner) {
        endingCorner = null;
        singlePostEndingCorner = matchingEndCorner;
      }
    }
  }

  const angle = Math.atan((y2 - y1) / (x2 - x1));

  let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
  let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

  if (xComponent > 0 && x2 - x1 < 0) {
    xComponent = xComponent * -1;
  }

  if (yComponent > 0 && y2 - y1 < 0) {
    yComponent = yComponent * -1;
  }

  if (x2 - x1 > 0) {
    xComponent = Math.abs(xComponent);
  }

  if (y2 - y1 > 0) {
    yComponent = Math.abs(yComponent);
  }

  if (startingCorner && !singlePostStartingCorner) {
    // Move start post position when corner is present.
    posts[0].x += xComponent;
    posts[0].y += yComponent;
  }

  if (endingCorner && !singlePostEndingCorner) {
    posts[posts.length - 1].x -= xComponent;
    posts[posts.length - 1].y -= yComponent;
  }

  return posts;
}
