import { createStore, applyMiddleware, Store, EmptyObject } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// @ts-ignore
import refx from "refx";

import rootReducer from "./reducers";
import effects from "./effects";
import { AppReducerAction, AppReducerState } from "./reducers/appReducer";
import {
  SequenceReducerAction,
  SequenceReducerState,
} from "./reducers/sequenceReducer";
import {
  WindowStateReducerAction,
  WindowStateReducerState,
} from "./reducers/windowStateReducer";
import {
  EstimatorReducerAction,
  EstimatorReducerState,
} from "./reducers/estimatorReducer";
import {
  CustomerReducerAction,
  CustomerReducerState,
} from "./reducers/customerReducer";
import { SaveReducerAction, SaveReducerState } from "./reducers/saveReducer";
import {
  RequiredFieldsReducerAction,
  RequiredFieldsReducerState,
} from "./reducers/requiredFieldsReducer";
import {
  RenderCanvasesReducerAction,
  RenderCanvasesReducerState,
} from "./reducers/renderCanvasesReducer";
import {
  WindowsZIndexReducerAction,
  WindowsZIndexReducerState,
} from "./reducers/windowsZIndexReducer";
import { StateReducerAction, StateReducerState } from "./reducers/stateReducer";

export type RootState = EmptyObject & {
  state: StateReducerState;
  sequenceId: SequenceReducerState;
  appState: AppReducerState;
  windows: WindowStateReducerState;
  estimators: EstimatorReducerState;
  customerReducer: CustomerReducerState;
  saveState: SaveReducerState;
  requiredFields: RequiredFieldsReducerState;
  renderCanvases: RenderCanvasesReducerState;
  windowsZIndex: WindowsZIndexReducerState;
};

export type RootStore = Store<
  RootState,
  | StateReducerAction
  | SequenceReducerAction
  | AppReducerAction
  | WindowStateReducerAction
  | EstimatorReducerAction
  | CustomerReducerAction
  | SaveReducerAction
  | RequiredFieldsReducerAction
  | RenderCanvasesReducerAction
  | WindowsZIndexReducerAction
> & {
  dispatch: unknown;
};

const store: RootStore = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(refx(effects)))
);

export default function configureStore() {
  return store;
}
