import { InventoryOtherCharge } from "./parsers/inventoryOtherChargeParser";

const items: InventoryOtherCharge = {
  "?xml": { "@_version": "1.0" },
  QBXML: {
    QBXMLMsgsRs: {
      ItemOtherChargeQueryRs: {
        ItemOtherChargeRet: [
          {
            ListID: "80000D2B-1593980052",
            TimeCreated: "2020-07-05T13:14:12-08:00",
            TimeModified: "2020-07-05T13:14:12-08:00",
            EditSequence: 1593980052,
            Name: "CROSSOVER ITEMS",
            FullName: "CROSSOVER ITEMS",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000FC-1581309616",
                FullName: "Due From - Eglass",
              },
            },
          },
          {
            ListID: "80000D37-1593983234",
            TimeCreated: "2020-07-05T14:07:14-08:00",
            TimeModified: "2020-07-05T14:07:14-08:00",
            EditSequence: 1593983234,
            Name: "Asset Transfer (Computers)",
            FullName: "CROSSOVER ITEMS:Asset Transfer (Computers)",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "8000004E-1514577014",
                FullName: "Fixed Assets:01 Computer Equipment",
              },
            },
          },
          {
            ListID: "80000D32-1593980518",
            TimeCreated: "2020-07-05T13:21:58-08:00",
            TimeModified: "2020-07-05T13:49:31-08:00",
            EditSequence: 1593982171,
            Name: "Dental Insurance",
            FullName: "CROSSOVER ITEMS:Dental Insurance",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000D6-1571769372",
                FullName: "Payroll Tax Payable:16 Dental Insurance Payable",
              },
            },
          },
          {
            ListID: "80000D31-1593980431",
            TimeCreated: "2020-07-05T13:20:31-08:00",
            TimeModified: "2020-07-05T13:20:31-08:00",
            EditSequence: 1593980431,
            Name: "Health Insurance",
            FullName: "CROSSOVER ITEMS:Health Insurance",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000A6-1529529506",
                FullName: "Employee Expense:Health Insurance",
              },
            },
          },
          {
            ListID: "80000D2F-1593980325",
            TimeCreated: "2020-07-05T13:18:45-08:00",
            TimeModified: "2020-07-05T13:18:45-08:00",
            EditSequence: 1593980325,
            Name: "Honey Bucket Service",
            FullName: "CROSSOVER ITEMS:Honey Bucket Service",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000099-1515168058",
                FullName: "Utilities:Garbage and Recycling Expense",
              },
            },
          },
          {
            ListID: "80000D30-1593980395",
            TimeCreated: "2020-07-05T13:19:55-08:00",
            TimeModified: "2020-07-05T13:19:55-08:00",
            EditSequence: 1593980395,
            Name: "NW Natural Gas",
            FullName: "CROSSOVER ITEMS:NW Natural Gas",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000070-1514587268",
                FullName: "Utilities:Natural Gas/Propane Expense",
              },
            },
          },
          {
            ListID: "80000D39-1593984101",
            TimeCreated: "2020-07-05T14:21:41-08:00",
            TimeModified: "2020-07-05T14:21:41-08:00",
            EditSequence: 1593984101,
            Name: "Office Supplies",
            FullName: "CROSSOVER ITEMS:Office Supplies",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000011-1510596455",
                FullName: "Office Expense:Office Supplies",
              },
            },
          },
          {
            ListID: "80000D33-1593980558",
            TimeCreated: "2020-07-05T13:22:38-08:00",
            TimeModified: "2020-07-05T13:22:38-08:00",
            EditSequence: 1593980558,
            Name: "PDX Alarm Service",
            FullName: "CROSSOVER ITEMS:PDX Alarm Service",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000094-1514666917",
                FullName: "Utilities:Security Monitoring Expense",
              },
            },
          },
          {
            ListID: "80000D2C-1593980139",
            TimeCreated: "2020-07-05T13:15:39-08:00",
            TimeModified: "2020-07-05T13:18:54-08:00",
            EditSequence: 1593980334,
            Name: "PDX Warehouse Rent",
            FullName: "CROSSOVER ITEMS:PDX Warehouse Rent",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000013-1510596455",
                FullName: "Rent Expense:Portland Warehouse Rent",
              },
            },
          },
          {
            ListID: "80000D38-1593983290",
            TimeCreated: "2020-07-05T14:08:10-08:00",
            TimeModified: "2020-07-05T14:08:10-08:00",
            EditSequence: 1593983290,
            Name: "PSE Engineering",
            FullName: "CROSSOVER ITEMS:PSE Engineering",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000EB-1580694086",
                FullName: "Professional Fees:Professional Fees - Engineering",
              },
            },
          },
          {
            ListID: "80000D2E-1593980230",
            TimeCreated: "2020-07-05T13:17:10-08:00",
            TimeModified: "2020-07-05T13:19:02-08:00",
            EditSequence: 1593980342,
            Name: "Republic Services",
            FullName: "CROSSOVER ITEMS:Republic Services",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000099-1515168058",
                FullName: "Utilities:Garbage and Recycling Expense",
              },
            },
          },
          {
            ListID: "80000D34-1593980621",
            TimeCreated: "2020-07-05T13:23:41-08:00",
            TimeModified: "2020-07-05T13:23:41-08:00",
            EditSequence: 1593980621,
            Name: "Salaries and Wages",
            FullName: "CROSSOVER ITEMS:Salaries and Wages",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000021-1510598964",
                FullName: "Payroll Expenses:Salaries and Wages",
              },
            },
          },
          {
            ListID: "80000D35-1593980696",
            TimeCreated: "2020-07-05T13:24:56-08:00",
            TimeModified: "2020-07-05T13:24:56-08:00",
            EditSequence: 1593980696,
            Name: "Small Tools",
            FullName: "CROSSOVER ITEMS:Small Tools",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000D2D-1593980180",
            TimeCreated: "2020-07-05T13:16:20-08:00",
            TimeModified: "2020-07-05T13:19:09-08:00",
            EditSequence: 1593980349,
            Name: "Space 74 Rent",
            FullName: "CROSSOVER ITEMS:Space 74 Rent",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000D5-1571004033",
                FullName: "Rent Expense:B74 Rent",
              },
            },
          },
          {
            ListID: "80000D36-1593980767",
            TimeCreated: "2020-07-05T13:26:07-08:00",
            TimeModified: "2020-07-05T13:26:07-08:00",
            EditSequence: 1593980767,
            Name: "Warehouse/Shop Supplies",
            FullName: "CROSSOVER ITEMS:Warehouse/Shop Supplies",
            IsActive: true,
            ParentRef: {
              ListID: "80000D2B-1593980052",
              FullName: "CROSSOVER ITEMS",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "80000CF6-1581309809",
            TimeCreated: "2020-02-09T20:43:29-08:00",
            TimeModified: "2020-02-09T20:43:29-08:00",
            EditSequence: 1581309809,
            Name: "Crossover Payable",
            FullName: "Crossover Payable",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Common items paid by EGlass that we reimburse them for.",
              Price: 0,
              AccountRef: {
                ListID: "800000FD-1581309700",
                FullName: "Due To - Eglass",
              },
            },
          },
          {
            ListID: "80000CF7-1581309868",
            TimeCreated: "2020-02-09T20:44:28-08:00",
            TimeModified: "2020-02-09T20:45:00-08:00",
            EditSequence: 1581309900,
            Name: "Crossover Receivable",
            FullName: "Crossover Receivable",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Common items paid by SC&R that EGlass will reimburse.",
              Price: 0,
              AccountRef: {
                ListID: "800000FC-1581309616",
                FullName: "Due From - Eglass",
              },
            },
          },
          {
            ListID: "80000BC7-1550183709",
            TimeCreated: "2019-02-14T14:35:09-08:00",
            TimeModified: "2019-02-14T14:35:09-08:00",
            EditSequence: 1550183709,
            Name: "E-Glass Parts",
            FullName: "E-Glass Parts",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Charges from subcontractor for machine calibration, set up, programing, palletizing..",
              Price: 0,
              AccountRef: {
                ListID: "80000025-1510598965",
                FullName: "SC&R COGs:01 Material COGS",
              },
            },
          },
          {
            ListID: "800012E1-1649091856",
            TimeCreated: "2022-04-04T10:04:16-08:00",
            TimeModified: "2022-04-04T10:04:16-08:00",
            EditSequence: 1649091856,
            Name: "Fab Design Fee",
            FullName: "Fab Design Fee",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Fab Design Fee",
              Price: 0,
              AccountRef: {
                ListID: "8000016B-1645238628",
                FullName: "SC&R - Sales:02 Service Income",
              },
            },
          },
          {
            ListID: "800003B2-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound Freight",
            FullName: "Inbound Freight",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003B3-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound Air Freight",
            FullName: "Inbound Freight:Inbound Air Freight",
            IsActive: true,
            ParentRef: {
              ListID: "800003B2-1510599163",
              FullName: "Inbound Freight",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Inbound Air Freight",
              Price: 0,
              AccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003B4-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound Ground Freight",
            FullName: "Inbound Freight:Inbound Ground Freight",
            IsActive: true,
            ParentRef: {
              ListID: "800003B2-1510599163",
              FullName: "Inbound Freight",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Inbound Ground Freight\n\nCarrier Name:\nResidential Delivery\nInsurance\nLiftgate Services\n\nNOTE:   CUSTOMER MAY BE EXPECTED TO HELP ASSIST FREIGHT DRIVER WITH OFFLOADING PACKAGES FROM TRUCK\n\nCUSTOMER WILL BE CONTACTED BY FREIGHT CARRIER TO SCHEDULE DELIVERY DAY AND TIME\n\n_____ Emailed Customer Freight Tracking Info",
              Price: 0,
              AccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003B5-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound Sea Freight",
            FullName: "Inbound Freight:Inbound Sea Freight",
            IsActive: true,
            ParentRef: {
              ListID: "800003B2-1510599163",
              FullName: "Inbound Freight",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Inbound Sea Freight",
              Price: 0,
              AccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BC-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound UPS Ground-RETURN LABEL",
            FullName: "Inbound Freight:Inbound UPS Ground-RETURN LABEL",
            IsActive: true,
            ParentRef: {
              ListID: "800003B2-1510599163",
              FullName: "Inbound Freight",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS RETURN SHIPPING LABEL\n- For use when providing the customer a return shipping label to send back product - NO CHARGE.",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "80000CA0-1571178713",
            TimeCreated: "2019-10-15T15:31:53-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Inbound USPS Priority - RETURNS",
            FullName: "Inbound Freight:Inbound USPS Priority - RETURNS",
            IsActive: true,
            ParentRef: {
              ListID: "800003B2-1510599163",
              FullName: "Inbound Freight",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "USPS PRIORITY RETURN SHIPPING LABEL\n- For use when providing the customer a return shipping label to send back product - NO CHARGE.",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "80000A7B-1519661686",
            TimeCreated: "2018-02-26T08:14:46-08:00",
            TimeModified: "2022-08-09T15:59:54-08:00",
            EditSequence: 1660085994,
            Name: "Magni Coat Dip-Spin",
            FullName: "Magni Coat Dip-Spin",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesAndPurchase: {
              SalesDesc:
                "Magni 565 Black\n\nMinimum charge 175.000\n1.931 cents per lbs\ntry to do at least 90 lbs to avoid updcharges",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000016B-1645238628",
                FullName: "SC&R - Sales:02 Service Income",
              },
              PurchaseCost: 2.45,
              ExpenseAccountRef: {
                ListID: "800000F6-1581111049",
                FullName: "Subcontractor Expense",
              },
              PrefVendorRef: {
                ListID: "80004C0A-1519661479",
                FullName: "East Side Plating Inc.",
              },
            },
          },
          {
            ListID: "80000B6E-1533832554",
            TimeCreated: "2018-08-09T09:35:54-08:00",
            TimeModified: "2018-08-09T09:35:54-08:00",
            EditSequence: 1533832554,
            Name: "Management Fee",
            FullName: "Management Fee",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Employee crossover SC&R and eGlass",
              Price: 0,
              AccountRef: {
                ListID: "8000001E-1510598964",
                FullName: "SC&R - Sales:05 Management/Consulting",
              },
            },
          },
          {
            ListID: "80000A33-1514925547",
            TimeCreated: "2018-01-02T12:39:07-08:00",
            TimeModified: "2020-02-07T19:09:29-08:00",
            EditSequence: 1581131369,
            Name: "Other Fee/Surcharge",
            FullName: "Other Fee/Surcharge",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Fuel Surcharges\nWire Fees\nMisc",
              Price: 0,
              AccountRef: {
                ListID: "800000F4-1581110878",
                FullName: "Inbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003B7-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound Shipping",
            FullName: "Outbound Shipping",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003B8-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound Fuel Surcharge",
            FullName: "Outbound Shipping:Outbound Fuel Surcharge",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesAndPurchase: {
              SalesDesc: "Fuel Surcharge",
              SalesPrice: 6.9,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "80000CF9-1581547440",
            TimeCreated: "2020-02-12T14:44:00-08:00",
            TimeModified: "2022-02-18T08:38:30-08:00",
            EditSequence: 1645202310,
            Name: "Outbound Ground Freight",
            FullName: "Outbound Shipping:Outbound Ground Freight",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "OUTBOUND GROUND FREIGHT:\n\nREFERENCE ID: __________________\n\nCarrier Name: __________________\n\nDelivery: RESIDENTIAL or COMMERCIAL\n\nLiftgate Services: YES or NO\n\nNOTE: CUSTOMER MAY BE EXPECTED TO ASSIST FREIGHT DRIVER WITH OFFLOADING PACKAGES FROM TRUCK\n\nCUSTOMER WILL BE CONTACTED BY FREIGHT CARRIER TO SCHEDULE DELIVERY DAY AND TIME\n\n_____Emailed Customer Freight Tracking Info",
              Price: 0,
              AccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
            },
          },
          {
            ListID: "800003B9-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound Packaging Fee",
            FullName: "Outbound Shipping:Outbound Packaging Fee",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc: "Packaging/Handling Fee",
              SalesPrice: 0.15,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BB-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPS Ground",
            FullName: "Outbound Shipping:Outbound UPS Ground",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS REGULAR GROUND\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)\n_____  EMAIL COPY OF INVOICE",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BD-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPS Next Day Air",
            FullName: "Outbound Shipping:Outbound UPS Next Day Air",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS NEXT DAY AIR\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BE-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPS Std to Canada",
            FullName: "Outbound Shipping:Outbound UPS Std to Canada",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS STANDARD INTERNATIONAL TO CANADA\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)\n_____  EMAIL COPY OF INVOICE",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BF-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPS2ndDayAir",
            FullName: "Outbound Shipping:Outbound UPS2ndDayAir",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS 2ND DAY AIR\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003C0-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPS3DaySelect",
            FullName: "Outbound Shipping:Outbound UPS3DaySelect",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS 3 DAY SELECT\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003C1-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPSAirFreight",
            FullName: "Outbound Shipping:Outbound UPSAirFreight",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc: "UPS AIR FREIGHT",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003C2-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound UPSCRD FREE",
            FullName: "Outbound Shipping:Outbound UPSCRD FREE",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "UPS REGULAR GROUND - FREE SHIPPING\n\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)\n_____  EMAIL COPY OF INVOICE",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003C3-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Outbound USPSPriority",
            FullName: "Outbound Shipping:Outbound USPSPriority",
            IsActive: true,
            ParentRef: {
              ListID: "800003B7-1510599163",
              FullName: "Outbound Shipping",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "USPS PRIORITY\n\n(TRACKING EMAIL AND INVOICE TO BE SENT WHEN SHIPPED)",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
          {
            ListID: "800003BA-1510599163",
            TimeCreated: "2017-11-13T10:52:43-08:00",
            TimeModified: "2022-02-18T19:24:23-08:00",
            EditSequence: 1645241063,
            Name: "Passivation Fee",
            FullName: "Passivation Fee",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Passivation Fee",
              Price: 0,
              AccountRef: {
                ListID: "8000016B-1645238628",
                FullName: "SC&R - Sales:02 Service Income",
              },
            },
          },
          {
            ListID: "8000093F-1513367649",
            TimeCreated: "2017-12-15T11:54:09-08:00",
            TimeModified: "2020-02-07T19:17:26-08:00",
            EditSequence: 1581131846,
            Name: "Subcontractor Charges",
            FullName: "Subcontractor Charges",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Charges from subcontractor for machine calibration, set up, programing, palletizing..",
              Price: 0,
              AccountRef: {
                ListID: "800000F6-1581111049",
                FullName: "Subcontractor Expense",
              },
            },
          },
          {
            ListID: "80000AD1-1523283830",
            TimeCreated: "2018-04-09T07:23:50-08:00",
            TimeModified: "2021-04-24T09:04:39-08:00",
            EditSequence: 1619280279,
            Name: "TOOLING",
            FullName: "TOOLING",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Tooling charges for Product Dies: Description of Item",
              Price: 0,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000130B-1651881439",
            TimeCreated: "2022-05-06T16:57:19-08:00",
            TimeModified: "2022-05-06T16:57:19-08:00",
            EditSequence: 1651881439,
            Name: "DIE-ABC_7216-01",
            FullName: "TOOLING:DIE-ABC_7216-01",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-TopRail-Rect - 730116",
              Price: 4235,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000130C-1651881511",
            TimeCreated: "2022-05-06T16:58:31-08:00",
            TimeModified: "2022-05-06T17:02:57-08:00",
            EditSequence: 1651881777,
            Name: "DIE-ABC_7216-02",
            FullName: "TOOLING:DIE-ABC_7216-02",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-SnapCover - 730123",
              Price: 5134.25,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "800012CF-1644604041",
            TimeCreated: "2022-02-11T10:27:21-08:00",
            TimeModified: "2022-02-11T10:27:21-08:00",
            EditSequence: 1644604041,
            Name: "DIE-ACCU-TEK_810509",
            FullName: "TOOLING:DIE-ACCU-TEK_810509",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-TopRail-Rect",
              Price: 3000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "800012D1-1644604207",
            TimeCreated: "2022-02-11T10:30:07-08:00",
            TimeModified: "2022-02-11T10:30:07-08:00",
            EditSequence: 1644604207,
            Name: "DIE-ACCU-TEK_810510A",
            FullName: "TOOLING:DIE-ACCU-TEK_810510A",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-SnapCover",
              Price: 3500,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "800012D0-1644604136",
            TimeCreated: "2022-02-11T10:28:56-08:00",
            TimeModified: "2022-02-11T10:28:56-08:00",
            EditSequence: 1644604136,
            Name: "DIE-ACCU-TEK_810878",
            FullName: "TOOLING:DIE-ACCU-TEK_810878",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-TopRail-Shaped",
              Price: 3500,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CC0-1574374549",
            TimeCreated: "2019-11-21T14:15:49-08:00",
            TimeModified: "2021-04-24T09:05:03-08:00",
            EditSequence: 1619280303,
            Name: "DIE-ALLWIN-2507_1x2TR ADJ CON",
            FullName: "TOOLING:DIE-ALLWIN-2507_1x2TR ADJ CON",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017488-2507",
              Price: 375,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CBF-1574373793",
            TimeCreated: "2019-11-21T14:03:13-08:00",
            TimeModified: "2021-04-24T09:05:27-08:00",
            EditSequence: 1619280327,
            Name: "DIE-ALLWIN-2507_1x2TR ADJ ELBOW",
            FullName: "TOOLING:DIE-ALLWIN-2507_1x2TR ADJ ELBOW",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017471-2507",
              Price: 375,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CBE-1574373719",
            TimeCreated: "2019-11-21T14:01:59-08:00",
            TimeModified: "2021-04-24T09:05:47-08:00",
            EditSequence: 1619280347,
            Name: "DIE-ALLWIN-2507_1x2TR BRACK",
            FullName: "TOOLING:DIE-ALLWIN-2507_1x2TR BRACK",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017440-2507",
              Price: 245,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CC3-1574375146",
            TimeCreated: "2019-11-21T14:25:46-08:00",
            TimeModified: "2021-04-24T09:06:16-08:00",
            EditSequence: 1619280376,
            Name: "DIE-ALLWIN-2507_1x2TR END CAP",
            FullName: "TOOLING:DIE-ALLWIN-2507_1x2TR END CAP",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017501-2507",
              Price: 245,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CC1-1574374603",
            TimeCreated: "2019-11-21T14:16:43-08:00",
            TimeModified: "2021-04-24T09:06:35-08:00",
            EditSequence: 1619280395,
            Name: "DIE-ALLWIN-2507_1x2TR SPLICE",
            FullName: "TOOLING:DIE-ALLWIN-2507_1x2TR SPLICE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017495-2507",
              Price: 245,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CC4-1574446128",
            TimeCreated: "2019-11-22T10:08:48-08:00",
            TimeModified: "2021-04-24T09:06:54-08:00",
            EditSequence: 1619280414,
            Name: "DIE-ALLWIN-2507_COVER PLATE",
            FullName: "TOOLING:DIE-ALLWIN-2507_COVER PLATE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017457-2507",
              Price: 3500,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000CC2-1574374711",
            TimeCreated: "2019-11-21T14:18:31-08:00",
            TimeModified: "2021-04-24T09:07:14-08:00",
            EditSequence: 1619280434,
            Name: "DIE-ALLWIN-2507_FLANGE",
            FullName: "TOOLING:DIE-ALLWIN-2507_FLANGE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 017433-2507",
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000DC2-1607630865",
            TimeCreated: "2020-12-10T12:07:45-08:00",
            TimeModified: "2021-04-24T09:07:36-08:00",
            EditSequence: 1619280456,
            Name: "DIE-AMWARE_731359",
            FullName: "TOOLING:DIE-AMWARE_731359",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for 731359",
              Price: 980,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B95-1538147142",
            TimeCreated: "2018-09-28T08:05:42-08:00",
            TimeModified: "2021-04-24T09:07:52-08:00",
            EditSequence: 1619280472,
            Name: "DIE-BEST RING_101X3MM",
            FullName: "TOOLING:DIE-BEST RING_101X3MM",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 1050,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000AD0-1523283479",
            TimeCreated: "2018-04-09T07:17:59-08:00",
            TimeModified: "2021-04-24T09:08:13-08:00",
            EditSequence: 1619280493,
            Name: "DIE-GVA_SCR00001",
            FullName: "TOOLING:DIE-GVA_SCR00001",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 03-001-01x37-D2",
              Price: 1299,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000AD2-1523284002",
            TimeCreated: "2018-04-09T07:26:42-08:00",
            TimeModified: "2021-04-24T09:08:37-08:00",
            EditSequence: 1619280517,
            Name: "DIE-GVA_SCR00002",
            FullName: "TOOLING:DIE-GVA_SCR00002",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 670,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000AD3-1523284053",
            TimeCreated: "2018-04-09T07:27:33-08:00",
            TimeModified: "2021-04-24T09:09:01-08:00",
            EditSequence: 1619280541,
            Name: "DIE-GVA_SCR00003",
            FullName: "TOOLING:DIE-GVA_SCR00003",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Die for 03-002-01",
              Price: 666,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000AD4-1523284107",
            TimeCreated: "2018-04-09T07:28:27-08:00",
            TimeModified: "2021-04-24T09:09:22-08:00",
            EditSequence: 1619280562,
            Name: "DIE-GVA_SCR00004",
            FullName: "TOOLING:DIE-GVA_SCR00004",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Die for 03-004-01",
              Price: 666,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000AD5-1523284142",
            TimeCreated: "2018-04-09T07:29:02-08:00",
            TimeModified: "2021-04-24T09:09:44-08:00",
            EditSequence: 1619280584,
            Name: "DIE-GVA_SCR00005",
            FullName: "TOOLING:DIE-GVA_SCR00005",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Price: 554,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B5F-1530633984",
            TimeCreated: "2018-07-03T09:06:24-08:00",
            TimeModified: "2021-04-24T09:10:11-08:00",
            EditSequence: 1619280611,
            Name: "DIE-INDAL_62046",
            FullName: "TOOLING:DIE-INDAL_62046",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for Elliptical Top Rail",
              Price: 1025,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B60-1530634027",
            TimeCreated: "2018-07-03T09:07:07-08:00",
            TimeModified: "2021-04-24T09:10:36-08:00",
            EditSequence: 1619280636,
            Name: "DIE-INDAL_62047",
            FullName: "TOOLING:DIE-INDAL_62047",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for Intermediate Post",
              Price: 1025,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B62-1530634146",
            TimeCreated: "2018-07-03T09:09:06-08:00",
            TimeModified: "2021-04-24T09:11:11-08:00",
            EditSequence: 1619280671,
            Name: "DIE-INDAL_62048",
            FullName: "TOOLING:DIE-INDAL_62048",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for P2P",
              Price: 575,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B5E-1530633841",
            TimeCreated: "2018-07-03T09:04:01-08:00",
            TimeModified: "2021-04-24T09:11:59-08:00",
            EditSequence: 1619280719,
            Name: "DIE-INDAL_62049",
            FullName: "TOOLING:DIE-INDAL_62049",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for Rectangular Top Rail",
              Price: 1025,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B63-1530634192",
            TimeCreated: "2018-07-03T09:09:52-08:00",
            TimeModified: "2021-04-24T09:12:22-08:00",
            EditSequence: 1619280742,
            Name: "DIE-INDAL_62050",
            FullName: "TOOLING:DIE-INDAL_62050",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for Snap Cover/Underside Dressing",
              Price: 500,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B61-1530634082",
            TimeCreated: "2018-07-03T09:08:02-08:00",
            TimeModified: "2021-04-24T09:12:53-08:00",
            EditSequence: 1619280773,
            Name: "DIE-INDAL_62051",
            FullName: "TOOLING:DIE-INDAL_62051",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for Terminal Post",
              Price: 1750,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B64-1530894489",
            TimeCreated: "2018-07-06T09:28:09-08:00",
            TimeModified: "2021-04-24T09:14:18-08:00",
            EditSequence: 1619280858,
            Name: "DIE-JMA_BASE PLATE1",
            FullName: "TOOLING:DIE-JMA_BASE PLATE1",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 03-009-01 (New)",
              Price: 5091,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B20-1528838487",
            TimeCreated: "2018-06-12T14:21:27-08:00",
            TimeModified: "2022-06-25T17:58:52-08:00",
            EditSequence: 1656205132,
            Name: "DIE-JMA_COVER PLATE",
            FullName: "TOOLING:DIE-JMA_COVER PLATE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 722756 and related colors",
              Price: 4167,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B36-1529441189",
            TimeCreated: "2018-06-19T13:46:29-08:00",
            TimeModified: "2021-04-24T09:19:31-08:00",
            EditSequence: 1619281171,
            Name: "DIE-JMA_HR SADDLE BRACK-INT",
            FullName: "TOOLING:DIE-JMA_HR SADDLE BRACK-INT",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 03-008-SBI",
              Price: 2166.5,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B37-1529441215",
            TimeCreated: "2018-06-19T13:46:55-08:00",
            TimeModified: "2021-04-24T09:19:52-08:00",
            EditSequence: 1619281192,
            Name: "DIE-JMA_HR SADDLE BRACK-TERM",
            FullName: "TOOLING:DIE-JMA_HR SADDLE BRACK-TERM",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 03-008-SBT",
              Price: 2166.5,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000DD5-1610676482",
            TimeCreated: "2021-01-14T18:08:02-08:00",
            TimeModified: "2021-04-24T09:20:09-08:00",
            EditSequence: 1619281209,
            Name: "DIE-JMA_LINK ADJ ELBOW FEMALE",
            FullName: "TOOLING:DIE-JMA_LINK ADJ ELBOW FEMALE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for Link adjustable elbow. This die is for the female side of the elbow.",
              Price: 5000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000DD4-1610676434",
            TimeCreated: "2021-01-14T18:07:14-08:00",
            TimeModified: "2021-04-24T09:15:53-08:00",
            EditSequence: 1619280953,
            Name: "DIE-JMA_LINK ADJ ELBOW MALE",
            FullName: "TOOLING:DIE-JMA_LINK ADJ ELBOW MALE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for Link adjustable elbow. This die is for the male side of the elbow.",
              Price: 4667,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000BB1-1543607976",
            TimeCreated: "2018-11-30T11:59:36-08:00",
            TimeModified: "2021-04-24T09:22:21-08:00",
            EditSequence: 1619281341,
            Name: "DIE-JMA_P2P STAIR BRACKET",
            FullName: "TOOLING:DIE-JMA_P2P STAIR BRACKET",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesOrPurchase: {
              Desc: "Used for 03-011A-01",
              Price: 2710,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B35-1529441036",
            TimeCreated: "2018-06-19T13:43:56-08:00",
            TimeModified: "2021-04-24T09:23:01-08:00",
            EditSequence: 1619281381,
            Name: "DIE-JMA_P2P WALL RETURN",
            FullName: "TOOLING:DIE-JMA_P2P WALL RETURN",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 03-013-01",
              Price: 4008,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000BB4-1544484987",
            TimeCreated: "2018-12-10T15:36:27-08:00",
            TimeModified: "2021-04-24T09:23:22-08:00",
            EditSequence: 1619281402,
            Name: "DIE-JMA_RECTANGULAR WALL PLATE",
            FullName: "TOOLING:DIE-JMA_RECTANGULAR WALL PLATE",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for 06-265-01",
              Price: 565,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000B34-1529440947",
            TimeCreated: "2018-06-19T13:42:27-08:00",
            TimeModified: "2021-04-24T09:23:47-08:00",
            EditSequence: 1619281427,
            Name: "DIE-JMA_SURFACE MOUNT TAB",
            FullName: "TOOLING:DIE-JMA_SURFACE MOUNT TAB",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "Used for SURFACE MOUNT TAB",
              Price: 0,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C66-1565110073",
            TimeCreated: "2019-08-06T09:47:53-08:00",
            TimeModified: "2021-04-24T09:24:22-08:00",
            EditSequence: 1619281462,
            Name: "DIE-KAMCO_LK6372",
            FullName: "TOOLING:DIE-KAMCO_LK6372",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-TopRail-Shaped",
              Price: 625,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C62-1565109807",
            TimeCreated: "2019-08-06T09:43:27-08:00",
            TimeModified: "2021-04-24T09:24:50-08:00",
            EditSequence: 1619281490,
            Name: "DIE-KAMCO_LK6373",
            FullName: "TOOLING:DIE-KAMCO_LK6373",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-Post-Term",
              Price: 1000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C61-1565109769",
            TimeCreated: "2019-08-06T09:42:49-08:00",
            TimeModified: "2021-04-24T09:25:17-08:00",
            EditSequence: 1619281517,
            Name: "DIE-KAMCO_LK6374",
            FullName: "TOOLING:DIE-KAMCO_LK6374",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-Post-Int",
              Price: 1000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C63-1565109929",
            TimeCreated: "2019-08-06T09:45:29-08:00",
            TimeModified: "2021-04-24T09:25:45-08:00",
            EditSequence: 1619281545,
            Name: "DIE-KAMCO_LK6375",
            FullName: "TOOLING:DIE-KAMCO_LK6375",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-PostToPost",
              Price: 900,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C65-1565110027",
            TimeCreated: "2019-08-06T09:47:07-08:00",
            TimeModified: "2021-04-24T09:26:20-08:00",
            EditSequence: 1619281580,
            Name: "DIE-KAMCO_LK6376",
            FullName: "TOOLING:DIE-KAMCO_LK6376",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-TopRail-Rect",
              Price: 625,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C64-1565109974",
            TimeCreated: "2019-08-06T09:46:14-08:00",
            TimeModified: "2021-04-24T09:26:49-08:00",
            EditSequence: 1619281609,
            Name: "DIE-KAMCO_LK6400",
            FullName: "TOOLING:DIE-KAMCO_LK6400",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL-SnapCover",
              Price: 380,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C9B-1567189471",
            TimeCreated: "2019-08-30T11:24:31-08:00",
            TimeModified: "2021-04-24T09:27:31-08:00",
            EditSequence: 1619281651,
            Name: "DIE-KAMCO_LK6475",
            FullName: "TOOLING:DIE-KAMCO_LK6475",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for LINK bracket",
              Price: 625,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C9C-1567189519",
            TimeCreated: "2019-08-30T11:25:19-08:00",
            TimeModified: "2021-04-24T09:27:57-08:00",
            EditSequence: 1619281677,
            Name: "DIE-KAMCO_LK6476",
            FullName: "TOOLING:DIE-KAMCO_LK6476",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for LINK sleeve",
              Price: 450,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000E9C-1619741355",
            TimeCreated: "2021-04-29T17:09:15-08:00",
            TimeModified: "2021-04-29T17:09:15-08:00",
            EditSequence: 1619741355,
            Name: "DIE-KAMCO_LK6734",
            FullName: "TOOLING:DIE-KAMCO_LK6734",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL Fascia Brackat Standoff Plate",
              Price: 400,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000E9D-1619741412",
            TimeCreated: "2021-04-29T17:10:12-08:00",
            TimeModified: "2021-04-29T17:10:12-08:00",
            EditSequence: 1619741412,
            Name: "DIE-KAMCO_LK6735",
            FullName: "TOOLING:DIE-KAMCO_LK6735",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for AL Inline Fascia Bracket Bottom Plate",
              Price: 400,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000E9B-1619139368",
            TimeCreated: "2021-04-22T17:56:08-08:00",
            TimeModified: "2021-04-24T09:28:16-08:00",
            EditSequence: 1619281696,
            Name: "DIE-KAMCO_LK6744",
            FullName: "TOOLING:DIE-KAMCO_LK6744",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for Two Post Outside Corner Bracket",
              Price: 950,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000EA4-1626305931",
            TimeCreated: "2021-07-14T16:38:51-08:00",
            TimeModified: "2021-07-14T16:39:29-08:00",
            EditSequence: 1626305969,
            Name: "DIE-X_NEWAY_PAD A",
            FullName: "TOOLING:DIE-X_NEWAY_PAD A",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for inside, inline, and two post outside corner fascia bracket.",
              Price: 1000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000EA5-1626305955",
            TimeCreated: "2021-07-14T16:39:15-08:00",
            TimeModified: "2021-07-14T16:39:15-08:00",
            EditSequence: 1626305955,
            Name: "DIE-X_NEWAY_PAD B",
            FullName: "TOOLING:DIE-X_NEWAY_PAD B",
            IsActive: true,
            ParentRef: { ListID: "80000AD1-1523283830", FullName: "TOOLING" },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Used for inside, inline, and two post outside corner fascia bracket.",
              Price: 1000,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000A32-1514907790",
            TimeCreated: "2018-01-02T07:43:10-08:00",
            TimeModified: "2021-04-24T09:20:45-08:00",
            EditSequence: 1619281245,
            Name: "Tooling Charges",
            FullName: "Tooling Charges",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Die #",
              Price: 0,
              AccountRef: {
                ListID: "80000136-1616091096",
                FullName: "Fixed Assets:08 Overseas Tools and Equipment",
              },
            },
          },
          {
            ListID: "800003C4-1510599164",
            TimeCreated: "2017-11-13T10:52:44-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "WILL-CALL",
            FullName: "WILL-CALL",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc:
                "***               WILL-CALL               ***\n\nCUSTOMER WILL PICK UP ON THIS DAY AND TIME:\n\n_________________________________________\n\nCALL CUSTOMER WHEN ORDER IS READY",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "8000002C-1510599163",
                FullName: "SC&R - Sales:03 Freight/Shipping Income",
              },
              PurchaseDesc: "Charged to SC&R",
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
            },
          },
        ],
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default items;
