// @ts-nocheck
import { useState, useContext, useEffect } from "react";

import ProjectDashboardHeader from "./ProjectDashboardHeader";
import ProjectDashboardTable from "./ProjectDashboardTable";
import EnvironmentBar from "../EnvironmentBar";
import { CustomersContext, EstimatorContext, ProjectsContext } from "../../App";
import { Map } from "immutable";
import Icon from "../Icon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { serviceUrl } from "../../environment";
import { getCustomerName } from "../../entities/utils";

const sortProjects = (sorts, customers) => (projects) => {
  if (
    !sorts.name.length &&
    !sorts.estimateNumber.length &&
    !sorts.date.length &&
    !sorts.customer.length
  ) {
    return projects.sort((projectA, projectB) => {
      return projectB.projectEstimate - projectA.projectEstimate;
    });
  }

  const sortedProjects = projects;

  if (sorts.activeSort === "name") {
    return sortedProjects.sort((projectA, projectB) => {
      if (!sorts.name.length) {
        return 0;
      }

      return sorts.name === "ASC"
        ? projectA.settings.projectTitle.localeCompare(
            projectB.settings.projectTitle
          )
        : projectB.settings.projectTitle.localeCompare(
            projectA.settings.projectTitle
          );
    });
  }

  if (sorts.activeSort === "estimateNumber") {
    return sortedProjects.sort((projectA, projectB) => {
      if (!sorts.estimateNumber.length) {
        return 0;
      }

      return sorts.estimateNumber === "ASC"
        ? projectA.projectEstimate - projectB.projectEstimate
        : projectB.projectEstimate - projectA.projectEstimate;
    });
  }

  if (sorts.activeSort === "date") {
    return sortedProjects.sort((projectA, projectB) => {
      if (!sorts.date.length) {
        return 0;
      }

      if (projectA.createdOn && projectB.createdOn) {
        return sorts.date === "ASC"
          ? Date.parse(projectA.createdOn) - Date.parse(projectB.createdOn)
          : Date.parse(projectB.createdOn) - Date.parse(projectA.createdOn);
      }

      if (!projectA.createdOn) {
        return sorts.date === "ASC" ? -1 : -1;
      }

      if (!projectB.createdOn) {
        return sorts.date === "ASC" ? -1 : -1;
      }

      return 0;
    });
  }

  if (sorts.activeSort === "customer") {
    return sortedProjects.sort((projectA, projectB) => {
      if (!sorts.customer.length) {
        return 0;
      }

      const customerA =
        projectA.customer && customers.has(projectA.customer)
          ? getCustomerName(customers.get(projectA.customer)!)
          : "None";
      const customerB =
        projectB.customer && customers.has(projectB.customer)
          ? getCustomerName(customers.get(projectB.customer)!)
          : "None";

      return sorts.customer === "ASC"
        ? customerA.localeCompare(customerB)
        : customerB.localeCompare(customerA);
    });
  }

  return sortedProjects;
};

function ProjectsDashboard() {
  const { projects, setProjects, projectsLoading } =
    useContext(ProjectsContext);
  const { customers } = useContext(CustomersContext);
  const { estimators } = useContext(EstimatorContext);

  const [filters, setFilters] = useState({
    search: "",
    estimator: "",
    status: "",
  });

  const [sorts, setSorts] = useState({
    name: "",
    estimateNumber: "DSC",
    customer: "",
    date: "",
    activeSort: "estimateNumber",
  });

  const [selected, setSelected] = useState(Map());

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const check = setInterval(() => {
      if (false && process.env.NODE_ENV === "production") {
        fetch(serviceUrl("api/auth/check"), { method: "POST" })
          .then((response) => {
            if (!response.ok) {
              navigate("/login");
            }

            return response.json();
          })
          .then((data) => {
            if (!data.isLoggedIn) {
              navigate("/login");
            }
          })
          .catch((error) => {
            console.log("error", error);
            navigate("/login");
          });
      }
    }, 1000);

    return () => clearInterval(check);
  }, [navigate]);

  const sortProjectCallback = sortProjects(sorts, customers);
  const sortedProjects = sortProjectCallback(projects);

  return (
    <div className="project-dashboard">
      <EnvironmentBar />
      <ProjectDashboardHeader filters={filters} setFilters={setFilters} />
      <ProjectDashboardTable
        projects={sortedProjects}
        sortProjects={sortProjectCallback}
        setProjects={setProjects}
        sorts={sorts}
        setSorts={setSorts}
        filters={filters}
        setFilters={setFilters}
        customers={customers}
        selected={selected}
        setSelected={setSelected}
        estimators={estimators}
      />
      {projectsLoading && (
        <div className="project-dashboard__loading">Projects Loading...</div>
      )}
      {!!selected.size && (
        <div className="project-dashboard__selected">
          <button
            onClick={() => {
              const newProjects = projects.map((project) => {
                let newProject = project;

                if (selected.has(project.id)) {
                  newProject = project.setIn(["settings", "status"], "trash");
                  dispatch({
                    type: "project/update-from-dashboard",
                    project: newProject,
                  });
                }

                return newProject;
              });
              setProjects(newProjects);
              setSelected(Map());
            }}
            className="project-dashboard__selected__button"
          >
            <Icon
              icon="delete"
              className="project-dashboard__delete-item-icon"
            />
            <span className="project-dashboard__delete-item-text">
              Delete Selected Projects
            </span>
          </button>
          <button
            onClick={() => {
              setSelected(Map());
            }}
            className="project-dashboard__selected__button project-dashboard__clear-selected__button"
          >
            <Icon
              icon="alert"
              className="project-dashboard__delete-item-icon"
            />
            <span className="project-dashboard__delete-item-text">
              Clear Selection of Projects
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export default ProjectsDashboard;
