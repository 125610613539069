import { Customer, ProjectSettings } from "../../entities";
import { getCustomerName } from "../../entities/utils";

const errorClassName = (className: string, errors: any, field: string) => {
  if (errors[field] === false || errors[field] !== true) {
    className = className + " input--error";
  }

  return className;
};

function getBillAddressFromCustomer(customer: Customer) {
  let lines: any[] = [];
  if (customer && customer.data && customer.data.billing) {
    if (customer.data.billing.name) {
      lines.push(customer.data.billing.name);
    } else {
      lines.push(getCustomerName(customer));
    }

    if (customer.data.billing.name) {
      lines.push(customer.data.billing.name);
    }

    if (customer.data.billing.street) {
      lines.push(customer.data.billing.street);
    }

    if (
      customer.data.billing.city &&
      customer.data.billing.state &&
      customer.data.billing.zip
    ) {
      lines.push(
        customer.data.billing.city +
          ", " +
          customer.data.billing.state +
          " " +
          customer.data.billing.zip
      );
    }
  } else if (
    customer &&
    customer.quickbooksData &&
    customer.quickbooksData.BillAddressBlock
  ) {
    lines = Object.values(customer.quickbooksData.BillAddressBlock).map(
      (item) => item
    );
  }

  return lines;
}

function getShipAddressFromCustomer(
  customer: Customer,
  settings: ProjectSettings
) {
  const customerName = getCustomerName(customer);
  let lines: any[] = [];

  if (
    settings.shipToAddress.overrideQBAddress &&
    settings.shipToAddress &&
    settings.shipToAddress.address &&
    settings.shipToAddress.address.street
  ) {
    let name = "";
    if (settings.shipToAddress.address.name) {
      name = settings.shipToAddress.address.name;
    } else {
      name = customerName;
    }
    if (name) {
      lines.push(name);
    }

    if (settings.shipToAddress.address.street) {
      lines.push(settings.shipToAddress.address.street);
    }

    if (
      settings.shipToAddress.address.city &&
      settings.shipToAddress.address.state &&
      settings.shipToAddress.address.zip
    ) {
      lines.push(
        settings.shipToAddress.address.city +
          ", " +
          settings.shipToAddress.address.state +
          " " +
          settings.shipToAddress.address.zip
      );
    }
  } else if (customer && customer.data && customer.data.shipping) {
    if (customer.data.shipping.name) {
      lines.push(customer.data.shipping.name);
    } else {
      lines.push(customerName);
    }

    if (customer.data.shipping.street) {
      lines.push(customer.data.shipping.street);
    }

    if (
      customer.data.shipping.city &&
      customer.data.shipping.state &&
      customer.data.shipping.zip
    ) {
      lines.push(
        customer.data.shipping.city +
          ", " +
          customer.data.shipping.state +
          " " +
          customer.data.shipping.zip
      );
    }
  } else if (
    customer &&
    customer.quickbooksData &&
    customer.quickbooksData.ShipAddressBlock
  ) {
    lines = Object.values(customer.quickbooksData.ShipAddressBlock).map(
      (item) => item
    );
  }

  return lines;
}

export {
  errorClassName,
  getBillAddressFromCustomer,
  getShipAddressFromCustomer,
};
