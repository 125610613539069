export function getMainScrewUpc() {
  // const screwUpc = 662187019321;
  const screwUpc = "QB:01033918054979";

  return screwUpc;
}

export function getWoodScrewUpcs() {
  return {
    "stainless-steel": 616320732882,
    "stainless-2507-flat": 691027723708,
    aluminum: 662187011585,
    wood: 616320732820,
  };
}

export function getWoodP2PWasherUpc() {
  return 616320730888;
}

export function getStainlessSteelTopRailBracketUpcs() {
  const stainlessTopRailBrackets = {
    "2507": {
      round: {
        terminal: {
          fixed: 0,
          adjustable: 0,
        },
        intermediate: {
          fixed: 0,
          adjustable: 0,
        },
      },
      square: {
        terminal: {
          fixed: 662187017440,
          adjustable: 0,
        },
        intermediate: {
          fixed: 662187017440,
          adjustable: 0,
        },
      },
    },
    round: {
      round: {
        terminal: {
          fixed: 616320733124,
          adjustable: 616320733117,
        },
        intermediate: {
          fixed: 616320731526,
          adjustable: 616320731519,
        },
      },
      square: {
        terminal: {
          fixed: 616320731366,
          adjustable: 616320731328,
        },
        intermediate: {
          fixed: 616320731359,
          adjustable: 616320731311,
        },
      },
    },
    flat: {
      round: {
        terminal: {
          fixed: 616320734787,
          adjustable: 616320733100,
        },
        intermediate: {
          fixed: 616320731595,
          adjustable: 616320731502,
        },
      },
      square: {
        terminal: {
          fixed: 616320731342,
          adjustable: 616320731304,
        },
        intermediate: {
          fixed: 616320731335,
          adjustable: 616320731298,
        },
      },
    },
  };

  return stainlessTopRailBrackets;
}

export function lagHardwareUpcs() {
  return {
    316: {
      '3/8" x 4 1/2"': 662187018669,
      '1/2" x 8"': 616453990227,
      '3/8" x 2-1/2"': 662187012834,
      '3/8" x 2"': 662187012858,
      '3/8" x 6"': 662187012865,
      '2507 4 1/2"': 691027724408,
    },
    304: {
      '3/8" x 4 1/2"': 662187019833,
      '3/8" x 2-1/2"': 616453987586,
      '3/8" x 4"': 616453987593,
      '3/8" x 5-1/2"': 616453991507,
      '3/8" x 6"': 616453991514,
      '3/8" x 7"': 616453991521,
      '3/8" x 8"': 616453991491,
      '2507 4 1/2"': 691027724408,
    },
  };
}

export function boltHardwareUpcs() {
  return {
    316: {
      '1/2" x 2 1/2"': 616453990470,
      '1/2" x 2"': 616453990463,
      '1/2" x 4"': 616453990487,
      '1/2" x 5"': 616453990494,
      '1/2" x 5 1/2"': 616453990500,
      '1/2" x 6"': 616453990517,
      '1/2" x 6 1/2"': 616453990524,
      '1/2" x 7"': 616453990531,
      '1/2" x 7 1/2"': 616453990548,
      '1/2" x 8"': 616453990555,
      '3/8" x 1"': 662187012735,
      '3/8" x 2"': 662187012742,
      '3/8" x 2 1/2"': 662187012759,
      '3/8" x 4"': 662187012766,
      '3/8" x 4 1/2"': 0,
      '3/8" x 5"': 662187012773,
      '3/8" x 5 1/2"': 662187012780,
      '3/8" x 6"': 662187012797,
      '3/8" x 6 1/2"': 662187012803,
      '3/8" x 7"': 662187012810,
      '3/8" x 8"': 662187012827,
      '2507 7"': 691027724415,
    },
    304: {
      '3/8" x 1"': 616453987470,
      '3/8" x 1 1/2"': 616453987463,
      '3/8" x 2"': 616453987494,
      '3/8" x 2 1/2"': 616453987487,
      '3/8" x 4"': 616453987500,
      '3/8" x 4 1/2"': 662187020617,
      '3/8" x 5"': 616453987524,
      '3/8" x 5 1/2"': 616453987517,
      '3/8" x 6"': 616453987548,
      '3/8" x 6 1/2"': 616453987531,
      '3/8" x 7"': 616453987562,
      '3/8" x 7 1/2"': 616453987555,
      '3/8" x 8"': 616453987579,
      '2507 7"': 691027724415,
    },
  };
}

export function listOf38HexBoltUpcs() {
  return [
    662187012735, 662187012742, 662187012759, 662187012766, 662187012773,
    662187012780, 662187012797, 662187012803, 662187012810, 662187012827,
    616453987470, 616453987463, 616453987494, 616453987487, 616453987500,
    662187020617, 616453987524, 616453987517, 616453987548, 616453987531,
    616453987562, 616453987555, 616453987579, 691027724415,
  ];
}

export function concreteHardwareUpcs() {
  return {
    tapcon: {
      '3/8" x 3"': 662187019352,
      '3/8" x 5"': 662187019345,
      '3/8" x 1 1/4"': 662187016955,
      '3/8" x 1 3/4"': 616453982918,
      '3/8" x 2 1/2"': 616453982925,
    },
    "drop-in": {
      '1/4" x 1 1/4"': 662187011615,
      '1/4" x 1 3/4"': 662187011608,
      '1/4" x 1 1/2"': 662187011592,
      '1/4" x 7/8"': 616320730512,
      '1/4" anchor': 616320730956,
      '3/8" anchor': 616320732929,
    },
    "wedge-anchor": {
      '1/2" x 7"': 616453990357,
      '1/4" x 2"': 616320730970,
      '1/4" x 3 1/4"': 662187012124,
      '3/8" x 5"': 616453990296,
    },
  };
}

export function cableUpcs2507() {
  return {
    "1 x 19 - 1/8”": 662187017310,
  };
}

export function cableUpcs() {
  return {
    "7 x 7 - 3/16”": 616320730161,
    "1 x 19 - 3/16”": 616320730154,
    "1 x 19 - 1/8”": 616320730147,
  };
}

export function fasciaBracketUpcs() {
  return {
    none: 0,
    aluminum: 0,
    "stainless-steel": {
      square: {
        "ss-square-4hole-rect": {
          intermediate: 616453982215,
          terminal: 616453982222,
        },
        "ss-round-4hole-rect": {
          intermediate: 0,
          terminal: 0,
        },
        "ss-round-2hole-rect": {
          intermediate: 0,
          terminal: 0,
        },
        "ss-round-2hole-round": {
          intermediate: 0,
          terminal: 0,
        },
      },
      round: {
        "ss-square-4hole-rect": {
          intermediate: 0,
          terminal: 0,
        },
        "ss-round-4hole-rect": {
          intermediate: 616320732592,
          terminal: 616453989733,
        },
        "ss-round-2hole-rect": {
          intermediate: 616320732585,
          terminal: 616320732585,
        },
        "ss-round-2hole-round": {
          intermediate: 616320732608,
          terminal: 616320732608,
        },
      },
      "2507-square": {
        "ss-square-4hole-rect": {
          intermediate: 616453982215,
          terminal: 616453982222,
        },
        "ss-round-4hole-rect": {
          intermediate: 0,
          terminal: 0,
        },
        "ss-round-2hole-rect": {
          intermediate: 0,
          terminal: 0,
        },
        "ss-round-2hole-round": {
          intermediate: 0,
          terminal: 0,
        },
      },
    },
  };
}

export function fasciaDirectMountDrillingService() {
  return "SERVICE STAINLESS:SVC-FasciaDrilling-SS";
}

export function fasciaDirectMountSpacerUpc() {
  return 616320732486;
}

export function undrilledPostUpcs() {
  return {
    aluminum: {
      bronze: {
        deck: {
          36: {
            intermediate: 616320729707,
            terminal: 616453983489,
          },
          42: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
          custom: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734626,
            terminal: 616320734497,
          },
          42: {
            intermediate: 616320734626,
            terminal: 616320734497,
          },
          custom: {
            intermediate: 616320734626,
            terminal: 616320734497,
          },
        },
        core: {
          36: {
            intermediate: 616320729707,
            terminal: 616453983489,
          },
          42: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
          custom: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729707,
            terminal: 616453983489,
          },
          42: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
          custom: {
            intermediate: 616320729707,
            terminal: 616320729806,
          },
        },
      },
      black: {
        deck: {
          36: {
            intermediate: 616320729691,
            terminal: 616453983007,
          },
          42: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
          custom: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
        },
        fascia: {
          36: {
            intermediate: 662187012155,
            terminal: 616320734480,
          },
          42: {
            intermediate: 662187012155,
            terminal: 616320734480,
          },
          custom: {
            intermediate: 662187012155,
            terminal: 616320734480,
          },
        },
        core: {
          36: {
            intermediate: 616320729691,
            terminal: 616453983007,
          },
          42: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
          custom: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729691,
            terminal: 616453983007,
          },
          42: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
          custom: {
            intermediate: 616320729691,
            terminal: 616320729790,
          },
        },
      },
      Natural: {
        deck: {
          36: {
            intermediate: 616320729721,
            terminal: 616453984158,
          },
          42: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
          custom: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
          42: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
          custom: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
        },
        core: {
          36: {
            intermediate: 616320729721,
            terminal: 616453984158,
          },
          42: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
          custom: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729721,
            terminal: 616453984158,
          },
          42: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
          custom: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
        },
      },
      clay: {
        deck: {
          36: {
            intermediate: 616320729714,
            terminal: 616453983878,
          },
          42: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
          custom: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734619,
            terminal: 616320734503,
          },
          42: {
            intermediate: 616320734619,
            terminal: 616320734503,
          },
          custom: {
            intermediate: 616320734619,
            terminal: 616320734503,
          },
        },
        core: {
          36: {
            intermediate: 616320729714,
            terminal: 616453983878,
          },
          42: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
          custom: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729714,
            terminal: 616453983878,
          },
          42: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
          custom: {
            intermediate: 616320729714,
            terminal: 616320729813,
          },
        },
      },
      white: {
        deck: {
          36: {
            intermediate: 616320729738,
            terminal: 616453984523,
          },
          42: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
          custom: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734596,
            terminal: 616320734527,
          },
          42: {
            intermediate: 616320734596,
            terminal: 616320734527,
          },
          custom: {
            intermediate: 616320734596,
            terminal: 616320734527,
          },
        },
        core: {
          36: {
            intermediate: 616320729738,
            terminal: 616453984523,
          },
          42: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
          custom: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729738,
            terminal: 616453984523,
          },
          42: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
          custom: {
            intermediate: 616320729738,
            terminal: 616320729837,
          },
        },
      },
      "anodized-black": {
        deck: {
          36: {
            intermediate: 691027710128,
            terminal: 691027710142,
          },
          42: {
            intermediate: 691027710128,
            terminal: 691027710159,
          },
          custom: {
            intermediate: 691027722411,
            terminal: 616320729790,
          },
        },
        fascia: {
          36: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          42: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          custom: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
        },
        core: {
          36: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          42: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          custom: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
        },
        // Ponywall is not used but we want to still have valid upcs for type checks to pass.
        "pony-wall": {
          36: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          42: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
          custom: {
            intermediate: 691027710197,
            terminal: 691027710210,
          },
        },
      },
      "anodized-clear": {
        deck: {
          36: {
            intermediate: 691027710609,
            terminal: 691027710623,
          },
          42: {
            intermediate: 691027710609,
            terminal: 691027710630,
          },
          custom: {
            intermediate: 616320729721,
            terminal: 616320729820,
          },
        },
        fascia: {
          36: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          42: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          custom: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
        },
        core: {
          36: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          42: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          custom: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          42: {
            intermediate: 691027710661,
            terminal: 691027710685,
          },
          custom: {
            intermediate: 616320734602,
            terminal: 616320734510,
          },
        },
      },
    },
    "stainless-steel": {
      square: {
        deck: {
          36: {
            intermediate: 616453987975,
            terminal: 616453988057,
          },
          42: {
            intermediate: 616453987982,
            terminal: 616453988064,
          },
          custom: {
            intermediate: 616453987982,
            terminal: 616453988064,
          },
        },
        fascia: {
          36: {
            intermediate: 616453988118,
            terminal: 616453988170,
          },
          42: {
            intermediate: 616453988125,
            terminal: 616453988187,
          },
          custom: {
            intermediate: 616453988125,
            terminal: 616453988187,
          },
        },
        core: {
          36: {
            intermediate: 616453987838,
            terminal: 616453987913,
          },
          42: {
            intermediate: 616453987845,
            terminal: 616453987920,
          },
          custom: {
            intermediate: 616453987845,
            terminal: 616453987920,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616453987838,
            terminal: 616453987913,
          },
          42: {
            intermediate: 616453987845,
            terminal: 616453987920,
          },
          custom: {
            intermediate: 616453987845,
            terminal: 616453987920,
          },
        },
      },
      "2507-square": {
        deck: {
          36: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          42: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          custom: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
        },
        fascia: {
          36: {
            intermediate: 691027724774,
            terminal: 691027724767,
          },
          42: {
            intermediate: 691027724774,
            terminal: 691027724767,
          },
          custom: {
            intermediate: 691027724774,
            terminal: 691027724767,
          },
        },
        core: {
          36: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          42: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          custom: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          42: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
          custom: {
            intermediate: 691027724750,
            terminal: 691027724743,
          },
        },
      },
      round: {
        deck: {
          36: {
            intermediate: 616453988613,
            terminal: 616453988675,
          },
          42: {
            intermediate: 616453988620,
            terminal: 616453988682,
          },
          custom: {
            intermediate: 616453988620,
            terminal: 616453988682,
          },
        },
        fascia: {
          36: {
            intermediate: 616453988712,
            terminal: 616453988774,
          },
          42: {
            intermediate: 616453988729,
            terminal: 616453988781,
          },
          custom: {
            intermediate: 616453988729,
            terminal: 616453988781,
          },
        },
        core: {
          36: {
            intermediate: 616453988484,
            terminal: 616453988545,
          },
          42: {
            intermediate: 616453988477,
            terminal: 616453988491,
          },
          custom: {
            intermediate: 616453988477,
            terminal: 616453988491,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616453988484,
            terminal: 616453988545,
          },
          42: {
            intermediate: 616453988477,
            terminal: 616453988491,
          },
          custom: {
            intermediate: 616453988477,
            terminal: 616453988491,
          },
        },
      },
    },
    wood: {
      "4x4 Batu": {
        deck: {
          36: {
            intermediate: 616320734695,
            terminal: 616320734695,
          },
          42: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
          custom: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734695,
            terminal: 616320734695,
          },
          42: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
          custom: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
        },
        core: {
          36: {
            intermediate: 616320734695,
            terminal: 616320734695,
          },
          42: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
          custom: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320734695,
            terminal: 616320734695,
          },
          42: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
          custom: {
            intermediate: 616320734701,
            terminal: 616320734701,
          },
        },
      },
    },
    "customer-provided": {},
  };
}

export function getToprailInstallKitUpcs() {
  return {
    aluminum: 0,
    "stainless-steel": {
      flat: 662187017129,
      round: 616453987685,
    },
    wood: 616453987784,
    "customer-provided": 0,
  };
}

export function getStainlessToprailInstallKitDrillBitUpcs() {
  return {
    flat: 662187013961,
    round: 616320731106,
    "2507": 662187013961,
  };
}

export function getToprailCornerInstallKitUpcs() {
  return {
    aluminum: 616453987630,
    "stainless-steel": 0,
    wood: 0,
    "customer-provided": 0,
  };
}

export function ponyWallPostUpcs() {
  return {
    aluminum: {
      intermediate: 616320730093,
      terminal: 616320730130,
    },
    "stainless-steel": {
      square: {
        36: { terminal: 616453987913, intermediate: 616453987838 },
        42: { terminal: 616453987920, intermediate: 616453987845 },
        custom: { terminal: 616453987920, intermediate: 616453987845 },
      },
      "2507-square": {
        36: { terminal: 691027711187, intermediate: 691027711187 },
        42: { terminal: 662187017549, intermediate: 662187017549 },
        custom: { terminal: 662187017549, intermediate: 662187017549 },
      },
      round: {
        36: { terminal: 616453988545, intermediate: 616453988484 },
        42: { terminal: 616453988552, intermediate: 616453988491 },
        custom: { terminal: 616453988552, intermediate: 616453988491 },
      },
    },
  };
}

export function ponyWallBasePlateUpcs() {
  return {
    aluminum: 662187011530,
    "stainless-steel": {
      square: 662187016436,
      round: 662187013107,
      "2507-square": 662187016436,
    },
  };
}

export function ponyWallMitreCutServices() {
  return {
    aluminum: {
      stairs: "SERVICE ALUMINUM:SVC-MiterCutting-AL",
      flat: "SERVICE ALUMINUM:SVC-PostCutting-AL",
    },
    "stainless-steel": "SERVICE STAINLESS:SVC-CustomFab-SS",
    "stainless-steel-2507": "SERVICE STAINLESS:SVC-MitreCutting-SS",
  };
}

export function miterCuttingServices() {
  return {
    aluminum: {
      stairs: "SERVICE ALUMINUM:SVC-MiterCutting-AL",
      flat: "SERVICE ALUMINUM:SVC-PostCutting-AL",
    },
    "stainless-steel": {
      "pony-wall": "SERVICE STAINLESS:SVC-CustomFab-SS",
      "2507": "SERVICE STAINLESS:SVC-MitreCutting-SS",
    },
  };
}

export function ponyWallWeldingServices() {
  return {
    aluminum: 662187020358,
    "stainless-steel": "SERVICE STAINLESS:SVC-FlangeWelding-SS",
  };
}

export function ponyWallPowderCoatingServices() {
  return {
    aluminum: {
      black: "SERVICE ALUMINUM:SVC-Custom-Powder-AL",
      bronze: "SERVICE ALUMINUM:SVC-Custom-Powder-AL",
      Natural: "SERVICE ALUMINUM:SVC-Custom-Powder-AL",
      clay: "SERVICE ALUMINUM:SVC-Custom-Powder-AL",
      white: "SERVICE ALUMINUM:SVC-Custom-Powder-AL",
      "anodized-black": "SERVICE ALUMINUM:SVC-Custom-Anodize-AL",
      "anodized-clear": "SERVICE ALUMINUM:SVC-Custom-Anodize-AL",
    },
  };
}

export function ponyWallHoleDrillingService() {
  return {
    aluminum: "SVC-HoleDrilling",
    "stainless-steel": "SVC-HoleDrilling",
  };
}

export function postUpcs() {
  return {
    aluminum: {
      bronze: {
        deck: {
          36: {
            intermediate: 616320729608,
            terminal: 616320729653,
          },
          42: {
            intermediate: 616320729608,
            terminal: 616320734572,
          },
          custom: {
            intermediate: 616320729608,
            terminal: 616320734572,
          },
        },
        fascia: {
          36: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          42: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          custom: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
        },
        core: {
          36: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          42: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          custom: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          42: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
          custom: {
            intermediate: 616320729752,
            terminal: 616320729851,
          },
        },
      },
      black: {
        deck: {
          36: {
            intermediate: 616320729592,
            terminal: 616320729646,
          },
          42: {
            intermediate: 616320729592,
            terminal: 616320734589,
          },
          custom: {
            intermediate: 616320729592,
            terminal: 616320734589,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          42: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          custom: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
        },
        core: {
          36: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          42: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          custom: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          42: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
          custom: {
            intermediate: 616320734633,
            terminal: 616320729844,
          },
        },
      },
      Natural: {
        deck: {
          36: {
            intermediate: 616320729622,
            terminal: 616320729677,
          },
          42: {
            intermediate: 616320729622,
            terminal: 616320734558,
          },
          custom: {
            intermediate: 616320729622,
            terminal: 616320734558,
          },
        },
        fascia: {
          36: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          42: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
        core: {
          36: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          42: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          42: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
      },
      clay: {
        deck: {
          36: {
            intermediate: 616320729615,
            terminal: 616320729660,
          },
          42: {
            intermediate: 616320729615,
            terminal: 616320734565,
          },
          custom: {
            intermediate: 616320729615,
            terminal: 616320734565,
          },
        },
        fascia: {
          36: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          42: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          custom: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
        },
        core: {
          36: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          42: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          custom: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          42: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
          custom: {
            intermediate: 616320729769,
            terminal: 616320729868,
          },
        },
      },
      white: {
        deck: {
          36: {
            intermediate: 616320729639,
            terminal: 616320729684,
          },
          42: {
            intermediate: 616320729639,
            terminal: 616320734541,
          },
          custom: {
            intermediate: 616320729639,
            terminal: 616320734541,
          },
        },
        fascia: {
          36: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          42: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          custom: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
        },
        core: {
          36: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          42: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          custom: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          42: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
          custom: {
            intermediate: 616320729783,
            terminal: 616320729882,
          },
        },
      },
      "anodized-black": {
        deck: {
          36: {
            intermediate: 691027710135,
            terminal: 691027710166,
          },
          42: {
            intermediate: 691027710135,
            terminal: 691027710173,
          },
          custom: {
            intermediate: 691027722411,
            terminal: 616320734589,
          },
        },
        fascia: {
          36: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          42: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          custom: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
        },
        core: {
          36: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          42: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          custom: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
        },
        // Ponywall is not used but we want to still have valid upcs for type checks to pass.
        "pony-wall": {
          36: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          42: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
          custom: {
            intermediate: 691027710203,
            terminal: 691027710227,
          },
        },
      },
      "anodized-clear": {
        deck: {
          36: {
            intermediate: 691027710616,
            terminal: 691027710647,
          },
          42: {
            intermediate: 691027710616,
            terminal: 691027710654,
          },
          custom: {
            intermediate: 616320729622,
            terminal: 616320734558,
          },
        },
        fascia: {
          36: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          42: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
        core: {
          36: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          42: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          42: {
            intermediate: 691027710678,
            terminal: 691027710692,
          },
          custom: {
            intermediate: 616320729776,
            terminal: 616320729875,
          },
        },
      },
    },
    "stainless-steel": {
      square: {
        deck: {
          36: {
            intermediate: 616453987951,
            terminal: 616453988019,
          },
          42: {
            intermediate: 616453987968,
            terminal: 616453988026,
          },
          custom: {
            intermediate: 616453987968,
            terminal: 616453988026,
          },
        },
        fascia: {
          36: {
            intermediate: 616453988095,
            terminal: 616453988156,
          },
          42: {
            intermediate: 616453988101,
            terminal: 616453988163,
          },
          custom: {
            intermediate: 616453988101,
            terminal: 616453988163,
          },
        },
        core: {
          36: {
            intermediate: 616453987814,
            terminal: 616453987890,
          },
          42: {
            intermediate: 616453987821,
            terminal: 616453987883,
          },
          custom: {
            intermediate: 616453987821,
            terminal: 616453987883,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616453987814,
            terminal: 616453987890,
          },
          42: {
            intermediate: 616453987821,
            terminal: 616453987883,
          },
          custom: {
            intermediate: 616453987821,
            terminal: 616453987883,
          },
        },
      },
      "2507-square": {
        deck: {
          36: {
            intermediate: 691027724712,
            terminal: 691027711187,
          },
          42: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
          custom: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
        },
        fascia: {
          36: {
            intermediate: 691027724729,
            terminal: 691027722084,
          },
          42: {
            intermediate: 691027724736,
            terminal: 691027722091,
          },
          custom: {
            intermediate: 691027724736,
            terminal: 691027722091,
          },
        },
        core: {
          36: {
            intermediate: 691027724712,
            terminal: 691027711187,
          },
          42: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
          custom: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 691027724712,
            terminal: 691027711187,
          },
          42: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
          custom: {
            intermediate: 662187017587,
            terminal: 662187017549,
          },
        },
      },
      round: {
        deck: {
          36: {
            intermediate: 616453988590,
            terminal: 616453988651,
          },
          42: {
            intermediate: 616453988606,
            terminal: 616453988668,
          },
          custom: {
            intermediate: 616453988606,
            terminal: 616453988668,
          },
        },
        fascia: {
          36: {
            intermediate: 616453988699,
            terminal: 616453988750,
          },
          42: {
            intermediate: 616453988705,
            terminal: 616453988767,
          },
          custom: {
            intermediate: 616453988705,
            terminal: 616453988767,
          },
        },
        core: {
          36: {
            intermediate: 616453988507,
            terminal: 616453988569,
          },
          42: {
            intermediate: 616453988477,
            terminal: 616453988538,
          },
          custom: {
            intermediate: 616453988477,
            terminal: 616453988538,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616453988507,
            terminal: 616453988569,
          },
          42: {
            intermediate: 616453988477,
            terminal: 616453988538,
          },
          custom: {
            intermediate: 616453988477,
            terminal: 616453988538,
          },
        },
      },
    },
    wood: {
      "4x4 Batu": {
        deck: {
          36: {
            intermediate: 616320734718,
            terminal: 616320734732,
          },
          42: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
          custom: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
        },
        fascia: {
          36: {
            intermediate: 616320734718,
            terminal: 616320734732,
          },
          42: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
          custom: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
        },
        core: {
          36: {
            intermediate: 616320734718,
            terminal: 616320734732,
          },
          42: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
          custom: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
        },
        "pony-wall": {
          36: {
            intermediate: 616320734718,
            terminal: 616320734732,
          },
          42: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
          custom: {
            intermediate: 616320734725,
            terminal: 616320734749,
          },
        },
      },
    },
  };
}

export function getPassivationServiceName() {
  return "SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS";
}

export function getGateUpcs() {
  return {
    wood: {
      "clear-cedar": { "3/16": 616320731243, "1/8": 662187019208 },
      "tight-knot-cedar": { "3/16": 616320731243, "1/8": 662187019208 },
      ipe: { "3/16": 616320730949, "1/8": 616453988842 },
      balau: { "3/16": 616320730949, "1/8": 616453988842 },
      cedar: { "3/16": 616320731243, "1/8": 662187019208 },
    },
    aluminum: { "3/16": 662187017051, "1/8": 616453984844 },
    "stainless-steel": {
      square: { "3/16": 616320730895, "1/8": 616453990715 },
      "2507-square": { "3/16": 616320730895, "1/8": 616453990715 },
      round: { "3/16": 616320730901, "1/8": 616453988446 },
    },
    // Same as wood.
    "customer-provided": {
      "clear-cedar": { "3/16": 616320731243, "1/8": 662187019208 },
      "tight-knot-cedar": { "3/16": 616320731243, "1/8": 662187019208 },
      ipe: { "3/16": 616320730949, "1/8": 616453988842 },
      balau: { "3/16": 616320730949, "1/8": 616453988842 },
    },
  };
}

export function getPassivationSprayUpc() {
  return 616320731151;
}

export function getRustRescueUpc() {
  return 662187012445;
}

export function getCrimperUpc() {
  return 616320731878;
}

export function getCableCutterUpcs() {
  return {
    small: 616320731908,
    large: 616320731915,
  };
}

export function getP2PInstallKitUpc() {
  return 616453987777;
}

export function getEpoxyUpcs() {
  return {
    large: 616453989245,
    small: 616320731120,
  };
}

export function getDieCrimperUpcs() {
  return {
    "1/8": 691027722671,
    "3/16": 691027722688,
    "1/8-regular": 616320734916,
    "3/16-regular": 616320731892,
  };
}

export function getPostStockUpcs() {
  return {
    aluminum: {
      black: {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      bronze: {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      clay: {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      Natural: {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      white: {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      "anodized-black": {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
      "anodized-clear": {
        intermediate: 616320730093,
        terminal: 616320730130,
      },
    },
    "stainless-steel": {
      round: {
        intermediate: 616453988576,
        terminal: 662187012094,
      },
      square: {
        intermediate: 662187012087,
        terminal: 616453988231,
      },
      "2507-square": {
        intermediate: 0,
        terminal: 0,
      },
    },
    wood: {
      "4x4 Batu": { terminal: 662187019215, intermediate: 662187019215 },
    },
    "customer-provided": {
      terminal: 0,
      intermediate: 0,
    },
  };
}

export function getCableCrimperHolderUpc() {
  return 662187019260;
}

export function getGrommetUpcs() {
  return {
    intermediate: 662187013046,
    terminal: 662187013039,
    installKit: 662187014074,
  };
}

export function get2507InstallKitUpc() {
  return 691027724538;
}

export function getDrillingServiceNames(): { [key: string]: string } {
  return {
    "stainless-steel": "SERVICE STAINLESS:SVC-HoleDrilling-Angled-S.S.",
    aluminum: "SERVICE ALUMINUM:SVC-HoleDrilling-Angled-AL",
    wood: "*SVC-HoleDrilling-18-Angled",
    "customer-provided": "",
  };
}

export function getTouchupPaintUpcs() {
  return {
    bronze: 616320729554,
    black: 616320729547,
    clay: 616320729561,
    Natural: 616320729578,
    white: 616320729585,
    "anodized-black": 616320729547,
    "anodized-clear": 616320729578,
  };
}

export function getStainlessSteelTopRailSpliceUpcs() {
  return {
    flat: 616320734466,
    round: 616320731564,
    "2507": 662187017495,
  };
}

export function getAluminumTopRailSpliceUpcs() {
  return {
    "alum-p2p": 616453984899,
    "wood-p2p": 616453984899,
    shaped: 616453984936,
    rectangular: 616453984936,
  };
}

export function getAluminumScrewUpcs() {
  return {
    black: 616453990579,
    bronze: 616453990586,
    clay: 616453990616,
    Natural: 616453990616,
    white: 616453990593,
    "anodized-black": 616453990579,
    "anodized-clear": 616453990616,
  };
}

export function getAluminumMountingHardwareCoverUpcs() {
  return {
    black: 662187012988,
    bronze: 662187012995,
    clay: 662187013008,
    Natural: 662187013015,
    white: 662187013022,
    "anodized-black": 662187012988,
    "anodized-clear": 662187013015,
  };
}

export function getCornerUpcs() {
  return {
    aluminum: {
      rectangular: {
        black: {
          adjustable: 616453982987,
          right: 616453982949,
        },
        bronze: {
          adjustable: 616453983465,
          right: 616453983427,
        },
        clay: {
          adjustable: 616453983854,
          right: 616453983816,
        },
        Natural: {
          adjustable: 616453984134,
          right: 616453984097,
        },
        white: {
          adjustable: 616453984509,
          right: 616453984462,
        },
        "anodized-black": {
          adjustable: 691027710708,
          right: 691027710708,
        },
        "anodized-clear": {
          adjustable: 691027710715,
          right: 691027710715,
        },
      },
      shaped: {
        black: {
          adjustable: 616453982994,
          right: 616453982956,
        },
        bronze: {
          adjustable: 616453983472,
          right: 616453983434,
        },
        clay: {
          adjustable: 616453983861,
          right: 616453983823,
        },
        Natural: {
          adjustable: 616453984141,
          right: 616453984103,
        },
        white: {
          adjustable: 616453984516,
          right: 616453984479,
        },
        "anodized-black": {
          adjustable: 0,
          right: 0,
        },
        "anodized-clear": {
          adjustable: 0,
          right: 0,
        },
      },
      "alum-p2p": {
        black: {
          adjustable: 616453983038,
          right: 616453983021,
        },
        bronze: {
          adjustable: 616453983519,
          right: 616453983502,
        },
        clay: {
          adjustable: 616453983908,
          right: 616453983892,
        },
        Natural: {
          adjustable: 616453984189,
          right: 616453984172,
        },
        white: {
          adjustable: 616453984554,
          right: 616453984547,
        },
        "anodized-black": {
          adjustable: 616453983038,
          right: 691027722329,
        },
        "anodized-clear": {
          adjustable: 616453984189,
          right: 691027722336,
        },
      },
      "wood-p2p": {
        black: {
          adjustable: 616453983038,
          right: 616453983021,
        },
        bronze: {
          adjustable: 616453983519,
          right: 616453983502,
        },
        clay: {
          adjustable: 616453983908,
          right: 616453983892,
        },
        Natural: {
          adjustable: 616453984189,
          right: 616453984172,
        },
        white: {
          adjustable: 616453984554,
          right: 616453984547,
        },
        "anodized-black": {
          adjustable: 616453983038,
          right: 691027722329,
        },
        "anodized-clear": {
          adjustable: 616453984189,
          right: 691027722336,
        },
      },
    },
    "stainless-steel": {
      round: {
        adjustable: 616320731427,
        right: 616320731410,
      },
      flat: {
        adjustable: 616320734763,
        right: 616320734442,
      },
      "2507": {
        adjustable: 662187017471,
        right: 662187017471,
      },
    },
    wood: null,
    "customer-provided": null,
  };
}

export function getShrinkTubeUpcs() {
  return {
    terminal: 616320732875,
    intermediate: 616320732868,
  };
}

export function getAluminumBasePlateForWoodToprailUpcs() {
  return {
    "2x4": {
      black: 616453982963,
      bronze: 616453983441,
      clay: 616453983830,
      Natural: 616453984110,
      white: 616453984486,
      "anodized-black": 616453982963,
      "anodized-clear": 616453984110,
    },
    "5/4x4": {
      black: 616453982963,
      bronze: 616453983441,
      clay: 616453983830,
      Natural: 616453984110,
      white: 616453984486,
      "anodized-black": 616453982963,
      "anodized-clear": 616453984110,
    },
    "2x6": {
      black: 616453982970,
      bronze: 616453983458,
      clay: 616453983847,
      Natural: 616453984127,
      white: 616453984493,
      "anodized-black": 691027710111,
      "anodized-bronze": 691027722695,
      "anodized-clear": 691027710593,
    },
    "5/4x6": {
      black: 616453982970,
      bronze: 616453983458,
      clay: 616453983847,
      Natural: 616453984127,
      white: 616453984493,
      "anodized-black": 691027710111,
      "anodized-bronze": 691027722695,
      "anodized-clear": 691027710593,
    },
  };
}

export function getStainlessLowProfileUpcs() {
  return {
    welded: { intermediate: 616453990654, terminal: 616453990654 },
    normal: { intermediate: 616320731373, terminal: 616320731380 },
  };
}

export function getStainless2507LowProfileUpcs() {
  return {
    welded: { intermediate: 662187017440, terminal: 662187017440 },
    normal: { intermediate: 662187017440, terminal: 662187017440 },
  };
}

export function getStainlessWeldService() {
  return {
    flange: "SERVICE STAINLESS:SVC-FlangeWelding-SS",
    caps: "SERVICE STAINLESS:SVC-PostCapWelding-SS",
  };
}

export function getWeldedBasePlateUpcs() {
  return {
    square: { intermediate: 662187012704, terminal: 662187012728 },
    round: { intermediate: 662187012711, terminal: 662187012711 },
    "2507-square": { intermediate: 0, terminal: 0 },
  };
}

export function getStainlessLowProfileStairUpcs() {
  return {
    intermediate: 616320731182,
    terminal: 616320731199,
  };
}

export function getStainless2507LowProfileStairUpcs() {
  return {
    intermediate: 662187017440,
    terminal: 662187017440,
  };
}

export function getElectroPolishUpc() {
  return "SERVICE STAINLESS:SVC-Electropolish-SSR";
}

export function getAluminumPostCapUpcs() {
  return {
    black: {
      terminal: 616453983366,
      intermediate: 616453983359,
    },
    bronze: {
      terminal: 616453983762,
      intermediate: 616453983755,
    },
    clay: {
      terminal: 616453984035,
      intermediate: 616453984028,
    },
    Natural: {
      terminal: 616453984417,
      intermediate: 616453984400,
    },
    white: {
      terminal: 616453984783,
      intermediate: 616453984776,
    },
    "anodized-black": {
      terminal: 691027710531,
      intermediate: 691027710531,
    },
    "anodized-clear": {
      terminal: 691027710548,
      intermediate: 691027710548,
    },
  };
}

export function getStainlessPostCapUpcs() {
  return {
    square: {
      intermediate: 616320732264,
      terminal: 616320732271,
    },
    round: {
      intermediate: 616453982185,
      terminal: 616320734756,
    },
    "2507-square": {
      intermediate: 0,
      terminal: 0,
    },
  };
}

export function getAluminumSnapCoverUpcs() {
  return {
    // Anodized are not assemblies.
    "anodized-black": 662187020129,
    "anodized-clear": 662187019475,
    "anodized-bronze": 691027722633,
    black: 662187017006,
    bronze: 662187017013,
    clay: 662187017020,
    Natural: 662187017037,
    white: 662187017044,
  };
}

export function getEndCapUpcs() {
  return {
    aluminum: {
      rectangular: {
        black: 616453983373,
        bronze: 616453983779,
        clay: 616453984042,
        Natural: 616453984424,
        white: 616453984790,
        "anodized-black": 691027710555,
        "anodized-clear": 691027710722,
      },
      shaped: {
        black: 616453983397,
        bronze: 616453983793,
        clay: 616453984066,
        Natural: 616453984448,
        white: 616453984813,
        "anodized-black": 0,
        "anodized-clear": 0,
      },
      "alum-p2p": {
        black: null,
        bronze: null,
        clay: null,
        Natural: null,
        white: null,
        "anodized-black": null,
        "anodized-clear": null,
      },
      // "alum-p2p": {
      //   black: 616453983373,
      //   bronze: 616453983779,
      //   clay: 616453984042,
      //   Natural: 616453984424,
      //   white: 616453984790,
      //   "anodized-black": 691027710555,
      //   "anodized-clear": 691027710722,
      // },
      "wood-p2p": {
        black: null,
        bronze: null,
        clay: null,
        Natural: null,
        white: null,
        "anodized-black": null,
        "anodized-clear": null,
      },
      // "wood-p2p": {
      //   black: 616453983373,
      //   bronze: 616453983779,
      //   clay: 616453984042,
      //   Natural: 616453984424,
      //   white: 616453984790,
      //   "anodized-black": 691027710555,
      //   "anodized-clear": 691027710722,
      // },
    },
    "stainless-steel": {
      flat: 616320734459,
      round: 616453982185,
    },
    wood: null,
    "customer-provided": null,
  };
}

export function getEpoxyKitUpcs() {
  return {
    "readhead-g5": 616453990203,
    "3m-scotch-weld": 616453988439,
  };
}

export function getCoverPlateUpcs() {
  return {
    aluminum: {
      black: 691027722718,
      bronze: 691027722725,
      clay: 691027722732,
      Natural: 691027722732,
      white: 691027722749,
      "anodized-black": 691027722718,
      "anodized-clear": 691027722732,
    },
    "stainless-steel": {
      // For round intermediate and terminal are the same.
      round: {
        intermediate: {
          nonwelded: 662187010892,
          welded: 662187010892,
        },
        terminal: {
          nonwelded: 662187010892,
          welded: 662187010892,
        },
      },
      // welded square baseplates are the same as nonwelded.
      square: {
        intermediate: {
          nonwelded: 616453989184,
          welded: 616453989184,
        },
        terminal: {
          nonwelded: 616453991866,
          welded: 616453991866,
        },
      },
      "2507-square": {
        intermediate: {
          nonwelded: 0,
          welded: 0,
        },
        terminal: {
          nonwelded: 0,
          welded: 0,
        },
      },
    },
    wood: 0,
    "customer-provided": 0,
  };
}

export function getStainlessFlangeUpcs() {
  return {
    round: {
      intermediate: 616320731458,
      terminal: 616320733094,
    },
    square: {
      terminal: 616320731236,
      intermediate: 616320731229,
    },
    "2507-square": {
      terminal: 0,
      intermediate: 0,
    },
  };
}

export function getSurfaceMountFittingsUpcs() {
  return {
    "7 x 7 - 3/16”": {},
    "1 x 19 - 3/16”": {},
    "1 x 19 - 1/8”": {},
  };
}

export function getSurfaceMountFittingsScrewUpcs() {
  return 616320730338;
}

export function getSurfaceMountSleeveUpcs() {
  return {
    "7 x 7 - 3/16”": 616320732097,
    "1 x 19 - 3/16”": 616320732097,
    "1 x 19 - 1/8”": 616320732080,
  };
}

export function getSurfaceMountInstallKitUpcs() {
  return { "1 x 19 - 1/8”": 616453991750 };
}

export function getBlackOxideCableKitUpcs() {
  return {
    swageless: {
      "type-2": {
        long: {
          // For wood add 2 HARDWARE MF:HW-Washer-15mmID-28mmOD BLACK OXIDE
          10: 662187019048,
          20: 662187018720,
          30: 662187018737,
          40: 662187018744,
        },
        short: {
          10: 662187018829,
          20: 662187018836,
          30: 662187018843,
          40: 662187018850,
        },
      },
    },
    "field-swaged": {
      10: 662187018751,
      20: 662187018768,
      30: 662187018775,
      40: 662187018782,
    },
    "quick-receiver": {
      long: {
        10: 662187019116,
        20: 662187019123,
        30: 662187019130,
        40: 662187019147,
      },
      short: {
        10: 662187019154,
        20: 662187019161,
        30: 662187019178,
        40: 662187019185,
      },
    },
  };
}

export function fittingsGroup(items: any) {
  return {
    type: "fittingsGroup",
    items: items,
  };
}

export function getFittingsItems() {
  const fittings = {
    "7 x 7 - 3/16”": {
      wood: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-ball": {
            "field-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Low profile only available for 1/8" cable.
          "low-profile": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 1 },
            // Terminal
            { upc: 616320732554, quantity: 1 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 2 },
            // Terminal
            { upc: 616320732554, quantity: 0 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
        },
      },
      metal: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                { upc: 616320730314, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                // { upc: 616320730314, quantity: 2 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                { upc: 616320730314, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                // { upc: 616320730314, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-ball": {
            "field-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Low profile only available for 1/8" cable.
          "low-profile": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 1 },
            // Terminal
            { upc: 616320732554, quantity: 1 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 2 },
            // Terminal
            { upc: 616320732554, quantity: 0 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
        },
      },
    },
    "1 x 19 - 3/16”": {
      wood: {
        swageless: {
          "type-1": {
            normal: fittingsGroup([]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 616320731052, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS USED FOR WOOD INSTALLATIONS
                  { upc: 616320731052, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-ball": {
            "field-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Low profile only available for 1/8" cable.
          "low-profile": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 1 },
            // Terminal
            { upc: 616320732554, quantity: 1 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 2 },
            // Terminal
            { upc: 616320732554, quantity: 0 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
        },
      },
      metal: {
        swageless: {
          "type-1": {
            normal: fittingsGroup([]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                { upc: 616320730314, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                { upc: 616320732073, quantity: 1 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                // { upc: 616320730314, quantity: 2 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // ACORN NUT FOR 3/16" FITTINGS
                { upc: 616320730260, quantity: 2 },
                // HEX JAM NUT FOR 3/16" FITTINGS
                { upc: 616320730277, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                { upc: 616320730314, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                { upc: 616320732059, quantity: 2 },
                // 3/16 quick nut.
                { upc: 616320731762, quantity: 2 },
                // 3/16 quick nut cover
                { upc: 616320731786, quantity: 2 },
                // WASHER FOR 3/16" FITTINGS
                // { upc: 616320730314, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732073, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320732059, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 1 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 3/16" CABLE
                  { upc: 616320732011, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // ACORN NUT FOR 3/16" FITTINGS
                  { upc: 616320730260, quantity: 2 },
                  // HEX JAM NUT FOR 3/16" FITTINGS
                  { upc: 616320730277, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  { upc: 616320730314, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // FACTORY SWAGED THREADED TENSIONING END FOR 3/16" CABLE
                  { upc: 616320731991, quantity: 2 },
                  // 3/16 quick nut.
                  { upc: 616320731762, quantity: 2 },
                  // 3/16 quick nut cover
                  { upc: 616320731786, quantity: 2 },
                  // WASHER FOR 3/16" FITTINGS
                  //   { upc: 616320730314, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735074, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 3/16" CABLE
              { upc: 616320735043, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-ball": {
            "field-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985810, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BALL END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735050, quantity: 1 },
              // Ball End Assembly - Factory Swaged for 3/16" Cable
              { upc: 616453985797, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985858, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // CLASSIC BUTTON END TURNBUCKLE ASSEMBLY FOR 3/16" CABLE
              { upc: 616320735067, quantity: 1 },
              // Button End Assembly - Field Swaged for 3/16" Cable
              { upc: 616453985834, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Low profile only available for 1/8" cable.
          "low-profile": {
            short: fittingsGroup([]),
            long: fittingsGroup([]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 1 },
            // Terminal
            { upc: 616320732554, quantity: 1 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732530, quantity: 2 },
            // Terminal
            { upc: 616320732554, quantity: 0 },
            // Screw
            { upc: 616320730390, quantity: 4 },
            // Sleeve
            { upc: 616320732097, quantity: 0 },
          ]),
        },
      },
    },
    "1 x 19 - 1/8”": {
      wood: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // Wood Washer.
                { upc: 616320732813, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 616320732066, quantity: 1 },
              ]),
              "quick-nut": fittingsGroup([
                // Wood Washer not added in Quick Nut for now.
                { upc: 616320732813, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 616320731779, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 616320731755, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 616320732066, quantity: 1 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // Wood Washer.
                { upc: 616320732813, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // Wood Washer not added in Quick Nut for now.
                { upc: 616320732813, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 616320731779, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 616320731755, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Wood Washer.
                  { upc: 616320732813, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732066, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732066, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Wood Washer.
                  { upc: 616320732813, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732066, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732042, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Wood Washer
                  { upc: 616320732813, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732004, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732004, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Wood Washer
                  { upc: 616320732813, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // Ball End Assembly - Field Swaged for 1/8" Cable
              { upc: 616453985803, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // SwageBallEnd-Fact1/8
              { upc: 616453985780, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly -  Field Swaged for 1/8" Cable
              { upc: 616453985841, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly- Factory Swaged for 1/8" Cable
              { upc: 616453985827, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Classic not available in 1/8"
          "classic-ball": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          // Receiver Stud Length.
          "low-profile": {
            // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
            short: fittingsGroup([{ upc: 616320735012, quantity: 1 }]),
            // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
            long: fittingsGroup([{ upc: 616453982079, quantity: 1 }]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([
              // Cable Quick Nut Cover 1/8"
              { upc: 616320731779, quantity: 2 },
              // Cable Quick Nut 1/8"
              { upc: 616320731755, quantity: 2 },
              // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
              { upc: 616320731984, quantity: 1 },
              // WASHER FOR 1/8" FITTINGS USED FOR WOOD INSTALLATIONS
              { upc: 616320732813, quantity: 2 },
              // Cable Quick Nut Connector
              { upc: 616320731793, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([
              // Cable Quick Receiver Stud-Short
              { upc: 616320732295, quantity: 1 },
              // Cable Quick Receiver
              { upc: 616320732288, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
              // 28mm WOOD WASHER FOR QUICK RECEIVER
              { upc: 616320732837, quantity: 2 },
            ]),
            long: fittingsGroup([
              { upc: 616453990135, quantity: 1 },
              // Cable Quick Receiver
              { upc: 616320732288, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
              // 28mm WOOD WASHER FOR QUICK RECEIVER
              { upc: 616320732837, quantity: 2 },
            ]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 1 },
            // Terminal
            { upc: 616320732547, quantity: 1 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 2 },
            // Terminal
            { upc: 616320732547, quantity: 0 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
        },
      },
      metal: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // Washer.
                { upc: 616320730307, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 616320732066, quantity: 1 },
              ]),
              "quick-nut": fittingsGroup([
                // Washer not in quick nut for now.
                // { upc: 616320730307, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 616320731779, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 616320731755, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 616320732066, quantity: 1 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // Washer
                { upc: 616320730307, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // Washer not in quick nut for now.
                // { upc: 616320730307, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 616320731779, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 616320731755, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320732042, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732066, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732066, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 616320732042, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 616320732042, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732004, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 616320732004, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // Ball End Assembly - Field Swaged for 1/8" Cable
              { upc: 616453985803, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // SwageBallEnd-Fact1/8
              { upc: 616453985780, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly -  Field Swaged for 1/8" Cable
              { upc: 616453985841, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly- Factory Swaged for 1/8" Cable
              { upc: 616453985827, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Classic not available in 1/8"
          "classic-ball": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          // Receiver Stud Length.
          "low-profile": {
            // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
            short: fittingsGroup([{ upc: 616320735005, quantity: 1 }]),
            // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
            long: fittingsGroup([{ upc: 616453982062, quantity: 1 }]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([
              // Cable Quick Nut Cover 1/8"
              { upc: 616320731779, quantity: 2 },
              // Cable Quick Nut 1/8"
              { upc: 616320731755, quantity: 2 },
              // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
              { upc: 616320731984, quantity: 1 },
              // Cable Quick Nut Connector
              { upc: 616320731793, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([
              // Cable Quick Receiver Stud-Short
              { upc: 616320732295, quantity: 1 },
              // Cable Quick Receiver
              { upc: 616320732288, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            long: fittingsGroup([
              { upc: 616453990135, quantity: 1 },
              // Cable Quick Receiver
              { upc: 616320732288, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 1 },
            // Terminal
            { upc: 616320732547, quantity: 1 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 2 },
            // Terminal
            { upc: 616320732547, quantity: 0 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
        },
      },
    },
  };

  return fittings;
}

export function get2507FittingsUpcs() {
  const fittings = {
    "1 x 19 - 1/8”": {
      wood: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([]),
              "quick-nut": fittingsGroup([
                // Wood Washer not added in Quick Nut for now.
                { upc: 616320732813, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 691027722800, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 691027722794, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 691027722787, quantity: 1 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([]),
              "quick-nut": fittingsGroup([
                // Wood Washer not added in Quick Nut for now.
                { upc: 616320732813, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 691027722800, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 691027722794, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([]),
                "quick-nut": fittingsGroup([
                  // Wood Washer not added in Quick Nut for now.
                  { upc: 616320732813, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          // Classic not available in 1/8"
          "classic-ball": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          // Receiver Stud Length.
          "low-profile": {
            // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
            short: fittingsGroup([{ upc: 616320735012, quantity: 1 }]),
            // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
            long: fittingsGroup([{ upc: 616453982079, quantity: 1 }]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([
              // Cable Quick Nut Cover 1/8"
              { upc: 691027722800, quantity: 2 },
              // Cable Quick Nut 1/8"
              { upc: 691027722794, quantity: 2 },
              // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
              { upc: 616320731984, quantity: 1 },
              // WASHER FOR 1/8" FITTINGS USED FOR WOOD INSTALLATIONS
              { upc: 616320732813, quantity: 2 },
              // Cable Quick Nut Connector
              { upc: 616320731793, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 691027723623, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([
              // Cable Quick Receiver Stud-Short
              { upc: 662187017334, quantity: 1 },
              // Cable Quick Receiver
              { upc: 662187017327, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
              // 28mm WOOD WASHER FOR QUICK RECEIVER
              { upc: 616320732837, quantity: 2 },
            ]),
            long: fittingsGroup([
              { upc: 616453990135, quantity: 1 },
              // Cable Quick Receiver
              { upc: 662187017327, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
              // 28mm WOOD WASHER FOR QUICK RECEIVER
              { upc: 616320732837, quantity: 2 },
            ]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 1 },
            // Terminal
            { upc: 616320732547, quantity: 1 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 2 },
            // Terminal
            { upc: 616320732547, quantity: 0 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
        },
      },
      metal: {
        "thru-post": {
          "field-swaged": {
            singleTensioner: {
              acorn: fittingsGroup([
                // Washer.
                { upc: 616320730307, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 691027722787, quantity: 1 },
              ]),
              "quick-nut": fittingsGroup([
                // Washer not in quick nut for now.
                // { upc: 616320730307, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 691027722800, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 691027722794, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 1 },
                // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                { upc: 691027722787, quantity: 1 },
              ]),
            },
            doubleTensioner: {
              acorn: fittingsGroup([
                // Washer
                { upc: 616320730307, quantity: 2 },
                // HEX NUT FOR 1/8" FITTINGS
                { upc: 616320730284, quantity: 2 },
                // ACORN NUT FOR 1/8" FITTINGS
                { upc: 616320730253, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 2 },
              ]),
              "quick-nut": fittingsGroup([
                // Washer not in quick nut for now.
                // { upc: 616320730307, quantity: 2 },
                // 1/8 quick nut cover
                { upc: 691027722800, quantity: 2 },
                /* 1/8 quick nut */
                { upc: 691027722794, quantity: 2 },
                // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 691027722770, quantity: 2 },
              ]),
            },
          },
          "factory-swaged": {
            "one-end-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 616320732042, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 616320731779, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 616320731755, quantity: 2 },
                  // Tensioner Factory
                  { upc: 616320731984, quantity: 1 },
                  // FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 616320732042, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                ]),
              },
            },
            "both-ends-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 1 },
                  // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 691027722787, quantity: 1 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // HEX NUT FOR 1/8" FITTINGS
                  { upc: 616320730284, quantity: 2 },
                  // ACORN NUT FOR 1/8" FITTINGS
                  { upc: 616320730253, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not used in quick nut.
                  // { upc: 616320730307, quantity: 2 },
                  // 1/8 quick nut cover
                  { upc: 691027722800, quantity: 2 },
                  /* 1/8 quick nut */
                  { upc: 691027722794, quantity: 2 },
                  // Tensioner Factory
                  { upc: 691027722770, quantity: 2 },
                  { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                ]),
              },
            },
          },
          "fineline-ball": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // Ball End Assembly - Field Swaged for 1/8" Cable
              { upc: 616453985803, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
              { upc: 616320735029, quantity: 1 },
              // SwageBallEnd-Fact1/8
              { upc: 616453985780, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          "fineline-button": {
            "field-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly -  Field Swaged for 1/8" Cable
              { upc: 616453985841, quantity: 1 },
            ]),
            "factory-swaged": fittingsGroup([
              // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
              { upc: 616320735036, quantity: 1 },
              // Button End Assembly- Factory Swaged for 1/8" Cable
              { upc: 616453985827, quantity: 1 },
              { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
            ]),
          },
          // Classic not available in 1/8"
          "classic-ball": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          "classic-button": {
            "field-swaged": fittingsGroup([]),
            "factory-swaged": fittingsGroup([]),
          },
          // Receiver Stud Length.
          "low-profile": {
            // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
            short: fittingsGroup([{ upc: 616320735005, quantity: 1 }]),
            // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
            long: fittingsGroup([{ upc: 616453982062, quantity: 1 }]),
          },
        },
        swageless: {
          "type-1": {
            normal: fittingsGroup([
              // Cable Quick Nut Cover 1/8"
              { upc: 691027722800, quantity: 2 },
              // Cable Quick Nut 1/8"
              { upc: 691027722794, quantity: 2 },
              // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
              { upc: 616320731984, quantity: 1 },
              // Cable Quick Nut Connector
              { upc: 616320731793, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 691027723623, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            "black-oxide": fittingsGroup([]),
          },
          "type-2": {
            short: fittingsGroup([
              // Cable Quick Receiver Stud-Short
              { upc: 662187017334, quantity: 1 },
              // Cable Quick Receiver
              { upc: 662187017358, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
            long: fittingsGroup([
              { upc: 616453990135, quantity: 1 },
              // Cable Quick Receiver
              { upc: 662187017358, quantity: 1 },
              // Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
              { upc: 616320732387, quantity: 1 },
              // Cable Quick Lock Threaded End for Swageless Receiver
              { upc: 662187017624, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 616320732219, quantity: 1 },
              // Cable Quick Lock Swageless Receiver Body
              { upc: 662187017617, quantity: 1 },
              // Component Part for CableQuick Lock Swageless Receiver
              { upc: 662187016580, quantity: 1 },
            ]),
          },
        },
        "surface-mount": {
          singleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 1 },
            // Terminal
            { upc: 616320732547, quantity: 1 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
          doubleTensioner: fittingsGroup([
            // Tensioner
            { upc: 616320732523, quantity: 2 },
            // Terminal
            { upc: 616320732547, quantity: 0 },
            // Screw
            { upc: 616320730338, quantity: 4 },
            // Sleeve
            { upc: 616320732080, quantity: 0 },
          ]),
        },
      },
    },
  };

  return fittings;
}

export function getBlackOxideFittingsUpcs() {
  return {
    acorn: 662187013268,
    "quick-receiver": {
      thick: 662187018805,
      thin: 662187018812,
    },
    "quicklock-swageless-receiver": 662187013336,
    "cable-quick-receiver": 662187013312,
    "cable-quick-receiver-stud": {
      long: 662187013343,
      short: 662187013350,
    },
    "short-terminal-hex-bolt": 662187013329,
    "hex-bolt": 662187013329,
    "hex-nut": 662187013275,
    "quick-cable-terminal": 662187020730,
    screw: 662187016252,
    "surface-mount-sleeve": 691027720127,
    // Use two for Wood Post systems.
    "quick-receive-washer": 662187013367,
    washer: 662187013244,
    "wood-washer": 662187013251,
    "field-swaged-tensioner": 662187013220,
    "field-swaged-terminal": 662187013237,
    "surface-mount-turnbuckle": 662187016245,
    "surface-mount-terminal": 662187016238,
    "quick-nut": 662187017075,
    "quick-nut-cover": 662187017082,
  };
}

export function getBlackOxideCableBundleUpcs() {
  return {
    10: 662187014203,
    20: 662187014210,
    30: 662187014227,
    40: 662187014234,
    100: 662187014197,
    250: 662187014159,
    500: 662187014166,
    // 1000ft not in use.
    1000: 662187014173,
  };
}

export function getBlackOxideFittingsItems() {
  const fittings = {
    kits: {
      "1 x 19 - 1/8”": {
        wood: {
          "thru-post": {
            "field-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // BO Wood Washer.
                  { upc: 662187013251, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // BO Wood Washer not added in Quick Nut for now.
                  // { upc: 662187013251, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // BO Wood Washer.
                  { upc: 662187013251, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // BO Wood Washer not added in Quick Nut for now.
                  // { upc: 662187013251, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
              },
            },
            "factory-swaged": {
              "one-end-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer.
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer.
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
              },
              "both-ends-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
              },
            },
            "fineline-ball": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // Ball End Assembly - Field Swaged for 1/8" Cable
                { upc: 616453985803, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // SwageBallEnd-Fact1/8
                { upc: 616453985780, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            "fineline-button": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly -  Field Swaged for 1/8" Cable
                { upc: 616453985841, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly- Factory Swaged for 1/8" Cable
                { upc: 616453985827, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            // Classic not available in 1/8"
            "classic-ball": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            "classic-button": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            // Receiver Stud Length.
            "low-profile": {
              // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
              short: fittingsGroup([{ upc: 616320735012, quantity: 1 }]),
              // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
              long: fittingsGroup([{ upc: 616453982079, quantity: 1 }]),
            },
          },
          swageless: {
            "type-1": {
              normal: fittingsGroup([
                // BO Cable Quick Nut Cover 1/8"
                { upc: 662187017082, quantity: 2 },
                // BO Cable Quick Nut 1/8"
                { upc: 662187017075, quantity: 2 },
                // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320731984, quantity: 1 },
                // BO WASHER FOR 1/8" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 662187013251, quantity: 2 },
                // Cable Quick Nut Connector
                { upc: 616320731793, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              "black-oxide": fittingsGroup([]),
            },
            "type-2": {
              short: fittingsGroup([
                // BO Cable Quick Receiver Stud-Short
                { upc: 662187013350, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
                // 28mm WOOD WASHER FOR QUICK RECEIVER
                { upc: 616320732837, quantity: 2 },
              ]),
              long: fittingsGroup([
                { upc: 662187013343, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
                // 28mm WOOD WASHER FOR QUICK RECEIVER
                { upc: 616320732837, quantity: 2 },
              ]),
            },
          },
          "surface-mount": {
            singleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 1 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 1 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
            doubleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 2 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 0 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
          },
        },
        metal: {
          "thru-post": {
            "field-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not in quick nut for now.
                  // { upc: 616320730307, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer
                  { upc: 616320730307, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not in quick nut for now.
                  // { upc: 616320730307, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
              },
            },
            "factory-swaged": {
              "one-end-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // Tensioner field.
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
              },
              "both-ends-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
              },
            },
            "fineline-ball": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // Ball End Assembly - Field Swaged for 1/8" Cable
                { upc: 616453985803, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // SwageBallEnd-Fact1/8
                { upc: 616453985780, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            "fineline-button": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly -  Field Swaged for 1/8" Cable
                { upc: 616453985841, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly- Factory Swaged for 1/8" Cable
                { upc: 616453985827, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            // Classic not available in 1/8"
            "classic-ball": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            "classic-button": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            // Receiver Stud Length.
            "low-profile": {
              // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
              short: fittingsGroup([{ upc: 616320735005, quantity: 1 }]),
              // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
              long: fittingsGroup([{ upc: 616453982062, quantity: 1 }]),
            },
          },
          swageless: {
            "type-1": {
              normal: fittingsGroup([
                // BO Cable Quick Nut Cover 1/8"
                { upc: 662187017082, quantity: 2 },
                // BO Cable Quick Nut 1/8"
                { upc: 662187017075, quantity: 2 },
                // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320731984, quantity: 1 },
                // Cable Quick Nut Connector
                { upc: 616320731793, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              "black-oxide": fittingsGroup([]),
            },
            "type-2": {
              short: fittingsGroup([
                // BO Cable Quick Receiver Stud-Short
                { upc: 662187013350, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              long: fittingsGroup([
                // BO Cable Quick Receiver Stud-Long
                { upc: 662187013343, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
            },
          },
          "surface-mount": {
            singleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 1 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 1 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
            doubleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 2 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 0 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
          },
        },
      },
    },
    bundles: {
      "1 x 19 - 1/8”": {
        wood: {
          "thru-post": {
            "field-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // BO Wood Washer.
                  { upc: 662187013251, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // BO Wood Washer not added in Quick Nut for now.
                  // { upc: 662187013251, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // BO Wood Washer.
                  { upc: 662187013251, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // BO Wood Washer not added in Quick Nut for now.
                  // { upc: 662187013251, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
              },
            },
            "factory-swaged": {
              "one-end-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer.
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer.
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
              },
              "both-ends-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // BO Wood Washer
                    { upc: 662187013251, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // BO Wood Washer not added in Quick Nut for now.
                    // { upc: 662187013251, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
              },
            },
            "fineline-ball": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // Ball End Assembly - Field Swaged for 1/8" Cable
                { upc: 616453985803, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // SwageBallEnd-Fact1/8
                { upc: 616453985780, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            "fineline-button": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly -  Field Swaged for 1/8" Cable
                { upc: 616453985841, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly- Factory Swaged for 1/8" Cable
                { upc: 616453985827, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            // Classic not available in 1/8"
            "classic-ball": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            "classic-button": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            // Receiver Stud Length.
            "low-profile": {
              // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
              short: fittingsGroup([{ upc: 616320735012, quantity: 1 }]),
              // LOW PROFILE BUTTON ASSEMBLY FOR WOOD POSTS (1/8" CABLE)
              long: fittingsGroup([{ upc: 616453982079, quantity: 1 }]),
            },
          },
          swageless: {
            "type-1": {
              normal: fittingsGroup([
                // BO Cable Quick Nut Cover 1/8"
                { upc: 662187017082, quantity: 2 },
                // BO Cable Quick Nut 1/8"
                { upc: 662187017075, quantity: 2 },
                // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320731984, quantity: 1 },
                // BO WASHER FOR 1/8" FITTINGS USED FOR WOOD INSTALLATIONS
                { upc: 662187013251, quantity: 2 },
                // Cable Quick Nut Connector
                { upc: 616320731793, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              "black-oxide": fittingsGroup([]),
            },
            "type-2": {
              short: fittingsGroup([
                // BO Cable Quick Receiver Stud-Short
                { upc: 662187013350, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
                // 28mm WOOD WASHER FOR QUICK RECEIVER
                { upc: 616320732837, quantity: 2 },
              ]),
              long: fittingsGroup([
                { upc: 662187013343, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
                // 28mm WOOD WASHER FOR QUICK RECEIVER
                { upc: 616320732837, quantity: 2 },
              ]),
            },
          },
          "surface-mount": {
            singleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 1 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 1 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
            doubleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 2 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 0 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
          },
        },
        metal: {
          "thru-post": {
            "field-swaged": {
              singleTensioner: {
                acorn: fittingsGroup([
                  // Washer.
                  { upc: 616320730307, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not in quick nut for now.
                  // { upc: 616320730307, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 1 },
                  // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                  { upc: 662187013237, quantity: 1 },
                ]),
              },
              doubleTensioner: {
                acorn: fittingsGroup([
                  // Washer
                  { upc: 616320730307, quantity: 2 },
                  // BO HEX NUT FOR 1/8" FITTINGS
                  { upc: 662187013275, quantity: 2 },
                  // BO ACORN NUT FOR 1/8" FITTINGS
                  { upc: 662187013268, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
                "quick-nut": fittingsGroup([
                  // Washer not in quick nut for now.
                  // { upc: 616320730307, quantity: 2 },
                  // BO 1/8 quick nut cover
                  { upc: 662187017082, quantity: 2 },
                  /* BO 1/8 quick nut */
                  { upc: 662187017075, quantity: 2 },
                  // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                  { upc: 662187013220, quantity: 2 },
                ]),
              },
            },
            "factory-swaged": {
              "one-end-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 662187013237, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // BO FIELD SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // Tensioner field.
                    { upc: 662187013220, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
                  ]),
                },
              },
              "both-ends-swaged": {
                singleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 1 },
                    // FACTORY SWAGED THREADED TERMINAL END FOR 1/8" CABLE
                    { upc: 616320732004, quantity: 1 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
                doubleTensioner: {
                  acorn: fittingsGroup([
                    // Washer.
                    { upc: 616320730307, quantity: 2 },
                    // BO HEX NUT FOR 1/8" FITTINGS
                    { upc: 662187013275, quantity: 2 },
                    // BO ACORN NUT FOR 1/8" FITTINGS
                    { upc: 662187013268, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                  "quick-nut": fittingsGroup([
                    // Washer not used in quick nut.
                    // { upc: 616320730307, quantity: 2 },
                    // BO 1/8 quick nut cover
                    { upc: 662187017082, quantity: 2 },
                    /* BO 1/8 quick nut */
                    { upc: 662187017075, quantity: 2 },
                    // Tensioner Factory
                    { upc: 616320731984, quantity: 2 },
                    { upc: "SERVICE CABLE:SVC-Swaging", quantity: 2 },
                  ]),
                },
              },
            },
            "fineline-ball": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // Ball End Assembly - Field Swaged for 1/8" Cable
                { upc: 616453985803, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BALL TURNBUCKLE INCLUDES TENSIONING END FOR 1/8" CABLE
                { upc: 616320735029, quantity: 1 },
                // SwageBallEnd-Fact1/8
                { upc: 616453985780, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            "fineline-button": {
              "field-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly -  Field Swaged for 1/8" Cable
                { upc: 616453985841, quantity: 1 },
              ]),
              "factory-swaged": fittingsGroup([
                // FINE-LINE BUTTON TURNBUCKLE TENSIONING END FOR 1/8" CABLE
                { upc: 616320735036, quantity: 1 },
                // Button End Assembly- Factory Swaged for 1/8" Cable
                { upc: 616453985827, quantity: 1 },
                { upc: "SERVICE CABLE:SVC-Swaging", quantity: 1 },
              ]),
            },
            // Classic not available in 1/8"
            "classic-ball": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            "classic-button": {
              "field-swaged": fittingsGroup([]),
              "factory-swaged": fittingsGroup([]),
            },
            // Receiver Stud Length.
            "low-profile": {
              // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
              short: fittingsGroup([{ upc: 616320735005, quantity: 1 }]),
              // LOW PROFILE BUTTON ASSEMBLY FOR METAL POSTS (1/8" CABLE)
              long: fittingsGroup([{ upc: 616453982062, quantity: 1 }]),
            },
          },
          swageless: {
            "type-1": {
              normal: fittingsGroup([
                // BO Cable Quick Nut Cover 1/8"
                { upc: 662187017082, quantity: 2 },
                // BO Cable Quick Nut 1/8"
                { upc: 662187017075, quantity: 2 },
                // FACTORY SWAGED THREADED TENSIONING END FOR 1/8" CABLE
                { upc: 616320731984, quantity: 1 },
                // Cable Quick Nut Connector
                { upc: 616320731793, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              "black-oxide": fittingsGroup([]),
            },
            "type-2": {
              short: fittingsGroup([
                // BO Cable Quick Receiver Stud-Short
                { upc: 662187013350, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
              long: fittingsGroup([
                // BO Cable Quick Receiver Stud-Long
                { upc: 662187013343, quantity: 1 },
                // BO Cable Quick Receiver
                { upc: 662187013312, quantity: 1 },
                // BO Short Terminal Hex Bolt for use on Inline 1/8" or 3/16" Cable Quick Terminal Fitting for 316SS Grade Cable
                { upc: 662187013329, quantity: 1 },
                // Cable Quick Lock Threaded End for Swageless Receiver
                { upc: 662187017624, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 616320732219, quantity: 1 },
                // Cable Quick Lock Swageless Receiver Body
                { upc: 662187017617, quantity: 1 },
                // Component Part for CableQuick Lock Swageless Receiver
                { upc: 662187016580, quantity: 1 },
              ]),
            },
          },
          "surface-mount": {
            singleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 1 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 1 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
            doubleTensioner: fittingsGroup([
              // BO Tensioner Turnbuckle
              { upc: 662187016245, quantity: 2 },
              // BO Surface Mounted Terminal
              { upc: 662187016238, quantity: 0 },
              // #12x1 1/4" PHIL PAN 316 SS Screw
              { upc: 662187016252, quantity: 4 },
              // HOLE PROTECTOR SLEEVE FOR USE WITH 1/8" CABLE
              { upc: 691027720127, quantity: 0 },
            ]),
          },
        },
      },
    },
  };

  return fittings;
}

export function getStairWashers() {
  const washers = {
    flat: {
      5: {
        "6mm": 662187014111,
        "8mm": 662187014128,
      },
      38: {
        "6mm": 616320731724,
        "8mm": 616320731731,
      },
      35: {
        "6mm": 616320731687,
        "8mm": 616320731694,
      },
      32: {
        "6mm": 616320731649,
        "8mm": 616320731656,
      },
      28: {
        "6mm": 616453989962,
        "8mm": 616453989924,
      },
    },
    radius: {
      5: {
        "6mm": 662187014135,
        "8mm": 662187014142,
      },
      38: {
        "6mm": 616320731700,
        "8mm": 616320731717,
      },
      35: {
        "6mm": 616320731663,
        "8mm": 616320731670,
      },
      32: {
        "6mm": 616320731625,
        "8mm": 616320731632,
      },
      28: {
        "6mm": 616453989948,
        "8mm": 616453989900,
      },
    },
  };

  const swagelessWashers = {
    radius: {
      5: 662187017303,
      38: 662187017297,
      35: 662187017280,
      32: 662187017273,
      28: 616453989931,
    },
    flat: {
      5: 662187016634,
      38: 662187016641,
      35: 662187011783,
      32: 616453989979,
      28: 616453989931,
    },
  };

  return { washers: washers, swageless: swagelessWashers };
}

export function getStainlessSteelToprailUpcs() {
  const toprailUpc = {
    round: 616320731618,
    flat: 616320734435,
  };

  return toprailUpc;
}

export function getStainlessSteelRectangularTopRail() {
  return 662187017402;
}

export function getWoodToprailUpcs() {
  return {
    "2x4": {
      "clear-cedar": {
        10: "616453989825",
        11: "616453989832",
        12: "616453989849",
        13: "616453989856",
        14: "616453989863",
        15: "616453989757",
        16: "616453989740",
        17: "616453989764",
        18: "616453989771",
        19: "616453989870",
        20: "616453989191",
        5: "616453989887",
        6: "616453989788",
        7: "616453989795",
        8: "616453989801",
        9: "616453989818",
      },
      ipe: {
        10: "616453988910",
        11: "662187013213",
        12: "662187016382",
        14: "662187016399",
        15: "616453988927",
        16: "616453990708",
        18: "616453990692",
        19: "662187016405",
        20: "616453988934",
        4: "662187018898",
        5: "662187016313",
        6: "662187013176",
        8: "662187013169",
        9: "616320732851",
      },
      balau: {
        10: "616453989047",
        11: "662187018935",
        12: "662187012209",
        13: "662187020143",
        14: "616453991811",
        15: "616453989054",
        16: "616453991828",
        17: "662187011417",
        18: "662187011424",
        19: "662187011431",
        20: "616453989061",
        21: "662187020525",
        22: "662187020532",
        5: "QB:01033918054974",
        6: "662187018928",
        7: "662187016344",
        8: "616453991804",
        9: "662187016351",
      },
      "tight-knot-cedar": {
        10: "616453989535",
        11: "616320732561",
        12: "662187014548",
        14: "662187014555",
        15: "616453989542",
        16: "662187014678",
        18: "662187014661",
        20: "616453989559",
      },
    },
    "2x6": {
      "clear-cedar": {
        10: "662187014487",
        11: "662187020518",
        12: "662187014494",
        13: "662187014500",
        14: "662187014517",
        15: "616453989214",
        16: "662187014463",
        17: "662187014692",
        18: "662187016306",
        19: "662187014470",
        20: "616453989221",
        5: "616320731816",
        6: "616453989207",
        7: "662187014524",
        8: "662187016290",
        9: "662187014531",
      },
      "tight-knot-cedar": {
        10: "616453989566",
        15: "616453989573",
        16: "662187020549",
        20: "616453989580",
      },
    },
    "5/4x4": {
      "clear-cedar": {
        10: "616453989436",
        11: "662187019024",
        12: "662187018645",
        13: "662187018652",
        14: "662187014098",
        15: "616453989443",
        16: "662187014081",
        17: "662187018676",
        18: "616453989450",
        19: "616453989467",
        20: "616453989474",
        3: "662187018959",
        4: "662187018942",
        5: "662187018911",
        6: "662187018614",
        7: "662187018621",
        8: "662187018904",
        9: "662187018638",
      },
      ipe: {
        10: "616453988941",
        12: "616453990661",
        14: "616453990678",
        15: "616453988958",
        16: "616453990685",
        18: "616453988965",
        19: "616453988972",
        20: "616453988989",
        6: "662187016948",
        7: "616320732691",
        8: "662187013206",
        9: "662187016320",
      },
      balau: {
        10: "616453989078",
        11: "616453991774",
        12: "616453991767",
        13: "616453991545",
        14: "662187016368",
        15: "616453989085",
        16: "662187011486",
        17: "616453991538",
        18: "616453989092",
        19: "616453989108",
        20: "616453989115",
        4: "662187018966",
        6: "616453991552",
        7: "616453991798",
        8: "616453991781",
        9: "662187011479",
      },
      "tight-knot-cedar": {
        10: "616453989597",
        15: "616453989603",
        16: "662187014104",
        18: "616453989610",
        19: "616453989627",
        20: "616453989634",
        8: "691027720103",
      },
    },
    "5/4x6": {
      "clear-cedar": {
        10: "616453989481",
        15: "616453989498",
        16: "691027721841",
        18: "616453989504",
        19: "616453989511",
        20: "616453989528",
      },
      ipe: {
        10: "662187014647",
        12: "662187016337",
        14: "662187014685",
        15: "616453989009",
        16: "662187014654",
        18: "616453989016",
        19: "616453989023",
        20: "616453989030",
        2: "616453988996",
        4: "662187014630",
        6: "691027720080",
        8: "691027720097",
        9: "616320730796",
      },
      balau: {
        10: "662187014456",
        14: "QB:01033918053733",
        15: "691027722459",
        16: "662187013138",
        17: "662187013152",
        18: "616453989146",
        19: "616453989153",
        20: "616453989160",
        6: "662187011462",
        7: "662187011455",
        8: "616453989122",
        9: "662187014449",
      },
      "tight-knot-cedar": {
        10: "616453989641",
        15: "616453989658",
        18: "616453989665",
        19: "616453989672",
        20: "616453989689",
      },
    },
  };
}

export function getAluminumToprailUpcs() {
  // Assemblies.
  const toprailUpc = {
    rectangular: {
      bronze: 616453983786,
      black: 616453983380,
      clay: 616453984059,
      Natural: 616453984431,
      white: 616453984806,
      "anodized-black": 691027710562,
      "anodized-clear": 691027710739,
    },
    shaped: {
      bronze: 616453983809,
      black: 616453983410,
      clay: 616453985292,
      Natural: 616453984455,
      white: 616453984837,
      "anodized-black": 0,
      "anodized-clear": 0,
    },
    // 20ft runs.
    // "alum-p2p": {
    //   "anodized-black": 691027710340,
    //   "anodized-clear": 691027710869,
    //   bronze: 662187011080,
    //   black: 616453983212,
    //   clay: 662187011097,
    //   Natural: 662187011103,
    //   white: 662187011110,
    // },
    // 5ft runs.
    "alum-p2p": {
      "anodized-black": 691027710272,
      "anodized-clear": 691027710791,
      bronze: 616320733988,
      black: 616320733889,
      clay: 616320734084,
      Natural: 616320734183,
      white: 616320734275,
    },
    "wood-p2p": {
      "5/4x4": {
        "clear-cedar": 616453989474,
        "tight-knot-cedar": 616453989634,
        ipe: 616453988989,
        balau: 616453989115,
      },
      "2x4": {
        "clear-cedar": 616453989191,
        "tight-knot-cedar": 616453989559,
        ipe: 616453988934,
        balau: 616453989061,
      },
      "2x6": {
        "clear-cedar": 616453989221,
        "tight-knot-cedar": 616453989580,
        ipe: 616453988934,
        balau: 616453989061,
      },
      "5/4x6": {
        "clear-cedar": 616453989528,
        "tight-knot-cedar": 616453989689,
        ipe: 616453989030,
        balau: 616453989160,
      },
    },
  };

  return toprailUpc;
}

export function getPenofinWoodStainUpcs() {
  return {
    red: {
      250: {
        quart: 616320734404,
        gallon: 616320734381,
      },
      550: {
        quart: 616320734411,
        gallon: 616320734398,
      },
    },
    natural: {
      250: {
        quart: 616320734367,
        gallon: 616320734350,
      },
      550: {
        quart: 616320734374,
        gallon: 616320733216,
      },
    },
  };
}

export function getWoodSealerUpc() {
  return 616320733230;
}

export function getCustomAluminumStockPostUpcs() {
  return {
    616320730093: true,
    616320730130: true,
  };
}

export function getCustomStainlessStockPostUpcs() {
  return {
    616453988231: true,
    662187012087: true,
    662187012094: true,
    616453988576: true,
  };
}

export function getWoodStockPostUpcs() {
  return {
    662187019215: true,
  };
}

export function getAlumP2PSaddleBracketUpcs() {
  return {
    "anodized-black": {
      terminal: 662187013671,
      intermediate: 662187013626,
    },
    "anodized-clear": {
      terminal: 662187016535,
      intermediate: 662187016665,
    },
    black: {
      terminal: 662187013671,
      intermediate: 662187013626,
    },
    bronze: {
      terminal: 662187016528,
      intermediate: 662187016689,
    },
    Natural: {
      terminal: 662187016535,
      intermediate: 662187016665,
    },
    clay: {
      terminal: 662187016542,
      intermediate: 662187016672,
    },
    white: {
      terminal: 662187016559,
      intermediate: 662187016658,
    },
  };
}

export function getBasePlateBitForScrewsUpc() {
  return 616320731748;
}

export function getAluminumP2PInlineUpcs() {
  return {
    "anodized-black": {
      1: "691027710234",
      2: "691027710241",
      3: "691027710258",
      4: "691027710265",
      5: "691027710272",
      6: "691027710289",
      7: "691027710296",
      8: "691027710302",
      10: "691027710319",
      11: "691027710326",
      12: "691027710333",
      16: "691027722343",
    },
    "anodized-clear": {
      1: "691027710753",
      2: "691027710760",
      3: "691027710777",
      4: "691027710784",
      5: "691027710791",
      6: "691027710807",
      7: "691027710814",
      8: "691027710821",
      10: "691027710838",
      11: "691027710845",
      12: "691027710852",
      13: "691027722350",
      14: "691027722374",
      15: "691027722367",
      16: "691027722381",
    },
    black: {
      1: "662187016894",
      2: "616320733827",
      3: "616320733841",
      4: "616320733865",
      5: "616320733889",
      6: "616320733902",
      7: "616453983229",
      8: "616453983236",
      10: "616453983199",
      11: "616453983250",
      12: "616453983205",
      20: "616453983212",
    },
    bronze: {
      2: "616320733926",
      3: "616320733940",
      4: "616320733964",
      5: "616320733988",
      6: "616320734008",
      7: "616453983632",
      8: "616453983649",
      10: "662187011905",
      12: "662187016283",
      20: "662187011080",
    },
    Natural: {
      2: "616320734121",
      3: "616320734145",
      4: "616320734169",
      5: "616320734183",
      6: "662187011844",
      7: "662187011899",
      8: "616453984295",
      10: "616453984288",
      14: "662187012162",
      20: "662187011103",
    },
    clay: {
      2: "616320734022",
      3: "616320734046",
      4: "616320734060",
      5: "616320734084",
      6: "616320734107",
      20: "662187011097",
    },
    white: {
      2: "616320734213",
      3: "616320734237",
      4: "616320734251",
      5: "616320734275",
      6: "616320734299",
      7: "616453984684",
      8: "616453984691",
      20: "662187011110",
    },
  };
}

export function getGalvanicScrewProtectionUpc() {
  return 616453989269;
}

export function getAluminumP2PStairsUpcs() {
  return {
    "anodized-black": {
      2: 691027710357,
      3: 691027710364,
      4: 691027710371,
      5: 691027710388,
      6: 691027710395,
      7: 691027710401,
      8: 691027710418,
      9: 691027710425,
      10: 691027710432,
      11: 691027710449,
      12: 691027710456,
      13: 691027710463,
      14: 691027710470,
      15: 691027710487,
      16: 691027710494,
    },
    "anodized-clear": {
      2: 691027710876,
      3: 691027710883,
      4: 691027710890,
      5: 691027710906,
      6: 691027710913,
      7: 691027710920,
      8: 691027710937,
      9: 691027710944,
      10: 691027710951,
      11: 691027710968,
      12: 691027710975,
      13: 691027710982,
      14: 691027710999,
      15: 691027711002,
      16: 691027711019,
    },
    black: {
      2: 616320733810,
      3: 616320733834,
      4: 616320733858,
      5: 616320733872,
      6: 616320733896,
      7: 616453983328,
      8: 616453983335,
      9: 616453983342,
      10: 616453983267,
      11: 662187011912,
      12: 616453983274,
      13: 662187019017,
      14: 616453983281,
      15: 616453983298,
      16: 616453983304,
      17: 662187020563,
      18: 616453991330,
      20: 616453983311,
    },
    bronze: {
      1: 616453983656,
      2: 616320733919,
      3: 616320733933,
      4: 616320733957,
      5: 616320733971,
      6: 616320733995,
      7: 616453983731,
      8: 616453983748,
      9: 662187011950,
      10: 616453983663,
      11: 616453983670,
      12: 616453983687,
      14: 616453983694,
      15: 616453983700,
      16: 616453983717,
      18: 691027715772,
      20: 616453983724,
    },
    Natural: {
      2: 616320734114,
      3: 616320734138,
      4: 616320734152,
      5: 616320734176,
      6: 616320734190,
      7: 662187011936,
      8: 616453984356,
      9: 616453984387,
      10: 616453984318,
      12: 616453984325,
      13: 662187016917,
      14: 662187019000,
      15: 662187011929,
      16: "QB:01033918053735",
      18: 616453984332,
      20: 616453984349,
    },
    clay: {
      2: 616320734015,
      3: 616320734039,
      4: 616320734053,
      5: 616320734077,
      6: 616320734091,
      7: 616453984004,
      8: 616453984011,
      10: 616453983977,
      12: 616453991378,
      14: 691027715758,
      15: 616453983984,
      16: 691027715765,
      18: 691027715796,
      20: 616453983991,
    },
    white: {
      2: "616320734206",
      3: "616320734220",
      4: "616320734244",
      5: "616320734268",
      6: "616320734282",
      7: "662187011943",
      8: "616453984769",
      9: "662187011967",
      10: "616453984707",
      11: "616453984394",
      12: "616453984714",
      14: "616453984721",
      15: "616453984738",
      16: "616453984745",
      17: "QB:01033918053746",
      18: "691027715789",
      20: "616453984752",
    },
  };
}

export function getNylonWasherUpcs() {
  return {
    "1 x 19 - 1/8”": 662187011837,
    "1 x 19 - 3/16”": 616320730932,
    "7 x 7 - 3/16”": 616320730932,
  };
}

export function getNylonStairWasherUpcs() {
  return {
    "1 x 19 - 1/8”": 662187011820,
    "1 x 19 - 3/16”": 616320730932,
    "7 x 7 - 3/16”": 616320730932,
  };
}

export function getBlackOxideStairWashers() {
  const stairWashers = getStairWashers();

  stairWashers.washers["flat"][35]["6mm"] = 662187013299;
  stairWashers.washers["flat"][35]["8mm"] = 662187013299;
  stairWashers.washers["radius"][35]["8mm"] = 662187013282;
  stairWashers.washers["radius"][35]["6mm"] = 662187013282;

  // todo: fix this
  if (stairWashers.swageless) {
    (stairWashers.swageless as any)["radius"] = 662187013282;
    (stairWashers.swageless as any)["flat"] = 662187013299;
  }

  return stairWashers;
}
