/**
 * External dependencies.
 */
import React, { createContext, useEffect, useRef, useState } from "react";
import { Map } from "immutable";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

/**
 * Internal dependencies.
 */
import "./App.css";

import SingleProjectView from "./components/SingleProjectView";
import ProjectsDashboard from "./components/ProjectsDashboard/ProjectsDashboard";
import Login from "./components/Login";
import fetchCustomers from "./data/fetch-customers";
import fetchInventory, { AllInventoryTypes } from "./data/fetch-inventory";
import fetchEstimators from "./data/fetch-estimators";
import ErrorBoundary from "./components/ErrorBoundary";
import useProjectsLoader from "./hooks/useProjectsLoader";
import { Customer, Estimator, Project } from "./entities";
import { fetchSaltwaterLocations } from "./data/fetch-saltwaterLocations";

export const CustomersContext = createContext<{
  customers: Map<string, Customer>;
  setCustomers: React.Dispatch<React.SetStateAction<Map<string, Customer>>>;
}>({ customers: Map<string, Customer>(), setCustomers: () => {} });

export const InventoryContext = createContext<AllInventoryTypes[]>([]);

export const EstimatorContext = createContext<{
  estimators: Map<string, Estimator>;
  setEstimators: React.Dispatch<React.SetStateAction<Map<string, Estimator>>>;
}>({ estimators: Map<string, Estimator>(), setEstimators: () => {} });

export const ProjectsContext = createContext<{
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  projectsLoading: boolean;
  projectsError: any;
}>({
  projects: [],
  setProjects: () => {},
  projectsLoading: false,
  projectsError: null,
});

let setProjectsHandler: React.Dispatch<React.SetStateAction<Project[]>> | null =
  null;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <ProjectsDashboard />
      </ErrorBoundary>
    ),
  },
  {
    path: "/login",
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: "/logout",
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: "/projects/:id",
    element: (
      <ErrorBoundary>
        <SingleProjectView />
      </ErrorBoundary>
    ),
  },
  {
    path: "/*",
    element: (
      <ErrorBoundary>
        <ProjectsDashboard />
      </ErrorBoundary>
    ),
  },
]);

function App() {
  const [customers, setCustomers] = useState<Map<string, Customer>>(
    Map<string, Customer>()
  );
  const [inventory, setInventory] = useState<AllInventoryTypes[]>([]);
  const [estimators, setEstimators] = useState<Map<string, Estimator>>(
    Map<string, Estimator>()
  );
  // const [projects, setProjects] = useState([]);

  const {
    projects,
    setProjects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjectsLoader();

  setProjectsHandler = setProjects;

  const [hasFetchedCustomers, setHasFetchedCustomers] = useState(false);
  const [hasFetchedInventory, setHasFetchedInventory] = useState(false);
  const [hasFetchedEstimators, setHasFetchedEstimators] = useState(false);
  const hasFetchedSaltwaterLocations = useRef(false);

  useEffect(() => {
    (async function () {
      if (!hasFetchedCustomers) {
        setCustomers(await fetchCustomers());
        setHasFetchedCustomers(true);
      }
    })();
  }, [hasFetchedCustomers]);

  useEffect(() => {
    (async function () {
      if (!hasFetchedInventory) {
        setInventory(await fetchInventory());
        setHasFetchedInventory(true);
      }
    })();
  }, [hasFetchedInventory]);

  useEffect(() => {
    (async function () {
      if (!hasFetchedEstimators) {
        setEstimators(await fetchEstimators());
        setHasFetchedEstimators(true);
      }
    })();
  }, [hasFetchedEstimators]);

  useEffect(() => {
    (async function () {
      if (!hasFetchedSaltwaterLocations.current) {
        await fetchSaltwaterLocations();
        hasFetchedSaltwaterLocations.current = true;
      }
    })();
  }, []);

  return (
    <CustomersContext.Provider value={{ customers, setCustomers }}>
      <InventoryContext.Provider value={inventory}>
        <EstimatorContext.Provider value={{ estimators, setEstimators }}>
          <ProjectsContext.Provider
            value={{ projects, setProjects, projectsLoading, projectsError }}
          >
            <RouterProvider router={router}></RouterProvider>
          </ProjectsContext.Provider>
        </EstimatorContext.Provider>
      </InventoryContext.Provider>
    </CustomersContext.Provider>
  );
}

export { setProjectsHandler };

export default App;
