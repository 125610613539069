// @ts-nocheck
/**
 * External dependencies.
 */
import { useContext } from "react";
import {
  CustomersContext,
  EstimatorContext,
  InventoryContext,
  ProjectsContext,
} from "../App";
import { Customer } from "../entities";
import { getCustomerName } from "../entities/utils";
import { getTotal, roundToHundreth } from "../partsList";
import { projectEstimateNumber } from "../utils";
import { getItemLists } from "../utils/partsList";
import {
  getBillAddressFromCustomer,
  getShipAddressFromCustomer,
} from "./CustomerSelect/utils";

function getFirstWeekStartDate() {
  return new Date("2023-03-01T00:00:00.000Z");
}

function getWeekNumber(date, firstWeekStartDate) {
  const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
  const timeDiff = date - firstWeekStartDate;
  return Math.floor(timeDiff / millisecondsPerWeek);
}

function getPhoneNumber(customer) {
  let phone = "";

  if (customer?.data?.phone) {
    phone = customer?.data?.phone;
  } else if (customer.quickbooksData?.Phone) {
    phone = customer.quickbooksData.Phone;
  }

  return phone;
}

function getEmail(customer) {
  let email = "";

  if (customer?.data?.email) {
    email = customer?.data?.email;
  } else if (customer.quickbooksData?.Email) {
    email = customer.quickbooksData.Email;
  }

  return email;
}

function customerType(customer) {
  let type = "";

  if (customer?.data?.customerType) {
    type = customer?.data?.customerType;
  } else if (customer.quickbooksData?.CustomerTypeRef?.FullName) {
    type = customer.quickbooksData?.CustomerTypeRef?.FullName;
  }

  return type;
}

function shippingAddress(customer, settings) {
  return getShipAddressFromCustomer(customer, settings).join("\n");
}

function billingAddress(customer) {
  return getBillAddressFromCustomer(customer).join("\n");
}

export default function Reports() {
  const { projects } = useContext(ProjectsContext);
  const { customers } = useContext(CustomersContext);
  const { estimators } = useContext(EstimatorContext);
  const inventoryList = useContext(InventoryContext);

  return (
    <button
      onClick={() => {
        console.log("Generate reports");

        const targetDate = new Date("2023-03-01T00:00:00.000Z");

        const report = projects
          .map((project) => {
            if (!project) {
              return null;
            }
            // console.log("project", project.toJS());
            try {
              const customer = project.customer
                ? customers.has(project.customer)
                  ? customers.get(project.customer)?.toJS()
                  : ""
                : "";

              const projectEstimators = project.estimators.size
                ? project.estimators.reduce((map, id) => {
                    if (estimators.has(id)) {
                      map = estimators.get(id)!.name;
                    }

                    return map;
                  }, "")
                : "";

              const estimateNumber = projectEstimateNumber(project).replace(
                "#",
                ""
              );

              const canvases = project.canvases;

              const itemLists = getItemLists(canvases, inventoryList, project);
              const getTotals = itemLists.map((itemList) => getTotal(itemList));
              const total = getTotals.reduce(
                (acc, total) => roundToHundreth(acc + total),
                0
              );

              const tax = project.tax;

              const data = {
                estimateNumber,
                customer:
                  customer && typeof customer !== "string"
                    ? // @ts-ignore
                      getCustomerName(customer)
                    : "N/A",
                date: project.createdOn,
                salesRep: projectEstimators,
                phone: getPhoneNumber(customer),
                email: getEmail(customer),
                customerType: customerType(customer),
                projectType: project.settings.projectType,
                total: total.toString(),
                tax: tax.value,
                grandTotal: total + tax.value,
                exportedDate: project.frozenProject.date || "",
                projectTitle: project?.settings?.projectTitle || "",
                shippingAddress: shippingAddress(customer, project.settings),
                billingAddress: billingAddress(customer),
              };

              return data;
            } catch (error) {
              console.log("error", error);
              return null;
            }
          })
          .filter((report) => report)
          .filter((item) => {
            const createdOnDate = new Date(item.date);
            return createdOnDate >= targetDate;
          });

        console.log("RAW REPORT");
        console.log(JSON.stringify(report));

        const weeklyReport = report.reduce((buckets, item) => {
          const itemDate = new Date(item.date);
          const firstWeekStartDate = getFirstWeekStartDate();
          const weekNumber = getWeekNumber(itemDate, firstWeekStartDate);

          if (!buckets[weekNumber]) {
            buckets[weekNumber] = 0;
          }

          buckets[weekNumber]++;

          return buckets;
        }, {});

        console.log("WEEKLY COUNT REPORT");
        console.log(JSON.stringify(weeklyReport));

        const salesPersonReport = report.reduce((buckets, item) => {
          const { salesRep } = item;

          if (!buckets[salesRep]) {
            buckets[salesRep] = 0;
          }

          buckets[salesRep]++;

          return buckets;
        }, {});

        console.log("SALES PERSON REPORT");
        console.log(JSON.stringify(salesPersonReport));
      }}
    >
      Generate Reports
    </button>
  );
}
