// @ts-nocheck
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getItemList } from "../partsList";
import { findProductByFullName, getDollarAmount } from "../utils";
import Icon from "./Icon";
import { roundToHundreth } from "../utils";
import sortItemList from "../partsList/sortItemList";

const PartsList = ({
  getTotals,
  expanded,
  selected,
  setSelected,
  inventory,
}) => {
  const state = useSelector((state) => state.state);

  const currentCanvas = state.present.canvases.get(state.present.currentCanvas);
  currentCanvas.settings = state.present.settings;
  currentCanvas.currentCanvas = state.present.currentCanvas;
  const itemList = sortItemList(
    getItemList(currentCanvas, inventory, state.present)
  );

  const itemsPresent = Object.values(itemList).length;

  const PartsListComponent = itemsPresent
    ? expanded
      ? ExpandedItemList
      : ItemList
    : EmptyPartsList;

  const partsListClassName = itemsPresent
    ? "parts-list-component"
    : "parts-list-component parts-list--empty";

  return (
    <div className={partsListClassName}>
      <PartsListComponent
        getTotals={getTotals}
        selected={selected}
        setSelected={setSelected}
        inventory={inventory}
      />
    </div>
  );
};

const EmptyPartsList = () => {
  return (
    <div className="empty-parts-list">Start adding objects to the canvas</div>
  );
};

const ItemList = ({ getTotals, inventory }) => {
  const state = useSelector((state) => state.state);

  const project = state.present;
  const currentCanvas = state.present.canvases.get(state.present.currentCanvas);
  currentCanvas.settings = state.present.settings;
  currentCanvas.currentCanvas = state.present.currentCanvas;
  const itemList = sortItemList(
    getItemList(currentCanvas, inventory, state.present)
  );

  const canvases = state.present.canvases;

  let overridenUpcs = {};

  if (project.overrides) {
    project.overrides.forEach((change) => {
      if (change.item && change.item.upc) {
        overridenUpcs[change.item.upc] = true;
      }
    });
  }

  // Add upcs of items that have been overriden in a canvas.
  if (currentCanvas.overrides) {
    currentCanvas.overrides.forEach((change) => {
      if (change.product && change.product.upc) {
        overridenUpcs[change.product.upc] = true;
      }

      if (change.product && change.product.BarCodeValue) {
        overridenUpcs[change.product.BarCodeValue] = true;
      }

      if (
        change.product &&
        change.product.FullName &&
        !change.product.BarCodeValue &&
        !change.product.upc
      ) {
        overridenUpcs[change.product.FullName] = true;
      }

      if (
        change.product &&
        change.product.type &&
        change.product.type === "group"
      ) {
        if (change.product.ItemGroupLine) {
          if (Array.isArray(change.product.ItemGroupLine)) {
            change.product.ItemGroupLine.forEach((item) => {
              const product = findProductByFullName(
                item.ItemRef.FullName,
                inventory
              );

              if (product) {
                overridenUpcs[
                  product.BarCodeValue || product.upc || product.FullName
                ] = true;
              }
            });
          } else {
            const product = findProductByFullName(
              change.product.ItemGroupLine.ItemRef.FullName,
              inventory
            );

            if (product) {
              overridenUpcs[
                product.BarCodeValue || product.upc || product.FullName
              ] = true;
            }
          }
        }
      }

      if (change.type && change.type === "removeGroupItem") {
        if (overridenUpcs[change.id]) {
          delete overridenUpcs[change.id];
        }
      }
    });
  }

  const runs = currentCanvas.runs;

  // Add upcs of items that have been overriden in runs.
  runs.forEach((run) => {
    if (run.overrides.itemListChanges) {
      run.overrides.itemListChanges.forEach((change) => {
        if (change.product && change.product.upc) {
          overridenUpcs[change.product.upc] = true;
        }

        if (change.product && change.product.BarCodeValue) {
          overridenUpcs[change.product.BarCodeValue] = true;
        }

        if (
          change.product &&
          change.product.FullName &&
          !change.product.BarCodeValue &&
          !change.product.upc
        ) {
          overridenUpcs[change.product.FullName] = true;
        }

        if (
          change.product &&
          change.product.type &&
          change.product.type === "group"
        ) {
          if (change.product.ItemGroupLine) {
            if (Array.isArray(change.product.ItemGroupLine)) {
              change.product.ItemGroupLine.forEach((item) => {
                const product = findProductByFullName(
                  item.ItemRef.FullName,
                  inventory
                );

                overridenUpcs[
                  product.BarCodeValue || product.upc || product.FullName
                ] = true;
              });
            } else {
              const product = findProductByFullName(
                change.product.ItemGroupLine.ItemRef.FullName,
                inventory
              );

              overridenUpcs[
                product.BarCodeValue || product.upc || product.FullName
              ] = true;
            }
          }
        }
      });
    }
  });

  return (
    <div className="item-list__container">
      <table className="item-list">
        <thead>
          <tr>
            <th>Qty</th>
            <th>Description</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(itemList).map((item) => {
            const isOverriden = overridenUpcs[item.upc];

            let rowClassName = "table-item__row";

            if (isOverriden) {
              rowClassName += " table-item__row--overriden";
            }

            return (
              <tr className={rowClassName} key={item.upc}>
                <td className="table-item table-item--qty">
                  {item.quantity}
                  <span className="unit-measure">{item.measure}</span>
                </td>
                <td className="table-item table-item--desc">
                  {item.description}
                </td>
                <td className="table-item table-item--total">
                  {isNaN(item.total) ? "" : getDollarAmount(item.total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {getTotals.size > 1 && (
        <div className="canvas-totals">
          {canvases.entrySeq().map(([_, canvas], index) => {
            return (
              <div key={canvas.id} className="item-list__canvas-total">
                <span className="item-list__canvas-name">{canvas.name}: </span>
                <span className="item-list__canvas-number">
                  ${getDollarAmount(getTotals.get(index))}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ExpandedItemList = ({ getTotals, selected, setSelected, inventory }) => {
  const state = useSelector((state) => state.state);
  const dispatch = useDispatch();

  const project = state.present;
  const currentCanvas = state.present.canvases.get(state.present.currentCanvas);
  currentCanvas.settings = state.present.settings;
  const itemList = sortItemList(
    getItemList(currentCanvas, inventory, state.present)
  );

  const [quantities, setQuantities] = useState(
    Object.values(itemList).reduce((acc, item) => {
      acc[item.upc] = item.quantity;

      return acc;
    }, {})
  );

  const [editingQuantities, setEditingQuantities] = useState(
    Object.values(itemList).reduce((acc, item) => {
      acc[item.upc] = false;

      return acc;
    }, {})
  );

  useEffect(() => {
    setQuantities(
      Object.values(itemList).reduce((acc, item) => {
        acc[item.upc] = item.quantity;

        return acc;
      }, {})
    );
  }, [state.present.hashCode()]); // eslint-disable-line react-hooks/exhaustive-deps

  const canvases = state.present.canvases;

  let overridenUpcs = {};

  if (project.overrides) {
    project.overrides.forEach((change) => {
      if (change.item && change.item.upc) {
        overridenUpcs[change.item.upc] = true;
      }
    });
  }

  if (currentCanvas.overrides) {
    currentCanvas.overrides.forEach((change) => {
      if (change.product && change.product.upc) {
        overridenUpcs[change.product.upc] = true;
      }

      if (change.product && change.product.BarCodeValue) {
        overridenUpcs[change.product.BarCodeValue] = true;
      }

      if (
        change.product &&
        change.product.FullName &&
        !change.product.BarCodeValue &&
        !change.product.upc
      ) {
        overridenUpcs[change.product.FullName] = true;
      }

      if (
        change.product &&
        change.product.type &&
        change.product.type === "group"
      ) {
        if (change.product.ItemGroupLine) {
          if (Array.isArray(change.product.ItemGroupLine)) {
            change.product.ItemGroupLine.forEach((item) => {
              const product = findProductByFullName(
                item.ItemRef.FullName,
                inventory
              );

              overridenUpcs[
                product.BarCodeValue || product.upc || product.FullName
              ] = true;
            });
          } else {
            const product = findProductByFullName(
              change.product.ItemGroupLine.ItemRef.FullName,
              inventory
            );

            overridenUpcs[
              product.BarCodeValue || product.upc || product.FullName
            ] = true;
          }
        }
      }

      if (change.type && change.type === "removeGroupItem") {
        if (overridenUpcs[change.id]) {
          delete overridenUpcs[change.id];
        }
      }
    });
  }

  const runs = currentCanvas.runs;

  runs.forEach((run) => {
    if (run.overrides.itemListChanges) {
      run.overrides.itemListChanges.forEach((change) => {
        if (change.product && change.product.upc) {
          overridenUpcs[change.product.upc] = true;
        }

        if (change.product && change.product.BarCodeValue) {
          overridenUpcs[change.product.BarCodeValue] = true;
        }

        if (
          change.product &&
          change.product.FullName &&
          !change.product.BarCodeValue &&
          !change.product.upc
        ) {
          overridenUpcs[change.product.FullName] = true;
        }

        if (
          change.product &&
          change.product.type &&
          change.product.type === "group"
        ) {
          if (change.product.ItemGroupLine) {
            if (Array.isArray(change.product.ItemGroupLine)) {
              change.product.ItemGroupLine.forEach((item) => {
                const product = findProductByFullName(
                  item.ItemRef.FullName,
                  inventory
                );

                overridenUpcs[
                  product.BarCodeValue || product.upc || product.FullName
                ] = true;
              });
            } else {
              const product = findProductByFullName(
                change.product.ItemGroupLine.ItemRef.FullName,
                inventory
              );

              overridenUpcs[
                product.BarCodeValue || product.upc || product.FullName
              ] = true;
            }
          }
        }
      });
    }
  });

  return (
    <div className="item-list__container">
      <table className="item-list">
        <thead>
          <tr>
            <th className="hardware-table__header-item checkbox"></th>
            <th>Qty</th>
            <th>U/M</th>
            <th>UPC</th>
            <th>Name</th>
            <th>Description</th>
            <th>Cost</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(itemList).map((item) => {
            const isOverriden = overridenUpcs[item.upc];

            let rowClassName = "table-item__row";

            if (isOverriden) {
              rowClassName += " table-item__row--overriden";
            }

            return (
              <tr className={rowClassName} key={item.upc}>
                <td className="hardware-table__item checkbox">
                  <EditDescription item={item} totals={getTotals} />
                  <input
                    type="checkbox"
                    className="hardware-table__checkbox"
                    onChange={() => {
                      setSelected({
                        ...selected,
                        [item.upc]: !selected[item.upc],
                      });
                    }}
                    checked={selected[item.upc] === true}
                  />
                </td>
                <td className="table-item table-item--qty">
                  <input
                    className="hardware-table__quantity-input"
                    value={
                      editingQuantities[item.upc]
                        ? quantities[item.upc]
                        : item.quantity
                    }
                    onFocus={(event) => {
                      setEditingQuantities({
                        ...editingQuantities,
                        [item.upc]: true,
                      });
                    }}
                    onChange={(event) => {
                      let newQuantity = parseInt(event.target.value, 10);

                      if (isNaN(newQuantity)) {
                        newQuantity = "";
                      }

                      setQuantities({ ...quantities, [item.upc]: newQuantity });
                    }}
                    onBlur={(event) => {
                      let newQuantity = parseInt(event.target.value || 0, 10);

                      if (newQuantity === item.quantity) {
                        return;
                      }

                      if (isNaN(newQuantity)) {
                        newQuantity = 0;
                      }

                      let overrides = state.present.canvases.get(
                        state.present.currentCanvas
                      ).overrides;

                      setEditingQuantities({
                        ...editingQuantities,
                        [item.upc]: false,
                      });

                      overrides = overrides.push({
                        product: item,
                        newQuantity: newQuantity,
                        quantityChange: newQuantity - item.quantity,
                        type: "quantityChange",
                      });

                      dispatch({
                        type: "canvas/overrides",
                        overrides: overrides,
                      });
                    }}
                  />
                </td>
                <td className="table-item table-item--um">
                  <span className="unit-measure">{item.measure}</span>
                </td>
                <td className="table-item table-item--upc">{item.upc}</td>
                <td className="table-item table-item--name">{item.name}</td>
                <td className="table-item table-item--desc">
                  {item.description}
                </td>
                <td className="table-item table-item--price">
                  {isNaN(item.price) ? "" : getDollarAmount(item.price)}
                </td>
                <td className="table-item table-item--total">
                  {isNaN(item.total) ? "" : getDollarAmount(item.total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {getTotals.size > 1 && (
        <div className="canvas-totals">
          {canvases.entrySeq().map(([_, canvas], index) => {
            return (
              <div key={"total" + index} className="item-list__canvas-total">
                <span className="item-list__canvas-name">{canvas.name}: </span>
                <span className="item-list__canvas-number">
                  ${getDollarAmount(getTotals.get(index))}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

function EditDescription(props) {
  const [open, setOpen] = useState(false);

  if (props.item.upc === "SubTotal") {
    return null;
  }

  return [
    <button
      key={props.item.upc + "button"}
      onClick={() => {
        setOpen((previousValue) => !previousValue);
      }}
      className="edit-description__button edit-qb-value__button"
    >
      <Icon className="edit-qb-item__icon" icon="edit" />
    </button>,
    open && (
      <EditQBItemWindow
        key={props.item.upc + "edit"}
        setOpen={setOpen}
        item={props.item}
        totals={props.totals}
      />
    ),
  ];
}

function EditQBItemWindow(props) {
  const [description, setDescription] = useState(props.item.description);
  const [price, setPrice] = useState(
    props.item?.price
      ? !props.item?.otherDiscounts?.percent
        ? props.item?.price
        : ""
      : ""
  );
  const [discountPercent, setDiscountPercent] = useState(
    props.item.discountPercent
      ? !props.item?.otherDiscounts?.flat
        ? props.item.discountPercent
        : ""
      : ""
  );
  const [flatDiscount, setFlatDiscount] = useState(
    props.item?.otherDiscounts?.flat ? props.item?.otherDiscounts?.flat : ""
  );

  const [percentDiscount, setPercentDiscount] = useState(
    props.item?.otherDiscounts?.percent
      ? props.item?.otherDiscounts?.percent
      : ""
  );

  const { item, totals } = props;

  let priceText = "Price";
  let otherDiscountText = "Discount %";

  let total = totals?.size ? totals.reduce((sum, value) => sum + value, 0) : 0;

  if (item.type === "discount") {
    if (item.discountPercent && item.discountPercent > 0) {
      priceText = "Discount %";
      otherDiscountText = "Flat Discount";
    } else {
      priceText = "Flat Discount";
      otherDiscountText = "Discount %";
    }
  }

  const dispatch = useDispatch();

  return (
    <div className="edit-qb-item__window">
      <p>Description</p>
      <textarea
        className="edit-qb-item__description"
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        value={description}
      ></textarea>
      {item.type !== "discount" && (
        <div>
          <p>Price</p>
          <input
            className="edit-qb-item__price"
            onChange={(event) => {
              setPrice(event.target.value);
            }}
            value={price.toString() || ""}
          />
        </div>
      )}
      {item.type === "discount" && (
        <div>
          <p>{priceText}</p>
          {item.discountPercent && item.discountPercent > 0 && (
            <>
              <input
                className="edit-qb-item__price"
                onChange={(event) => {
                  setDiscountPercent(event.target.value);
                  setFlatDiscount("");
                }}
                value={discountPercent || ""}
              />
              <p>{otherDiscountText}</p>
              <input
                className="edit-qb-item__price"
                onChange={(event) => {
                  const value = parseFloat(event.target.value);

                  if (isNaN(value) && event.target.value !== "") {
                    return;
                  }

                  setFlatDiscount(event.target.value);
                  setDiscountPercent("");
                }}
                value={flatDiscount || ""}
              />
            </>
          )}
          {!item.discountPercent && !discountPercent && (
            <>
              <input
                className="edit-qb-item__price"
                onChange={(event) => {
                  setPrice(event.target.value);
                  setPercentDiscount("");
                }}
                value={price || ""}
              />
              <p>{otherDiscountText}</p>
              <input
                className="edit-qb-item__price"
                onChange={(event) => {
                  const value = parseFloat(event.target.value);

                  if (isNaN(value) && event.target.value !== "") {
                    return;
                  }

                  setPrice("");
                  setPercentDiscount(event.target.value);
                }}
                value={percentDiscount || ""}
              />
            </>
          )}
        </div>
      )}
      <button
        onClick={() => {
          const item = props.item;

          if (item.type !== "discount" || !item.discountPercent) {
            item.description = description;
            let newPrice = parseFloat(price);

            if (isNaN(newPrice)) {
              newPrice = props.item.price;
            } else {
              newPrice = roundToHundreth(newPrice);
            }

            if (item.type === "discount") {
              if (price && price.indexOf && price.indexOf("-") === 0) {
                newPrice = -1 * newPrice;
              }

              newPrice = -1 * newPrice;

              item.otherDiscounts = {
                percent: percentDiscount,
              };

              if (percentDiscount !== 0 && percentDiscount !== "") {
                const percent = percentDiscount / 100;

                newPrice = roundToHundreth(total * -1 * percent);
              }
            }

            if (percentDiscount === 0 || percentDiscount === "") {
              item.price = newPrice;
            }

            const override = {
              type: "itemDescPriceChange",
              item: item,
            };

            dispatch({ type: "project/add-override", override: override });
            props.setOpen(false);
          } else {
            item.description = description;
            let newDiscountPercent = parseFloat(discountPercent);

            if (isNaN(newDiscountPercent)) {
              // Do not allow value to go to zero.
              newDiscountPercent = 1;
            } else {
              // Do not allow value to go to zero.
              if (newDiscountPercent === 0 || newDiscountPercent < 0) {
                newDiscountPercent = 1;
              }
            }

            item.otherDiscounts = {
              flat: flatDiscount,
            };

            if (flatDiscount > 0) {
              const percent = flatDiscount / total;

              newDiscountPercent = percent * 100;
            }

            item.discountPercent = newDiscountPercent;

            const override = {
              type: "itemDiscountPercentChange",
              item: item,
            };
            dispatch({ type: "project/add-override", override: override });
            props.setOpen(false);
          }
        }}
        className="edit-qb-item__submit-button button button--primary"
      >
        Update
      </button>
      <button
        onClick={() => {
          props.setOpen((previousValue) => !previousValue);
        }}
        className="edit-qb-item__submit-button button button--secondary"
      >
        Cancel
      </button>
    </div>
  );
}

export default PartsList;
