// @ts-nocheck
/**
 * External dependencies.
 */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "./Icon";
import EstimatorSelect from "./EstimatorSelect";
import CustomerSelect from "./CustomerSelect/CustomerSelect";
import {
  getYearMonthDay,
  maximumCustomPostSpacing,
  projectEstimateNumber,
} from "../utils";
import SelectBox from "./SelectBox";
import { isProjectFrozen } from "../utils/freezeProject";

const tabClassName = (tabName, tab) => {
  if (tabName === tab) {
    return "project-settings__tab project-settings__tab--active";
  }

  return "project-settings__tab";
};

const statusChangeHandler =
  (status, settings, currentProject, dispatch, setOpen) => () => {
    setOpen(false);

    if (settings.status === status) {
      return;
    }

    dispatch({
      type: "settings/edit",
      settings: settings.set("status", status),
      id: currentProject,
    });
  };

const statusClassName = (status, settings) => {
  let className = "status-select__option";

  if (settings.status === status) {
    className += " status-select__option--selected";
  }

  return className;
};

const StatusDropdown = (props) => {
  const { currentProject, settings } = props;

  const project = useSelector((state) => state.state.present);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  let selectBoxClass = "select-box status-select-box";

  if (open) {
    selectBoxClass += " status-select-box--open";
  }

  return (
    <div className="project-status">
      <label htmlFor="project-status" className="project-settings__label">
        Status
      </label>
      <div
        onClick={() => {
          if (isProjectFrozen(project)) {
            dispatch({
              type: "window/open-project-frozen-dialog",
            });
          } else {
            setOpen((open) => !open);
          }
        }}
        className={selectBoxClass}
      >
        <div className="status-select-box__selected">{settings.status}</div>
        <Icon icon="chev-down" className="select-box__icon" />
      </div>
      {open && (
        <div className="status-select__dropdown">
          <button
            onClick={statusChangeHandler(
              "pending",
              settings,
              currentProject,
              dispatch,
              setOpen
            )}
            className={statusClassName("pending", settings)}
          >
            Pending
          </button>
          <button
            onClick={statusChangeHandler(
              "complete",
              settings,
              currentProject,
              dispatch,
              setOpen
            )}
            className={statusClassName("complete", settings)}
          >
            Complete
          </button>
          <button
            onClick={statusChangeHandler(
              "cancelled",
              settings,
              currentProject,
              dispatch,
              setOpen
            )}
            className={statusClassName("cancelled", settings)}
          >
            Cancelled
          </button>
          <button
            onClick={statusChangeHandler(
              "archived",
              settings,
              currentProject,
              dispatch,
              setOpen
            )}
            className={statusClassName("archived", settings)}
          >
            Archived
          </button>
          <button
            onClick={statusChangeHandler(
              "trash",
              settings,
              currentProject,
              dispatch,
              setOpen
            )}
            className={statusClassName("trash", settings)}
          >
            Trash
          </button>
        </div>
      )}
    </div>
  );
};

const GeneralDetails = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const settings = state.present.settings;
  const project = state.present;

  const requiredFields = useSelector((state) => state.requiredFields);

  let resellerPermitClass = "reseller-permit";

  if (settings.resellerPermit) {
    resellerPermitClass += " reseller-permit--checked";
  }

  let projectTypeClass = "project-id__item";

  if (requiredFields.has("projectType")) {
    projectTypeClass += " field--required";
  }

  return (
    <div className="project-settings__content general-details">
      <div className="project-settings__details">
        <div className="project-title">
          <label htmlFor="project-title" className="project-settings__label">
            Project Title
          </label>
          <input
            className="project-title__input"
            id="project-title"
            maxLength="42"
            placeholder="Untitled Project"
            value={settings.projectTitle}
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });
              } else {
                dispatch({
                  type: "settings/edit",
                  settings: settings.set("projectTitle", event.target.value),
                  id: currentProject,
                });
              }
            }}
          />
          <p className="input-subtext">Enter up to 42 characters</p>
        </div>
        <div className="project-people">
          <CustomerSelect project={project} />
          <EstimatorSelect />
        </div>
        <div className="project-resellerpermit">
          <div
            className={resellerPermitClass}
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });
              } else {
                dispatch({
                  type: "settings/edit",
                  settings: settings.set(
                    "resellerPermit",
                    !settings.resellerPermit
                  ),
                  id: currentProject,
                });
              }
            }}
          >
            <Icon className="reseller-permit__icon" icon="check" />
          </div>
          <p className="reseller-permit__note">
            Customer has a Reseller Permit
          </p>
        </div>
      </div>
      <div className="project-settings__status">
        <StatusDropdown settings={settings} currentProject={currentProject} />
        <div className="project-id">
          <div className="project-id__item">
            <label
              htmlFor="estimate-number"
              className="project-settings__label"
            >
              Estimate Number
            </label>
            <p className="project-settings__value">
              {projectEstimateNumber(project)}
            </p>
          </div>
          <div className="project-id__item">
            <label
              htmlFor="estimate-number"
              className="project-settings__label"
            >
              Created On
            </label>
            <p className="project-settings__value">
              {getYearMonthDay(project.createdOn)}
            </p>
          </div>
          <div className={projectTypeClass}>
            <label htmlFor="project-type" className="project-settings__label">
              Project Type
            </label>
            <SelectBox
              options={{
                none: "Not Set",
                residential: "Residential",
                contractor: "Contractor",
                commercial: "Commercial",
              }}
              selected={settings.projectType}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });
                } else {
                  const value = event.target.value;

                  dispatch({
                    type: "settings/edit",
                    settings: settings.set("projectType", value),
                    id: currentProject,
                  });
                  dispatch({
                    type: "fields/unrequire-field",
                    field: "projectType",
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const railingOptionClassName = (option, value) => {
  if (option === value) {
    return "railing-info__option railing-info__option--active";
  }

  return "railing-info__option";
};

const railingImageOptionClassName = (option, value) => {
  if (option === value) {
    return "railing-info__image-option railing-info__image-option--active";
  }

  return "railing-info__image-option";
};

const RailingInfo = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const requiredFields = useSelector((state) => state.requiredFields);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  let nearSaltwaterClassName = "railing-info__item";
  let nearPoolClassName = "railing-info__item";

  if (requiredFields.has("nearSaltwater")) {
    nearSaltwaterClassName += " field--required";
  }

  if (requiredFields.has("nearPoolwater")) {
    nearPoolClassName += " field--required";
  }

  const [customPostSpacing, setCustomPostSpacing] = useState(
    settings.customPostSpacing
  );

  const [customRailHeight, setCustomRailHeight] = useState(
    settings.customRailHeight
  );

  const allFasciaBracketOptions = {
    aluminum: {
      none: "None",
      direct: "Direct Fascia Mounting",
      "direct-spacer": "Direct Fascia Mounting with Spacer",
    },
    "stainless-steel": {
      none: "None",
      direct: "Direct Fascia Mounting",
      "direct-spacer": "Direct Fascia Mounting with Spacer",
      "ss-square-4hole-rect": "Rectangular Fascia Bracket for Square Post",
      "ss-round-4hole-rect": "Rectangular Fascia Bracket 4 Hole for Round Post",
      "ss-round-2hole-rect": "Rectangular Fascia Bracket 2 Hole for Round Post",
      "ss-round-2hole-round": "Round Fascia Bracket 2 Hole for Round Post",
    },
    wood: {
      none: "None",
      direct: "Direct Fascia Mounting",
      "direct-spacer": "Direct Fascia Mounting with Spacer",
    },
    "customer-provided": {
      none: "None",
      direct: "Direct Fascia Mounting",
      "direct-spacer": "Direct Fascia Mounting with Spacer",
    },
  };
  const fasciaBracketOptions = allFasciaBracketOptions[settings.postMaterial];

  return (
    <div className="project-settings__content railing-info">
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Post Spacing</p>
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });
              } else {
                if (settings.postSpacing !== "4") {
                  dispatch({
                    type: "settings/edit-spacing",
                    settings: settings
                      .set("postSpacing", "4")
                      .set("customPostSpacing", 4),
                    id: currentProject,
                  });
                  setCustomPostSpacing(4);
                }
              }
            }}
            className={railingOptionClassName(settings.postSpacing, "4")}
          >
            4'
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });
              } else {
                if (settings.postSpacing !== "5") {
                  dispatch({
                    type: "settings/edit-spacing",
                    settings: settings
                      .set("postSpacing", "5")
                      .set("customPostSpacing", 5),
                    id: currentProject,
                  });
                  setCustomPostSpacing(5);
                }
              }
            }}
            className={railingOptionClassName(settings.postSpacing, "5")}
          >
            5'
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });
              } else {
                if (settings.get("postSpacing") !== "custom") {
                  dispatch({
                    type: "settings/edit-spacing",
                    settings: settings.set("postSpacing", "custom"),
                    id: currentProject,
                  });
                }
              }
            }}
            className={railingOptionClassName(settings.postSpacing, "custom")}
          >
            Custom
          </button>
        </div>
      </div>
      {settings.postSpacing === "custom" && (
        <div className="railing-info__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Custom Post Spacing</p>
          </div>
          <div className="railing-info__options">
            <input
              className="number-of-posts"
              style={{ width: "max-content", flexGrow: 0 }}
              value={customPostSpacing}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });
                } else {
                  setCustomPostSpacing(event.target.value);

                  const spacing = parseFloat(event.target.value);

                  if (!isNaN(spacing)) {
                    updateCustomSpacing(
                      spacing,
                      setCustomPostSpacing,
                      settings,
                      dispatch,
                      currentProject
                    );
                  }
                }
              }}
              type="number"
            />
          </div>
        </div>
      )}
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Cable Size</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a run
          </p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                let newSettings = settings.set("cableSize", "1 x 19 - 1/8”");

                // Only 3/16th cable can have classic thru post assembly type.
                if (
                  settings.thruPostType === "classic-ball" ||
                  settings.thruPostType === "classic-button"
                ) {
                  newSettings = newSettings.set("thruPostType", "field-swaged");
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.cableSize,
                "1 x 19 - 1/8”"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/cable-img03.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">1 x 19 - 1/8”</p>
          </div>
          {settings.cableType !== "black-oxide" &&
            settings.cableType !== "2507-steel" && (
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    let newSettings = settings.set(
                      "cableSize",
                      "1 x 19 - 3/16”"
                    );

                    // Only 1/8th cable can have classic thru post assembly type.
                    if (settings.cableFittingType === "swageless") {
                      newSettings = newSettings.set(
                        "cableFittingType",
                        "thru-post"
                      );
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: newSettings,
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.cableSize,
                    "1 x 19 - 3/16”"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/cable-img03.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">1 x 19 - 3/16”</p>
              </div>
            )}
          {settings.cableType !== "black-oxide" &&
            settings.cableType !== "2507-steel" && (
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    let newSettings = settings.set(
                      "cableSize",
                      "7 x 7 - 3/16”"
                    );

                    // Only 1/8th cable can have classic thru post assembly type.
                    if (settings.cableFittingType === "swageless") {
                      newSettings = newSettings.set(
                        "cableFittingType",
                        "thru-post"
                      );
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: newSettings,
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.cableSize,
                    "7 x 7 - 3/16”"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/cable-img01.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">7 x 7 - 3/16”</p>
              </div>
            )}
        </div>
      </div>
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Cable Type</p>
        </div>
        <div className="railing-info__options">
          <SelectBox
            options={{
              default: "Stainless Steel",
              "black-oxide": "Black Oxide",
              // todo-2507: check where to use this value
              "2507-steel": "2507 Steel",
            }}
            className="fascia-bracket-options"
            selected={settings.cableType}
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              const value = event.target.value;
              let newSettings = settings.set("cableType", value);

              if (value === "black-oxide") {
                newSettings = newSettings
                  .set("cableSize", "1 x 19 - 1/8”")
                  .set("swagelessType", "type-2");
              }

              if (value === "2507-steel") {
                newSettings = newSettings
                  .set("cableSize", "1 x 19 - 1/8”")
                  .set("cableFittingType", "thru-post")
                  .set("thruPostType", "factory-swaged")
                  .set("endFitting", "quick-nut");
              }

              dispatch({
                type: "settings/edit",
                settings: newSettings,
                id: currentProject,
              });
            }}
          />
        </div>
      </div>
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Rail Height</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a run
          </p>
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("railHeight", "36"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.railHeight, "36")}
          >
            36”
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("railHeight", "42"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.railHeight, "42")}
          >
            42”
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("railHeight", "custom"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.railHeight, "custom")}
          >
            Custom
          </button>
        </div>
      </div>
      {settings.railHeight === "custom" && (
        <div className="railing-info__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Custom Rail Height</p>
          </div>
          <div className="railing-info__options">
            <label>
              Feet
              <input
                className="number-of-posts"
                style={{ width: "max-content", flexGrow: 0 }}
                value={customRailHeight.feet}
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const feet = parseInt(event.target.value);

                  setCustomRailHeight({
                    feet,
                    inches: customRailHeight.inches,
                  });

                  if (!isNaN(feet)) {
                    updateCustomRailheight(
                      { feet, inches: customRailHeight.inches },
                      setCustomRailHeight,
                      settings,
                      dispatch,
                      currentProject
                    );
                  }
                }}
                type="number"
                min="0"
              />
            </label>
            <label style={{ marginLeft: "9px" }}>
              Inches
              <input
                className="number-of-posts"
                style={{ width: "max-content", flexGrow: 0 }}
                value={customRailHeight.inches}
                min="0"
                max="11"
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const inches = parseInt(event.target.value);

                  setCustomRailHeight({
                    feet: customRailHeight.feet,
                    inches: inches,
                  });

                  if (!isNaN(inches)) {
                    updateCustomRailheight(
                      { inches, feet: customRailHeight.feet },
                      setCustomRailHeight,
                      settings,
                      dispatch,
                      currentProject
                    );
                  }
                }}
                type="number"
              />
            </label>
          </div>
        </div>
      )}
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Mount Style</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a post
          </p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("mountStyle", "deck")
                    .set("hardwareType", "lags")
                    .set("includeCoverPlate", "yes")
                    .set("hardwareSize", '3/8" x 4 1/2"'),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.mountStyle,
                "deck"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/deckmount-img03.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Deck / Top</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("mountStyle", "fascia")
                    .set("hardwareType", "bolts")
                    .set("hardwareSize", '3/8" x 5 1/2"'),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.mountStyle,
                "fascia"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/deckmount-img02.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Fascia / Side</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("mountStyle", "core")
                    // Set hardware to none when core mount is selected.
                    .set("hardwareType", "none")
                    // Set cover plate to none when set.
                    .set("includeCoverPlate", "no"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.mountStyle,
                "core"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/deckmount-img01.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Core / Ground</p>
          </div>
          {
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  let newSettings = settings
                    .set("mountStyle", "pony-wall")
                    .set("includeCoverPlate", "no");
                  dispatch({
                    type: "settings/edit",
                    settings: newSettings,
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.mountStyle,
                  "pony-wall"
                )}
              >
                <img
                  alt="Stainless Cable & Railing"
                  src={process.env.PUBLIC_URL + "/ponywall.jpg"}
                  className="option-image ponywall-image"
                />
              </button>
              <p className="option__image-label">Pony Wall</p>
            </div>
          }
        </div>
      </div>
      {(settings.mountStyle === "deck" ||
        settings.mountStyle === "core" ||
        settings.mountStyle === "pony-wall") && (
        <div className="railing-info__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Include Cover Plate</p>
          </div>
          <div className="railing-info__options">
            <SelectBox
              options={{
                yes: "Yes",
                no: "No",
              }}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const value = event.target.value;
                let newSettings = settings.set("includeCoverPlate", value);

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.includeCoverPlate}
              className="fascia-bracket-options"
            />
          </div>
        </div>
      )}
      {settings.mountStyle === "fascia" && (
        <div className="railing-info__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">
              Select Fascia Bracket/Mount Type
            </p>
          </div>
          <div className="railing-info__options">
            <SelectBox
              options={fasciaBracketOptions}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const value = event.target.value;
                let newSettings = settings.set("fasciaBracketType", value);

                if (value === "ss-square-4hole-rect") {
                  newSettings = newSettings
                    .set("postMaterial", "stainless-steel")
                    .set("stainlessPostShape", "square")
                    .set("receiverStudSize", "short");
                }

                if (
                  value === "ss-square-4hole-rect" &&
                  settings.stainlessPostShape === "2507-square"
                ) {
                  newSettings = newSettings
                    .set("postMaterial", "stainless-steel")
                    .set("stainlessPostShape", "2507-square")
                    .set("receiverStudSize", "short")
                    .set("hardwareSize", '2507 7"');
                }

                if (
                  value === "ss-round-4hole-rect" ||
                  value === "ss-round-2hole-rect" ||
                  value === "ss-round-2hole-round"
                ) {
                  newSettings = newSettings
                    .set("postMaterial", "stainless-steel")
                    .set("stainlessPostShape", "round")
                    .set("receiverStudSize", "short");
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.fasciaBracketType}
              className="fascia-bracket-options"
            />
          </div>
        </div>
      )}
      {settings.mountStyle === "pony-wall" && (
        <div className="railing-info__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Select Pony Wall Size</p>
          </div>
          <div className="railing-info__options">
            <SelectBox
              options={{
                "2x4": "2 x 4",
                "2x6greater": "2 x 6 or greater",
              }}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const value = event.target.value;

                let newSettings = settings.set("ponyWallSize", value);

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.ponyWallSize}
              className="fascia-bracket-options"
            />
          </div>
        </div>
      )}
      <div className={nearSaltwaterClassName}>
        <div className="railing-info__label">
          <p className="railing-info__option-label">
            Within 2 miles of a saltwater area?
          </p>
        </div>
        <div className="railing-info__options">
          {/* <p className="railing-info__option--text">
            {capitalizeString(settings.nearSaltwater)}
          </p> */}
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("nearSaltwater", "yes")
                  .set("includePassivation", "yes"),
                id: currentProject,
              });
              dispatch({
                type: "fields/unrequire-field",
                field: "nearSaltwater",
              });
            }}
            className={railingOptionClassName(settings.nearSaltwater, "yes")}
          >
            Yes
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("nearSaltwater", "no")
                  .set("includePassivation", "no"),
                id: currentProject,
              });
              dispatch({
                type: "fields/unrequire-field",
                field: "nearSaltwater",
              });
            }}
            className={railingOptionClassName(settings.nearSaltwater, "no")}
          >
            No
          </button>
        </div>
      </div>
      <div className={nearPoolClassName}>
        <div className="railing-info__label">
          <p className="railing-info__option-label">Near pool water?</p>
          {!settings.nearPool && (
            <p className="railing-info__subtext important">
              Please select yes or no
            </p>
          )}
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("nearPoolwater", "yes")
                  .set("includePassivation", "yes"),
                id: currentProject,
              });
              dispatch({
                type: "fields/unrequire-field",
                field: "nearPoolwater",
              });
            }}
            className={railingOptionClassName(settings.nearPoolwater, "yes")}
          >
            Yes
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("nearPoolwater", "no")
                  .set("includePassivation", "no"),
                id: currentProject,
              });
              dispatch({
                type: "fields/unrequire-field",
                field: "nearPoolwater",
              });
            }}
            className={railingOptionClassName(settings.nearPoolwater, "no")}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const AluminumOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;
  const title = props.title || "Aluminum Post Color";

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">{title}</p>
      </div>
      <div className="railing-info__options">
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "black"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "black"
            )}
          >
            <div className="color-item black"></div>
          </button>
          <p className="option__image-label">Black</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "bronze"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "bronze"
            )}
          >
            <div className="color-item bronze"></div>
          </button>
          <p className="option__image-label">Bronze</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "Natural"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "Natural"
            )}
          >
            <div className="color-item natural"></div>
          </button>
          <p className="option__image-label">Natural</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "clay"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "clay"
            )}
          >
            <div className="color-item clay"></div>
          </button>
          <p className="option__image-label">Clay</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "white"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "white"
            )}
          >
            <div className="color-item white"></div>
          </button>
          <p className="option__image-label">White</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "anodized-black"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "anodized-black"
            )}
          >
            <div className="color-item black"></div>
          </button>
          <p className="option__image-label option__image-label--anodized">
            Anodized Black
          </p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("aluminumColor", "anodized-clear"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.aluminumColor,
              "anodized-clear"
            )}
          >
            <div className="color-item natural"></div>
          </button>
          <p className="option__image-label option__image-label--anodized">
            Anodized Clear
          </p>
        </div>
      </div>
    </div>
  );
};

const StainlessSteelOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  const postCapOptions = {
    "hi-profile": "High Profile",
    "lo-profile": "Low Profile",
  };

  const weldingOptions = {
    none: "No Welding",
    top: "Weld Top Only",
    bottom: "Weld Bottom Only",
    both: "Weld Both",
  };

  const electroPolishOptions = {
    yes: "Yes",
    no: "No",
  };

  if (settings.stainlessPostShape === "round") {
    delete postCapOptions["lo-profile"];
  }

  if (settings.stainlessPostShape === "2507-square") {
    delete postCapOptions["hi-profile"];
    delete weldingOptions["top"];
    delete weldingOptions["bottom"];
    delete weldingOptions["none"];
  }

  return (
    <div>
      <div className="railing-info__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Stainless Post Shape</p>
          <p className="railing-info__subtext">
            This will affect the shape of posts on the canvas
          </p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("stainlessPostShape", "square"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.stainlessPostShape,
                "square"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/postshape-img02.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Square</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("stainlessPostShape", "round")
                    .set("stainlessPostCapType", "hi-profile"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.stainlessPostShape,
                "round"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/postshape-img01.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Round</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("stainlessPostShape", "2507-square")
                    .set("stainlessSteelToprailType", "2507")
                    .set("thruPostType", "2507-field-swaged")
                    .setIn(["handrailParts", "type"], "ss-2507-2")
                    .set("stainlessPostCapType", "lo-profile")
                    .set("stainlessWeldType", "both")
                    .set("hardwareSize", '2507 4 1/2"'),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.stainlessPostShape,
                "2507-square"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                // todo: change image
                src={process.env.PUBLIC_URL + "/postshape-img01.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">2507 Square</p>
          </div>
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">
            Stainless Steel Post Cap Type
          </p>
        </div>
        <div className="railing-info__options">
          <SelectBox
            options={postCapOptions}
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              const value = event.target.value;

              dispatch({
                type: "settings/edit",
                settings: settings.set("stainlessPostCapType", value),
                id: currentProject,
              });
            }}
            selected={settings.stainlessPostCapType}
          />
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">
            Stainless Steel Post Weld Type
          </p>
        </div>
        <div className="railing-info__options">
          <SelectBox
            options={weldingOptions}
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              const value = event.target.value;

              dispatch({
                type: "settings/edit",
                settings: settings.set("stainlessWeldType", value),
                id: currentProject,
              });
            }}
            selected={settings.stainlessWeldType}
          />
        </div>
      </div>
      {settings.stainlessPostShape === "round" && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Add Electro Polish</p>
          </div>
          <div className="railing-info__options">
            <SelectBox
              options={electroPolishOptions}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const value = event.target.value;

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("includeElectroPolish", value),
                  id: currentProject,
                });
              }}
              selected={settings.includeElectroPolish}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const WoodTypeOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  return (
    <div className="railing-info__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Wood Type</p>
        <p className="railing-info__subtext">
          This can be overwritten when editing a post
        </p>
      </div>
      <div className="railing-info__options">
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("woodType", "4x4 Batu"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.woodType,
              "4x4 Batu"
            )}
          >
            <img
              alt="Stainless Cable & Railing"
              src={process.env.PUBLIC_URL + "/postwood-img03.png"}
              className="option-image"
            />
          </button>
          <p className="option__image-label">4x4 Batu</p>
        </div>
        {
          // 	<div className="option__image-container">
          //   <button
          //     onClick={() => {
          //       dispatch({
          //         type: "settings/edit",
          //         settings: settings.set('woodType', "4x4 Quattro" ),
          //         id: currentProject,
          //       });
          //     }}
          //     className={railingImageOptionClassName(
          //       settings.woodType,
          //       "4x4 Quattro"
          //     )}
          //   >
          //     <img
          //       alt="Stainless Cable & Railing"
          //       src={process.env.PUBLIC_URL + "/postwood-img02.png"}
          //       className="option-image"
          //     />
          //   </button>
          //   <p className="option__image-label">4x4 Quattro</p>
          // </div>
          // <div className="option__image-container">
          //   <button
          //     onClick={() => {
          //       dispatch({
          //         type: "settings/edit",
          //         settings: settings.set('woodType', "6x6 Quattro" ),
          //         id: currentProject,
          //       });
          //     }}
          //     className={railingImageOptionClassName(
          //       settings.woodType,
          //       "6x6 Quattro"
          //     )}
          //   >
          //     <img
          //       alt="Stainless Cable & Railing"
          //       src={process.env.PUBLIC_URL + "/postwood-img01.png"}
          //       className="option-image"
          //     />
          //   </button>
          //   <p className="option__image-label">6x6 Quattro</p>
          // </div>
        }
      </div>
    </div>
  );
};

function stainlessFaciaBracketsTypes() {
  return [
    "ss-square-4hole-rect",
    "ss-round-4hole-rect",
    "ss-round-2hole-rect",
    "ss-round-2hole-round",
  ];
}

const PostStyles = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const project = state.present;
  const { currentProject } = appState;
  const settings = project.settings;

  const materialOptions = {
    aluminum: AluminumOptions,
    "stainless-steel": StainlessSteelOptions,
    wood: WoodTypeOptions,
    "customer-provided": () => null,
  };

  const MaterialOptions = materialOptions[settings.postMaterial];

  return (
    <div className="project-settings__content post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Post Material</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a post
          </p>
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              let newSettings = settings
                .set("postMaterial", "aluminum")
                .set("toprailMaterial", "aluminum")
                .set("receiverStudSize", "short");

              if (
                stainlessFaciaBracketsTypes().includes(
                  settings.get("fasciaBracketType")
                )
              ) {
                newSettings = newSettings.set("fasciaBracketType", "direct");
              }
              dispatch({
                type: "settings/edit",
                settings: newSettings,
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.postMaterial,
              "aluminum"
            )}
          >
            Aluminum
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("postMaterial", "stainless-steel")
                  .set("toprailMaterial", "stainless-steel")
                  .set("receiverStudSize", "short"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.postMaterial,
              "stainless-steel"
            )}
          >
            Stainless Steel
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              let newSettings = settings
                .set("postMaterial", "wood")
                .set("toprailMaterial", "wood")
                .set("woodToprailSetup", "wood")
                .set("receiverStudSize", "long")
                .set("mountStyle", "fascia");

              if (
                stainlessFaciaBracketsTypes().includes(
                  settings.get("fasciaBracketType")
                )
              ) {
                newSettings = newSettings.set("fasciaBracketType", "direct");
              }
              dispatch({
                type: "settings/edit",
                settings: newSettings,
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.postMaterial, "wood")}
          >
            Wood
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              let newSettings = settings.set(
                "postMaterial",
                "customer-provided"
              );

              if (
                stainlessFaciaBracketsTypes().includes(
                  settings.get("fasciaBracketType")
                )
              ) {
                newSettings = newSettings.set("fasciaBracketType", "direct");
              }
              dispatch({
                type: "settings/edit",
                settings: newSettings,
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.postMaterial,
              "customer-provided"
            )}
          >
            Customer Provided
          </button>
        </div>
      </div>
      <MaterialOptions settings={settings} />
    </div>
  );
};

const AluminumToprailOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  return (
    <div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Aluminum Type</p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("aluminumToprailType", "rectangular"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.aluminumToprailType,
                "rectangular"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/alum-toprail.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Rectangular</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("aluminumToprailType", "shaped"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.aluminumToprailType,
                "shaped"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/alum-shaped-toprail.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Shaped</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("aluminumToprailType", "alum-p2p"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.aluminumToprailType,
                "alum-p2p"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/alum-p2p-toprail.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Post to Post</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const newSettings = settings
                  .set("toprailMaterial", "wood")
                  .set("aluminumToprailType", "rectangular")
                  .set("postMaterial", "aluminum")
                  .set("woodToprailSetup", "wood-alum-p2p")
                  .set("receiverStudSize", "short");

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.aluminumToprailType,
                "wood-p2p"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/alum-wood-p2p-toprail.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Wood w/ P2P</p>
          </div>
        </div>
      </div>
      {settings.aluminumToprailType !== "wood-p2p" && (
        <AluminumOptions settings={settings} title="Aluminum Color" />
      )}
      {settings.aluminumToprailType === "wood-p2p" && (
        <div>
          <div
            style={{ justifyContent: "flex-start" }}
            className="railing-info__item post-material__item"
          >
            <div className="railing-info__label">
              <p className="railing-info__option-label">Wood Top Rail Type</p>
            </div>
            <div className="">
              <div className="select-box">
                <select
                  value={settings.woodToprailSize}
                  onChange={(event) => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: settings.set(
                        "woodToprailSize",
                        event.target.value
                      ),
                      id: currentProject,
                    });
                  }}
                  id="woodToprailSize"
                >
                  <option key="5/4x4" value="5/4x4">
                    5/4" x 4" with Post to Post
                  </option>
                  <option key="2x4" value="2x4">
                    2" x 4" with Post to Post
                  </option>
                  <option key="2x6" value="2x6">
                    2" x 6" with Post to Post
                  </option>
                  <option key="5/4x6" value="5/4x6">
                    5/4" x 6" with Post to Post
                  </option>
                </select>
                <Icon icon="chev-down" className="select-box__icon" />
              </div>
            </div>
          </div>
          <div className="railing-info__item post-material__item">
            <div className="railing-info__label">
              <p className="railing-info__option-label">Wood Type</p>
            </div>
            <div className="railing-info__options">
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: settings.set("woodToprailType", "clear-cedar"),
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.woodToprailType,
                    "clear-cedar"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/woodtype-img04.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">Clear Cedar</p>
              </div>
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: settings.set(
                        "woodToprailType",
                        "tight-knot-cedar"
                      ),
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.woodToprailType,
                    "tight-knot-cedar"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/woodtype-img03.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">Tight Knot Cedar</p>
              </div>
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: settings.set("woodToprailType", "balau"),
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.woodToprailType,
                    "balau"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/woodtype-img02.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">Balau</p>
              </div>
              <div className="option__image-container">
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: settings.set("woodToprailType", "ipe"),
                      id: currentProject,
                    });
                  }}
                  className={railingImageOptionClassName(
                    settings.woodToprailType,
                    "ipe"
                  )}
                >
                  <img
                    alt="Stainless Cable & Railing"
                    src={process.env.PUBLIC_URL + "/woodtype-img01.png"}
                    className="option-image"
                  />
                </button>
                <p className="option__image-label">IPE</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StainlessSteelToprailOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Stainless Steel Type</p>
      </div>
      <div className="railing-info__options">
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("stainlessSteelToprailType", "round"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.stainlessSteelToprailType,
              "round"
            )}
          >
            <img
              alt="Stainless Cable & Railing"
              src={process.env.PUBLIC_URL + "/stainless-round.png"}
              className="option-image"
            />
          </button>
          <p className="option__image-label">2” Round</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("stainlessSteelToprailType", "flat"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.stainlessSteelToprailType,
              "flat"
            )}
          >
            <img
              alt="Stainless Cable & Railing"
              src={process.env.PUBLIC_URL + "/stainless-flat.png"}
              className="option-image"
            />
          </button>
          <p className="option__image-label">Flat Rectangular</p>
        </div>
        <div className="option__image-container">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("stainlessSteelToprailType", "2507"),
                id: currentProject,
              });
            }}
            className={railingImageOptionClassName(
              settings.stainlessSteelToprailType,
              "2507"
            )}
          >
            <img
              alt="Stainless Cable & Railing"
              //   todo-2507: update image
              src={process.env.PUBLIC_URL + "/stainless-flat.png"}
              className="option-image"
            />
          </button>
          <p className="option__image-label">2507</p>
        </div>
      </div>
    </div>
  );
};

const WoodToprailOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);

  const { currentProject } = appState;
  const { settings } = props;

  return (
    <div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Wood Toprail Setup</p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("woodToprailSetup", "wood"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailSetup,
                "wood"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/alum-wood-p2p-toprail.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Wood</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings
                    .set("woodToprailSetup", "wood-alum-p2p")
                    .set("postMaterial", "aluminum")
                    .set("receiverStudSize", "short"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailSetup,
                "wood-alum-p2p"
              )}
              style={{ height: "75px" }}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/woodtype-img03.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Wood w/ P2P</p>
          </div>
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Wood Type</p>
        </div>
        <div className="railing-info__options">
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("woodToprailType", "clear-cedar"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailType,
                "clear-cedar"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/woodtype-img04.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Clear Cedar</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("woodToprailType", "tight-knot-cedar"),
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailType,
                "tight-knot-cedar"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/woodtype-img03.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Tight Knot Cedar</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                let newSettings = settings.set("woodToprailType", "balau");
                const size = newSettings.get("woodToprailSize");

                if (size !== "2x4") {
                  newSettings = newSettings.set("woodToprailSize", "2x4");
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailType,
                "balau"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/woodtype-img02.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">Balau</p>
          </div>
          <div className="option__image-container">
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                let newSettings = settings.set("woodToprailType", "ipe");

                const size = newSettings.get("woodToprailSize");

                if (size !== "2x4") {
                  newSettings = newSettings.set("woodToprailSize", "2x4");
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              className={railingImageOptionClassName(
                settings.woodToprailType,
                "ipe"
              )}
            >
              <img
                alt="Stainless Cable & Railing"
                src={process.env.PUBLIC_URL + "/woodtype-img01.png"}
                className="option-image"
              />
            </button>
            <p className="option__image-label">IPE</p>
          </div>
        </div>
      </div>
      <div
        style={{ justifyContent: "flex-start" }}
        className="railing-info__item post-material__item"
      >
        <div className="railing-info__label">
          <p className="railing-info__option-label">Wood Top Rail Size</p>
        </div>
        <div className="">
          <div className="select-box">
            <select
              value={settings.woodToprailSize}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("woodToprailSize", event.target.value),
                  id: currentProject,
                });
              }}
              id="woodToprailSize"
            >
              <option key="5/4x4" value="5/4x4">
                5/4" x 4"
              </option>
              <option key="5/4x6" value="5/4x6">
                5/4" x 6"
              </option>
              <option key="2x4" value="2x4">
                2" x 4"
              </option>
              {(settings.get("woodToprailType") === "clear-cedar" ||
                settings.get("woodToprailType") === "tight-knot-cedar") && (
                <option key="2x6" value="2x6">
                  2" x 6"
                </option>
              )}
            </select>
            <Icon icon="chev-down" className="select-box__icon" />
          </div>
        </div>
      </div>
      {settings.get("woodToprailSetup") === "wood-alum-p2p" && (
        <div
          style={{ justifyContent: "flex-start" }}
          className="railing-info__item post-material__item"
        >
          <div className="railing-info__label">
            <p className="railing-info__option-label">
              Wood P2P Stairs Configuration
            </p>
          </div>
          <div className="">
            <div className="select-box">
              <select
                value={settings.woodAlumP2PStairsSetup}
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.set(
                      "woodAlumP2PStairsSetup",
                      event.target.value
                    ),
                    id: currentProject,
                  });
                }}
                id="woodAlumP2PStairsSetup"
                style={{ paddingRight: "24px" }}
              >
                <option key="in-between" value="in-between">
                  P2P in between posts
                </option>
                <option key="on-top-brackets" value="on-top-brackets">
                  P2P on top of brackets
                </option>
              </select>
              <Icon icon="chev-down" className="select-box__icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function updateCustomRailheight(
  customRailheight,
  setCustomRailheight,
  settings,
  dispatch,
  currentProject
) {
  if (
    settings.customRailHeight.feet !== customRailheight.feet ||
    settings.customRailHeight.inches !== customRailheight.inches
  ) {
    dispatch({
      type: "settings/edit",
      settings: settings.set("customRailHeight", customRailheight),
      id: currentProject,
    });
  }
}

function updateCustomSpacing(
  customPostSpacing,
  setCustomPostSpacing,
  settings,
  dispatch,
  currentProject
) {
  let spacing = parseFloat(customPostSpacing);

  if (isNaN(spacing)) {
    spacing = 1;
  }

  if (spacing < 1) {
    spacing = 1;
  }

  if (spacing > maximumCustomPostSpacing) {
    spacing = maximumCustomPostSpacing;
  }

  setCustomPostSpacing(spacing);
  if (settings.customPostSpacing !== spacing) {
    dispatch({
      type: "settings/edit-spacing",
      settings: settings.set("customPostSpacing", spacing),
      id: currentProject,
    });
  }
}

const TopRailOptions = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  const materialOptions = {
    aluminum: AluminumToprailOptions,
    "stainless-steel": StainlessSteelToprailOptions,
    wood: WoodToprailOptions,
    "customer-provided": () => null,
  };

  const MaterialOptions = materialOptions[settings.toprailMaterial];

  return (
    <div className="project-settings__content post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Top Rail Type</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a post
          </p>
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("toprailMaterial", "aluminum"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.toprailMaterial,
              "aluminum"
            )}
          >
            Aluminum
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("toprailMaterial", "stainless-steel"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.toprailMaterial,
              "stainless-steel"
            )}
          >
            Stainless Steel
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              let newSettings = settings.set("toprailMaterial", "wood");

              if (
                newSettings.get("woodToprailSize") === "5/4x4" ||
                newSettings.get("woodToprailSize") === "5/4x6"
              ) {
                newSettings = newSettings.set("woodToprailSize", "2x4");
              }
              dispatch({
                type: "settings/edit",
                settings: newSettings,
              });
            }}
            className={railingOptionClassName(settings.toprailMaterial, "wood")}
          >
            Wood
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("toprailMaterial", "customer-provided"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.toprailMaterial,
              "customer-provided"
            )}
          >
            Customer Provided
          </button>
        </div>
      </div>
      <MaterialOptions settings={settings} />
    </div>
  );
};

const MountingHardware = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  const hardwareOptions = {
    lags: LagOptions,
    bolts: BoltOptions,
    concrete: ConcreteOptions,
    none: NoHardwareOptions,
  };

  const HardwareOptions = hardwareOptions[settings.hardwareType];

  return (
    <div className="project-settings__content mounting-hardware post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Mounting Hardware Type</p>
          <p className="railing-info__subtext">
            This can be overwritten when editing a run
          </p>
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("hardwareType", "lags")
                  .set("hardwareSize", '3/8" x 4 1/2"'),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.hardwareType, "lags")}
          >
            Lags
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("hardwareType", "bolts")
                  .set("hardwareSize", '3/8" x 5 1/2"'),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.hardwareType, "bolts")}
          >
            Bolts
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("hardwareType", "concrete"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.hardwareType,
              "concrete"
            )}
          >
            Concrete
          </button>
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("hardwareType", "none"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(settings.hardwareType, "none")}
          >
            None
          </button>
        </div>
      </div>
      <HardwareOptions settings={settings} />
    </div>
  );
};

const NoHardwareOptions = (props) => {
  return <div className="railing-info__item post-material__item"></div>;
};

const LagOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  // When not near saltwater use default 304 stainless.
  const normalOptions = [
    {
      name: '3/8" x 4 1/2" Lag Screw',
      value: '3/8" x 4 1/2"',
    },
    {
      name: '3/8" x 2-1/2" Lag Screw',
      value: '3/8" x 2-1/2"',
    },
    {
      name: '3/8" x 4" Lag Screw',
      value: '3/8" x 4"',
    },
    {
      name: '3/8" x 5-1/2" Lag Screw',
      value: '3/8" x 5-1/2"',
    },
    {
      name: '3/8" x 6" Lag Screw',
      value: '3/8" x 6"',
    },
    {
      name: '3/8" x 7" Lag Screw',
      value: '3/8" x 7"',
    },
    {
      name: '3/8" x 8" Lag Screw',
      value: '3/8" x 8"',
    },
    {
      name: '2507 4 1/2" Lag Screws',
      value: '2507 4 1/2"',
    },
  ];

  const marineOptions = [
    {
      name: '3/8" x 4 1/2" Lag Screw - Marine Grade',
      value: '3/8" x 4 1/2"',
    },
    {
      name: '1/2" x 8" Lag Screw - Marine Grade',
      value: '1/2" x 8"',
    },
    {
      name: '3/8" x 2-1/2" Lag Screw - Marine Grade',
      value: '3/8" x 2-1/2"',
    },
    {
      name: '3/8" x 2" Lag Screw - Marine Grade',
      value: '3/8" x 2"',
    },
    {
      name: '3/8" x 6" Lag Screw - Marine Grade',
      value: '3/8" x 6"',
    },
    {
      name: '2507 4 1/2" Lag Screws',
      value: '2507 4 1/2"',
    },
  ];

  const options =
    settings.nearSaltwater === "yes" || settings.nearPoolwater === "yes"
      ? marineOptions
      : normalOptions;

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Lag Size</p>
      </div>
      <div className="railing-info__options">
        <select
          onChange={(event) => {
            if (isProjectFrozen(project)) {
              dispatch({
                type: "window/open-project-frozen-dialog",
              });

              // Exit early if frozen.
              return;
            }

            dispatch({
              type: "settings/edit",
              settings: settings.set("hardwareSize", event.target.value),
              id: currentProject,
            });
          }}
          value={settings.hardwareSize}
          className="settings-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const BoltOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  // When not near saltware use default 304 stainless.
  const marineOptions = [
    {
      name: '1/2" x 2 1/2" Hex Bolt - Marine Grade',
      value: '1/2" x 2 1/2"',
    },
    {
      name: '1/2" x 2" Hex Bolt - Marine Grade',
      value: '1/2" x 2"',
    },
    {
      name: '1/2" x 4" Hex Bolt - Marine Grade',
      value: '1/2" x 4"',
    },
    {
      name: '1/2" x 5" Hex Bolt - Marine Grade',
      value: '1/2" x 5"',
    },
    {
      name: '1/2" x 5 1/2" Hex Bolt - Marine Grade',
      value: '1/2" x 5 1/2"',
    },
    {
      name: '1/2" x 6" Hex Bolt - Marine Grade',
      value: '1/2" x 6"',
    },
    {
      name: '1/2" x 6 1/2" Hex Bolt - Marine Grade',
      value: '1/2" x 6 1/2"',
    },
    {
      name: '1/2" x 7" Hex Bolt - Marine Grade',
      value: '1/2" x 7"',
    },
    {
      name: '1/2" x 7 1/2" Hex Bolt - Marine Grade',
      value: '1/2" x 7 1/2"',
    },
    {
      name: '1/2" x 8" Hex Bolt - Marine Grade',
      value: '1/2" x 8"',
    },
    {
      name: '3/8" x 1" Hex Bolt - Marine Grade',
      value: '3/8" x 1"',
    },
    {
      name: '3/8" x 2" Hex Bolt - Marine Grade',
      value: '3/8" x 2"',
    },
    {
      name: '3/8" x 2 1/2" Hex Bolt - Marine Grade',
      value: '3/8" x 2 1/2"',
    },
    {
      name: '3/8" x 4" Hex Bolt - Marine Grade',
      value: '3/8" x 4"',
    },
    {
      name: '3/8" x 5" Hex Bolt - Marine Grade',
      value: '3/8" x 5"',
    },
    {
      name: '3/8" x 5 1/2" Hex Bolt - Marine Grade',
      value: '3/8" x 5 1/2"',
    },
    {
      name: '3/8" x 6" Hex Bolt - Marine Grade',
      value: '3/8" x 6"',
    },
    {
      name: '3/8" x 6 1/2" Hex Bolt - Marine Grade',
      value: '3/8" x 6 1/2"',
    },
    {
      name: '3/8" x 7" Hex Bolt - Marine Grade',
      value: '3/8" x 7"',
    },
    {
      name: '3/8" x 8" Hex Bolt - Marine Grade',
      value: '3/8" x 8"',
    },
    {
      name: '2507 7" Hex Bolt',
      value: '2507 7"',
    },
  ];

  const normalOptions = [
    {
      name: '3/8" x 1" Hex Bolt',
      value: '3/8" x 1"',
    },
    {
      name: '3/8" x 1 1/2" Hex Bolt',
      value: '3/8" x 1 1/2"',
    },
    {
      name: '3/8" x 2" Hex Bolt',
      value: '3/8" x 2"',
    },
    {
      name: '3/8" x 2 1/2" Hex Bolt',
      value: '3/8" x 2 1/2"',
    },
    {
      name: '3/8" x 4" Hex Bolt',
      value: '3/8" x 4"',
    },
    {
      name: '3/8" x 4 1/2" Hex Bolt',
      value: '3/8" x 4 1/2"',
    },
    {
      name: '3/8" x 5" Hex Bolt',
      value: '3/8" x 5"',
    },
    {
      name: '3/8" x 5 1/2" Hex Bolt',
      value: '3/8" x 5 1/2"',
    },
    {
      name: '3/8" x 6" Hex Bolt',
      value: '3/8" x 6"',
    },
    {
      name: '3/8" x 6 1/2" Hex Bolt',
      value: '3/8" x 6 1/2"',
    },
    {
      name: '3/8" x 7" Hex Bolt',
      value: '3/8" x 7"',
    },
    {
      name: '3/8" x 7 1/2" Hex Bolt',
      value: '3/8" x 7 1/2"',
    },
    {
      name: '3/8" x 8" Hex Bolt',
      value: '3/8" x 8"',
    },
    {
      name: '2507 7" Hex Bolt',
      value: '2507 7"',
    },
  ];

  const options =
    settings.nearSaltwater === "yes" || settings.nearPoolwater === "yes"
      ? marineOptions
      : normalOptions;

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Bolt Size</p>
      </div>
      <div className="railing-info__options">
        <select
          onChange={(event) => {
            if (isProjectFrozen(project)) {
              dispatch({
                type: "window/open-project-frozen-dialog",
              });

              // Exit early if frozen.
              return;
            }

            dispatch({
              type: "settings/edit",
              settings: settings.set("hardwareSize", event.target.value),
              id: currentProject,
            });
          }}
          value={settings.hardwareSize}
          className="settings-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ConcreteOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  const concreteTypeOptions = [
    {
      name: "Tapcon",
      value: "tapcon",
    },
    {
      name: "Drop In Bolt",
      value: "drop-in",
    },
    {
      name: "Wedge Anchor",
      value: "wedge-anchor",
    },
  ];

  const hardwareOptions = {
    tapcon: TapconOptions,
    "drop-in": DropInOptions,
    "wedge-anchor": AnchorOptions,
  };

  const HardwareOptions = hardwareOptions[settings.concreteHardwareType];

  return (
    <div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Concrete Hardware Type</p>
        </div>
        <div className="railing-info__options">
          <select
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              let size = '3/8" x 5"';

              if (event.target.value === "tapcon") {
                size = '3/8" x 5"';
              }

              if (event.target.value === "drop-in") {
                size = '1/4" x 1 1/4"';
              }

              if (event.target.value === "wedge-anchor") {
                size = '1/2" x 7"';
              }
              dispatch({
                type: "settings/edit",
                settings: settings
                  .set("concreteHardwareType", event.target.value)
                  .set("concreteHardwareSize", size),
                id: currentProject,
              });
            }}
            value={settings.concreteHardwareType}
            className="settings-select"
          >
            {concreteTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <HardwareOptions settings={settings} />
    </div>
  );
};

const TapconOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  const options = [
    {
      name: '3/8" x 3" Tapcon',
      value: '3/8" x 3"',
    },
    {
      name: '3/8" x 5" Tapcon',
      value: '3/8" x 5"',
    },
    {
      name: '3/8" x 1 1/4" Tapcon',
      value: '3/8" x 1 1/4"',
    },
    {
      name: '3/8" x 1 3/4" Tapcon',
      value: '3/8" x 1 3/4"',
    },
    {
      name: '3/8" x 2 1/2" Tapcon',
      value: '3/8" x 2 1/2"',
    },
  ];

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Tapcon Size</p>
      </div>
      <div className="railing-info__options">
        <select
          onChange={(event) => {
            if (isProjectFrozen(project)) {
              dispatch({
                type: "window/open-project-frozen-dialog",
              });

              // Exit early if frozen.
              return;
            }

            dispatch({
              type: "settings/edit",
              settings: settings.set(
                "concreteHardwareSize",
                event.target.value
              ),
              id: currentProject,
            });
          }}
          value={settings.concreteHardwareSize}
          className="settings-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const DropInOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  const options = [
    {
      name: 'CONCRETE DROP-IN HEX BOLT - Red Head 1/4" x 1-1/4"',
      value: '1/4" x 1 1/4"',
    },
    {
      name: 'CONCRETE DROP-IN HEX BOLT - Red Head 1/4" x 1-3/4"',
      value: '1/4" x 1 3/4"',
    },
    {
      name: 'CONCRETE DROP-IN HEX BOLT - Red Head 1/4" x 1-1/2"',
      value: '1/4" x 1 1/2"',
    },
    {
      name: 'CONCRETE DROP-IN HEX BOLT - Red Head 1/4" x 7/8"',
      value: '1/4" x 7/8"',
    },
    {
      name: 'CONCRETE DROP-IN ANCHOR - Red Head HDI 1/4"-20 18-8 Stainless Steel',
      value: '1/4" anchor',
    },
    {
      name: 'CONCRETE DROP-IN ANCHOR - Red Head HDI 3/8"-16 18-8 Stainless Steel',
      value: '3/8" anchor',
    },
  ];

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Drop In Size</p>
      </div>
      <div className="railing-info__options">
        <select
          onChange={(event) => {
            if (isProjectFrozen(project)) {
              dispatch({
                type: "window/open-project-frozen-dialog",
              });

              // Exit early if frozen.
              return;
            }

            dispatch({
              type: "settings/edit",
              settings: settings.set(
                "concreteHardwareSize",
                event.target.value
              ),
              id: currentProject,
            });
          }}
          value={settings.concreteHardwareSize}
          className="settings-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const AnchorOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const { settings } = props;

  const options = [
    {
      name: '1/2" x 7" 304 Stainless',
      value: '1/2" x 7"',
    },
    {
      name: '1/4" -20 304 Stainless',
      value: '1/4" x 2"',
    },
    {
      name: '1/4" X 3 1/4" 304 Stainless',
      value: '1/4" x 3 1/4"',
    },
    {
      name: '3/8" X 5" 316 Marine Stainless',
      value: '3/8" x 5"',
    },
  ];

  return (
    <div className="railing-info__item post-material__item">
      <div className="railing-info__label">
        <p className="railing-info__option-label">Wedge Anchor Size</p>
      </div>
      <div className="railing-info__options">
        <select
          onChange={(event) => {
            if (isProjectFrozen(project)) {
              dispatch({
                type: "window/open-project-frozen-dialog",
              });

              // Exit early if frozen.
              return;
            }

            dispatch({
              type: "settings/edit",
              settings: settings.set(
                "concreteHardwareSize",
                event.target.value
              ),
              id: currentProject,
            });
          }}
          value={settings.concreteHardwareSize}
          className="settings-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ProjectSettingsHeader = (props) => {
  const dispatch = useDispatch();
  const { setTab, tab, setPosition, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
      zIndex: down ? 1 : 0,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="project-settings__header">
      <div className="project-settings__header-tabs">
        <button
          className={tabClassName("general-details", tab)}
          onClick={() => {
            setTab("general-details");
          }}
        >
          General Details
        </button>
        <button
          className={tabClassName("railing-info", tab)}
          onClick={() => {
            setTab("railing-info");
          }}
        >
          Railing Info
        </button>
        <button
          className={tabClassName("post-styles", tab)}
          onClick={() => {
            setTab("post-styles");
          }}
        >
          Post Styles
        </button>
        <button
          className={tabClassName("top-rail-options", tab)}
          onClick={() => {
            setTab("top-rail-options");
          }}
        >
          Top Rail Options
        </button>
        <button
          className={tabClassName("fittings", tab)}
          onClick={() => {
            setTab("fittings");
          }}
        >
          Fittings Options
        </button>
        <button
          className={tabClassName("handrail", tab)}
          onClick={() => {
            setTab("handrail");
          }}
        >
          Grabrail Options
        </button>
        <button
          className={tabClassName("mounting-hardware", tab)}
          onClick={() => {
            setTab("mounting-hardware");
          }}
        >
          Hardware
        </button>
        <button
          className={tabClassName("tools", tab)}
          onClick={() => {
            setTab("tools");
          }}
        >
          Tools / Services
        </button>
        <button
          className={tabClassName("tracking-details", tab)}
          onClick={() => {
            setTab("tracking-details");
          }}
        >
          Marketing
        </button>
      </div>
      <button
        className="app__tooltip-close"
        onClick={() => {
          // Timeout is needed to keep dom element active for intersection check.
          setTimeout(function () {
            dispatch({ type: "settings/toggle" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
};

const ThruPostOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  let options = [
    {
      name: "Field Swaged Assembly",
      value: "field-swaged",
    },
    {
      name: "Factory Swaged Assembly",
      value: "factory-swaged",
    },
    {
      name: "FineLine Ball Assembly",
      value: "fineline-ball",
    },
    {
      name: "FineLine Button Assembly",
      value: "fineline-button",
    },
  ];

  // No ball or button for 2507.
  if (settings.cableType === "2507-steel") {
    options = options.filter((option) => {
      switch (option.value) {
        case "fineline-ball":
          return false;
        case "fineline-button":
          return false;
        default:
          break;
      }

      return true;
    });
  }

  if (settings.cableSize === "1 x 19 - 1/8”") {
    options.push({
      name: "Low Profile Button Assembly",
      value: "low-profile",
    });
  }

  // Classic is only available for 3/16th cable.
  if (
    settings.cableSize === "7 x 7 - 3/16”" ||
    settings.cableSize === "1 x 19 - 3/16”"
  ) {
    options.push({
      name: "Classic Ball Assembly",
      value: "classic-ball",
    });
    options.push({
      name: "Classic Button Assembly",
      value: "classic-button",
    });
  }

  if (settings.cableType === "black-oxide") {
    options = options.filter((option) => {
      switch (option.value) {
        case "fineline-ball":
          return false;
        case "fineline-button":
          return false;
        case "low-profile":
          return false;
        case "classic-ball":
          return false;
        case "classic-button":
          return false;
        default:
          break;
      }

      return true;
    });
  }

  let thruPostFittingsOptions = [
    { value: "acorn", name: "Acorn Nut" },
    { value: "quick-nut", name: "Quick Nut Cover" },
  ];

  if (settings.cableType === "2507-steel") {
    thruPostFittingsOptions = [{ value: "quick-nut", name: "Quick Nut Cover" }];
  }

  return (
    <div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Thru-Post Type</p>
        </div>
        <div className="railing-info__options">
          <select
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("thruPostType", event.target.value),
                id: currentProject,
              });
            }}
            value={settings.thruPostType}
            className="settings-select"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {settings.thruPostType === "field-swaged" && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">End Fitting</p>
          </div>
          <div className="railing-info__options">
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("endFitting", event.target.value),
                  id: currentProject,
                });
              }}
              value={settings.endFitting}
              className="settings-select"
            >
              {thruPostFittingsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {settings.thruPostType === "factory-swaged" && (
        <div>
          <div className="railing-info__item post-material__item">
            <div className="railing-info__label">
              <p className="railing-info__option-label">Ends Swaged</p>
            </div>
            <div className="railing-info__options">
              <select
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.set("factorySwaged", event.target.value),
                    id: currentProject,
                  });
                }}
                value={settings.factorySwaged}
                className="settings-select"
              >
                {[
                  { value: "one-end-swaged", name: "One End Swaged" },
                  { value: "both-ends-swaged", name: "Both Ends Swaged" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="railing-info__item post-material__item">
            <div className="railing-info__label">
              <p className="railing-info__option-label">End Fitting</p>
            </div>
            <div className="railing-info__options">
              <select
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.set("endFitting", event.target.value),
                    id: currentProject,
                  });
                }}
                value={settings.endFitting}
                className="settings-select"
              >
                {thruPostFittingsOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      {(settings.thruPostType === "fineline-ball" ||
        settings.thruPostType === "fineline-button" ||
        settings.thruPostType === "classic-ball" ||
        settings.thruPostType === "classic-button") && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">
              Terminal End Swage Style
            </p>
          </div>
          <div className="railing-info__options">
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("finelineSwaged", event.target.value),
                  id: currentProject,
                });
              }}
              value={settings.finelineSwaged}
              className="settings-select"
            >
              {[
                { value: "field-swaged", name: "Field Swaged End" },
                { value: "factory-swaged", name: "Factory Swaged End" },
              ].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {settings.thruPostType === "low-profile" && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Receiver Stud Size</p>
          </div>
          <div className="railing-info__options">
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set(
                    "receiverStudSize",
                    event.target.value
                  ),
                  id: currentProject,
                });
              }}
              value={settings.receiverStudSize}
              className="settings-select"
            >
              {[
                { value: "short", name: "Short" },
                { value: "long", name: "Long" },
              ].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

const SwagelessOptions = (props) => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const project = useSelector((state) => state.state.present);
  const { currentProject } = appState;
  const settings = props.settings;

  return (
    <div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Swageless Type</p>
        </div>
        <div className="railing-info__options">
          <select
            onChange={(event) => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("swagelessType", event.target.value),
                id: currentProject,
              });
            }}
            value={settings.swagelessType}
            className="settings-select"
          >
            {[
              { value: "type-1", name: "Type 1" },
              { value: "type-2", name: "Type 2" },
            ].map((option) => {
              if (
                settings.cableType === "black-oxide" &&
                option.value === "type-1"
              ) {
                return null;
              }

              return (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {settings.swagelessType === "type-2" && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Receiver Stud Size</p>
          </div>
          <div className="railing-info__options">
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set(
                    "receiverStudSize",
                    event.target.value
                  ),
                  id: currentProject,
                });
              }}
              value={settings.receiverStudSize}
              className="settings-select"
            >
              {[
                { value: "short", name: "Short" },
                { value: "long", name: "Long" },
              ].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

const FittingsOptions = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  const materialOptions = {
    "thru-post": ThruPostOptions,
    swageless: SwagelessOptions,
    "surface-mount": () => null,
    "customer-provided": () => null,
  };

  const MaterialOptions = materialOptions[settings.cableFittingType];

  const blackOxideCableOptions = {
    "use-bundles": "Use Black Oxide Cable Bundles",
    "use-kits": "Use Black Oxide Cable Kits",
  };
  return (
    <div className="project-settings__content fittings-options post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Default Cable Fittings</p>
          {/* <p className="railing-info__subtext">
            This can be overwritten when editing a post
          </p> */}
        </div>
        <div className="railing-info__options">
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("cableFittingType", "thru-post"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.cableFittingType,
              "thru-post"
            )}
          >
            Thru-Post Fittings
          </button>
          {settings.cableSize === "1 x 19 - 1/8”" && (
            <button
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("cableFittingType", "swageless"),
                  id: currentProject,
                });
              }}
              className={railingOptionClassName(
                settings.cableFittingType,
                "swageless"
              )}
            >
              Swageless Fittings
            </button>
          )}
          <button
            onClick={() => {
              if (isProjectFrozen(project)) {
                dispatch({
                  type: "window/open-project-frozen-dialog",
                });

                // Exit early if frozen.
                return;
              }

              dispatch({
                type: "settings/edit",
                settings: settings.set("cableFittingType", "surface-mount"),
                id: currentProject,
              });
            }}
            className={railingOptionClassName(
              settings.cableFittingType,
              "surface-mount"
            )}
          >
            Surface Mount
          </button>
        </div>
      </div>
      {settings.cableType === "black-oxide" && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Black Oxide Cable Type</p>
            {/* <p className="railing-info__subtext">
            This can be overwritten when editing a post
          </p> */}
          </div>
          <div className="railing-info__options">
            <SelectBox
              className="fascia-bracket-options"
              options={blackOxideCableOptions}
              selected={settings.blackOxideBundles}
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const value = event.target.value;

                dispatch({
                  type: "settings/edit",
                  settings: settings.set("blackOxideBundles", value),
                  id: currentProject,
                });
              }}
            />
          </div>
        </div>
      )}
      <MaterialOptions settings={settings} />
    </div>
  );
};

const ToolsOptions = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  return (
    <div className="project-settings__content fittings-options post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Crimper</p>
          <p className="railing-info__subtext">
            Include or exclude the cable crimper.
          </p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Cable Crimper</div>
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.setIn(
                    ["cableCrimper", "include"],
                    event.target.value === "yes" ? true : false
                  ),
                  id: currentProject,
                });
              }}
              value={settings.cableCrimper.include ? "yes" : "no"}
              className="settings-select"
            >
              <option key="yes" value="yes">
                Yes
              </option>
              <option key="no" value="no">
                No
              </option>
            </select>
          </div>
          {settings.cableCrimper.include && (
            <div className="tools-info__item">
              <div className="tools-info__item-label">Crimper Die Size</div>
              <select
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["cableCrimper", "die"],
                      event.target.value
                    ),
                    id: currentProject,
                  });
                }}
                value={settings.cableCrimper.die}
                className="settings-select"
              >
                <option key="auto" value="auto">
                  Match Cable Size
                </option>
                <option key="3/16" value="3/16">
                  3/16"
                </option>
                <option key="1/8" value="1/8">
                  1/8"
                </option>
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Cable Cutter</p>
          <p className="railing-info__subtext">
            Include or exclude the cable cutter.
          </p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Include Cutter</div>
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.setIn(
                    ["cableCutter", "include"],
                    event.target.value === "yes" ? true : false
                  ),
                  id: currentProject,
                });
              }}
              value={settings.cableCutter.include ? "yes" : "no"}
              className="settings-select"
            >
              <option key="yes" value="yes">
                Yes
              </option>
              <option key="no" value="no">
                No
              </option>
            </select>
          </div>
          {settings.cableCutter.include && (
            <div className="tools-info__item">
              <div className="tools-info__item-label">Cable Cutter Size</div>
              <select
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["cableCutter", "size"],
                      event.target.value
                    ),
                    id: currentProject,
                  });
                }}
                value={settings.cableCutter.size}
                className="settings-select"
              >
                <option key="auto" value="auto">
                  Match Cable Size
                </option>
                <option key="3/16" value="3/16">
                  3/16"
                </option>
                <option key="1/8" value="1/8">
                  1/8"
                </option>
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Passivation Service</p>
          <p className="railing-info__subtext">
            Include or exclude passivation service.
          </p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Add Service</div>
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({
                  type: "settings/edit",
                  settings: settings.setIn(
                    ["includePassivation"],
                    event.target.value === "yes" ? "yes" : "no"
                  ),
                  id: currentProject,
                });
              }}
              value={settings.includePassivation === "yes" ? "yes" : "no"}
              className="settings-select"
            >
              <option key="yes" value="yes">
                Yes
              </option>
              <option key="no" value="no">
                No
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrackingDetails = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  return (
    <div className="project-settings__content fittings-options post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">UTM Source</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item tracking-item">
            <div className="tools-info__item-label">UTM Source</div>
            <SelectBox
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const type = event.target.value;

                let newSettings = settings;

                if (type === "no-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "source", "set"],
                    "no-value"
                  );
                }

                if (type === "has-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "source", "set"],
                    "has-value"
                  );
                }

                if (type === "not-set") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "source", "set"],
                    false
                  );
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.trackingDetails.utm.source.set}
              className="select-box fascia-bracket-options"
              options={{
                "not-set": "Please select an option",
                "no-value": "No UTM Source",
                "has-value": "Has UTM Source",
              }}
            />
          </div>
          {settings.trackingDetails.utm.source.set === "has-value" && (
            <div className="tools-info__item tracking-item">
              <div className="tools-info__item-label">UTM Source</div>
              <input
                placeholder="Enter UTM Source"
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const value = event.target.value;

                  let newSettings = settings.setIn(
                    ["trackingDetails", "utm", "source", "value"],
                    value
                  );

                  dispatch({
                    type: "settings/edit",
                    settings: newSettings,
                    id: currentProject,
                  });
                }}
                value={settings.trackingDetails.utm.source.value}
                className="number-of-posts"
              />
            </div>
          )}
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">UTM Medium</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item tracking-item">
            <div className="tools-info__item-label">UTM Medium</div>
            <SelectBox
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const type = event.target.value;

                let newSettings = settings;

                if (type === "no-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "medium", "set"],
                    "no-value"
                  );
                }

                if (type === "has-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "medium", "set"],
                    "has-value"
                  );
                }

                if (type === "not-set") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "medium", "set"],
                    false
                  );
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.trackingDetails.utm.medium.set}
              className="select-box fascia-bracket-options"
              options={{
                "not-set": "Please select an option",
                "no-value": "No UTM Medium",
                "has-value": "Has UTM Medium",
              }}
            />
          </div>
          {settings.trackingDetails.utm.medium.set === "has-value" && (
            <div className="tools-info__item tracking-item">
              <div className="tools-info__item-label">UTM Medium</div>
              <input
                placeholder="Enter UTM Medium"
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const value = event.target.value;

                  let newSettings = settings.setIn(
                    ["trackingDetails", "utm", "medium", "value"],
                    value
                  );

                  dispatch({
                    type: "settings/edit",
                    settings: newSettings,
                    id: currentProject,
                  });
                }}
                value={settings.trackingDetails.utm.medium.value}
                className="number-of-posts"
              />
            </div>
          )}
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">UTM Campaign</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item tracking-item">
            <div className="tools-info__item-label">UTM Campaign</div>
            <SelectBox
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const type = event.target.value;

                let newSettings = settings;

                if (type === "no-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "campaign", "set"],
                    "no-value"
                  );
                }

                if (type === "has-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "campaign", "set"],
                    "has-value"
                  );
                }

                if (type === "not-set") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "utm", "campaign", "set"],
                    false
                  );
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.trackingDetails.utm.campaign.set}
              className="select-box fascia-bracket-options"
              options={{
                "not-set": "Please select an option",
                "no-value": "No UTM Campaign",
                "has-value": "Has UTM Campaign",
              }}
            />
          </div>
          {settings.trackingDetails.utm.campaign.set === "has-value" && (
            <div className="tools-info__item tracking-item">
              <div className="tools-info__item-label">UTM Campaign</div>
              <input
                placeholder="Enter UTM Campaign"
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const value = event.target.value;

                  let newSettings = settings.setIn(
                    ["trackingDetails", "utm", "campaign", "value"],
                    value
                  );

                  dispatch({
                    type: "settings/edit",
                    settings: newSettings,
                    id: currentProject,
                  });
                }}
                value={settings.trackingDetails.utm.campaign.value}
                className="number-of-posts"
              />
            </div>
          )}
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Callrail</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item tracking-item">
            <div className="tools-info__item-label">Callrail</div>
            <SelectBox
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const type = event.target.value;

                let newSettings = settings;

                if (type === "no-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "callrail", "set"],
                    "no-value"
                  );
                }

                if (type === "has-value") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "callrail", "set"],
                    "has-value"
                  );
                }

                if (type === "not-set") {
                  newSettings = newSettings.setIn(
                    ["trackingDetails", "callrail", "set"],
                    false
                  );
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              selected={settings.trackingDetails.callrail.set}
              className="select-box fascia-bracket-options"
              options={{
                "not-set": "Please select an option",
                "no-value": "No Callrail Number",
                "has-value": "Has Callrail Number",
              }}
            />
          </div>
          {settings.trackingDetails.callrail.set === "has-value" && (
            <div className="tools-info__item tracking-item">
              <div className="tools-info__item-label">Callrail</div>
              <input
                placeholder="Enter Callrail Number"
                onChange={(event) => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  const value = event.target.value;

                  let newSettings = settings.setIn(
                    ["trackingDetails", "callrail", "value"],
                    value
                  );

                  dispatch({
                    type: "settings/edit",
                    settings: newSettings,
                    id: currentProject,
                  });
                }}
                value={settings.trackingDetails.callrail.value}
                className="number-of-posts"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HandrailOptions = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const project = state.present;
  const settings = project.settings;

  const handRailOptions = {
    link: "Link",
    "alum-p2p": "Aluminum P2P",
    "ss-flat": 'SS Flat .5" x 2"',
    "ss-round-2": 'SS Round 2"',
    "ss-round-1.5": 'SS Round 1.5"',
    "ss-2507-2": 'SS 2507 2" x 1"',
  };

  return (
    <div className="project-settings__content fittings-options post-styles">
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Grabrail Type</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Grabrail Type</div>
            <select
              onChange={(event) => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                const type = event.target.value;

                let newSettings = settings.setIn(
                  ["handrailParts", "type"],
                  type
                );

                // If selecting other than ss round 1.5 do not allow for 24" loop to be set.
                if (type !== "ss-round-1.5") {
                  if (settings.handrailParts.start.loop === "24") {
                    newSettings = newSettings.setIn(
                      ["handrailParts", "start", "loop"],
                      "include"
                    );
                  }

                  if (settings.handrailParts.end.loop === "24") {
                    newSettings = newSettings.setIn(
                      ["handrailParts", "end", "loop"],
                      "include"
                    );
                  }
                }

                dispatch({
                  type: "settings/edit",
                  settings: newSettings,
                  id: currentProject,
                });
              }}
              value={settings.handrailParts.type}
              className="settings-select"
            >
              {Object.entries(handRailOptions).map(([type, label]) => {
                return (
                  <option key={type} value={type}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      {(settings.handrailParts.type === "link" ||
        settings.handrailParts.type === "alum-p2p") && (
        <div className="railing-info__item post-material__item">
          <div className="railing-info__label">
            <p className="railing-info__option-label">Grabrail Color</p>
          </div>
          <div className="railing-info__options">
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "black"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "black"
                )}
              >
                <div className="color-item black"></div>
              </button>
              <p className="option__image-label">Black</p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "bronze"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "bronze"
                )}
              >
                <div className="color-item bronze"></div>
              </button>
              <p className="option__image-label">Bronze</p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "Natural"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "Natural"
                )}
              >
                <div className="color-item natural"></div>
              </button>
              <p className="option__image-label">Natural</p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "clay"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "clay"
                )}
              >
                <div className="color-item clay"></div>
              </button>
              <p className="option__image-label">Clay</p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "white"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "white"
                )}
              >
                <div className="color-item white"></div>
              </button>
              <p className="option__image-label">White</p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "anodized-black"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "anodized-black"
                )}
              >
                <div className="color-item black"></div>
              </button>
              <p className="option__image-label option__image-label--anodized">
                Anodized Black
              </p>
            </div>
            <div className="option__image-container">
              <button
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  dispatch({
                    type: "settings/edit",
                    settings: settings.setIn(
                      ["handrailParts", "linkColor"],
                      "anodized-clear"
                    ),
                    id: currentProject,
                  });
                }}
                className={railingImageOptionClassName(
                  settings.handrailParts.linkColor,
                  "anodized-clear"
                )}
              >
                <div className="color-item natural"></div>
              </button>
              <p className="option__image-label option__image-label--anodized">
                Anodized Clear
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Starting End Parts</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Select Options</div>
            <div className="grabrail-tools__group">
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include Wall Return
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "start", "wallReturn"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.start.wallReturn === "include"
                        ? "yes"
                        : "no"
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </label>
              </div>
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include End Caps
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "start", "endCap"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.start.endCap === "include"
                        ? "yes"
                        : "no"
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </label>
              </div>
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include Loop
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      if (value !== "yes" && value !== "no") {
                        newValue = value;
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "start", "loop"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.start.loop === "include" ||
                      settings.handrailParts.start.loop === "exclude"
                        ? settings.handrailParts.start.loop === "include"
                          ? "yes"
                          : "no"
                        : settings.handrailParts.start.loop
                    }
                  >
                    <option key="yes" value="yes">
                      Yes
                    </option>
                    <option key="no" value="no">
                      No
                    </option>
                    {settings.handrailParts.type === "ss-round-1.5" && (
                      <option key="24" value="24">
                        24"
                      </option>
                    )}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="railing-info__item post-material__item">
        <div className="railing-info__label">
          <p className="railing-info__option-label">Ending End Parts</p>
        </div>
        <div className="railing-info__options">
          <div className="tools-info__item">
            <div className="tools-info__item-label">Select Options</div>
            <div className="grabrail-tools__group">
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include Wall Return
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "end", "wallReturn"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.end.wallReturn === "include"
                        ? "yes"
                        : "no"
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </label>
              </div>
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include End Caps
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "end", "endCap"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.end.endCap === "include"
                        ? "yes"
                        : "no"
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </label>
              </div>
              <div className="grabrail-tools">
                <label>
                  <span
                    style={{ marginRight: "8px" }}
                    className="checkbox-label"
                  >
                    Include Loop
                  </span>
                  <select
                    className="settings-select"
                    onChange={(event) => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      const value = event.target.value;

                      let newValue = "include";
                      if (value === "yes") {
                        newValue = "include";
                      }

                      if (value === "no") {
                        newValue = "exclude";
                      }

                      if (value !== "yes" && value !== "no") {
                        newValue = value;
                      }

                      dispatch({
                        type: "settings/edit",
                        settings: settings.setIn(
                          ["handrailParts", "end", "loop"],
                          newValue
                        ),
                        id: currentProject,
                      });
                    }}
                    value={
                      settings.handrailParts.end.loop === "include" ||
                      settings.handrailParts.end.loop === "exclude"
                        ? settings.handrailParts.end.loop === "include"
                          ? "yes"
                          : "no"
                        : settings.handrailParts.end.loop
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                    {settings.handrailParts.type === "ss-round-1.5" && (
                      <option key="24" value="24">
                        24"
                      </option>
                    )}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectSettings = (props) => {
  const { setPosition, animation } = props;
  let [tab, setTab] = useState("general-details");
  const dispatch = useDispatch();

  const requiredFields = useSelector((state) => state.requiredFields);

  if (
    requiredFields.has("nearSaltwater") ||
    requiredFields.has("nearPoolwater")
  ) {
    tab = "railing-info";
  }

  if (requiredFields.has("estimator")) {
    tab = "general-details";
  }

  if (requiredFields.has("customer")) {
    tab = "general-details";
  }

  if (requiredFields.has("projectType")) {
    tab = "general-details";
  }

  const contentComponent = {
    "general-details": GeneralDetails,
    "railing-info": RailingInfo,
    "post-styles": PostStyles,
    "top-rail-options": TopRailOptions,
    fittings: FittingsOptions,
    "mounting-hardware": MountingHardware,
    tools: ToolsOptions,
    handrail: HandrailOptions,
    "tracking-details": TrackingDetails,
  };

  const ContentComponent = contentComponent[tab];
  return (
    <div
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "project-settings" });
      }}
      className="project-settings"
    >
      <ProjectSettingsHeader
        tab={tab}
        setTab={setTab}
        setPosition={setPosition}
        animation={animation}
      />
      <ContentComponent />
      <div className="project-settings__footer">
        <button
          onClick={() => {
            dispatch({ type: "project/reset-details" });
          }}
          className="project-settings__footer-button"
        >
          Reset Details
        </button>
        <button
          onClick={() => {
            // Timeout is needed to keep dom element active for intersection check.
            setTimeout(function () {
              dispatch({ type: "settings/toggle" });
            }, 0);
          }}
          className="project-settings__footer-primary-button"
        >
          Update Details
        </button>
      </div>
    </div>
  );
};

export default ProjectSettings;
