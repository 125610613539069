import { v4 as uuid } from "uuid";
import { AllInventoryTypes } from "../data/fetch-inventory";
import { Project } from "../entities";
import { getItemListsByCanvas, getMasterItemList } from "./partsList";

export const freezeEstimate = (
  project: Project,
  inventoryList: AllInventoryTypes[]
) => {
  const frozenId = uuid();

  project = project.setIn(["frozenEstimate", "id"], frozenId);

  const frozenProject = {
    id: frozenId,
    estimateId: project.id,
    project,
    itemLists: null,
  };

  const itemListsByCanvas = getItemListsByCanvas(
    project.canvases,
    inventoryList,
    project
  );

  const masterItemList = getMasterItemList(
    itemListsByCanvas.entrySeq().map(([index, itemList]) => itemList)
  );

  (frozenProject.itemLists as any) = { masterItemList, itemListsByCanvas };

  return frozenProject;
};

export const freezeProject = (
  project: Project,
  inventoryList?: AllInventoryTypes[]
) => {
  const frozenId = uuid();

  project = project
    .setIn(["frozenProject", "id"], frozenId)
    .setIn(["settings", "status"], "complete");

  const frozenProject = {
    id: frozenId,
    projectId: project.id,
    project,
    itemLists: null,
  };

  const itemListsByCanvas = getItemListsByCanvas(
    project.canvases,
    inventoryList,
    project
  );

  const materItemList = getMasterItemList(
    itemListsByCanvas.entrySeq().map(([index, itemList]) => itemList)
  );

  (frozenProject.itemLists as any) = { materItemList, itemListsByCanvas };

  return frozenProject;
};

export const isProjectFrozen = (project: Project) => {
  return project.frozenProject.id
    ? project.frozenProject.id
    : project.frozenEstimate.id
    ? project.frozenEstimate.id
    : false;
};
