import { ActionCreators } from "redux-undo";
import { v4 as uuid } from "uuid";
import { serviceUrl } from "../environment";
import { Customer, Estimator, Post, Project, Run } from "../entities";
import {
  getUnrotatedStairsCorners,
  isNearPointArrayPoints,
  isNumber,
} from "../utils";
import { Edge } from "../utils/graph";
import { getContinuousSpanOfStairs } from "../components/Canvas";
import { setProjectsHandler } from "../App";
import { projectJsonToImmutable } from "../data/fetch-projects";
import { freezeEstimate, freezeProject } from "../utils/freezeProject";
import { Map } from "immutable";
import { RootState, RootStore } from "./configureStore";
import { AllInventoryTypes } from "../data/fetch-inventory";
import { getShipAddressFromCustomer } from "../components/CustomerSelect/utils";
import { fetchSaltwaterLocations } from "../data/fetch-saltwaterLocations";
import {
  boltHardwareUpcs,
  lagHardwareUpcs,
} from "../partsList/RunItemList/getParts";
import { formatCustomerPhone, getCustomerName } from "../entities/utils";

export const getProjectFromState = (state: RootState) => state.state.present;
export const getProjectAsJSON = (state: RootState) =>
  getProjectFromState(state).toJS();
export const getSequenceId = (state: RootState) => state.sequenceId;

let saveTimeout: NodeJS.Timeout | null = null;

export const projectSave = async (
  json: any,
  dispatch: any,
  action: any,
  override = false
) => {
  if (setProjectsHandler) {
    setProjectsHandler((projects) => {
      const index = projects.findIndex((project) => json.id === project.id);

      if (index > -1) {
        projects[index] = projectJsonToImmutable(json);
      } else {
        projects.push(projectJsonToImmutable(json));
      }

      return projects;
    });
  }

  if (override) {
    json.override = true;
  }

  dispatch({ type: "project/saving" });

  const url = serviceUrl("saveProject");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    });

    if (!res || !res.ok) {
      dispatch({ type: "project/save-error" });
      console.error(await res?.json());
      return;
    }

    dispatch({ type: "project/saved" });

    const jsonResult = await res.json();

    if (action && action.type === "projects/add") {
      dispatch({
        type: "project/set-estimate",
        projectEstimate: jsonResult.projectEstimate,
      });
      action.navigate("projects/" + jsonResult.id);
    }
  } catch (error) {
    dispatch({ type: "project/save-error" });
    console.error(error);
  }
};

const saveProject = (action: any, store: RootStore) => {
  if (action.noSave) {
    return;
  }

  if (saveTimeout) {
    clearTimeout(saveTimeout);
  }

  saveTimeout = setTimeout(() => {
    const json = getProjectAsJSON(store.getState());
    const sequenceId = getSequenceId(store.getState());

    json.sequenceId = sequenceId;

    projectSave(json, store.dispatch, action);
  }, 200);
};

const updateProject = (action: { project: Project }, store: RootStore) => {
  const json = action.project.toJS();
  json.sequenceId++;

  const url = serviceUrl("saveProject");
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
};

const saveEstimator = (action: { estimator: Estimator }, store: RootStore) => {
  const json = action.estimator.toJS();

  const url = serviceUrl("saveEstimator");

  try {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    });
  } catch (error) {
    console.error(error);
  }
};

const saveCustomer = (action: { customer: Customer }, store: RootStore) => {
  const json = action.customer.toJS ? action.customer.toJS() : action.customer;

  const url = serviceUrl("saveCustomer");

  try {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    });
  } catch (error) {
    console.error(error);
  }
};

// const deleteEstimator = async (
//   action: { estimator: Estimator },
//   store: RootStore
// ) => {
//   const json = action.estimator.toJS();

//   const url = serviceUrl("deleteEstimator");

//   try {
//     await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(json),
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

function getDistanceFromLatLonInMiles(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 0.621371;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

const getDistanceBetweenPoints = (
  point1: { lat: number; lng: number },
  point2: { lat: number; lng: number }
) => {
  return getDistanceFromLatLonInMiles(
    point1.lat,
    point1.lng,
    point2.lat,
    point2.lng
  );
};

const calculateIfNearSaltwater = async (address: string) => {
  const getLatLng = async () => {
    const res = await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=" +
        process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY
    );
    const data = await res.json();
    const location = data?.results?.["0"]?.geometry?.location;
    const lat = location?.lat;
    const lng = location?.lng;

    if (typeof lat === "number" && typeof lng === "number") {
      return { lat, lng };
    } else {
      throw new Error(res?.statusText);
    }
  };

  const getSaltwaterLocations = async () => {
    const locations = await fetchSaltwaterLocations();
    return locations.features;
  };

  try {
    const [addressLocation, saltwaterLocations] = await Promise.all([
      getLatLng(),
      getSaltwaterLocations(),
    ]);

    for (const saltwaterLocation of saltwaterLocations) {
      const coordinates = saltwaterLocation.geometry.coordinates.find(
        (coordinates) => {
          const point = { lat: coordinates[1], lng: coordinates[0] };
          const distance = getDistanceBetweenPoints(addressLocation, point);
          return distance < 2;
        }
      );
      if (coordinates) {
        return true;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return false;
};

const setIsNearSaltwater = async (
  action: { customer: Customer | null | undefined },
  store: RootStore
) => {
  if (action.customer) {
    const state = store.getState();
    const project = getProjectFromState(state);
    const { currentProject } = state.appState;

    const addressLines = getShipAddressFromCustomer(
      action.customer,
      project.settings
    );
    if (addressLines.length > 0) {
      const address = addressLines
        .map((line: string) => line.replaceAll(" ", "+"))
        .join(",");

      try {
        const isNearSaltwater = await calculateIfNearSaltwater(address);

        const hardwareSize = project.settings.hardwareSize;
        const boltUpcs = boltHardwareUpcs();
        const lagUpcs = lagHardwareUpcs();

        let settings = project.settings;

        const marine = isNearSaltwater ? "316" : "304";

        // Set hardware to existing hardware for marine environments.
        if (
          isNearSaltwater &&
          !boltUpcs[marine][
            hardwareSize as keyof (typeof boltUpcs)[typeof marine]
          ] &&
          settings.hardwareType === "bolts"
        ) {
          settings = settings.set("hardwareSize", '3/8" x 4"');
        }

        // Set hardware to existing hardware for marine environments.
        if (
          isNearSaltwater &&
          !lagUpcs[marine][
            hardwareSize as keyof (typeof lagUpcs)[typeof marine]
          ] &&
          settings.hardwareType === "lags"
        ) {
          settings = settings.set("hardwareSize", '3/8" x 4 1/2"');
        }

        store.dispatch({
          type: "settings/edit",
          settings: settings.set(
            "nearSaltwater",
            isNearSaltwater ? "yes" : "no"
          ),
          id: currentProject,
        });
        store.dispatch({
          type: "fields/unrequire-field",
          field: "nearSaltwater",
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};

const effects = {
  // "canvas/set-pan": saveProject,
  // "canvas/set-scale": saveProject,
  "canvas/overrides": saveProject,
  "project/reset-details": saveProject,
  "canvas/edit-entities": saveProject,
  "canvas/add": saveProject,
  "canvas/select": saveProject,
  "canvas/settings-open": saveProject,
  "canvas/settings-close": saveProject,
  "canvas/edit-settings": saveProject,
  "canvas/rename": saveProject,
  "canvas/duplicate": saveProject,
  "canvas/delete": saveProject,
  "revisions/add": saveProject,
  "revisions/switch": saveProject,
  "runs/add": saveProject,
  "runs/edit-open": saveProject,
  "runs/edit": saveProject,
  "runs/edit-spacing": saveProject,
  "runs/remove": saveProject,
  "posts/add": saveProject,
  "posts/edit-open": saveProject,
  "posts/edit": saveProject,
  "posts/remove": saveProject,
  "handrails/add": saveProject,
  "handrails/add-many": saveProject,
  "handrails/edit": saveProject,
  "handrails/remove": saveProject,
  "shapes/add": saveProject,
  "shapes/edit": saveProject,
  "shapes/edit-open": saveProject,
  "shapes/remove": saveProject,
  "gates/add": saveProject,
  "gates/insert-into-run": saveProject,
  "gates/replace-run": saveProject,
  "gates/edit": saveProject,
  "gates/edit-open": saveProject,
  "gates/remove": saveProject,
  "edit-popup/close": saveProject,
  "images/add": saveProject,
  "images/edit": saveProject,
  "images/remove": saveProject,
  "notes/add": saveProject,
  "notes/edit": saveProject,
  "notes/move": saveProject,
  "notes/remove": saveProject,
  "stairs/add": saveProject,
  "stairs/edit-open": saveProject,
  "stairs/edit": saveProject,
  "stairs/remove": saveProject,
  "settings/edit": saveProject,
  "settings/edit-spacing": saveProject,
  "settings/toggle": saveProject,
  "projects/add": saveProject,
  "project/add-override": saveProject,
  "convert-to-sales-order": saveProject,
  "tax/set": saveProject,
  "tax/reset": saveProject,
  "estimators/add": (
    action: {
      type: "estimators/add";
      estimator: Estimator;
    },
    store: RootStore
  ) => {
    saveEstimator(action, store);
    saveProject(action, store);
  },
  "edit-entities-resize-handrail": saveProject,
  "edit-entities-resize-run": saveProject,
  "corners/edit": saveProject,
  "corners/edit-post-type": saveProject,
  "corners/remove": saveProject,
  "project/update-from-dashboard": updateProject,
  "estimators/edit": saveEstimator,
  "estimators/select": saveProject,
  "customers/add": saveCustomer,
  "customers/edit": saveCustomer,
  "customer/export-to-quickbooks": (
    action: { customer: Customer },
    store: RootStore
  ) => {
    (async () => {
      const customer = action.customer.toJS();

      if (!customer.name) {
        customer.name = getCustomerName(action.customer);
      }

      if (customer?.data?.phone) {
        customer.data.phone = formatCustomerPhone(customer.data.phone);
      }

      if (customer?.data?.altPhone) {
        customer.data.altPhone = formatCustomerPhone(customer.data.altPhone);
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_QUEUE_URL}/queue`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ customer, customerName: getCustomerName(action.customer), type: "addCustomer" }),
          }
        );

        if (!response || !response.ok) {
          console.error(response);

          store.dispatch({
            type: "window/error-message-open",
            message: "An error occurred while exporting customer to Quickbooks",
          });
        } else {
          const json = await response.json();

          let qbData = null;

          if (json?.QBXML?.QBXMLMsgsRs?.CustomerAddRs?.CustomerRet) {
            qbData = json.QBXML.QBXMLMsgsRs.CustomerAddRs.CustomerRet;
          }

          if (json?.QBXML?.QBXMLMsgsRs?.CustomerModRs?.CustomerRet) {
            qbData = json.QBXML.QBXMLMsgsRs.CustomerModRs.CustomerRet;
          }

          if (qbData) {
            const qbId = qbData.ListID;
            const qbName = qbData.Name;

            let customer = action.customer;

            customer = customer.set("quickbooksId", qbId);
            customer = customer.set("name", qbName);
            customer = customer.set("quickbooksData", qbData);
            customer = customer.set("exportedToQB", true);

            store.dispatch({
              // @ts-ignore
              type: "customers/edit",
              customer: customer,
            });

            store.dispatch({
              type: "window/error-message-open",
              message: "Customer exported to Quickbooks",
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  },
  "customer/edit-ship-to-address": (
    action: {
      type: "customer/edit-ship-to-address";
      address: any;
      customer: Customer | null | undefined;
    },
    store: RootStore
  ) => {
    // setIsNearSaltwater(action, store);
    saveProject(action, store);
  },
  "customer/remove-custom-ship-to": (
    action: {
      type: "customer/remove-custom-ship-to";
      customer: Customer | null | undefined;
    },
    store: RootStore
  ) => {
    // setIsNearSaltwater(action, store);
    saveProject(action, store);
  },
  "customer/select": (
    action: {
      type: "customer/select";
      customer: Customer | undefined | null;
      checked: boolean;
    },
    store: RootStore
  ) => {
    saveProject(action, store);
    store.dispatch({
      type: "customer/remove-custom-ship-to",
      customer: action.customer,
    });
  },
  "app/projects-view": (
    action: { type: "app/projects-view" },
    store: RootStore
  ) => {
    store.dispatch(ActionCreators.clearHistory());
  },
  "project/set-version": saveProject,
  "project/freeze-estimate": (action: any, store: any) => {
    const project = getProjectFromState(store.getState());
    const inventoryList = action.inventory;
    const url = serviceUrl("freezeEstimate");
    const frozenEstimate = freezeEstimate(project, inventoryList);
    const { dispatch } = store;
    const frozenEstimateToSave = Map(frozenEstimate).toJS();
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(frozenEstimateToSave),
    })
      .catch((error) => {
        console.error(error);
      })
      .then((res) => {
        if (res && res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while freezing estimate");
        }
      })
      .then((data) => {
        // Data.
        dispatch({
          type: "project/set-frozen-estimate",
          frozenEstimate: frozenEstimateToSave,
        });
      });
  },
  "project/unfreeze-estimate": (action: any, store: any) => {
    const project = action.project;
    // If already frozen delete the old one.
    if (project.frozenEstimate.id) {
      const url = serviceUrl("deleteFrozenEstimate");
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: project.frozenEstimate.id }),
      })
        .catch((error) => {
          console.error(error);
        })
        .then((res) => {
          if (res && res.ok) {
            return res.json();
          } else {
            throw new Error("An error occurred while unfreezing estimate");
          }
        })
        .then((data) => {
          // Data.
        });
    }
  },
  "project/set-frozen-estimate": saveProject,
  "project/set-frozen-project": saveProject,
  "project/export-to-quickbooks": (
    action: {
      type: "project/export-to-quickbooks";
      inventory?: AllInventoryTypes[];
    },
    store: RootStore
  ) => {
    let project = getProjectFromState(store.getState());

    // If already frozen delete the old one.
    if (project.frozenProject.id) {
      const url = serviceUrl("deleteFrozenProject");

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: project.frozenProject.id }),
      })
        .catch((error) => {
          console.error(error);
        })
        .then((res) => {
          return res?.json();
        })
        .then((data) => {
          // Data.
        });
    }

    const inventoryList = action.inventory;

    project = project.setIn(["settings", "status"], "complete");

    const frozenProject = freezeProject(project, inventoryList);

    const { dispatch } = store;
    const frozenProjectToSave = Map(frozenProject).toJS();

    const url = serviceUrl("freezeProject");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(frozenProjectToSave),
    })
      .then((res) => {
        if (!res || !res.ok) {
          dispatch({
            type: "window/error-message-open",
            message: "An error occurred while freezing project",
          });
          console.error(res.json());
          throw new Error("An error occurred while freezing project");
        } else {
          return res.json();
        }
      })
      .then((json) => {
        dispatch({ type: "project/set-frozen-project", frozenProject: json });
      })
      .catch((error) => {
        dispatch({
          type: "window/error-message-open",
          message: "An error occurred while freezing project",
        });
        console.error(error);
      });
  },
  "app/open-project": (
    action: { type: "app/open-project"; project: Project },
    store: RootStore
  ) => {
    // Handle backwards compatible change to settings to force wood p2p under wood toprail.
    if (
      action.project.settings.toprailMaterial === "aluminum" &&
      action.project.settings.aluminumToprailType === "wood-p2p"
    ) {
      const newSettings = action.project.settings
        .set("toprailMaterial", "wood")
        .set("aluminumToprailType", "rectangular")
        .set("postMaterial", "aluminum")
        .set("woodToprailSetup", "wood-alum-p2p");

      store.dispatch({
        type: "settings/edit",
        settings: newSettings,
        id: action.project.id,
      });
    }

    if (action.project.isSalesOrder.length) {
      store.dispatch({ type: "project/export-to-quickbooks" });
    }

    type A = { first: 1; second: 2 };
    const ehu = Map<string, A>();

    ehu.flatMap((val: A, key: string, iter: Map<string, A>) => {
      const res: [string, A][] = [[key, val]];
      return res;
    });

    // Handle backwards compatible change to settings to force wood p2p under wood toprail for individual runs.
    let runs: Map<string, Run> = action.project.runs
      .map((run: Run) => {
        if (
          !isNumber(run.x1) ||
          !isNumber(run.x2) ||
          !isNumber(run.y1) ||
          !isNumber(run.y2)
        ) {
          return null;
        }

        if (
          run.getIn(["settings", "toprailMaterial"]) === "aluminum" &&
          run.getIn(["settings", "aluminumToprailType"]) === "wood-p2p"
        ) {
          return run
            .setIn(["settings", "toprailMaterial"], "wood")
            .removeIn(["settings", "aluminumToprailType"])
            .setIn(["settings", "postMaterial"], "aluminum")
            .setIn(["settings", "woodToprailSetup"], "wood-alum-p2p");
        }

        return run;
      })
      .filter((run) => run !== null) as Map<string, Run>;

    let posts = action.project?.posts;

    // Handle backwards compatible change to change gates with "no-run" attribute to insert a post and set to noRun.
    const gates = action.project?.gates.map((gate) => {
      if ((gate.p1 as any).runIndex === "no-run") {
        let newPost = Post({
          id: uuid(),
        });

        const run = runs?.get((gate.p2 as any).runIndex);

        if (run) {
          if (run.x1 === gate.x1 && run.y1 === gate.y1) {
            newPost = newPost.set("x", run.x1).set("y", run.y1);
          }

          if (run.x2 === gate.x1 && run.y2 === gate.y1) {
            newPost = newPost.set("x", run.x2).set("y", run.y2);
          }

          if (run.x1 === gate.x2 && run.y1 === gate.y2) {
            newPost = newPost.set("x", run.x1).set("y", run.y1);
          }

          if (run.x2 === gate.x2 && run.y2 === gate.y2) {
            newPost = newPost.set("x", run.x2).set("y", run.y2);
          }

          posts = posts.set(newPost.id, newPost);

          return gate.set("p1", {
            runIndex: null,
            postIndex: newPost.id,
            noRun: true,
          });
        }
      }

      if ((gate.p2 as any).runIndex === "no-run") {
        let newPost = Post({
          id: uuid(),
        });

        const run = runs.get((gate.p1 as any).runIndex) as Run;

        if (run.x1 === gate.x1 && run.y1 === gate.y1) {
          newPost = newPost.set("x", run.x1).set("y", run.y1);
        }

        if (run.x2 === gate.x1 && run.y2 === gate.y1) {
          newPost = newPost.set("x", run.x2).set("y", run.y2);
        }

        if (run.x1 === gate.x2 && run.y1 === gate.y2) {
          newPost = newPost.set("x", run.x1).set("y", run.y1);
        }

        if (run.x2 === gate.x2 && run.y2 === gate.y2) {
          newPost = newPost.set("x", run.x2).set("y", run.y2);
        }

        posts = posts.set(newPost.id, newPost);

        return gate.set("p2", {
          runIndex: null,
          postIndex: newPost.id,
          noRun: true,
        });
      }

      return gate;
    });

    let stairs = action.project.stairs;

    if (action.project.version === "1") {
      stairs.forEach((stair) => {
        const points = getUnrotatedStairsCorners(stair);
        stairs.forEach((stair2) => {
          if (stair2.id === stair.id) {
            return;
          }

          const points2 = getUnrotatedStairsCorners(stair2);

          if (isNearPointArrayPoints(points["TL"], points2["BL"])) {
            if (isNearPointArrayPoints(points["TR"], points2["BR"])) {
              const edgeKey = "stairs-top-to-stairs";
              stairs = stairs.setIn(
                [stair.id, "stairsEdges", edgeKey],
                Edge({ from: stair.id, to: stair2.id })
              );

              const edgeKey2 = "stairs-bottom-to-stairs";
              stairs = stairs.setIn(
                [stair2.id, "stairsEdges", edgeKey2],
                Edge({ from: stair2.id, to: stair.id })
              );
            }
          }

          if (isNearPointArrayPoints(points["TL"], points2["TR"])) {
            if (isNearPointArrayPoints(points["BL"], points2["BR"])) {
              const edgeKey = "stairs-left-to-stairs";
              stairs = stairs.setIn(
                [stair.id, "stairsEdges", edgeKey],
                Edge({ from: stair.id, to: stair2.id })
              );

              const edgeKey2 = "stairs-right-to-stairs";
              stairs = stairs.setIn(
                [stair2.id, "stairsEdges", edgeKey2],
                Edge({ from: stair2.id, to: stair.id })
              );
            }
          }

          if (isNearPointArrayPoints(points["BR"], points2["TR"])) {
            if (isNearPointArrayPoints(points["BL"], points2["TL"])) {
              const edgeKey = "stairs-bottom-to-stairs";
              stairs = stairs.setIn(
                [stair.id, "stairsEdges", edgeKey],
                Edge({ from: stair.id, to: stair2.id })
              );

              const edgeKey2 = "stairs-top-to-stairs";
              stairs = stairs.setIn(
                [stair2.id, "stairsEdges", edgeKey2],
                Edge({ from: stair2.id, to: stair.id })
              );
            }
          }

          if (isNearPointArrayPoints(points["TR"], points2["TL"])) {
            if (isNearPointArrayPoints(points["BR"], points2["BL"])) {
              const edgeKey = "stairs-right-to-stairs";
              stairs = stairs.setIn(
                [stair.id, "stairsEdges", edgeKey],
                Edge({ from: stair.id, to: stair2.id })
              );

              const edgeKey2 = "stairs-left-to-stairs";
              stairs = stairs.setIn(
                [stair2.id, "stairsEdges", edgeKey2],
                Edge({ from: stair2.id, to: stair.id })
              );
            }
          }
        });
      });

      runs.forEach((run: Run) => {
        if (run.stairs && (run.stairs as any).keys) {
          runs = runs.set(
            run.id,
            run.setIn(
              ["stairs", "continuousStairs"],
              getContinuousSpanOfStairs(
                stairs,
                (run.stairs as any).stairsIndex,
                run.stairs
              )
            )
          );
        }
      });

      store.dispatch({ type: "project/set-version", version: "2" });
    }

    if (
      !runs.equals(action.project.runs) ||
      !posts.equals(action.project.posts) ||
      !gates.equals(action.project.gates) ||
      !stairs.equals(action.project.stairs)
    ) {
      store.dispatch({
        type: "canvas/edit-entities",
        posts: posts,
        gates: gates,
        runs: runs,
        stairs: stairs,
        handrails: action.project.handrails,
      });
    }

    store.dispatch(ActionCreators.clearHistory());
  },
  "app/project-view": (
    action: { type: "app/project-view"; id?: string },
    store: RootStore
  ) => {
    store.dispatch(ActionCreators.clearHistory());
  },
  "@@redux-undo/UNDO": saveProject,
  "@@redux-undo/REDO": saveProject,
  "@@redux-undo/CLEAR_HISTORY": saveProject,
};

export default effects;
