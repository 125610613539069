import otherCharges from "./inventoryOtherCharge";
import nonInventory from "./itemNonInventory";
import itemDiscount from "./itemDiscount";
import itemSubtotal from "./itemSubtotal";
import { InventoryItem, InventorySchema } from "./parsers/inventoryParser";
import { AssemblyItem, AssemblySchema } from "./parsers/assemblyParser";
import { GroupItem, GroupsSchema } from "./parsers/groupsParser";
import { ServiceItem, ServicesSchema } from "./parsers/servicesParser";
import { ItemDiscountRet } from "./parsers/itemDiscountParser";
import { ItemOtherChargeRet } from "./parsers/inventoryOtherChargeParser";
import { ItemNonInventoryRet } from "./parsers/itemNonInventoryParser";
import { ItemSubtotalRet } from "./parsers/itemSubtotalParser";
import {
  ItemDiscountRetQB,
  ItemNonInventoryRetQB,
  ItemOtherChargeRetQB,
  ItemSubtotalRetQB,
  QBObject,
} from "./parsers/qbObject";

type AllInventoryTypes =
  | InventoryItem
  | AssemblyItem
  | GroupItem
  | ServiceItem
  | ItemDiscountRetQB
  | ItemOtherChargeRetQB
  | ItemNonInventoryRetQB
  | ItemSubtotalRetQB;

export type { AllInventoryTypes };

const fetchInventoryItems = async () => {
  const response = await fetch("/data/inventory.json");
  const json = await response.json();
  const data = InventorySchema.parse(json);

  return data.QBXML.QBXMLMsgsRs.ItemInventoryQueryRs.ItemInventoryRet.map(
    (item) => {
      item.type = "inventory";
      return item;
    }
  );
};

const fetchAssemblyItems = async () => {
  const response = await fetch("/data/assembly.json");
  const json = await response.json();
  const data = AssemblySchema.parse(json);

  return data.QBXML.QBXMLMsgsRs.ItemInventoryAssemblyQueryRs.ItemInventoryAssemblyRet.map(
    (item) => {
      item.type = "assembly";
      return item;
    }
  );
};

const fetchGroupsItems = async () => {
  const response = await fetch("/data/groups.json");
  const json = await response.json();
  const data = GroupsSchema.parse(json);

  return data.QBXML.QBXMLMsgsRs.ItemGroupQueryRs.ItemGroupRet.map((item) => {
    item.FullName = item.Name;
    item.type = "group";
    return item;
  });
};

const fetchServicesItems = async () => {
  const response = await fetch("/data/services.json");
  const json = await response.json();
  const data = ServicesSchema.parse(json);

  return data.QBXML.QBXMLMsgsRs.ItemServiceQueryRs.ItemServiceRet.map(
    (item) => {
      item.type = "service";
      item.BarCodeValue = item.BarCodeValue || item.FullName;
      item = mapServiceToQBObject(item);
      return item;
    }
  );
};

function fetchInventory() {
  const fetched = {
    inventory: false,
    assembly: false,
    groups: false,
    services: false,
  };
  let sorted = false;
  let inventory: AllInventoryTypes[] = [];

  return async function () {
    if (
      !fetched.inventory ||
      !fetched.assembly ||
      !fetched.groups ||
      !fetched.services
    ) {
      const fetchInventory = async () => {
        const items = await fetchInventoryItems();
        fetched.inventory = true;
        return items;
      };

      const fetchAssembly = async () => {
        const items = await fetchAssemblyItems();
        fetched.assembly = true;
        return items;
      };

      const fetchGroups = async () => {
        const items = await fetchGroupsItems();
        fetched.groups = true;
        return items;
      };

      const fetchServices = async () => {
        const items = await fetchServicesItems();
        fetched.services = true;
        return items;
      };

      const fetchAll = [
        fetchInventory(),
        fetchAssembly(),
        fetchGroups(),
        fetchServices(),
      ];

      const allItems = await Promise.all(fetchAll);
      allItems.forEach((items) => {
        items.forEach((item) => {
          item.SalesDesc = item.SalesDesc || "";
        });
        inventory = inventory.concat(items);
      });

      if (otherCharges) {
        otherCharges.QBXML.QBXMLMsgsRs.ItemOtherChargeQueryRs.ItemOtherChargeRet.forEach(
          (item) => {
            const mappedItem = mapOtherChargeToQBObject(item);
            mappedItem.type = "otherCharge";
            inventory.push(mappedItem);
          }
        );
      }

      if (nonInventory) {
        nonInventory.QBXML.QBXMLMsgsRs.ItemNonInventoryQueryRs.ItemNonInventoryRet.forEach(
          (item) => {
            const mappedItem = mapNonInventoryToQBObject(item);
            mappedItem.type = "nonInventory";
            inventory.push(mappedItem);
          }
        );
      }

      if (itemDiscount) {
        itemDiscount.QBXML.QBXMLMsgsRs.ItemDiscountQueryRs.ItemDiscountRet.forEach(
          (item) => {
            const mappedItem = mapItemDiscountToQBObject(item);
            mappedItem.type = "discount";
            inventory.push(mappedItem);
          }
        );
      }

      if (itemSubtotal) {
        let item =
          itemSubtotal.QBXML.QBXMLMsgsRs.ItemSubtotalQueryRs.ItemSubtotalRet;

        const mappedItem = mapSubtotalToQBObject(item);
        mappedItem.type = "subtotal";
        inventory.push(mappedItem);
      }

      // todo: delete?
      //   if (false && salesTaxItems) {
      //     salesTaxItems.QBXML.QBXMLMsgsRs.ItemSalesTaxQueryRs.ItemSalesTaxRet.forEach(
      //       (item) => {
      //         item = mapSalesTaxToQBObject(item);
      //         item.type = "salesTax";
      //         inventory.push(item);
      //       }
      //     );
      //   }

      if (!sorted) {
        inventory = inventory.sort((a, b) => {
          return a.FullName.localeCompare(b.FullName);
        });
        sorted = true;
      }
    }
    return inventory;
  };
}

function mapItemDiscountToQBObject(item: ItemDiscountRet): ItemDiscountRetQB {
  return {
    ...item,
    BarCodeValue: item.Name,
    SalesDesc: item.ItemDesc ? item.ItemDesc : item.FullName,
    SalesPrice: item.DiscountRate ? -1 * item.DiscountRate : 0,
    discount: item.DiscountRate ? item.DiscountRate : 0,
    discountPercent: item.DiscountRatePercent ? item.DiscountRatePercent : 0,
  };
}

function mapSubtotalToQBObject(
  item: ItemSubtotalRet
): ItemSubtotalRet & QBObject {
  return {
    ...item,
    FullName: item.Name,
    BarCodeValue: item.Name,
    SalesDesc: item.ItemDesc,
    SalesPrice: 0,
  };
}

// function mapSalesTaxToQBObject(qbSalesTax: ItemSalesTaxRet) {
//   qbSalesTax.BarCodeValue = qbSalesTax.Name;
//   qbSalesTax.FullName = qbSalesTax.Name;

//   if (qbSalesTax.SalesOrPurchase) {
//     qbSalesTax.SalesDesc = qbSalesTax.ItemDesc;
//     qbSalesTax.SalesPrice = 0;
//   }

//   return qbSalesTax;
// }

function mapServiceToQBObject(qbService: any) {
  qbService.BarCodeValue = qbService.FullName;
  if (qbService.SalesOrPurchase) {
    qbService.SalesDesc = qbService.SalesOrPurchase.Desc;
    qbService.SalesPrice = qbService.SalesOrPurchase.Price;
  }

  if (qbService.SalesAndPurchase) {
    qbService.SalesDesc = qbService.SalesAndPurchase.SalesDesc;
    qbService.SalesPrice = qbService.SalesAndPurchase.SalesPrice;
  }

  return qbService;
}

function mapOtherChargeToQBObject(
  item: ItemOtherChargeRet
): ItemOtherChargeRet & QBObject {
  const mappedItem: ItemOtherChargeRet & QBObject = {
    ...item,
    BarCodeValue: item.FullName,
  };

  if (item.SalesAndPurchase) {
    mappedItem.SalesDesc = item.SalesAndPurchase.SalesDesc;
    mappedItem.SalesPrice = item.SalesAndPurchase.SalesPrice;
  } else if (item.SalesOrPurchase) {
    mappedItem.SalesDesc = item.SalesOrPurchase.Desc || item.FullName;
    mappedItem.SalesPrice = item.SalesOrPurchase.Price;
  }

  return mappedItem;
}

function mapNonInventoryToQBObject(
  item: ItemNonInventoryRet
): ItemNonInventoryRet & QBObject {
  const mappedItem: ItemNonInventoryRet & QBObject = {
    ...item,
    BarCodeValue: item.BarCodeValue.toString(),
  };

  if (item.SalesAndPurchase) {
    mappedItem.SalesDesc = item.SalesAndPurchase.SalesDesc;
    mappedItem.SalesPrice = item.SalesAndPurchase.SalesPrice;
  } else if (item.SalesOrPurchase) {
    mappedItem.SalesDesc = item.SalesOrPurchase.Desc || item.FullName;
    mappedItem.SalesPrice = item.SalesOrPurchase.Price;
  }

  return mappedItem;
}

export default fetchInventory();
