import { Map } from "immutable";
import { Customer } from "../entities";
import { serviceUrl } from "../environment";
import { arrayToKeyedObject } from "./utils";

import type { CustomerRet } from "./parsers/customerParser";

async function fetchFromMongo() {
  const url = serviceUrl("getCustomers");

  let map = Map<string, Customer>();

  try {
    const response = await fetch(url);
    const json = await response.json();
    // todo: add json schema validation?

    const customers: Customer[] = json.flatMap((item: any) => {
      try {
        return [Customer(item)];
      } catch {
        return [];
      }
    });

    map = Map(arrayToKeyedObject(customers));
  } catch (error) {
    console.error(error);
  }
  return map;
}

let customers = Map<string, Customer>();

function fetchCustomers() {
  let fetched = false;

  return async function () {
    if (!fetched) {
      if (process.env.REACT_APP_ENVIRONMENT_TYPE === "staging") {
        // Do not grab any customers from QuickBooks in staging.
        customers = Map();
      } else {
        const response = await fetch("/data/customers.json");
        const json = await response.json();
        // const data = CustomersSchema.parse(json);
        // todo: re enable parsing potentially async.
        const data = json;

        const customersArray: Customer[] =
          data.QBXML.QBXMLMsgsRs.CustomerQueryRs.CustomerRet.flatMap(
            (item: CustomerRet, index: number) => {
              try {
                return [
                  Customer({
                    id: item.ListID,
                    quickbooksId: item.ListID,
                    quickbooksData: item,
                    exportedToQB: true,
                  }),
                ];
              } catch {
                return [];
              }
            }
          );

        customers = Map(arrayToKeyedObject(customersArray));

        fetched = true;
      }
    }

    const fromMongo = await fetchFromMongo();

    customers = customers.merge(fromMongo);
    return customers;
  };
}

// todo: update
export function addCustomer(customer: any) {
  customers = customers.set(customer.id, customer);
}

export default fetchCustomers();
