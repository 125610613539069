import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon";
import { CustomersContext, EstimatorContext } from "../../App";
import EditCustomerShipToWindow from "./EditCustomerShipToWindow";
import CustomersDropdown from "./CustomersDropdown";
import AddCustomerWindow from "./AddCustomerWindow/AddCustomerWindow";
import { Customer, Project } from "../../entities";
import {
  getBillAddressFromCustomer,
  getShipAddressFromCustomer,
} from "./utils";
import { getCustomerName } from "../../entities/utils";

interface PropType {
  project: Project;
}

const CustomerSelect = ({ project }: PropType) => {
  const { settings } = project;

  const appState = useSelector((state: any) => state.appState);
  const requiredFields = useSelector((state: any) => state.requiredFields);
  const { currentProject } = appState;

  const dispatch = useDispatch();

  const { customers, setCustomers } = useContext(CustomersContext);
  const { estimators } = useContext(EstimatorContext);

  const [open, setOpen] = useState(false);
  const [addNewWindow, setAddNewWindow] = useState(false);
  const [editShipToWindow, setEditShipToWindow] = useState(false);
  const [listPopupOpen, setListPopupOpen] = useState(false);
  const [search, setSearch] = useState("");

  let nameField: string | undefined = "Please select or add a customer";

  if (project.customer && customers.has(project.customer)) {
    nameField = getCustomerName(customers.get(project.customer)!);
  }

  let billAddressLines = [];
  let shipAddressLines = [];

  let customer: Customer | undefined;
  if (project.customer && customers.has(project.customer)) {
    customer = customers.get(project.customer);
    if (customer) {
      billAddressLines = getBillAddressFromCustomer(customer);
      shipAddressLines = getShipAddressFromCustomer(customer, settings);
    }
  }

  let customerClassName = "project-customer";

  if (requiredFields.has("customer")) {
    customerClassName += " project-customer--required";
  }

  return (
    <div className={customerClassName}>
      <div>
        <label htmlFor="project-customer" className="people__label">
          Choose Customer
        </label>
        <div
          onClick={() => {
            setOpen((open) => !open);
          }}
          className="select-box"
        >
          <div className="estimators__select">{nameField}</div>
          <Icon icon="chev-down" className="select-box__icon" />
        </div>
        {open && !addNewWindow && !editShipToWindow && (
          <CustomersDropdown
            setAddNewWindow={setAddNewWindow}
            listPopupOpen={listPopupOpen}
            setListPopupOpen={setListPopupOpen}
            setEditShipToWindow={setEditShipToWindow}
            setCustomers={setCustomers}
            estimators={estimators}
            customers={customers}
            project={project}
            setOpen={setOpen}
            search={search}
            setSearch={setSearch}
          />
        )}
        {addNewWindow && (
          <AddCustomerWindow
            setAddNewWindow={setAddNewWindow}
            setOpen={setOpen}
            setCustomers={setCustomers}
            search={search}
            customer={customer}
          />
        )}
        {editShipToWindow && (
          <EditCustomerShipToWindow
            setEditShipToWindow={setEditShipToWindow}
            setCustomers={setCustomers}
            projectCustomer={project.customer}
            customers={customers}
            settings={settings}
          />
        )}
      </div>
      {project.customer && customers.has(project.customer) && (
        <div className="customer-address__container">
          {billAddressLines && (
            <div className="customer-address__item">
              <div className="customer-address__heading">Bill-to Address</div>
              {billAddressLines.map((line, index) => {
                return (
                  <div key={index} className="customer-address__line">
                    {line}
                  </div>
                );
              })}
            </div>
          )}
          {shipAddressLines && (
            <div className="customer-address__item">
              <div
                onClick={() => {
                  setEditShipToWindow(true);
                }}
              >
                <Icon icon="edit" className="customer-address__edit-icon" />
              </div>
              <div className="customer-address__heading">Ship-to Address</div>
              {shipAddressLines.map((line, index) => {
                return (
                  <div key={index} className="customer-address__line">
                    {line}
                  </div>
                );
              })}
              {!settings.shipToAddress.validated && (
                <button
                  onClick={() => {
                    dispatch({
                      type: "settings/edit",
                      settings: settings.setIn(
                        ["shipToAddress", "validated"],
                        !settings.shipToAddress.validated
                      ),
                      id: currentProject,
                    });
                  }}
                  className="customer-address__alert"
                >
                  <Icon icon="alert" className="customer-address__alert-icon" />
                  Please Validate Shipping Address
                </button>
              )}
              {settings.shipToAddress.validated && (
                <button
                  onClick={() => {
                    dispatch({
                      type: "settings/edit",
                      settings: settings.setIn(
                        ["shipToAddress", "validated"],
                        !settings.shipToAddress.validated
                      ),
                      id: currentProject,
                    });
                  }}
                  className="customer-address__validated"
                >
                  <Icon
                    icon="success"
                    className="customer-address__validated-icon"
                  />
                  Shipping Address Validated
                </button>
              )}
            </div>
          )}
          {!shipAddressLines && (
            <div className="customer-address__item">
              <div
                onClick={() => {
                  setEditShipToWindow(true);
                }}
              >
                <Icon icon="edit" className="customer-address__edit-icon" />
              </div>
              <div className="customer-address__heading">Ship-to Address</div>
            </div>
          )}
          {customer && (
            <div className="customer-edit">
              <button
                onClick={() => {
                  setAddNewWindow(true);
                }}
                className="customer-edit__button"
              >
                Edit All Customer Details
              </button>
            </div>
          )}
          {customer && (
            <div className="qb-export">
              <button
                onClick={() => {
                  if (process.env.REACT_APP_ENVIRONMENT_TYPE === "staging") {
                    dispatch({
                      type: "window/error-message-open",
                      message:
                        "Cannot export customer data on staging environment.",
                    });
                  } else {
                    dispatch({
                      type: "customer/export-to-quickbooks",
                      customer: customer,
                    });
                  }
                }}
                className="qb-export__button"
              >
                Export to Quickbooks
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSelect;
