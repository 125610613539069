import { useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Customer } from "../../entities";
import { Map } from "immutable";
import useOnClickOutside from "../../hooks/useOnClickOutside";

interface PropType {
  setEditShipToWindow: React.Dispatch<React.SetStateAction<boolean>>;
  projectCustomer: string;
  setCustomers: React.Dispatch<React.SetStateAction<Map<string, Customer>>>;
  customers: Map<string, Customer>;
  settings: any;
}

const EditCustomerShipToWindow = ({
  setEditShipToWindow,
  projectCustomer,
  setCustomers,
  customers,
  settings,
}: PropType) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);
  const clickOutside = useCallback(() => {
    setEditShipToWindow(false);
  }, [setEditShipToWindow]);

  useOnClickOutside(ref, clickOutside);

  let customer: Customer | null | undefined = null;

  if (projectCustomer && customers.has(projectCustomer)) {
    customer = customers.get(projectCustomer);
  }

  let ship = {
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  };

  if (settings.shipToAddress.overrideQBAddress) {
    ship = settings.shipToAddress.address;
  } else {
    if (customer && customer.data && customer.data.shipping) {
      ship = customer.data.shipping;
    } else if (
      customer?.quickbooksData &&
      customer.quickbooksData.ShipAddress
    ) {
      const shipAddress = customer.quickbooksData.ShipAddress;
      ship = {
        name: shipAddress.Addr2 ? shipAddress.Name?.toString()! : "",
        street: shipAddress.Addr2 ? shipAddress.Addr2.toString() : "",
        city: shipAddress.Addr2 ? shipAddress.City?.toString()! : "",
        state: shipAddress.Addr2 ? shipAddress.State?.toString()! : "",
        zip: shipAddress.Addr2 ? shipAddress.PostalCode?.toString()! : "",
      };
    }
  }

  const [shipTo, setShipTo] = useState(ship);

  return (
    <div ref={ref} className="edit-customer-shipping-address">
      <div className="add-estimator__fields add-customer__addresses">
        <div className="add-estimator__field add-address__field">
          <label
            htmlFor="add-customer-phone"
            className="add-estimator__field-label add-address__label"
          >
            Ship-to Address
          </label>
          <div className="add-address__inputs">
            <input
              className="address-input"
              placeholder="Name"
              value={shipTo.name}
              onChange={(event) => {
                setShipTo((addr) => ({
                  ...addr,
                  name: event.target.value,
                }));
              }}
            />
            <input
              className="address-input"
              placeholder="Street Address"
              value={shipTo.street}
              onChange={(event) => {
                setShipTo((addr) => ({
                  ...addr,
                  street: event.target.value,
                }));
              }}
            />
            <input
              className="address-input"
              placeholder="City"
              value={shipTo.city}
              onChange={(event) => {
                setShipTo((addr) => ({
                  ...addr,
                  city: event.target.value,
                }));
              }}
            />
            <div className="add-address__state-zip">
              <input
                className="address-input"
                placeholder="State"
                value={shipTo.state}
                onChange={(event) => {
                  setShipTo((addr) => ({
                    ...addr,
                    state: event.target.value,
                  }));
                }}
              />
              <input
                className="address-input"
                placeholder="Zip"
                value={shipTo.zip}
                onChange={(event) => {
                  setShipTo((addr) => ({
                    ...addr,
                    zip: event.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            dispatch({
              type: "customer/edit-ship-to-address",
              address: shipTo,
              customer: customer,
            });
            setCustomers((state) => {
              return state.setIn([customer?.id, "data", "shipping"], shipTo);
            });
            setEditShipToWindow(false);
          }}
          className="address__remove"
        >
          <span className="estimators__add-text">Set Ship To</span>
        </button>
        {settings.shipToAddress.overrideQBAddress && (
          <button
            onClick={() => {
              dispatch({
                type: "customer/remove-custom-ship-to",
                customer: customer,
              });
              setEditShipToWindow(false);
            }}
            className="address__remove"
          >
            <span className="estimators__add-text">Remove Custom Ship To</span>
          </button>
        )}
        <button
          onClick={() => {
            setEditShipToWindow(false);
          }}
          className="address__remove"
        >
          <span className="estimators__add-text">Cancel Edit Ship To</span>
        </button>
      </div>
    </div>
  );
};

export default EditCustomerShipToWindow;
