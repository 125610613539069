// @ts-nocheck
import { findByUpc, mapQBObjectToJS } from "../../partsList/RunItemList";
import { roundToHundreth } from "../../utils";

function getPickListItems(itemList, inventory) {
  const newList = {};

  Object.entries(itemList).forEach(([upc, item]) => {
    const qbItem = findByUpc(upc, inventory);

    if (qbItem) {
      if (
        qbItem.ItemInventoryAssemblyLine &&
        qbItem.ItemInventoryAssemblyLine.length
      ) {
        qbItem.ItemInventoryAssemblyLine.forEach((assemblyPart) => {
          if (
            assemblyPart.ItemInventoryRef &&
            assemblyPart.ItemInventoryRef.ListID
          ) {
            const listId = assemblyPart.ItemInventoryRef.ListID;

            const qbInventoryPart = findByQBListId(listId, inventory);

            if (qbInventoryPart) {
              const assemblyInventoryPart = mapQBObjectToJS(qbInventoryPart);

              assemblyInventoryPart.quantity =
                item.quantity * parseFloat(assemblyPart.Quantity, 10);

              assemblyInventoryPart.total = roundToHundreth(
                assemblyInventoryPart.quantity * assemblyInventoryPart.price
              );

              addItem(
                newList,
                assemblyInventoryPart.upc,
                assemblyInventoryPart
              );
            }
          } else {
            // console.log("Not found", assemblyPart, qbItem);
          }
        });
      } else {
        addItem(newList, upc, item);
      }
    } else {
      addItem(newList, upc, item);
    }
  });

  return newList;
}

function addItem(itemList, upc, item) {
  if (item.quantity <= 0) {
    return;
  }

  if (!itemList[upc]) {
    itemList[upc] = item;
  } else {
    itemList[upc].quantity = itemList[upc].quantity + item.quantity;
    itemList[upc].total = roundToHundreth(itemList[upc].quantity * item.price);
  }
}

export function findByQBListId(id, inventory) {
  if (inventory && inventory.find) {
    return inventory.find((item) => id.toString() === item.ListID);
  }

  return null;
}

export default getPickListItems;
