import { Map } from "immutable";
import { AllInventoryTypes } from "../data/fetch-inventory";
import { Canvas, Project } from "../entities";
import { getItemList, getTotal } from "../partsList";
import { addItemForceQuantity } from "../partsList/RunItemList";
import {
  getTouchupPaintUpcs,
  getStainlessSteelToprailUpcs,
  getStainlessSteelRectangularTopRail,
} from "../partsList/RunItemList/getParts";
import { ItemListType } from "../partsList/RunItemList/itemListTypes";
import { postsForTheRun } from "../partsList/RunItemList/utils/postsForTheRun";
import sortItemList from "../partsList/sortItemList";
import { getPosts } from "./getPosts";
import { roundToHundreth } from "./index";

export function getItemListsByCanvas(
  canvases: Map<string, Canvas>,
  inventoryList: AllInventoryTypes[] | undefined,
  project: Project
) {
  if (project?.frozenEstimate?.itemLists?.itemListsByCanvas) {
    return Map(project.frozenEstimate.itemLists.itemListsByCanvas);
  }

  return canvases.map((canvas: any) => {
    canvas.settings = project.settings;
    canvas.currentCanvas = project.currentCanvas;

    return getItemList(canvas, inventoryList, project);
  });
}

export function getItemLists(
  canvases: Map<string, Canvas>,
  inventoryList: AllInventoryTypes[] | undefined,
  project: Project
) {
  return getItemListsByCanvas(canvases, inventoryList, project)
    .entrySeq()
    .map(([index, itemList]) => {
      return itemList;
    });
}

export function formatItemListDescriptionsForPDF(itemList: ItemListType) {
  if (itemList["SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS"]) {
    itemList["SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS"].description =
      "MARINE ENVIRONMENT PASSIVATION SERVICE";
  }

  Object.values(itemList).forEach((item) => {
    itemList[item.upc].description = item.description
      ?.replace(/- Cut to[^]*/g, "")
      ?.replace(/\**\s*[Aa]ttach [Ff]abrication [Ss]heet\**/g, "");
  });

  return itemList;
}

export function formatStainlessTopRailQuantity(itemList: ItemListType) {
  const stainlessToprail = getStainlessSteelToprailUpcs();
  const stainlessSteelRectangularTopRail =
    getStainlessSteelRectangularTopRail();

  const allUpcs = [
    ...Object.values(stainlessToprail),
    stainlessSteelRectangularTopRail,
  ];

  allUpcs.forEach((upc) => {
    if (itemList[upc.toString()]) {
      itemList[upc.toString()].quantity = roundToHundreth(
        itemList[upc].quantity / 20
      );
      itemList[upc.toString()].measure = "20FT";
    }
  });

  return itemList;
}

export function projectItemListOverrides(
  lists: ItemListType,
  project: Project
) {
  if (project.overrides.size) {
    project.overrides.forEach((override: any) => {
      if (override.type === "itemDescPriceChange") {
        const upc = override.item.upc;

        if (lists[upc]) {
          if (override.item.description) {
            lists[upc].description = override.item.description;
          }

          if (
            override?.item?.price &&
            !override?.item?.otherDiscounts?.percent != false // eslint-disable-line eqeqeq
          ) {
            lists[upc].price = roundToHundreth(override.item.price);
            lists[upc].total = lists[upc]?.price
              ? roundToHundreth(lists[upc].quantity * lists[upc].price!)
              : undefined;
          }

          // Calculate totals for other discounts
          if (
            override?.item?.otherDiscounts?.percent &&
            override?.item?.otherDiscounts?.percent !== 0 &&
            override?.item?.otherDiscounts?.percent !== ""
          ) {
            lists[upc].price = Math.abs(override?.item?.price) * -1;

            lists[upc].total = lists[upc]?.price
              ? lists[upc].price! * override?.item?.quantity
              : undefined;
          }

          lists[upc].otherDiscounts = override.item.otherDiscounts;
          lists[upc].override = true;
        }
      }

      if (override.type === "itemDiscountPercentChange") {
        const upc = override.item.upc;

        if (lists[upc]) {
          if (override.item.description) {
            lists[upc].description = override.item.description;
          }

          if (
            override.item.discountPercent &&
            !override?.item?.otherDiscounts?.flat != false // eslint-disable-line eqeqeq
          ) {
            lists[upc].discountPercent = override.item.discountPercent;
          }

          if (
            override?.item?.otherDiscounts?.flat &&
            override?.item?.otherDiscounts?.flat !== 0 &&
            override?.item?.otherDiscounts?.flat !== ""
          ) {
            const total = getTotal(lists);

            lists[upc].discountPercent =
              (override?.item?.otherDiscounts?.flat / total) * 100;
          }

          lists[upc].override = true;

          lists[upc].otherDiscounts = override.item.otherDiscounts;
        }
      }

      if (override.type === "addPercentDiscount") {
        const upc = override.item.BarCodeValue;

        lists[upc] = {
          upc: override.item.BarCodeValue,
          description: override.item.SalesDesc,
          FullName: override.item.FullName,
          name: override.item.FullName,
          quantity: 1,
          type: "discount",
          measure: "ea",
          price: 0,
          discountPercent: override.item.discountPercent,
          total: 0,
        };
      }

      if (override.type === "removePercentageDiscount") {
        const upc = override.upc;

        delete lists[upc];
      }
    });
  }
  return lists;
}

export function getMasterItemList(itemLists: any) {
  return sortItemList(
    itemLists.reduce((master: any, itemList: any) => {
      Object.values(itemList).forEach((item: any) => {
        if (!master[item.upc]) {
          master[item.upc] = item;
        } else {
          master[item.upc].quantity = roundToHundreth(
            item.quantity + master[item.upc].quantity
          );
          master[item.upc].total = roundToHundreth(
            item.total + master[item.upc].total
          );
        }
      });
      return master;
    }, {})
  );
}

function getTotalPostsForEveryCanvas(canvases: any, settings: any, state: any) {
  if (canvases.size) {
    return canvases.reduce((count: any, canvas: any) => {
      canvas.runs.forEach((run: any) => {
        const posts = getPosts(run, settings, canvas);
        const postsPerRun = postsForTheRun(posts, run);

        count +=
          (postsPerRun.intermediate || 0) +
          (postsPerRun.terminal || 0) +
          (postsPerRun.stairPostIntermediate || 0) +
          (postsPerRun.stairPostTerminal || 0) +
          (postsPerRun.stairPostTransition || 0);
      });

      return count;
    }, 0);
  }

  return 0;
}

export function calculateTouchupPaint(
  itemList: any,
  inventory: any,
  canvases: any,
  settings: any,
  project: any
) {
  const touchupPaintUpcs = getTouchupPaintUpcs();

  const paintUpc = (touchupPaintUpcs as any)[settings.aluminumColor];

  const totalPosts = getTotalPostsForEveryCanvas(canvases, settings, project);

  // Every 25 posts add touchup paint.
  addItemForceQuantity(
    itemList,
    inventory,
    paintUpc,
    Math.ceil(totalPosts / 50)
  );

  return itemList;
}

// export function getTotalPosts(postsPerRun: any) {
//   const posts: {
//     intermediate?: number;
//     terminal?: number;
//     stairPostIntermediate?: number;
//     stairPostTerminal?: number;
//     stairPostTransition?: number;
//   } = {};
//   Object.values(postsPerRun).forEach((postsForRun: any) => {
//     //
//     if (
//       typeof posts.intermediate !== "undefined" &&
//       typeof posts.terminal !== "undefined"
//     ) {
//       posts.intermediate = posts.intermediate + (postsForRun.intermediate || 0);
//       posts.terminal = posts.terminal + (postsForRun.terminal || 0);
//       posts.stairPostIntermediate =
//         posts.stairPostIntermediate + (postsForRun.stairPostIntermediate || 0);
//       posts.stairPostTerminal =
//         posts.stairPostTerminal + (postsForRun.stairPostTerminal || 0);
//       posts.stairPostTransition =
//         posts.stairPostTransition + (postsForRun.stairPostTransition || 0);
//     } else {
//       posts.intermediate = postsForRun.intermediate || 0;
//       posts.terminal = postsForRun.terminal || 0;
//       posts.stairPostIntermediate = postsForRun.stairPostIntermediate || 0;
//       posts.stairPostTerminal = postsForRun.stairPostTerminal || 0;
//       posts.stairPostTransition = postsForRun.stairPostTransition || 0;
//     }
//   });
//   return posts;
// }

export function getTotalPosts(
  postsPerRun: Map<string, Record<string, number>>
) {
  const startValue: Record<string, number> = {};
  return postsPerRun.reduce((posts, postsForRun) => {
    if (
      typeof posts.intermediate !== "undefined" &&
      typeof posts.terminal !== "undefined"
    ) {
      posts.intermediate = posts.intermediate + (postsForRun.intermediate || 0);
      posts.terminal = posts.terminal + (postsForRun.terminal || 0);
      posts.stairPostIntermediate =
        posts.stairPostIntermediate + (postsForRun.stairPostIntermediate || 0);
      posts.stairPostTerminal =
        posts.stairPostTerminal + (postsForRun.stairPostTerminal || 0);
      posts.stairPostTransition =
        posts.stairPostTransition + (postsForRun.stairPostTransition || 0);
    } else {
      posts.intermediate = postsForRun.intermediate || 0;
      posts.terminal = postsForRun.terminal || 0;
      posts.stairPostIntermediate = postsForRun.stairPostIntermediate || 0;
      posts.stairPostTerminal = postsForRun.stairPostTerminal || 0;
      posts.stairPostTransition = postsForRun.stairPostTransition || 0;
    }

    return posts;
  }, startValue);
}
