// @ts-nocheck
function cableWeightsPerFoot() {
  return {
    616320730147: 0.0345, // 1 x 19 - 1/8”
    616320730154: 0.0765, // 1 x 19 - 3/16”
    616320730161: 0.062, // 7 x 7 - 3/16”
  };
}

function cableWeightUpcs() {
  return [616320730147, 616320730154, 616320730161];
}

function cableToolsWeights() {
  return {
    616320731908: 0.77, // Small Cutter
    616320731915: 6.56, // Large Cutter
    616453988804: 0.77, // Small Rental Cutter
    616453988811: 6.56, // Large Rental Cutter
    616320731878: 10.7, // Crimper
    616453988798: 10.7, // Crimper
    662187012193: 10.7, // Crimper
    616453989245: 0.711, // Epoxy Large Redhead
    616453988439: 0.711, // Epoxy Small 3M
    616320734381: 1.9474, // Penofin Gallon
    616320734398: 1.9474,
    616320734350: 1.9474,
    616320733216: 1.9474,
    616320734404: 0.018, // Penofin Quart
    616320734411: 0.018,
    616320734367: 0.018,
    616320734374: 0.018,
  };
}

function cableToolsUpcs() {
  return [
    616320731908, 616320731915, 616320731878, 616453988798, 662187012193,
    616453989245, 616453988439, 616320734381, 616320734398, 616320734350,
    616320733216, 616320734404, 616320734411, 616320734367, 616320734374,
  ];
}

function postWeights() {
  return {
    // Stainless Square
    616453988019: 12.71, // Stainless Steel Square DM 36 TERM
    616453987951: 4.86, // SSQ DM/INT/36
    616453988026: 14.8285, // SSQ DM/TERM/42
    616453987968: 5.67, // SSQ DM/INT/42
    616453988156: 12.71, // SSQ FM/TERM/36
    616453988095: 4.86, // SSQ FM/INT/36
    616453988163: 14.8285, // SSQ FM/TERM/42
    616453988101: 5.67, // SSQ FM/INT/42
    616453987890: 12.71, // SSQ CM/TERM/90/36
    616453987814: 4.86, // SSQ CM/TERM/90/36
    616453987883: 14.8285, // SSQ CM/TERM/42
    616453987821: 5.67, // SSQ CM/TERM/42
    // Stainless Round
    616453988651: 8.655, // SSR/DM/TERM 36 D
    616453988590: 3.852, // SSR/DM/INT 36 D
    616453988668: 10.0975, // SSR/DM/TERM 42 D
    616453988606: 4.494, // SSR/DM/INT 42 D
    616453988750: 8.655, // SSR/FM/TERM 36 D
    616453988699: 3.852, // SSR/FM/INT 36 D
    616453988767: 10.0975, // SSR/FM/TERM 42 D
    616453988705: 4.494, // SSR/FM/INT 42 D
    616453988569: 8.655, // SSR/CM/TERM/36IN D
    616453988507: 3.852, // SSR/CM/INT/36 D
    616453988538: 10.0975, // SSR/CM/TERM 42IN D
    616453988477: 4.494, // SSR/CM/INT 42 D
    // Wood
    616320734732: 17.5, // WD-Post-Tm-3-5x3-5x42-36-BALAU
    616320734718: 17.5, // WD-Post-Int-3-5x3-5x42-36-BALAU
    616320734749: 20, // WD-Post-Tm-3-5x3-5x48-42-BALAU
    616320734725: 20, // WD-Post-Int-3-5x3-5x48-42-BALAU
    // Aluminum
    616320729646: 5.5, // BLK:DM TERM/D2/36/BLK
    616320729653: 5.5, // BNZ:DM TERM/D2/36/BNZ
    616320729677: 5.5, // NAT:DM TERM/D2/36/NAT
    616320729660: 5.5, // CLY:DM TERM/D2/36/CLY
    616320729684: 5.5, // WHT:DM TERM/D2/36/WHT
    616320729592: 3.4, // BLK:DM INT/D1/BLK
    616320729608: 3.4, // BNZ:DM INT/D1/BNZ
    616320729622: 3.4, // NAT:DM INT/D1/NAT
    616320729615: 3.4, // CLY:DM INT/D1/CLY
    616320729639: 3.4, // WHT:DM INT/D1/WHT
    616320734589: 7.3, // BLK:DM TERM/D2/42/BLK
    616320734572: 7.3, // BNZ:DM TERM/D2/42/BNZ
    616320734558: 7.3, // NAT:DM TERM/D2/42/NAT
    616320734565: 7.3, // CLY:DM TERM/D2/42/CLY
    616320734541: 7.3, // WHT:DM TERM/D2/42/WHT
    616320729844: 7.3, // BLK:FM TERM/D2/BLK
    616320729851: 7.3, // BNZ:FM TERM/D2/BNZ
    616320729875: 7.3, // NAT:FM TERM/D2/NAT
    616320729868: 7.3, // CLY:FM TERM/D2/CLY
    616320729882: 7.3, // WHT:FM TERM/D2/WHT
    616320734633: 4, // BLK:FM INT/D1/BLK
    616320729752: 4, // BNZ:FM INT/D1/BNZ
    616320729776: 4, // NAT:FM INT/D1/NAT
    616320729769: 4, // CLY:FM INT/D1/CLY
    616320729783: 4, // WHT:FM INT/D1/WHT
  };
}

function postUpcs() {
  return [
    // Stainless Steel Square
    616453988019,
    616453987951,
    616453988026,
    616453987968,
    616453988156,
    616453988095,
    616453988163,
    616453988101,
    616453987890,
    616453987814,
    616453987883,
    616453987821,
    // Stainless Round
    616453988651,
    616453988590,
    616453988668,
    616453988606,
    616453988750,
    616453988699,
    616453988767,
    616453988705,
    616453988569,
    616453988507,
    616453988538,
    616453988477,
    // Wood
    616320734732,
    616320734718,
    616320734749,
    616320734725,
    // Aluminum
    616320729646, // Deck Mount 36
    616320729653,
    616320729592,
    616320729660,
    616320729684,
    616320729677,
    616320729608,
    616320729622,
    616320729615,
    616320729639,
    616320734589, // Deck Mount 42
    616320734572,
    616320734558,
    616320734565,
    616320734541,
    616320729844, // Fascia Mount 36 & 42
    616320729851,
    616320729875,
    616320729868,
    616320729882,
    616320734633,
    616320729752,
    616320729776,
    616320729769,
    616320729783,
  ];
}

function freightToprailUpcs() {
  return [
    // Aluminum.
    616453983380, // Rect 1ft BLK
    // 616453983380, // Rect 20ft BLK
    616453983786, // Rect 1ft BLK
    616453984431, // Rect 1ft NAT
    616453984059, // Rect 1ft CLY
    616453984806, // Rect 1ft WHT
    616453983410, // Shaped 1ft BLK
    616453983809, // Shaped 1ft BNZ
    616453984455, // Shaped 1ft NAT
    616453985292, // Shaped 1ft CLY
    616453984837, // Shaped 1ft WHT
    // Shaped 20ft
    616453983212, // P2P 1ft BLK
    662187011080, // P2P 1ft BNZ
    662187011103, // P2P 1ft NAT
    662187011097, // P2P 1ft CLY
    662187011110, // P2P 1ft WHT
    // P2P 20ft
    // P2P Brackets
    // Stainless Top Rail.
    // Stainless 1.5" Tube 1ft
    // Stainless 1.5" Tube 20ft
    616320734435, // Stainless .5" x 2" 1ft
    // Stainless .5" x 2" 20ft
    616320731618, // Stainless 2" Tube 1ft
    // Stainless 2" Tube 20ft
    // Wood
    616453989474, // Clear Cedar 5/4" X 4 1ft
    616453989528, // Clear Cedar 5/4" X 6 1ft
    616453989191, // Clear Cedar 2" x 4" 1ft
    616453989221, // Clear Cedar 2" x 6" 1ft
    616453989634, // Tight Know Cedar 5/4" X 4 1ft
    616453989689, // Tight Know Cedar 5/4" X 6 1ft
    616453989559, // Tight Know Cedar 2" x 4" 1ft
    616453989580, // Tight Know Cedar 2" x 6" 1ft
    616453989115, // Mahogany 5/4" x 4 1ft
    616453989160, // Mahogany 5/4" x 6 1ft
    616453989061, // Mahogany 2" x 4" 1ft
    616453988989, // IPE 5/4" x 4" 1ft
    616453989030, // IPE 5/4" x 6" 1ft
    616453988934, // IPE 2" x 4" 1ft
  ];
}

function freightToprailWeights() {
  return {
    // Aluminum.
    616453983380: 0.935, // Rect 1ft BLK
    616453983786: 0.935, // Rect 1ft BNZ
    616453984431: 0.935, // Rect 1ft NAT
    616453984059: 0.935, // Rect 1ft CLY
    616453984806: 0.935, // Rect 1ft WHT
    // 0: 18.777, // Rect 20ft
    616453983410: 0.817, // Shaped 1ft BLK
    616453983809: 0.817, // Shaped 1ft BNZ
    616453984455: 0.817, // Shaped 1ft NAT
    616453985292: 0.817, // Shaped 1ft CLY
    616453984837: 0.817, // Shaped 1ft WHT
    // 0: 16.355, // Shaped 20ft
    616453983212: 0.495, // P2P 1ft BLK
    662187011080: 0.495, // P2P 1ft BNZ
    662187011103: 0.495, // P2P 1ft NAT
    662187011097: 0.495, // P2P 1ft CLY
    662187011110: 0.495, // P2P 1ft WHT
    // 0: 9.9, // P2P 20ft
    // P2P Brackets
    // Stainless Top Rail.
    // 0: 1.34, // Stainless 1.5" Tube 1ft
    // 0: 26.8, // Stainless 1.5" Tube 20ft
    616320734435: 1.1, // Stainless .5" x 2" 1ft
    // 0: 22, // Stainless .5" x 2" 20ft
    616320731618: 1.62, // Stainless 2" Tube 1ft
    // 0: 32.4, // Stainless 2" Tube 20ft
    // Wood
    616453989474: 0.572, // Clear Cedar 5/4" X 4 1ft
    616453989528: 0.91, // Clear Cedar 5/4" X 6 1ft
    616453989191: 0.91, // Clear Cedar 2" x 4" 1ft
    616453989221: 1.273, // Clear Cedar 2" x 6" 1ft
    616453989634: 0.572, // Tight Know Cedar 5/4" X 4 1ft
    616453989689: 0.91, // Tight Know Cedar 5/4" X 6 1ft
    616453989559: 0.91, // Tight Know Cedar 2" x 4" 1ft
    616453989580: 1.273, // Tight Know Cedar 2" x 6" 1ft
    616453989115: 1.43, // Mahogany 5/4" x 4 1ft
    616453989160: 2.375, // Mahogany 5/4" x 6 1ft
    616453989061: 2.2, // Mahogany 2" x 4" 1ft
    616453988989: 1.625, // IPE 5/4" x 4" 1ft
    616453989030: 2.5, // IPE 5/4" x 6" 1ft
    616453988934: 2.4, // IPE 2" x 4" 1ft
  };
}

function calculateCableWeight(itemList) {
  return calculateWeight(itemList, cableWeightUpcs, cableWeightsPerFoot);
}

function calculateCableToolsWeight(itemList) {
  return calculateWeight(itemList, cableToolsUpcs, cableToolsWeights);
}

function calculatePostsWeight(itemList) {
  return calculateWeight(itemList, postUpcs, postWeights);
}

function calculateToprailWeight(itemList) {
  return calculateTopWeight(
    itemList,
    freightToprailUpcs,
    freightToprailWeights
  );
}

function calculateTopWeight(itemList, upcs, weights) {
  const Upcs = upcs();
  const Weights = weights();

  let weight = 0;

  Upcs.forEach((upc) => {
    if (itemList[upc]) {
      weight = roundToTenThousandth(
        weight +
          roundToTenThousandth(Weights[upc] * itemList[upc].quantity * 20)
      );
    }
  });

  return weight;
}

function calculateWeight(itemList, upcs, weights) {
  const Upcs = upcs();
  const Weights = weights();

  let weight = 0;

  Upcs.forEach((upc) => {
    if (itemList[upc]) {
      weight = roundToTenThousandth(
        weight + roundToTenThousandth(Weights[upc] * itemList[upc].quantity)
      );
    }
  });

  return weight;
}

function roundToTenThousandth(number) {
  return Math.round(number * 10000) / 10000;
}

export function get20x20x4BoxDetails(itemList) {
  let totalWeight = 0;

  totalWeight = roundToTenThousandth(
    totalWeight +
      calculateCableWeight(itemList) +
      calculateCableToolsWeight(itemList)
  );

  const roundedWeight = Math.ceil(totalWeight);
  const numberOfBoxes = Math.ceil(totalWeight / 45);
  const weightPerBoxAndPackaging = Math.ceil(
    totalWeight / Math.max(numberOfBoxes, 1) + 3
  );

  return {
    totalWeight: roundedWeight,
    boxSize: "20 x 20 x 4",
    numberOfBoxes: numberOfBoxes || 0,
    weightPerBoxAndPackaging: weightPerBoxAndPackaging || 0,
  };
}

export function get52x8x8BoxDetails(itemList) {
  let totalWeight = 0;

  totalWeight = roundToTenThousandth(
    totalWeight + calculatePostsWeight(itemList)
  );

  const roundedWeight = Math.ceil(totalWeight);
  const numberOfBoxes = Math.ceil(totalWeight / 45);
  const weightPerBoxAndPackaging = Math.ceil(
    totalWeight / Math.max(numberOfBoxes, 1) + 3
  );

  return {
    totalWeight: roundedWeight,
    boxSize: "52 x 8 x 8",
    numberOfBoxes: numberOfBoxes || 0,
    weightPerBoxAndPackaging: weightPerBoxAndPackaging || 0,
  };
}

export function getFreightDetails(itemList) {
  let totalWeight = 0;

  totalWeight = roundToTenThousandth(
    totalWeight + calculateToprailWeight(itemList)
  );

  const roundedWeight = Math.ceil(totalWeight);

  return {
    totalWeight: roundedWeight,
    boxSize: "Freight Tube",
    numberOfBoxes: 0,
    weightPerBoxAndPackaging: 0,
  };
}
