import { combineReducers } from "redux";
import { sequenceReducer } from "./reducers/sequenceReducer";
import { appReducer } from "./reducers/appReducer";
import { windowStateReducer } from "./reducers/windowStateReducer";
import { estimatorReducer } from "./reducers/estimatorReducer";
import { customerReducer } from "./reducers/customerReducer";
import { saveReducer } from "./reducers/saveReducer";
import { requiredFieldsReducer } from "./reducers/requiredFieldsReducer";
import { renderCanvasesReducer } from "./reducers/renderCanvasesReducer";
import { windowsZIndexReducer } from "./reducers/windowsZIndexReducer";
import { stateReducer } from "./reducers/stateReducer";

const rootReducer = combineReducers({
  state: stateReducer,
  sequenceId: sequenceReducer,
  appState: appReducer,
  windowsZIndex: windowsZIndexReducer,
  windows: windowStateReducer,
  estimators: estimatorReducer,
  customerReducer: customerReducer,
  saveState: saveReducer,
  requiredFields: requiredFieldsReducer,
  renderCanvases: renderCanvasesReducer,
});

export default rootReducer;
