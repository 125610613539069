import { ItemNonInventory } from "./parsers/itemNonInventoryParser";

const nonInventory: ItemNonInventory = {
  "?xml": { "@_version": "1.0" },
  QBXML: {
    QBXMLMsgsRs: {
      ItemNonInventoryQueryRs: {
        ItemNonInventoryRet: [
          {
            ListID: "80000350-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:44:46-08:00",
            EditSequence: 1580341486,
            Name: "AL-BASE-PLATE-RAW",
            FullName: "AL-BASE-PLATE-RAW",
            BarCodeValue: 662187011288,
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: ".250 x 4.125 BAR 6061-T6511 4.55'\nCut Tolerance + .125'/-.000'",
              Price: 39.95,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "80000351-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:44:47-08:00",
            EditSequence: 1580341487,
            Name: "AL-TOP-RAIL-PLATE-RAW",
            FullName: "AL-TOP-RAIL-PLATE-RAW",
            BarCodeValue: 662187011295,
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "03-016-01\nCustom Farication: \n365 143B61\n.250 x 3.000 BAR 6061-T6511 4.55'\nCut Tolerance + .125'/-.000'",
              Price: 34.95,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "80000352-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:44:48-08:00",
            EditSequence: 1580341488,
            Name: "ANTI-SEIZELUBRICANT/W LABEL",
            FullName: "ANTI-SEIZELUBRICANT/W LABEL",
            BarCodeValue: 616453985322,
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "079340-51299  LOCTITE 51299 2GR PH..COPPER BASE ANTSZ",
              Price: 0.95,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "80000C0D-1555624020",
            TimeCreated: "2019-04-18T14:47:00-08:00",
            TimeModified: "2020-01-29T15:44:51-08:00",
            EditSequence: 1580341491,
            Name: "CATALOG",
            FullName: "CATALOG",
            BarCodeValue: "NON-INVENTORY-113",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "SC&R CATALOG\n\nThank you for your interest in Stainless Cable & Railing, Inc products!!! Please let us know when you're ready for us to work up a complete quote for your project.",
              Price: 0,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "80000354-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-02-02T12:34:50-08:00",
            EditSequence: 1580675690,
            Name: "CONSUMABLES",
            FullName: "CONSUMABLES",
            BarCodeValue: "CATEGORY-43",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "80000355-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2023-03-07T15:29:34-08:00",
            EditSequence: 1678231774,
            Name: "1/2x18 120 Ceramic-Dynafile",
            FullName: "CONSUMABLES:1/2x18 120 Ceramic-Dynafile",
            BarCodeValue: "NON-INVENTORY-3",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "SB20877",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/2x18, 120 ceramic, Airfile Belt\nY-Wt Polyester\nSB20877\nFor Dynafile\n40 per pack/ $40.91\nAA Abrasives Inc.",
              Price: 1.02,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000356-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2023-03-07T15:31:26-08:00",
            EditSequence: 1678231886,
            Name: "1/2x18 80 ceramic-Dynafile",
            FullName: "CONSUMABLES:1/2x18 80 ceramic-Dynafile",
            BarCodeValue: "NON-INVENTORY-4",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "SB20861",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/2x18, 80 Zirc Plus, Airfile Belt\nY-Wt Polyester\nSB20861\nFor Dynafile\n40 per pack/ $41.54\nAA Abrasives Inc.",
              Price: 1.04,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000357-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2023-03-07T15:52:37-08:00",
            EditSequence: 1678233157,
            Name: "1/2x18 ACRS-Tan-Dynafile",
            FullName: "CONSUMABLES:1/2x18 ACRS-Tan-Dynafile",
            BarCodeValue: "NON-INVENTORY-5",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34205,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/2x18,ACRS,Tan,Surface Conditioning Belt, Extra Flex\n34205\nFor Dynafile\n20 per pack/ $64.96\nAA Abrasives Inc.",
              Price: 3.25,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000358-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:06:27-08:00",
            EditSequence: 1580339187,
            Name: "1/2x18 AMED-Maroon-Dynafile",
            FullName: "CONSUMABLES:1/2x18 AMED-Maroon-Dynafile",
            BarCodeValue: "NON-INVENTORY-6",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34209,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/2x18,AMED,Maroon,Surface Conditioning Belt, Extra Flex\n34209\nFor Dynafile\n20 per pack/ $46.87\nAA Abrasives Inc.",
              Price: 2.34,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000359-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:06:28-08:00",
            EditSequence: 1580339188,
            Name: "1/4x18 120 Zirc-Dynafile",
            FullName: "CONSUMABLES:1/4x18 120 Zirc-Dynafile",
            BarCodeValue: "NON-INVENTORY-7",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 20712,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18, 120 Zirc Plus, Airfile Belt\nY-Wt Polyester\n20712\nFor Dynafile\n50 per pack/ $44.79\nAA Abrasives Inc.",
              Price: 0.9,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000035A-1510599157",
            TimeCreated: "2017-11-13T10:52:37-08:00",
            TimeModified: "2020-01-29T15:06:29-08:00",
            EditSequence: 1580339189,
            Name: "1/4x18 60 Zirc-Dynafile",
            FullName: "CONSUMABLES:1/4x18 60 Zirc-Dynafile",
            BarCodeValue: "NON-INVENTORY-8",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 20688,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18, 60 Zirc Plus, Airfile Belt\nY-Wt Polyester\n20688\nFor Dynafile\n50 per pack/ $46.19\nAA Abrasives Inc.",
              Price: 0.92,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000137D-1679098559",
            TimeCreated: "2023-03-17T17:15:59-08:00",
            TimeModified: "2023-03-17T17:15:59-08:00",
            EditSequence: 1679098559,
            Name: "1/4x18 80 Ceramic-Dynafile",
            FullName: "CONSUMABLES:1/4x18 80 Ceramic-Dynafile",
            BarCodeValue: "QB:01033918054989",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18 Dynafile Sanding Belts 80 Grit Zirc Plus\n\nSB20696\n50 per pack/$53.60\nAA  Abrasives Inc.",
              Price: 0,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "8000035B-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:30-08:00",
            EditSequence: 1580339190,
            Name: "1/4x18 ACRS-Tan-Dynafile",
            FullName: "CONSUMABLES:1/4x18 ACRS-Tan-Dynafile",
            BarCodeValue: "NON-INVENTORY-9",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34181,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18,ACRS,Tan,Surface Conditioning Belt, Extra Flex\n34181\nFor Dynafile\n20 per pack/ $49.07\nAA Abrasives Inc.",
              Price: 2.39,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000035C-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:31-08:00",
            EditSequence: 1580339191,
            Name: "1/4x18 AMED-Maroon-Dynafile",
            FullName: "CONSUMABLES:1/4x18 AMED-Maroon-Dynafile",
            BarCodeValue: "NON-INVENTORY-10",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34185,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18,AMED,Maroon,Surface Conditioning Belt, Extra Flex\n34185\nFor Dynafile\n20 per pack/ $49.07\nAA Abrasives Inc.",
              Price: 2.28,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000035D-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:32-08:00",
            EditSequence: 1580339192,
            Name: "1/4x18 AVFN-Blue-Dynafile",
            FullName: "CONSUMABLES:1/4x18 AVFN-Blue-Dynafile",
            BarCodeValue: "NON-INVENTORY-11",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34189,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1/4x18,AVFN,Blue,Surface Conditioning Belt, Extra Flex\n34189\nFor Dynafile\n20 per pack/ $45.64\nAA Abrasives Inc.",
              Price: 2.28,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000035E-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:33-08:00",
            EditSequence: 1580339193,
            Name: '12" Sanding Disk 36 Grit',
            FullName: 'CONSUMABLES:12" Sanding Disk 36 Grit',
            BarCodeValue: "NON-INVENTORY-12",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051144-88869",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "12 X NH 36 777F 3M PSA DISC (1DC)\nRS HUGHES",
              Price: 12.46,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000035F-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:34-08:00",
            EditSequence: 1580339194,
            Name: '21" x 3" Sanding Belt 120 Grit',
            FullName: 'CONSUMABLES:21" x 3" Sanding Belt 120 Grit',
            BarCodeValue: "NON-INVENTORY-13",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 73220170,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "73220170\n120 GRIT\nMSC\nFOR HANDHELD BELT SANDER",
              Price: 2.89,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000360-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:35-08:00",
            EditSequence: 1580339195,
            Name: '21" x 3" Sanding Belt 80 Grit',
            FullName: 'CONSUMABLES:21" x 3" Sanding Belt 80 Grit',
            BarCodeValue: "NON-INVENTORY-14",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 73220154,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "73220154\n80 GRIT\nMSC\nFOR HANDHELD BELT SANDER",
              Price: 2.96,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000361-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:36-08:00",
            EditSequence: 1580339196,
            Name: "3-1/2x15--1/2 Zirc 120-3M",
            FullName: "CONSUMABLES:3-1/2x15--1/2 Zirc 120-3M",
            BarCodeValue: "NON-INVENTORY-15",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 22056,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3-1/2x15-1/2, 120 Zirc Plus, Portable Belt\nY-Wt Polyester\n22056\nFor 3M Air Tool\n10 per pack/ $36.66\nAA Abrasives Inc.",
              Price: 3.67,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000362-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:37-08:00",
            EditSequence: 1580339197,
            Name: "3-1/2x15--1/2 Zirc 80-3M",
            FullName: "CONSUMABLES:3-1/2x15--1/2 Zirc 80-3M",
            BarCodeValue: "NON-INVENTORY-16",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 22032,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3-1/2x15-1/2, 80 Zirc Plus, Portable Belt\nY-Wt Polyester\n22032\nFor 3M Air Tool\n10 per pack/ $37.32\nAA Abrasives Inc.",
              Price: 3.73,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000363-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:38-08:00",
            EditSequence: 1580339198,
            Name: "3-1/2x15-1/2 AVFN-Blue-3M",
            FullName: "CONSUMABLES:3-1/2x15-1/2 AVFN-Blue-3M",
            BarCodeValue: "NON-INVENTORY-17",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34285,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3-1/2x15-1/2 AVFN,Blue,Surface Conditioning Belt, Extra Flex\n34285\nFor 3M Air Tool\n10 per pack/ $122.44\nAA Abrasives Inc.",
              Price: 12.24,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000D3A-1594059632",
            TimeCreated: "2020-07-06T11:20:32-08:00",
            TimeModified: "2020-07-06T11:24:29-08:00",
            EditSequence: 1594059869,
            Name: "3/4x18 120 Zirc-Dynafile",
            FullName: "CONSUMABLES:3/4x18 120 Zirc-Dynafile",
            BarCodeValue: "QB:01033918053386",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 21036,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3/4x18, 120 Zirc Plus, Airfile Belt\nY-Wt Polyester\n21036\nFor Dynafile\n50 per pack/ $62.60\nAA Abrasives Inc.",
              Price: 1.25,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000137A-1678227866",
            TimeCreated: "2023-03-07T14:24:26-08:00",
            TimeModified: "2023-03-07T15:43:15-08:00",
            EditSequence: 1678232595,
            Name: "3/4x18 36 Ceramic-Dynafile",
            FullName: "CONSUMABLES:3/4x18 36 Ceramic-Dynafile",
            BarCodeValue: "QB:01033918054986",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "SB20965",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3/4x18, 36 grit, ceramic Airfile Belt\nY-Wt Polyester\nSB20965\nFor Dynafile\n40 per pack/ $62.59\n5+ Packs 59.11\nAA Abrasives Inc.",
              Price: 1.56,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000138B-1681933540",
            TimeCreated: "2023-04-19T12:45:40-08:00",
            TimeModified: "2023-04-19T12:45:40-08:00",
            EditSequence: 1681933540,
            Name: "3/4x18 80 Zirc-Dynafile",
            FullName: "CONSUMABLES:3/4x18 80 Zirc-Dynafile",
            BarCodeValue: "QB:01033918055003",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "SB21008",
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3/4x18 80 Zirc Plus, Airfile belt Y-wt Polyester SB21008",
              Price: 0,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "80000D3B-1594060653",
            TimeCreated: "2020-07-06T11:37:33-08:00",
            TimeModified: "2023-03-07T15:56:13-08:00",
            EditSequence: 1678233373,
            Name: "3/4x18 ACRS-Tan-Dynafile",
            FullName: "CONSUMABLES:3/4x18 ACRS-Tan-Dynafile",
            BarCodeValue: "QB:01033918053387",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 34229,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "3/4x18,ACRS,Tan,Surface Conditioning Belt, Extra Flex\n34229\nFor Dynafile\n20 per pack/ $90.31\nAA Abrasives Inc.",
              Price: 4.52,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000364-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:39-08:00",
            EditSequence: 1580339199,
            Name: '32"x .75"SandingBelt 100ceramic',
            FullName: 'CONSUMABLES:32"x .75"SandingBelt 100ceramic',
            BarCodeValue: "NON-INVENTORY-18",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: ".75-32CR100Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: ".75-32CR100Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 1.94,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000365-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:40-08:00",
            EditSequence: 1580339200,
            Name: '32"x .75"SandingBelt 120ceramic',
            FullName: 'CONSUMABLES:32"x .75"SandingBelt 120ceramic',
            BarCodeValue: "NON-INVENTORY-19",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: ".75-32CR120Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: ".75-32CR120Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 1.94,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000366-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:41-08:00",
            EditSequence: 1580339201,
            Name: '32"x .75"SandingBelt 180 silica',
            FullName: 'CONSUMABLES:32"x .75"SandingBelt 180 silica',
            BarCodeValue: "NON-INVENTORY-20",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: ".75-32SC180Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: ".75-32CR180Y\nSILICA CARBIDE\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 1.26,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000367-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:42-08:00",
            EditSequence: 1580339202,
            Name: '32"x 1.5"Sanding Belt 180 Grit',
            FullName: 'CONSUMABLES:32"x 1.5"Sanding Belt 180 Grit',
            BarCodeValue: "NON-INVENTORY-21",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "1.5-32SC180Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1.5-32SC180Y\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 1.64,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000368-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:43-08:00",
            EditSequence: 1580339203,
            Name: '32"x 1.5"Sanding Belt 220 Grit',
            FullName: 'CONSUMABLES:32"x 1.5"Sanding Belt 220 Grit',
            BarCodeValue: "NON-INVENTORY-22",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "1.5-32SC220Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1.5-32SC220Y\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 1.48,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000369-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:44-08:00",
            EditSequence: 1580339204,
            Name: '4 1/2"x4"x5/8-11" A60 FlapBrush',
            FullName: 'CONSUMABLES:4 1/2"x4"x5/8-11" A60 FlapBrush',
            BarCodeValue: "NON-INVENTORY-23",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051141-55744",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "051141-55744\nSB 4 1/2 x 4x 5/8-11 A60 CB-MF FLAP BRUSH\nRS HUGHES\nEACH",
              Price: 64.63,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000036A-1510599158",
            TimeCreated: "2017-11-13T10:52:38-08:00",
            TimeModified: "2020-01-29T15:06:45-08:00",
            EditSequence: 1580339205,
            Name: '48"x 6"SandingBelt SC 180',
            FullName: 'CONSUMABLES:48"x 6"SandingBelt SC 180',
            BarCodeValue: "NON-INVENTORY-24",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 63176879,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "63176879\nSILICON CARBIDE\nMSC\nFOR POWERMATIC",
              Price: 17.68,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000138C-1681935609",
            TimeCreated: "2023-04-19T13:20:09-08:00",
            TimeModified: "2023-04-19T13:20:09-08:00",
            EditSequence: 1681935609,
            Name: '48"x 6"SandingBelt ZA 120',
            FullName: 'CONSUMABLES:48"x 6"SandingBelt ZA 120',
            BarCodeValue: "QB:01033918055004",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "8000036B-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:06:46-08:00",
            EditSequence: 1580339206,
            Name: '48"x 6"SandingBelt ZA 180',
            FullName: 'CONSUMABLES:48"x 6"SandingBelt ZA 180',
            BarCodeValue: "NON-INVENTORY-25",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 82108275,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "82108275\nZIRCONIA  ALUMINA\nMSC\nFOR POWERMATIC",
              Price: 13.7,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000036C-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:06:47-08:00",
            EditSequence: 1580339207,
            Name: '48"x 6"SandingBelt ZA 80',
            FullName: 'CONSUMABLES:48"x 6"SandingBelt ZA 80',
            BarCodeValue: "NON-INVENTORY-26",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 466847,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "00466847\nZIRCONIA  ALUMINA\nMSC\nORDER 10\nFOR POWERMATIC",
              Price: 12.82,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000036D-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-05-04T16:05:54-08:00",
            EditSequence: 1588633554,
            Name: '5"Sanding Disk 120Grit',
            FullName: 'CONSUMABLES:5"Sanding Disk 120Grit',
            BarCodeValue: "NON-INVENTORY-27",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051125-86843",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "5 X NH 120+ 732U CUBITRON II HOOKIT (50)\nRS HUGHES\n50 PAK\n051125-86843\n\nNO LONGER PRODUCED - 0.36\n5 X NH P120 735U HK RGL FC PA OP (50)\nRS HUGHES\n50 PAK\n051141-20730",
              Price: 0.47,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000036E-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-05-04T16:06:06-08:00",
            EditSequence: 1588633566,
            Name: '5"Sanding Disk 180 Grit',
            FullName: 'CONSUMABLES:5"Sanding Disk 180 Grit',
            BarCodeValue: "NON-INVENTORY-28",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051125-87059",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "5 X NH 180+ 732U HOOKIT D/F 5 HOLES CUBITRON II (50)\nRS HUGHES\n50 PAK\n051125-87059\n\nNO LONGER PRODUCED - 0.36\n5 X NH P180 735U HK RGL FC PA OP (50)\nRS HUGHES\n50 PAK\n051141-20792",
              Price: 0.47,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000036F-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:06:51-08:00",
            EditSequence: 1580339211,
            Name: '5"Sanding Disk 220 Grit',
            FullName: 'CONSUMABLES:5"Sanding Disk 220 Grit',
            BarCodeValue: "NON-INVENTORY-29",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051141-20736",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "5 X NH P220 735U HK RGL FC PA OP (50)\nRS HUGHES\n50 PAK\n051141-20736",
              Price: 0.37,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000370-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-05-04T16:05:43-08:00",
            EditSequence: 1588633543,
            Name: '5"Sanding Disk 80 Grit',
            FullName: 'CONSUMABLES:5"Sanding Disk 80 Grit',
            BarCodeValue: "NON-INVENTORY-30",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051125-86842",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "5 X NH 80+ 732U CUBITRON II HOOKIT (50)\nRS HUGHES\n50 PAK\n051125-86842\n\nNO LONGER PRODUCED - 0.39\n5 X NH P80 735U HK RGL FC PA OP (50)\nRS HUGHES\n50 PAK\n051141-20726",
              Price: 0.5,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000371-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:06:53-08:00",
            EditSequence: 1580339213,
            Name: '6"x 216"SandingBelt 100ceramic',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt 100ceramic',
            BarCodeValue: "NON-INVENTORY-31",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "6-216CR100Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "6-216CR100Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 98.49,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000372-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:01-08:00",
            EditSequence: 1580339281,
            Name: '6"x 216"SandingBelt 120ceramic',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt 120ceramic',
            BarCodeValue: "NON-INVENTORY-32",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "6-216CR120Y",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "6-216CR120Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH",
              Price: 98.49,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000E94-1617145382",
            TimeCreated: "2021-03-30T16:03:02-08:00",
            TimeModified: "2021-08-09T12:40:37-08:00",
            EditSequence: 1628538037,
            Name: '6"x 216"SandingBelt 180 ceramic',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt 180 ceramic',
            BarCodeValue: "QB:01033918053732",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "4110060X62164",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Klingspor 180 Grit Sanding Belt\n6X216 Belt\n0610180F62161\n$23.07 each\n\nKlingspor 60 Grit Sanding Belt\n6X216 Belt\n4110060X62164\n$39.82 each\n\nALTERNATE\nAAAbrasives \n6 x 216 #80 Zirc Plus\n$33.13 each\n10 piece minimum order.\n\nAlternate Belt used in the past.\n6-216CR80Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH\nUPC: 051141-20726\nPart Number: 6-216CR80Y",
              Price: 39.82,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80001311-1652899196",
            TimeCreated: "2022-05-18T11:39:56-08:00",
            TimeModified: "2022-05-18T11:39:56-08:00",
            EditSequence: 1652899196,
            Name: '6"x 216"SandingBelt 220 ceramic',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt 220 ceramic',
            BarCodeValue: "QB:01033918054881",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "4110060X62164",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Klingspor 220 Grit Sanding Belt\n6X216 Belt\n0610220F62161\n$25.15/each\n\n\nKlingspor 60 Grit Sanding Belt\n6X216 Belt\n4110060X62164\n$39.82 each\n\nALTERNATE\nAAAbrasives \n6 x 216 #80 Zirc Plus\n$33.13 each\n10 piece minimum order.\n\nAlternate Belt used in the past.\n6-216CR80Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH\nUPC: 051141-20726\nPart Number: 6-216CR80Y",
              Price: 39.82,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000373-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2022-04-11T10:10:06-08:00",
            EditSequence: 1649697006,
            Name: '6"x 216"SandingBelt 80 ceramic',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt 80 ceramic',
            BarCodeValue: "NON-INVENTORY-33",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "4110060X62164",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Klingspor 80 Grit Sanding Belt\n6X216 Belt\n0610080F62164Y\n$29.92 each\n\nKlingspor 60 Grit Sanding Belt\n6X216 Belt\n4110060X62164\n$39.82 each\n\nALTERNATE\nAAAbrasives \n6 x 216 #80 Zirc Plus\n$33.13 each\n10 piece minimum order.\n\nAlternate Belt used in the past.\n6-216CR80Y\nCERAMIC\nCUSTOMSANDINGBELTS.COM\nEACH\nUPC: 051141-20726\nPart Number: 6-216CR80Y",
              Price: 39.82,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000D07-1584746258",
            TimeCreated: "2020-03-20T16:17:38-08:00",
            TimeModified: "2020-03-20T16:24:20-08:00",
            EditSequence: 1584746660,
            Name: '6"x 216"SandingBelt Brwn Scotch',
            FullName: 'CONSUMABLES:6"x 216"SandingBelt Brwn Scotch',
            BarCodeValue: "QB:01033918053335",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "NWXCRSE62164",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Klingspor 40 Grit Brown Extra-Course Scotchbrite belt\n6X216 Belt\n4110060X62164\n$117.64 each\n\nAlternative\nKlingspor 60 Grit Red Course Scotchbrite belt\nNWLSCRSE62164\n135.94 Call for pricing",
              Price: 117.64,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000374-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:03-08:00",
            EditSequence: 1580339283,
            Name: '79"x 6"SandingBelt  A100',
            FullName: 'CONSUMABLES:79"x 6"SandingBelt  A100',
            BarCodeValue: "NON-INVENTORY-34",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051135-94690",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "051135-94690\nRS HUGHES\nEACH",
              Price: 22.19,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000375-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:04-08:00",
            EditSequence: 1580339284,
            Name: '79"x 6"SandingBelt 80',
            FullName: 'CONSUMABLES:79"x 6"SandingBelt 80',
            BarCodeValue: "NON-INVENTORY-35",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051125-70885",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "051125-70885\nRS HUGHES\nEACH",
              Price: 17.11,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000376-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:06-08:00",
            EditSequence: 1580339286,
            Name: '8"x 3"Sanding Wheel',
            FullName: 'CONSUMABLES:8"x 3"Sanding Wheel',
            BarCodeValue: "NON-INVENTORY-36",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 1261544,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "01261544\nMSC\nFOR BENCHTOP GRINDER",
              Price: 87.94,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000377-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:07-08:00",
            EditSequence: 1580339287,
            Name: "Chopped Strand Glass",
            FullName: "CONSUMABLES:Chopped Strand Glass",
            BarCodeValue: "NON-INVENTORY-37",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "J62-6397",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'SKU: J62-6397\nQuart, 1/4" Chopped Strand Gla\nQuart, 1/4" Chopped Strand Gla',
              Price: 3.98,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C9D-1568737969",
            TimeCreated: "2019-09-17T09:32:49-08:00",
            TimeModified: "2020-02-02T12:36:53-08:00",
            EditSequence: 1580675813,
            Name: "Citrisurf 3050 55 gal drum",
            FullName: "CONSUMABLES:Citrisurf 3050 55 gal drum",
            BarCodeValue: "NON-INVENTORY-38",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "CR55-2050",
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "1249.60 for 1 Drum\n1171.50 for 2+ Drums",
              Price: 1249.6,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "80000A7A-1519163349",
            TimeCreated: "2018-02-20T13:49:09-08:00",
            TimeModified: "2020-02-02T12:36:12-08:00",
            EditSequence: 1580675772,
            Name: "Citrisurf 55 gal drum",
            FullName: "CONSUMABLES:Citrisurf 55 gal drum",
            BarCodeValue: "INACTIVE-3",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "CR55-2325",
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 668.8,
              AccountRef: {
                ListID: "800000A7-1530049923",
                FullName: "Warehouse/Shop Expense:Warehouse/Shop Supplies",
              },
            },
          },
          {
            ListID: "80000378-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:08-08:00",
            EditSequence: 1580339288,
            Name: "J-B Weld",
            FullName: "CONSUMABLES:J-B Weld",
            BarCodeValue: "NON-INVENTORY-39",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 8280,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "J-B Weld 8280 Original- Professional Size Steel Reinforced Epoxy-10oz",
              Price: 9.56,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000BC0-1546283179",
            TimeCreated: "2018-12-31T11:06:19-08:00",
            TimeModified: "2020-01-29T15:08:17-08:00",
            EditSequence: 1580339297,
            Name: "LennoxClassic12'x1\"x 1'W18T",
            FullName: "CONSUMABLES:LennoxClassic12'x1\"x 1'W18T",
            BarCodeValue: "NON-INVENTORY-40",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Lennox Classic Bi-Metal 12'x1\"-- 1' Wide X 0.035\" Thick 18T\nDOALL Band Saw Blade\nOrder online\nwww.bandsawbladesdirect.com",
              Price: 67.87,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000037B-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:09-08:00",
            EditSequence: 1580339289,
            Name: "RockwellSandingTriangle 80Grit",
            FullName: "CONSUMABLES:RockwellSandingTriangle 80Grit",
            BarCodeValue: "NON-INVENTORY-41",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Rockwell RW9141 Sonicrafter Sanding Sheet Set, 20-Piece\nAMAZON",
              Price: 12.99,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000037C-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:10-08:00",
            EditSequence: 1580339290,
            Name: "RockwellSandingTriangle Assort",
            FullName: "CONSUMABLES:RockwellSandingTriangle Assort",
            BarCodeValue: "NON-INVENTORY-42",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Rockwell RW9145 Sonicrafter Sanding Sheet Set, 20-Piece\nAMAZON",
              Price: 8,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000037D-1510599159",
            TimeCreated: "2017-11-13T10:52:39-08:00",
            TimeModified: "2020-01-29T15:08:11-08:00",
            EditSequence: 1580339291,
            Name: "Sanding Sponge Purple MED",
            FullName: "CONSUMABLES:Sanding Sponge Purple MED",
            BarCodeValue: "NON-INVENTORY-43",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "051111-02518",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "24100M 2.625 X 3.75 X 1 PRO GRADE MEDIUM SPONGE\nRS HUGHES\n6 PER PAK\nORDER MULTIPLE OF 6",
              Price: 1.63,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000037E-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:08:12-08:00",
            EditSequence: 1580339292,
            Name: "ScotchBrite MAROON PAD",
            FullName: "CONSUMABLES:ScotchBrite MAROON PAD",
            BarCodeValue: "NON-INVENTORY-44",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "048011-24037",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "SB 8447 HP-HP MAROON 3M PROD PAD 20/BX\nRS HUGHES\nORDER IN MULTIPLES OF 20",
              Price: 1.04,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000037F-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:08:13-08:00",
            EditSequence: 1580339293,
            Name: "ScotchBrite TAN PAD",
            FullName: "CONSUMABLES:ScotchBrite TAN PAD",
            BarCodeValue: "NON-INVENTORY-45",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "048011-04050",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "SB 7440 HP-HP TAN 10/BX 3M HVY DUTY PAD\nRS HUGHES\nORDER IN MULTIPLES OF 10",
              Price: 2.41,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000380-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:08:15-08:00",
            EditSequence: 1580339295,
            Name: "TL-BIT-DRILL-SHORT-.5047-CNC",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-.5047-CNC",
            BarCodeValue: "NON-INVENTORY-46",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 81439382 5/32" CARBIDE FIREX MSC',
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000381-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:08:16-08:00",
            EditSequence: 1580339296,
            Name: "TL-BIT-DRILL-SHORT-.5551-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-.5551-CNC-G",
            BarCodeValue: "NON-INVENTORY-47",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "CATALOG ITEM 79259735 0.5551 CARBIDE GUHRING MSC",
              Price: 193.91,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000137B-1678322191",
            TimeCreated: "2023-03-08T16:36:31-08:00",
            TimeModified: "2023-03-08T16:36:31-08:00",
            EditSequence: 1678322191,
            Name: "TL-BIT-DRILL-SHORT-.5625-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-.5625-CNC-G",
            BarCodeValue: "QB:01033918054987",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 89320733,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'Hertel- screw Machine Length Drill Bit: 0.5625" Dia, 118 &#176;, High Speed Steel &#150; Bright/Uncoated, Right Hand Cut\n\nitem # 89320733',
              Price: 26.67,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000383-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:13-08:00",
            EditSequence: 1580339413,
            Name: "TL-BIT-DRILL-SHORT-1764-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-1764-CNC-G",
            BarCodeValue: "NON-INVENTORY-48",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 53732517 17/64" LETTER H CARBIDE GUHRING MSC',
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000385-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:14-08:00",
            EditSequence: 1580339414,
            Name: "TL-BIT-DRILL-SHORT-2164-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-2164-CNC-G",
            BarCodeValue: "NON-INVENTORY-49",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 53732368 21/64" CARBIDE GUHRING MSC',
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000386-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:15-08:00",
            EditSequence: 1580339415,
            Name: "TL-BIT-DRILL-SHORT-2364-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-2364-CNC-G",
            BarCodeValue: "NON-INVENTORY-50",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 53732285 23/64" CARBIDE GUHRING MSC',
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000A43-1515786204",
            TimeCreated: "2018-01-12T11:43:24-08:00",
            TimeModified: "2020-01-29T15:10:22-08:00",
            EditSequence: 1580339422,
            Name: "TL-BIT-DRILL-SHORT-2564-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-2564-CNC-G",
            BarCodeValue: "NON-INVENTORY-51",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 53732194 25/64" CARBIDE GUHRING MSC',
              Price: 85.8,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000387-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:16-08:00",
            EditSequence: 1580339416,
            Name: "TL-BIT-DRILL-SHORT-5.22MM-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-5.22MM-CNC-G",
            BarCodeValue: "NON-INVENTORY-52",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "CATALOG ITEM 53732665 5.2MM CARBIDE GUHRING MSC",
              Price: 51.94,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000389-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:17-08:00",
            EditSequence: 1580339417,
            Name: "TL-BIT-DRILL-SHORT-532-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-532-CNC-G",
            BarCodeValue: "NON-INVENTORY-53",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 54147962 5/32" CARBIDE GUHRING MSC',
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000038A-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:19-08:00",
            EditSequence: 1580339419,
            Name: "TL-BIT-DRILL-SHORT-6MM-CNC-G",
            FullName: "CONSUMABLES:TL-BIT-DRILL-SHORT-6MM-CNC-G",
            BarCodeValue: "NON-INVENTORY-54",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "CATALOG ITEM 53732574 6MM CARBIDE GUHRING MSC",
              Price: 52.61,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000038B-1510599160",
            TimeCreated: "2017-11-13T10:52:40-08:00",
            TimeModified: "2020-01-29T15:10:20-08:00",
            EditSequence: 1580339420,
            Name: "TL-END MILL-18-12-CNC",
            FullName: "CONSUMABLES:TL-END MILL-18-12-CNC",
            BarCodeValue: "NON-INVENTORY-55",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 76683424,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 76683424\nProMax\n1/8" Diam, 1/4" LOC, 4 Flute Solid Carbide Roughing & Finishing Corner Radius End Mill\n\nPrevious Item 09-18-2018\nCATALOG ITEM 00592535\nAccupro - 1/8" Diam, 1/2" Length of Cut, 1/8" Shank Diam, 1-1/2" OAL, 3 Flute Solid Carbide Square End Mill --21.35',
              Price: 26.29,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000137C-1678736599",
            TimeCreated: "2023-03-13T12:43:19-08:00",
            TimeModified: "2023-03-13T12:43:19-08:00",
            EditSequence: 1678736599,
            Name: 'TL-END MILL-3/8" 4 FLUTE',
            FullName: 'CONSUMABLES:TL-END MILL-3/8" 4 FLUTE',
            BarCodeValue: "QB:01033918054988",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Niagara Cutter - Square End Mill: 3/8'' Dia, 1&#150;1/2'' LOC, 3/8'' Shank Dia, 3&#150;1/4'' OAL, 4 Flutes, Cobalt &#150; Single End, TiAlN Finish, Helical Flute, 30 &#176; Helix, RH Cut, RH Flute, Series REM445\n\nMSC Part#: 45534468",
              Price: 0,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80001138-1636570751",
            TimeCreated: "2021-11-10T10:59:11-08:00",
            TimeModified: "2021-11-10T15:45:04-08:00",
            EditSequence: 1636587904,
            Name: "TL-END MILL-316-3FL-AL-CNC",
            FullName: "CONSUMABLES:TL-END MILL-316-3FL-AL-CNC",
            BarCodeValue: "QB:01033918054408",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 76051366,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 00476606\nProMax\n3/16" Diam, 5/16" LOC, 3 Flute Solid Carbide Finishing End Mill',
              Price: 41.5,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "8000038C-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-01-29T15:10:21-08:00",
            EditSequence: 1580339421,
            Name: "TL-END MILL-316-916-CNC",
            FullName: "CONSUMABLES:TL-END MILL-316-916-CNC",
            BarCodeValue: "NON-INVENTORY-56",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            ManufacturerPartNumber: 76051366,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'CATALOG ITEM 76051366\nProMax\n3/16" Diam, 5/8" LOC, 4 Flute Solid Carbide Roughing & Finishing Corner Radius End Mill\n\nPrevious End Mill 09-18-2018\nCATALOG ITEM 00592576\nAccupro - 3/16" Diam, 9/16" Length of Cut, 3/16" Shank Diam, 2" OAL, 3 Flute Solid Carbide Square End Mill -- 24.03',
              Price: 41.5,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000A7E-1520008191",
            TimeCreated: "2018-03-02T08:29:51-08:00",
            TimeModified: "2020-01-29T15:10:23-08:00",
            EditSequence: 1580339423,
            Name: "TL-FELCO-C16/5-BLADE",
            FullName: "CONSUMABLES:TL-FELCO-C16/5-BLADE",
            BarCodeValue: "NON-INVENTORY-57",
            IsActive: true,
            ParentRef: {
              ListID: "80000354-1510599157",
              FullName: "CONSUMABLES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "C16/5 FELCO BENCH TOP REPLACEMENT BLADE\nORDER FROM LOOS",
              Price: 91.58,
              AccountRef: {
                ListID: "80000028-1510599157",
                FullName: "Warehouse/Shop Expense:Small Tools and Equipment",
              },
            },
          },
          {
            ListID: "80000C14-1557872068",
            TimeCreated: "2019-05-14T15:14:28-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "CORRUGATED BOXES",
            FullName: "CORRUGATED BOXES",
            BarCodeValue: "CATEGORY-44",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C2E-1557875297",
            TimeCreated: "2019-05-14T16:08:17-08:00",
            TimeModified: "2022-06-15T08:43:46-08:00",
            EditSequence: 1655307826,
            Name: "10x10x60",
            FullName: "CORRUGATED BOXES:10x10x60",
            BarCodeValue: "NON-INVENTORY-59",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n254x254x1524\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 2.131,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C25-1557874070",
            TimeCreated: "2019-05-14T15:47:50-08:00",
            TimeModified: "2022-06-15T08:51:22-08:00",
            EditSequence: 1655308282,
            Name: "10x4x4",
            FullName: "CORRUGATED BOXES:10x4x4",
            BarCodeValue: "NON-INVENTORY-61",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R69 / 25 BUNDLE\n254x102x102\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.37,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C16-1557872226",
            TimeCreated: "2019-05-14T15:17:06-08:00",
            TimeModified: "2022-06-15T08:50:14-08:00",
            EditSequence: 1655308214,
            Name: '12x12x6"',
            FullName: 'CORRUGATED BOXES:12x12x6"',
            BarCodeValue: "NON-INVENTORY-63",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R19 / 25 BUNDLE\n305x305x152\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.69,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C2D-1557875248",
            TimeCreated: "2019-05-14T16:07:28-08:00",
            TimeModified: "2022-06-15T08:54:30-08:00",
            EditSequence: 1655308470,
            Name: '12x8x6"',
            FullName: 'CORRUGATED BOXES:12x8x6"',
            BarCodeValue: "NON-INVENTORY-64",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R75 / 25 BUNDLE\n305x203x152\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.51,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C32-1557876674",
            TimeCreated: "2019-05-14T16:31:14-08:00",
            TimeModified: "2022-06-15T08:56:02-08:00",
            EditSequence: 1655308562,
            Name: '12x9x3"',
            FullName: 'CORRUGATED BOXES:12x9x3"',
            BarCodeValue: "NON-INVENTORY-65",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R133 / 25 BUNDLE\n305x229x76\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.54,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C28-1557874248",
            TimeCreated: "2019-05-14T15:50:48-08:00",
            TimeModified: "2022-06-15T08:56:48-08:00",
            EditSequence: 1655308608,
            Name: '16x16x3"',
            FullName: 'CORRUGATED BOXES:16x16x3"',
            BarCodeValue: "NON-INVENTORY-66",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n406x406x76\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.855,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C22-1557873812",
            TimeCreated: "2019-05-14T15:43:32-08:00",
            TimeModified: "2022-06-15T08:58:42-08:00",
            EditSequence: 1655308722,
            Name: '18x18x4"',
            FullName: 'CORRUGATED BOXES:18x18x4"',
            BarCodeValue: "NON-INVENTORY-67",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R214 / 25 BUNDLE\n457x457x102\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.34,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C1A-1557872507",
            TimeCreated: "2019-05-14T15:21:47-08:00",
            TimeModified: "2022-06-15T09:00:16-08:00",
            EditSequence: 1655308816,
            Name: '20x20x4"',
            FullName: 'CORRUGATED BOXES:20x20x4"',
            BarCodeValue: "NON-INVENTORY-68",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R109 / 20 BUNDLE\n508x508x102\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.66,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C1B-1557872700",
            TimeCreated: "2019-05-14T15:25:00-08:00",
            TimeModified: "2022-06-15T09:01:49-08:00",
            EditSequence: 1655308909,
            Name: '20x20x6"',
            FullName: 'CORRUGATED BOXES:20x20x6"',
            BarCodeValue: "NON-INVENTORY-69",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R563 / 20 BUNDLE\n508x508x152\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.66,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C1C-1557872928",
            TimeCreated: "2019-05-14T15:28:48-08:00",
            TimeModified: "2022-06-15T09:03:00-08:00",
            EditSequence: 1655308980,
            Name: '20x20x8"',
            FullName: 'CORRUGATED BOXES:20x20x8"',
            BarCodeValue: "NON-INVENTORY-70",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R712 / 15 BUNDLE\n508x508x203\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.73,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C27-1557874193",
            TimeCreated: "2019-05-14T15:49:53-08:00",
            TimeModified: "2022-06-15T09:03:32-08:00",
            EditSequence: 1655309012,
            Name: '20x8x4"',
            FullName: 'CORRUGATED BOXES:20x8x4"',
            BarCodeValue: "NON-INVENTORY-71",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n508x203x102\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.965,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C18-1557872390",
            TimeCreated: "2019-05-14T15:19:50-08:00",
            TimeModified: "2022-06-15T09:04:23-08:00",
            EditSequence: 1655309063,
            Name: '26x6x2"',
            FullName: 'CORRUGATED BOXES:26x6x2"',
            BarCodeValue: "NON-INVENTORY-72",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n660x152x51\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.486,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C33-1558365590",
            TimeCreated: "2019-05-20T08:19:50-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: '4"x4"x2" Mailer',
            FullName: 'CORRUGATED BOXES:4"x4"x2" Mailer',
            BarCodeValue: "NON-INVENTORY-73",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "WHITE\n101x101x51\nSpecification: \nDouble Face / Single Wall / B-Flute / Full Over Lap sslotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.734,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C21-1557873750",
            TimeCreated: "2019-05-14T15:42:30-08:00",
            TimeModified: "2022-06-15T09:04:47-08:00",
            EditSequence: 1655309087,
            Name: '44x12x12"',
            FullName: 'CORRUGATED BOXES:44x12x12"',
            BarCodeValue: "NON-INVENTORY-74",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n1118x305x305\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 2.231,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C30-1557876271",
            TimeCreated: "2019-05-14T16:24:31-08:00",
            TimeModified: "2022-06-15T09:07:24-08:00",
            EditSequence: 1655309244,
            Name: '48x62" PAD',
            FullName: 'CORRUGATED BOXES:48x62" PAD',
            BarCodeValue: "NON-INVENTORY-75",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ERNEST\n48\" x 62'\nMOQ 1.5M / NO FREIGHT / 4 WEEK LEAD TIME\nSpecification: \nDouble Face / Single Wall / B-Flute / Meet or exceed 200lbs per Sq Inch",
              Price: 2.35,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C2B-1557875099",
            TimeCreated: "2019-05-14T16:04:59-08:00",
            TimeModified: "2022-06-15T09:07:52-08:00",
            EditSequence: 1655309272,
            Name: '50x12x12"',
            FullName: 'CORRUGATED BOXES:50x12x12"',
            BarCodeValue: "NON-INVENTORY-77",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n1270x305x305\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 3.618,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C1E-1557873564",
            TimeCreated: "2019-05-14T15:39:24-08:00",
            TimeModified: "2022-06-15T09:09:18-08:00",
            EditSequence: 1655309358,
            Name: '5x5x3"',
            FullName: 'CORRUGATED BOXES:5x5x3"',
            BarCodeValue: "NON-INVENTORY-78",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R1015 / 25 BUNDLE\n127x127x76\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.3,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C1D-1557873332",
            TimeCreated: "2019-05-14T15:35:32-08:00",
            TimeModified: "2022-06-15T09:10:35-08:00",
            EditSequence: 1655309435,
            Name: '5x5x5"',
            FullName: 'CORRUGATED BOXES:5x5x5"',
            BarCodeValue: "NON-INVENTORY-79",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R74 / 25 BUNDLE\n127x127x127\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.28,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C29-1557874977",
            TimeCreated: "2019-05-14T16:02:57-08:00",
            TimeModified: "2022-06-15T09:10:59-08:00",
            EditSequence: 1655309459,
            Name: '60x12x12"',
            FullName: 'CORRUGATED BOXES:60x12x12"',
            BarCodeValue: "NON-INVENTORY-80",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n1524x305x305\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 4.187,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C17-1557872291",
            TimeCreated: "2019-05-14T15:18:11-08:00",
            TimeModified: "2022-06-15T09:12:16-08:00",
            EditSequence: 1655309536,
            Name: '6x6x4"',
            FullName: 'CORRUGATED BOXES:6x6x4"',
            BarCodeValue: "NON-INVENTORY-81",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R328 / 25 BUNDLE\n152x102x102\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.27,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C19-1557872455",
            TimeCreated: "2019-05-14T15:20:55-08:00",
            TimeModified: "2022-06-15T09:12:30-08:00",
            EditSequence: 1655309550,
            Name: '6x6x48"-90" Telescopic',
            FullName: 'CORRUGATED BOXES:6x6x48"-90" Telescopic',
            BarCodeValue: "NON-INVENTORY-82",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n152x152x1219-2286\nSpecification: \nDouble Face / Single Wall / B-Flute / Meet or exceed 200lbs per Sq Inch",
              Price: 1.139,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C2A-1557875040",
            TimeCreated: "2019-05-14T16:04:00-08:00",
            TimeModified: "2022-06-15T09:13:41-08:00",
            EditSequence: 1655309621,
            Name: '6x6x6"',
            FullName: 'CORRUGATED BOXES:6x6x6"',
            BarCodeValue: "NON-INVENTORY-83",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "PCA / R6 / 25 BUNDLE\n152x152x152\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.32,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C31-1557876599",
            TimeCreated: "2019-05-14T16:29:59-08:00",
            TimeModified: "2022-06-15T09:14:04-08:00",
            EditSequence: 1655309644,
            Name: "6x6x60",
            FullName: "CORRUGATED BOXES:6x6x60",
            BarCodeValue: "NON-INVENTORY-84",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n152x152x1524\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C26-1557874129",
            TimeCreated: "2019-05-14T15:48:49-08:00",
            TimeModified: "2022-06-15T09:14:32-08:00",
            EditSequence: 1655309672,
            Name: '6x6x72"',
            FullName: 'CORRUGATED BOXES:6x6x72"',
            BarCodeValue: "NON-INVENTORY-85",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n152x152x1829\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.754,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C15-1557872167",
            TimeCreated: "2019-05-14T15:16:07-08:00",
            TimeModified: "2022-06-15T09:15:03-08:00",
            EditSequence: 1655309703,
            Name: '8x8x40"',
            FullName: 'CORRUGATED BOXES:8x8x40"',
            BarCodeValue: "NON-INVENTORY-86",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n203x203x1016\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.317,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C23-1557873945",
            TimeCreated: "2019-05-14T15:45:45-08:00",
            TimeModified: "2022-06-15T09:15:14-08:00",
            EditSequence: 1655309714,
            Name: '8x8x48"-90" Telescopic',
            FullName: 'CORRUGATED BOXES:8x8x48"-90" Telescopic',
            BarCodeValue: "NON-INVENTORY-87",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n203x203x1219\nSpecification: \nDouble Face / Single Wall / B-Flute / Meet or exceed 200lbs per Sq Inch",
              Price: 1.53,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C20-1557873691",
            TimeCreated: "2019-05-14T15:41:31-08:00",
            TimeModified: "2022-06-15T09:15:38-08:00",
            EditSequence: 1655309738,
            Name: '8x8x60"',
            FullName: 'CORRUGATED BOXES:8x8x60"',
            BarCodeValue: "NON-INVENTORY-88",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "ULINE\n203x203x1524\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 1.849,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C2C-1557875183",
            TimeCreated: "2019-05-14T16:06:23-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "9 1/4x3x6 3/4 W",
            FullName: "CORRUGATED BOXES:9 1/4x3x6 3/4 W",
            BarCodeValue: "NON-INVENTORY-89",
            IsActive: true,
            ParentRef: {
              ListID: "80000C14-1557872068",
              FullName: "CORRUGATED BOXES",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "WHITE\n235x76x172\nSpecification: \nDouble Face / Single Wall / B-Flute / Regular Slotted Carton / Meet or exceed 200lbs per Sq Inch",
              Price: 0.24,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "8000038D-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-01-29T15:43:34-08:00",
            EditSequence: 1580341414,
            Name: "CRIMPER/CUTTER RENTALS",
            FullName: "CRIMPER/CUTTER RENTALS",
            BarCodeValue: "CATEGORY-45",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000026-1510599121",
                FullName: "SC&R - Sales:04 Tool Rental Income",
              },
            },
          },
          {
            ListID: "8000038E-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-01-29T15:20:54-08:00",
            EditSequence: 1580340054,
            Name: '!Tool Rental FEE 1/8"',
            FullName: 'CRIMPER/CUTTER RENTALS:!Tool Rental FEE 1/8"',
            BarCodeValue: "NON-INVENTORY-91",
            IsActive: true,
            ParentRef: {
              ListID: "8000038D-1510599161",
              FullName: "CRIMPER/CUTTER RENTALS",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '1/8" TOOL RENTAL FEE - INCLUDES BOX FOR RETURN',
              Price: 50,
              AccountRef: {
                ListID: "80000026-1510599121",
                FullName: "SC&R - Sales:04 Tool Rental Income",
              },
            },
          },
          {
            ListID: "8000038F-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-01-29T15:20:55-08:00",
            EditSequence: 1580340055,
            Name: '!Tool Rental FEE 3/16"',
            FullName: 'CRIMPER/CUTTER RENTALS:!Tool Rental FEE 3/16"',
            BarCodeValue: "NON-INVENTORY-92",
            IsActive: true,
            ParentRef: {
              ListID: "8000038D-1510599161",
              FullName: "CRIMPER/CUTTER RENTALS",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '3/16" TOOL RENTAL FEE - INCLUDES BOX FOR RETURN',
              Price: 50,
              AccountRef: {
                ListID: "80000026-1510599121",
                FullName: "SC&R - Sales:04 Tool Rental Income",
              },
            },
          },
          {
            ListID: "80000D25-1592239761",
            TimeCreated: "2020-06-15T09:49:21-08:00",
            TimeModified: "2021-01-15T16:27:59-08:00",
            EditSequence: 1610756879,
            Name: "CUSTOM PART-SEE DESCRIPTION",
            FullName: "CUSTOM PART-SEE DESCRIPTION",
            BarCodeValue: "QB:01033918053365",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "80000E91-1616091771",
            TimeCreated: "2021-03-18T11:22:51-08:00",
            TimeModified: "2021-03-18T11:22:51-08:00",
            EditSequence: 1616091771,
            Name: "Damaged Returned Goods",
            FullName: "Damaged Returned Goods",
            BarCodeValue: "QB:01033918053729",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000137-1616091331",
                FullName: "SC&R - Sales:06 Sales Returns & Allowances",
              },
            },
          },
          {
            ListID: "80000390-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2022-02-18T19:25:55-08:00",
            EditSequence: 1645241155,
            Name: "HW-HexNut-M8-Grinding",
            FullName: "HW-HexNut-M8-Grinding",
            BarCodeValue: "NON-INVENTORY-114",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "M8  HN METRIC STNLS 1.25 DIN 934 A/2",
              Price: 0.25,
              AccountRef: {
                ListID: "8000016B-1645238628",
                FullName: "SC&R - Sales:02 Service Income",
              },
            },
          },
          {
            ListID: "80001315-1654894958",
            TimeCreated: "2022-06-10T14:02:38-08:00",
            TimeModified: "2022-06-10T14:02:38-08:00",
            EditSequence: 1654894958,
            Name: "LIGHTING",
            FullName: "LIGHTING",
            BarCodeValue: "QB:01033918054885",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000002-1510596446", FullName: "Non" },
            SalesOrPurchase: {
              Desc: "TECH LIGHTING",
              Price: 0,
              AccountRef: {
                ListID: "80000132-1612303326",
                FullName: "Fixed Assets:07 Leasehold Improvements SC&R",
              },
            },
          },
          {
            ListID: "80000E92-1616091812",
            TimeCreated: "2021-03-18T11:23:32-08:00",
            TimeModified: "2021-03-18T11:23:52-08:00",
            EditSequence: 1616091832,
            Name: "Non-Damaged Returned Goods",
            FullName: "Non-Damaged Returned Goods",
            BarCodeValue: "QB:01033918053730",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000002B-1679350852",
              FullName: "Each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000137-1616091331",
                FullName: "SC&R - Sales:06 Sales Returns & Allowances",
              },
            },
          },
          {
            ListID: "80000393-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "Packaging Supplies",
            FullName: "Packaging Supplies",
            BarCodeValue: "CATEGORY-46",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000394-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "4' x 8' x 1\" MDF",
            FullName: "Packaging Supplies:4' x 8' x 1\" MDF",
            BarCodeValue: "NON-INVENTORY-95",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "4' x 8' x 1\" MDF",
              Price: 59.95,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80001337-1656372060",
            TimeCreated: "2022-06-27T16:21:00-08:00",
            TimeModified: "2022-06-27T16:32:00-08:00",
            EditSequence: 1656372720,
            Name: "48x62 200#",
            FullName: "Packaging Supplies:48x62 200#",
            BarCodeValue: "QB:01033918054919",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Sheet/Pad\n200 C BROWN\n3000 $2,210.00",
              Price: 0.73667,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000397-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2021-03-18T08:55:24-08:00",
            EditSequence: 1616082924,
            Name: '5"x 247" -.375 SHIP TUBE',
            FullName: 'Packaging Supplies:5"x 247" -.375 SHIP TUBE',
            BarCodeValue: "NON-INVENTORY-96",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '5" ID x 247" -.375 SHIP TUBE\nMICRYL COATED',
              Price: 34.69,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000398-1510599161",
            TimeCreated: "2017-11-13T10:52:41-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: '5"-.375 MDF END CAP',
            FullName:
              'Packaging Supplies:5"x 247" -.375 SHIP TUBE:5"-.375 MDF END CAP',
            BarCodeValue: "NON-INVENTORY-97",
            IsActive: true,
            ParentRef: {
              ListID: "80000397-1510599161",
              FullName: 'Packaging Supplies:5"x 247" -.375 SHIP TUBE',
            },
            Sublevel: 2,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '5" MDF END CAP FOR .375 WALL',
              Price: 3.5,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "8000039D-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2021-03-18T08:55:43-08:00",
            EditSequence: 1616082943,
            Name: '7"x 247" -.375 SHIP TUBE',
            FullName: 'Packaging Supplies:7"x 247" -.375 SHIP TUBE',
            BarCodeValue: "NON-INVENTORY-98",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '7" ID x 247" -.375 SHIP TUBE\nMICRYL COATED',
              Price: 41.5,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "8000039E-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: '7"-.375 MDF END CAP',
            FullName:
              'Packaging Supplies:7"x 247" -.375 SHIP TUBE:7"-.375 MDF END CAP',
            BarCodeValue: "NON-INVENTORY-99",
            IsActive: true,
            ParentRef: {
              ListID: "8000039D-1510599162",
              FullName: 'Packaging Supplies:7"x 247" -.375 SHIP TUBE',
            },
            Sublevel: 2,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '7" MDF END CAP FOR .375 WALL',
              Price: 3.5,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "8000039F-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2021-03-18T08:56:00-08:00",
            EditSequence: 1616082960,
            Name: '8" x 247" -.375 SHIP TUBE',
            FullName: 'Packaging Supplies:8" x 247" -.375 SHIP TUBE',
            BarCodeValue: "NON-INVENTORY-100",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '8" ID x 247" -.375 SHIP TUBE\nMICRYL COATED',
              Price: 47.95,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A0-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: '8"-.375 MDF END CAP',
            FullName:
              'Packaging Supplies:8" x 247" -.375 SHIP TUBE:8"-.375 MDF END CAP',
            BarCodeValue: "NON-INVENTORY-101",
            IsActive: true,
            ParentRef: {
              ListID: "8000039F-1510599162",
              FullName: 'Packaging Supplies:8" x 247" -.375 SHIP TUBE',
            },
            Sublevel: 2,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: '8" MDF END CAP FOR .375 WALL',
              Price: 3.5,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A1-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "BUBBLE",
            FullName: "Packaging Supplies:BUBBLE",
            BarCodeValue: "NON-INVENTORY-102",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "NB48H250S24P12",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "NB48H250S24P12\nNon Bar Bbl 48x1/2x250 S=24 P=12 250'/Bdl Clr 1/Bd-STOCK\nor order from Z-Pac\nBUBBLE-JL-24/12\nNote: Sold per bundle of 2 rolls\n28.05 per roll\nPriced and sold per bundle\nZ-PAC $41.50\nEARNEST $28.05",
              Price: 28.05,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A3-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "FILAMENT TAPE",
            FullName: "Packaging Supplies:FILAMENT TAPE",
            BarCodeValue: "NON-INVENTORY-103",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "SHUF#GS490",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "GS490260\nFilament Tape SHUF #GS490 sx60 Nat Polypro 24/64\n100 Lbs/in - STOCK",
              Price: 3.63,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A4-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "FOAM-48x3/32x600",
            FullName: "Packaging Supplies:FOAM-48x3/32x600",
            BarCodeValue: "NON-INVENTORY-104",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "F483T600S24P12WStd PE Foam 48x3/32x600 S=24 P=12 Wh - DOCK\nNote: Sold per bundle of 2 rolls\n$80.17 per roll\nPriced and sold per bundle\nULINE $89.00 + FREIGHT\nEARNEST $80.175 + FREE SHIP",
              Price: 80.17,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A5-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "KRAFT PAPER-36",
            FullName: "Packaging Supplies:KRAFT PAPER-36",
            BarCodeValue: "NON-INVENTORY-105",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "IKP3660",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'IKP3660\nMisc Rls 36"/60# Kraft x 360\' Indented\n1 / rl 3" Core-STOCK',
              Price: 37.5,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A6-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "PACKING TAPE",
            FullName: "Packaging Supplies:PACKING TAPE",
            BarCodeValue: "NON-INVENTORY-106",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "CST#058085",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "0580852110\nCST#058085 2x110 Clr 2.6mil PL Acryl 36 Rls/Cs - STOCK",
              Price: 2.32,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000D96-1599170055",
            TimeCreated: "2020-09-03T14:54:15-08:00",
            TimeModified: "2020-09-03T14:54:15-08:00",
            EditSequence: 1599170055,
            Name: 'PALLET 48" x 48"',
            FullName: 'Packaging Supplies:PALLET 48" x 48"',
            BarCodeValue: "QB:01033918053478",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: 'PALLET 48" x 48"',
              Price: 7,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000D27-1593029879",
            TimeCreated: "2020-06-24T13:17:59-08:00",
            TimeModified: "2021-10-11T13:11:04-08:00",
            EditSequence: 1633983064,
            Name: "PALLET 52' x 48'",
            FullName: "Packaging Supplies:PALLET 52' x 48'",
            BarCodeValue: "QB:01033918053367",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "Preferred Vendor: Camila's Pallets\nAlternate Vendor: Hardwood Pallets\n\nPALLET 52' x 48' 2-WAY\nClose Board Spacing (Same as Eglass)\nStandard Boards OK",
              Price: 20,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "800003A7-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-02-07T19:15:15-08:00",
            EditSequence: 1581131715,
            Name: "REINFORCED GUM TAPE",
            FullName: "Packaging Supplies:REINFORCED GUM TAPE",
            BarCodeValue: "NON-INVENTORY-107",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            ManufacturerPartNumber: "INT#235",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "23572450\nReinf Gum Tape INT#235 72mmx450\nGSI Nat 10 / 60-STOCK",
              Price: 64.42,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000C0C-1554829937",
            TimeCreated: "2019-04-09T10:12:17-08:00",
            TimeModified: "2022-06-09T08:30:54-08:00",
            EditSequence: 1654788654,
            Name: "SHRINK WRAP",
            FullName: "Packaging Supplies:SHRINK WRAP",
            BarCodeValue: "NON-INVENTORY-108",
            IsActive: true,
            ParentRef: {
              ListID: "80000393-1510599161",
              FullName: "Packaging Supplies",
            },
            Sublevel: 1,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "20X5000' 90GA/20.3M STRFLEX SF1 STRETCH FILM CAST 40RL/PL 10720\nOR EQUIVALENT",
              Price: 57.43,
              AccountRef: {
                ListID: "800000F7-1581111154",
                FullName: "Shipping Expense:Packaging Materials Expense",
              },
            },
          },
          {
            ListID: "80000E93-1617045197",
            TimeCreated: "2021-03-29T12:13:17-08:00",
            TimeModified: "2021-03-29T12:13:17-08:00",
            EditSequence: 1617045197,
            Name: "Portland Warehouse Sub-lease",
            FullName: "Portland Warehouse Sub-lease",
            BarCodeValue: "QB:01033918053731",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000013-1510596455",
                FullName: "Rent Expense:Portland Warehouse Rent",
              },
            },
          },
          {
            ListID: "80000A42-1515784415",
            TimeCreated: "2018-01-12T11:13:35-08:00",
            TimeModified: "2020-02-07T19:12:10-08:00",
            EditSequence: 1581131530,
            Name: "Q-Railing Direct Ship",
            FullName: "Q-Railing Direct Ship",
            BarCodeValue: "NON-INVENTORY-115",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesAndPurchase: {
              SalesDesc: "Item(s) shipped directly from Q-Railing to customer",
              SalesPrice: 0,
              IncomeAccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
              PurchaseCost: 0,
              ExpenseAccountRef: {
                ListID: "800000F5-1581110941",
                FullName: "Shipping Expense:Outbound Shipping Expense",
              },
              PrefVendorRef: {
                ListID: "80000020-1510599046",
                FullName: "Q-Railing",
              },
            },
          },
          {
            ListID: "800003A8-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-05-07T19:51:19-08:00",
            EditSequence: 1588906279,
            Name: "SAMPLES",
            FullName: "SAMPLES",
            BarCodeValue: "SAMPLES",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "QTY 1 OF EACH COLOR TOP RAIL SAMPLES.\nQTY 1 OF EACH COLOR POST SAMPLES.\nQTY 1 OF EACH COLOR POST-TO-POST SAMPLES.\nQTY 1 OF EACH CABLE SAMPLES.\n\nThank you for your interest in Stainless Cable & Railing, Inc products!!!  We've put together a samples package based on your interests.  Please let us know when you're ready for us to work up a complete quote for your project.",
              Price: 0,
              AccountRef: {
                ListID: "800000EB-1580694086",
                FullName: "Professional Fees:Professional Fees - Engineering",
              },
            },
          },
          {
            ListID: "800003A9-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-01-30T10:21:58-08:00",
            EditSequence: 1580408518,
            Name: "SCRAP-ALUMINUM POST",
            FullName: "SCRAP-ALUMINUM POST",
            BarCodeValue: "SCRAP-1",
            IsActive: true,
            Sublevel: 0,
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "2014\n\nAluminum Posts - FM Terminal: 195 ea $4739\nAluminum Posts - FM Intermediate: 242 ea $4961\n\nTop Rail - Rectangular: 61 stk $5002\nTop Rail - Shaped: 11 stk $671\nSnap Cover: 7 stk $210\nPost to Post: 32.5 stk $1920\nRAW Intermediate: 65 ft $2665\nRAW Terminal: 30 ft $2250\n\nStainless Steel Round: 16 stk $1072\nStainless Steel Square Teminal: 3.25 stk $1040\nStainless Steel Square Intermediate: 2.5 stk $325",
              Price: 24855,
              AccountRef: {
                ListID: "80000025-1510598965",
                FullName: "SC&R COGs:01 Material COGS",
              },
            },
          },
          {
            ListID: "800003AC-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-01-29T15:43:40-08:00",
            EditSequence: 1580341420,
            Name: "TOOLS",
            FullName: "TOOLS",
            BarCodeValue: "CATEGORY-49",
            IsActive: true,
            Sublevel: 0,
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Price: 0,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
          {
            ListID: "800003AE-1510599162",
            TimeCreated: "2017-11-13T10:52:42-08:00",
            TimeModified: "2020-01-29T15:43:41-08:00",
            EditSequence: 1580341421,
            Name: "Match-Finish Sander",
            FullName: "TOOLS:Match-Finish Sander",
            BarCodeValue: "NON-INVENTORY-112",
            IsActive: true,
            ParentRef: { ListID: "800003AC-1510599162", FullName: "TOOLS" },
            Sublevel: 1,
            ManufacturerPartNumber: "051141-28659",
            UnitOfMeasureSetRef: {
              ListID: "8000000B-1510857715",
              FullName: "Count in each",
            },
            SalesTaxCodeRef: { ListID: "80000001-1510596446", FullName: "Tax" },
            SalesOrPurchase: {
              Desc: "051141-28659\n3M MATCH & FINISH SANDER 4 x 5/8-11 TOOL ONLY- NO EXPANDER WHEEL",
              Price: 757.6,
              AccountRef: {
                ListID: "80000020-1510598964",
                FullName: "SC&R - Sales:01 Merchandise Sales",
              },
            },
          },
        ],
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default nonInventory;
