import { CustomerRet } from "../data/parsers/customerParser";

export const getCustomerName = (customer: {
  data: any;
  quickbooksData: CustomerRet;
  version: string;
  name: string | undefined;
}) => {
  if (customer.version === "1") {
    if (customer.name) {
      return customer.name;
    }

    if (customer?.data?.name) {
      return customer.data.name;
    }
  }

  if (customer.quickbooksData.FullName) {
    return customer.quickbooksData.FullName.toString() || "";
  }

  if (Object.keys(customer.data).length) {
    if (customer.data.companyName) {
      return (customer.data.companyName || "") as string;
    } else {
      return `${customer.data.firstName || ""} ${
        customer.data.lastName || ""
      }`.trim();
    }
  } else {
    if (customer.quickbooksData.FullName) {
      return customer.quickbooksData.FullName.toString() || "";
    } else {
      return `${customer.quickbooksData.FirstName || ""} ${
        customer.quickbooksData.LastName || ""
      }`.trim();
    }
  }
};

export const formatCustomerPhone = (phone: string | number) => {
  if (typeof phone === "number") {
    return phone.toString();
  }

  if (!phone || !phone.endsWith) {
    return "";
  }

  if (phone.endsWith(" ext: ______")) {
    return phone.replace(" ext: ______", "");
  }

  if (phone.endsWith("_")) {
    return phone.replace(/_+/, "");
  }

  return phone;
};
