// @ts-nocheck
import { Map, OrderedMap } from "immutable";
import { cornerDistance } from "../draw";
import { ProjectSettings, ProjectSettingsKeys, Run, Stairs } from "../entities";
import { getCustomerName } from "../entities/utils";
import { getPosts } from "./getPosts";

export function getDateValues() {
  const dateObj = new Date();
  const pacificTime = dateObj.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });

  const dateObjPacific = new Date(pacificTime);

  const month = dateObjPacific.getMonth() + 1; //months from 1-12
  const day = dateObjPacific.getDate();
  const year = dateObjPacific.getFullYear();

  return {
    month,
    day,
    year,
  };
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const pixelsPerFoot = function () {
  return 24;
};

export const pixelsPerInch = function () {
  return 2;
};

export const distanceInFeet = function (coordinates: {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}) {
  const { feet, inches } = distanceInFeetObject(coordinates);

  return Math.floor(feet) + "' " + inches + '"';
};

export const cornerHash = (corner) => {
  return Map(corner.points)
    .map((value) => Map(value))
    .hashCode()
    .toString();
};

export const distanceInFeetObject = function (coordinates: {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}) {
  const { x1, y1, x2, y2 } = coordinates;

  let distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  return distanceInFeetObjectFromDistance(distance);
};

export function distanceInFeetObjectFromDistance(distance: number) {
  if (distance % 1 === 0 && distance % 2 !== 0) {
    // Make distance even for calculating inches.
    distance++;
  }

  let distanceInFeet = distance / pixelsPerFoot();
  const remainder = distanceInFeet % 1;

  let inches = Math.round(remainder * 12);

  if (inches === 12) {
    inches = 0;
    distanceInFeet = distanceInFeet + 1;
  }

  return { feet: Math.floor(distanceInFeet), inches: inches };
}

export const distanceInFeetFromDistance = (distance: number) => {
  if (distance % 1 === 0 && distance % 2 !== 0) {
    // Make distance even for calculating inches.
    distance++;
  }

  let distanceInFeet = distance / pixelsPerFoot();
  const remainder = distanceInFeet % 1;

  let inches = Math.round(remainder * 12);

  if (inches === 12) {
    inches = 0;
    distanceInFeet = distanceInFeet + 1;
  }

  return Math.floor(distanceInFeet) + inches * (1 / 12);
};

export function calculateAngleOfTwoLines(
  A1x: number,
  A1y: number,
  A2x: number,
  A2y: number,
  B1x: number,
  B1y: number,
  B2x: number,
  B2y: number
) {
  var dAx = A2x - A1x;
  var dAy = A2y - A1y;
  var dBx = B2x - B1x;
  var dBy = B2y - B1y;
  var angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);

  var degree_angle = angle * (180 / Math.PI);
  return degree_angle;
}

export function getDollarAmount(amount: number) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(amount).replace("$", "");
}

export function isRunSettingsEmpty(obj: object) {
  if (!obj) {
    return true;
  }

  if (Map.isMap(obj)) {
    return obj.size === 0;
  } else {
    return Object.keys(obj).length === 0;
  }
}

function setRunSetting(
  runSettings: ProjectSettings,
  run: Run,
  property: ProjectSettingsKeys
) {
  if (
    (run.settings as any)[property] ||
    (Map.isMap(run.settings) && run.settings.has(property))
  ) {
    if (Map.isMap(run.settings)) {
      runSettings = runSettings.set(
        property,
        (run.settings as any).get(property)
      );
    } else {
      runSettings = runSettings.set(property, run.settings[property]);
    }
  }

  return runSettings;
}

export function getRunSettings(run: Run, settings: ProjectSettings) {
  let runSettings = settings;

  if (run.settings) {
    runSettings = setRunSetting(runSettings, run, "toprailMaterial");
    runSettings = setRunSetting(runSettings, run, "aluminumToprailType");
    runSettings = setRunSetting(runSettings, run, "woodToprailType");
    runSettings = setRunSetting(runSettings, run, "woodToprailSize");
    runSettings = setRunSetting(runSettings, run, "woodToprailSetup");
    runSettings = setRunSetting(runSettings, run, "woodAlumP2PStairsSetup");
    runSettings = setRunSetting(runSettings, run, "railHeight");
    runSettings = setRunSetting(runSettings, run, "postSpacing");
    runSettings = setRunSetting(runSettings, run, "stainlessSteelToprailType");
    runSettings = setRunSetting(runSettings, run, "stainlessPostShape");
    runSettings = setRunSetting(runSettings, run, "mountStyle");
    runSettings = setRunSetting(runSettings, run, "ponyWallSize");
    runSettings = setRunSetting(runSettings, run, "fasciaBracketType");
    runSettings = setRunSetting(runSettings, run, "postMaterial");
    runSettings = setRunSetting(runSettings, run, "customPostSpacing");
    runSettings = setRunSetting(runSettings, run, "customRailHeight");
    // Custom option only for runs.
    runSettings = setRunSetting(runSettings, run, "doubleTensioner");
  }

  return runSettings;
}

export function mergeSettings(
  settings: ProjectSettings,
  runSettings: ProjectSettings
) {
  settings = settings.mergeDeep(runSettings);

  return settings;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function findProductByFullName(fullName, inventory) {
  if (inventory && inventory.find) {
    return inventory.find((item) => fullName === item.FullName);
  }

  return null;
}

export function railingHeightFactors() {
  const factors = {
    36: 11,
    42: 13,
    custom: 11,
  };

  return factors;
}

export function getUnitOfMeasure(QBUnitOfMeasure) {
  const measures = {
    "Count in each": "ea",
  };

  if (measures[QBUnitOfMeasure]) {
    return measures[QBUnitOfMeasure];
  }

  return "ea";
}

export function getAddressForAvatax(customer, settings) {
  if (settings && settings.shipToAddress && settings.shipToAddress.address) {
    const address = settings.shipToAddress.address;
    return {
      line1: getCustomerName(customer),
      line2: address.street,
      city: address.city,
      region: address.state,
      country: "US",
      postalCode: address.zip,
    };
  }

  if (
    customer.data &&
    customer.data.shipping &&
    (customer.data.shipping.street ||
      customer.data.shipping.city ||
      customer.data.shipping.state ||
      customer.data.shipping.zip)
  ) {
    return {
      line1: getCustomerName(customer),
      line2: customer.data.shipping.street,
      city: customer.data.shipping.city,
      region: customer.data.shipping.state,
      country: customer.data.shipping.country || "US",
      postalCode: customer.data.shipping.zip,
    };
  }

  if (customer.quickbooksData && customer.quickbooksData.ShipAddress) {
    return {
      line1: customer.quickbooksData.ShipAddress.Addr1,
      line2: customer.quickbooksData.ShipAddress.Addr2,
      city: customer.quickbooksData.ShipAddress.City,
      region: customer.quickbooksData.ShipAddress.State,
      country: customer.quickbooksData.ShipAddress.Country || "US",
      postalCode: customer.quickbooksData.ShipAddress.PostalCode,
    };
  }

  if (
    customer.data &&
    customer.data.billing &&
    (customer.data.billing.street ||
      customer.data.billing.city ||
      customer.data.billing.state ||
      customer.data.billing.zip)
  ) {
    return {
      line1: getCustomerName(customer),
      line2: customer.data.billing.street,
      city: customer.data.billing.city,
      region: customer.data.billing.state,
      country: customer.data.billing.country || "US",
      postalCode: customer.data.billing.zip,
    };
  }

  if (customer.quickbooksData && customer.quickbooksData.BillAddress) {
    return {
      line1: customer.quickbooksData.BillAddress.Addr1,
      line2: customer.quickbooksData.BillAddress.Addr2,
      city: customer.quickbooksData.BillAddress.City,
      region: customer.quickbooksData.BillAddress.State,
      country: customer.quickbooksData.BillAddress.Country || "US",
      postalCode: customer.quickbooksData.BillAddress.PostalCode,
    };
  }

  return false;
}

export const roundToHundreth = (number: number) => {
  return Math.round(number * 100) / 100;
};

export function getYearMonthDay(date) {
  return new Date(date).toLocaleDateString("en-US", {
    timeZone: "America/Los_Angeles",
  });
}

export function projectEstimateNumber(project) {
  return "#" + (project.projectEstimate + 200001);
}

export function getDateString(date) {
  function ampm(hours) {
    if (hours < 12) {
      return "am";
    } else {
      return "pm";
    }
  }

  function suffix(day) {
    if (1 === day || 21 === day || 31 === day) {
      return "st";
    }

    if (2 === day || 22 === day || 32 === day) {
      return "nd";
    }

    return "th";
  }

  function minutes(value) {
    if (value === 0) {
      return "00";
    }

    if (value < 10) {
      return "0" + value;
    }

    return value;
  }

  function hours(value) {
    if (value > 12) {
      return value - 12;
    }

    return value;
  }

  const months = {
    1: "Jan.",
    2: "Feb.",
    3: "Mar.",
    4: "Apr.",
    5: "May",
    6: "Jun.",
    7: "Jul.",
    8: "Aug.",
    9: "Sep.",
    10: "Oct.",
    11: "Nov.",
    12: "Dec.",
  };

  const datetime =
    months[date.getMonth() + 1] +
    " " +
    date.getDate() +
    suffix(date.getDate()) +
    " " +
    date.getFullYear() +
    " at " +
    hours(date.getHours()) +
    ":" +
    minutes(date.getMinutes()) +
    ampm(date.getHours());

  return datetime;
}

export const maximumCustomPostSpacing = 20;

export const calculateNumPosts = (run, settings, angle = 0) => {
  const { x1, y1, x2, y2 } = run;
  const x = Math.abs(x2 - x1);
  const y = Math.abs(y2 - y1);

  let postSpacing = "4";

  if (settings && settings.get) {
    postSpacing = settings.get("postSpacing");
  }

  const distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  const length = distance / Math.cos(angle);

  if (run && run.getIn && run.getIn(["settings", "postSpacing"])) {
    postSpacing = run.getIn(["settings", "postSpacing"]);
  }

  let posts = 0;

  switch (postSpacing) {
    case "4":
      posts = Math.floor(length / (pixelsPerFoot() * 4));

      posts = checkPostsForExactDistance(posts, length, pixelsPerFoot() * 4, 4);
      break;
    case "5":
      posts = Math.floor(length / (pixelsPerFoot() * 5));

      posts = checkPostsForExactDistance(posts, length, pixelsPerFoot() * 5, 5);
      break;
    case "custom":
      let custom = settings.get("customPostSpacing") || 4;

      if (run.getIn(["settings", "customPostSpacing"])) {
        custom = run.getIn(["settings", "customPostSpacing"]);
      }

      posts = Math.floor(length / (pixelsPerFoot() * custom));

      posts = checkPostsForExactDistance(
        posts,
        length,
        pixelsPerFoot() * custom,
        custom
      );

      break;
    default:
      break;
  }

  return {
    posts: posts,
    xDistance: (x2 - x1) / (posts + 1),
    yDistance: (y2 - y1) / (posts + 1),
  };
};

function checkPostsForExactDistance(posts, length, distanceCheck, feetCheck) {
  const lengths = length / pixelsPerFoot();

  if (isCloseToValue(posts, lengths / feetCheck, 0.01) || feetCheck === 1) {
    posts = posts - 1;
  }

  return posts;
}

export function isCloseToValue(value, check, threshold = Number.EPSILON) {
  const isClose = Math.abs(value - check) < threshold;

  return isClose;
}

export function isWithinElement(mousePosition, elementRect) {
  const { x, width, y, height } = elementRect;

  if (
    mousePosition.x > x &&
    mousePosition.x < x + width &&
    mousePosition.y > y &&
    mousePosition.y < y + height
  ) {
    return true;
  } else {
    return false;
  }
}

export function isClickedInElement(query, mousePosition) {
  const elem = document.querySelector(query);

  if (elem) {
    const rect = elem.getBoundingClientRect();
    const isIntersecting = isWithinElement(mousePosition, rect);

    if (isIntersecting) {
      return true;
    }
  }
}

export function isClickedInElements(query, mousePosition) {
  const elems = document.querySelectorAll(query);

  if (elems) {
    for (let i = 0, len = elems.length; i < len; i++) {
      const elem = elems[i];
      const rect = elem.getBoundingClientRect();
      const isIntersecting = isWithinElement(mousePosition, rect);

      if (isIntersecting) {
        return true;
      }
    }
  }

  return false;
}

export function ellipseFunction(x, y, h, k, rx, ry) {
  return (x - h) ** 2 / rx ** 2 + (y - k) ** 2 / ry ** 2;
}

export function isPointInsideEllipse(ellipse, mousePoint) {
  const { x, y } = mousePoint;
  const { x1, y1, x2, y2 } = ellipse;
  const h = x2 > x1 ? Math.abs(x2 - x1) / 2 + x1 : Math.abs(x2 - x1) / 2 + x2;
  const k = y2 > y1 ? Math.abs(y2 - y1) / 2 + y1 : Math.abs(y2 - y1) / 2 + y2;

  const rx = Math.abs(h - x1);
  const ry = Math.abs(k - y1);

  const value = ellipseFunction(x, y, h, k, rx, ry);

  if (value <= 1) {
    return true;
  }

  return false;
}

export function textIntersection(note, mousePoint) {
  const text = note.text;
  const fontSize = parseInt(note.fontSize.replace("px", ""), 10);

  const widthRatio = 9 / 16;
  const heightRatio = 22 / 16;

  const charWidth = fontSize * widthRatio;
  const charHeight = fontSize * heightRatio;

  let lineCount = 0;

  const lines = text.split("\n");

  const longestLine = lines.reduce((longest, line) => {
    if (line.length > longest) {
      return line.length;
    }

    return longest;
  }, 0);

  lineCount = lines.length;

  const { x, y } = note;

  const newY = y - charHeight;

  const x2 = x + longestLine * charWidth;
  const y2 = newY + charHeight * lineCount;

  if (
    mousePoint.x > x &&
    mousePoint.x < x2 &&
    mousePoint.y > newY &&
    mousePoint.y < y2
  ) {
    return true;
  }
  return false;
}

export function lineIntersectionThreshold() {
  return 0.2;
}

export function getHandrailsWithFlipsCalculated(handrails, handrailsWithFlips) {
  let newHandrails = Map();

  const mergedHandrails = handrailsWithFlips.mergeDeep(handrails);

  mergedHandrails
    .filter((handrail) => handrail.isEnd)
    .forEach((handrail, id) => {
      if (newHandrails.has(id)) {
        return;
      }

      newHandrails = newHandrails.set(handrail.id, handrail);

      newHandrails = calculateFlips(
        mergedHandrails,
        handrail,
        newHandrails,
        false
      );
    });

  newHandrails = mergedHandrails.merge(newHandrails);

  return newHandrails;
}

function calculateFlips(handrails, handrail, newHandrails, previouslyFlipped) {
  const { nextHandrail, previousType } = getNextHandrail(
    handrails,
    handrail,
    newHandrails
  );

  let flip = false;

  if (nextHandrail) {
    if (previouslyFlipped) {
      if (nextHandrail.flips.startFlip && nextHandrail.flips.endFlip) {
        flip = false;
      } else if (nextHandrail.flips.startFlip) {
        if (previousType === "start") {
          flip = false;
        }
      } else if (nextHandrail.flips.endFlip) {
        if (previousType === "end") {
          flip = false;
        }
      }

      if (nextHandrail.noFlips.startNoFlip && nextHandrail.noFlips.endNoFlip) {
        flip = true;
      } else if (nextHandrail.noFlips.startNoFlip) {
        if (previousType === "start") {
          flip = true;
        }
      } else if (nextHandrail.noFlips.endNoFlip) {
        if (previousType === "end") {
          flip = true;
        }
      }

      newHandrails = newHandrails
        .set(nextHandrail.id, nextHandrail)
        .setIn([nextHandrail.id, "flip"], flip);
    } else {
      if (nextHandrail.flips.startFlip && nextHandrail.flips.endFlip) {
        flip = true;
      } else if (nextHandrail.flips.startFlip) {
        if (previousType === "start") {
          flip = true;
        }
      } else if (nextHandrail.flips.endFlip) {
        if (previousType === "end") {
          flip = true;
        }
      }

      if (nextHandrail.noFlips.startNoFlip && nextHandrail.noFlips.endNoFlip) {
        flip = false;
      } else if (nextHandrail.noFlips.startNoFlip) {
        if (previousType === "start") {
          flip = false;
        }
      } else if (nextHandrail.noFlips.endNoFlip) {
        if (previousType === "end") {
          flip = false;
        }
      }
      newHandrails = newHandrails
        .set(nextHandrail.id, nextHandrail)
        .setIn([nextHandrail.id, "flip"], flip);
    }

    newHandrails = calculateFlips(handrails, nextHandrail, newHandrails, flip);
  }

  return newHandrails;
}

function getNextHandrail(handrails, handrail, seenHandrails) {
  const nextEndEdgeKey = "handrail-end-to-handrail";
  const nextStartEdgeKey = "handrail-start-to-handrail";

  let nextHandrail = null;
  let startHandrail = null;
  let endHandrail = null;
  let previousType = null;

  if (handrail.edges.getIn([nextEndEdgeKey, "to"])) {
    endHandrail = handrails.get(handrail.edges.getIn([nextEndEdgeKey, "to"]));
  }

  if (handrail.edges.getIn([nextStartEdgeKey, "to"])) {
    startHandrail = handrails.get(
      handrail.edges.getIn([nextStartEdgeKey, "to"])
    );
  }

  if (endHandrail && !seenHandrails.has(endHandrail.id)) {
    nextHandrail = endHandrail;
  }

  if (startHandrail && !seenHandrails.has(startHandrail.id)) {
    nextHandrail = startHandrail;
  }

  if (nextHandrail) {
    if (nextHandrail.edges.getIn([nextEndEdgeKey, "to"]) === handrail.id) {
      previousType = "end";
    }

    if (nextHandrail.edges.getIn([nextStartEdgeKey, "to"]) === handrail.id) {
      previousType = "start";
    }
  }

  if (nextHandrail && seenHandrails.has(nextHandrail.id)) {
    return null;
  }

  return { nextHandrail, previousType };
}

function getNextRun(runs, run, seenRuns) {
  const nextEndEdgeKey = "run-end-to-run";
  const nextStartEdgeKey = "run-start-to-run";

  let nextRun = null;
  let startRun = null;
  let endRun = null;
  let previousType = null;

  if (run.edges.getIn([nextEndEdgeKey, "to"])) {
    endRun = runs.get(run.edges.getIn([nextEndEdgeKey, "to"]));
  }

  if (run.edges.getIn([nextStartEdgeKey, "to"])) {
    startRun = runs.get(run.edges.getIn([nextStartEdgeKey, "to"]));
  }

  if (endRun && !seenRuns.has(endRun.id)) {
    nextRun = endRun;
  }

  if (startRun && !seenRuns.has(startRun.id)) {
    nextRun = startRun;
  }

  if (nextRun) {
    if (nextRun.edges.getIn([nextEndEdgeKey, "to"]) === run.id) {
      previousType = "end";
    }

    if (nextRun.edges.getIn([nextStartEdgeKey, "to"]) === run.id) {
      previousType = "start";
    }
  }

  if (nextRun && seenRuns.has(nextRun.id)) {
    return null;
  }

  return { nextRun, previousType };
}

function calculateRunFlips(runs, run, newRuns, previouslyFlipped) {
  const { nextRun, previousType } = getNextRun(runs, run, newRuns);

  let flip = false;

  if (nextRun) {
    if (previouslyFlipped) {
      if (nextRun.flips.startFlip && nextRun.flips.endFlip) {
        flip = false;
      } else if (nextRun.flips.startFlip) {
        if (previousType === "start") {
          flip = false;
        }
      } else if (nextRun.flips.endFlip) {
        if (previousType === "end") {
          flip = false;
        }
      }

      if (nextRun.noFlips.startNoFlip && nextRun.noFlips.endNoFlip) {
        flip = true;
      } else if (nextRun.noFlips.startNoFlip) {
        if (previousType === "start") {
          flip = true;
        }
      } else if (nextRun.noFlips.endNoFlip) {
        if (previousType === "end") {
          flip = true;
        }
      }

      newRuns = newRuns
        .set(nextRun.id, nextRun)
        .setIn([nextRun.id, "flip"], flip);
    } else {
      if (nextRun.flips.startFlip && nextRun.flips.endFlip) {
        flip = true;
      } else if (nextRun.flips.startFlip) {
        if (previousType === "start") {
          flip = true;
        }
      } else if (nextRun.flips.endFlip) {
        if (previousType === "end") {
          flip = true;
        }
      }

      if (nextRun.noFlips.startNoFlip && nextRun.noFlips.endNoFlip) {
        flip = false;
      } else if (nextRun.noFlips.startNoFlip) {
        if (previousType === "start") {
          flip = false;
        }
      } else if (nextRun.noFlips.endNoFlip) {
        if (previousType === "end") {
          flip = false;
        }
      }
      newRuns = newRuns
        .set(nextRun.id, nextRun)
        .setIn([nextRun.id, "flip"], flip);
    }

    newRuns = calculateFlips(runs, nextRun, newRuns, flip);
  }

  return newRuns;
}

export function getRunsWithFlipsCalculated(runs, corners, runGraph) {
  const runsWithFlips = getRunsToCornersData(corners, runs);

  const mergedRuns = runsWithFlips.mergeDeep(runGraph);

  let newRuns = new Map();

  mergedRuns
    .filter((run) => run.isEnd)
    .forEach((run) => {
      if (newRuns.has(run.id)) {
        return;
      }

      newRuns = newRuns.set(run.id, run);

      newRuns = calculateRunFlips(mergedRuns, run, newRuns, false);
    });

  newRuns = mergedRuns.merge(newRuns);

  return newRuns;
}

function getRunsToCornersData(corners, runs) {
  const cornersWithFlips = getCornersWithFlips(corners);

  return runs.map((run) => {
    const cornersForRun = cornersWithFlips.filter((corner) => {
      return corner.points[run.id];
    });

    const isEnd = cornersForRun.length === 1;

    let flips = {
      startFlip: false,
      endFlip: false,
    };

    let noFlips = {
      startNoFlip: false,
      endNoFlip: false,
    };

    cornersForRun.forEach((corner) => {
      if (corner.flip) {
        if (corner.points[run.id].type === "1") {
          flips.startFlip = true;
        } else if (corner.points[run.id].type === "2") {
          flips.endFlip = true;
        }
      }

      if (corner.noFlip) {
        if (corner.points[run.id].type === "1") {
          noFlips.startNoFlip = true;
        } else if (corner.points[run.id].type === "2") {
          noFlips.endNoFlip = true;
        }
      }
    });

    return {
      id: run.id,
      flips: flips,
      noFlips: noFlips,
      isEnd: isEnd,
      run: run,
      corners: cornersForRun,
    };
  });
}

export function getHandrailsWithRunsAndCorners(handrails, runs, corners) {
  const cornersWithFlips = getCornersWithFlips(corners);

  return handrails.map((handrail) => {
    let run = null;

    if (handrail.run) {
      run = runs.get(handrail.run);
    }

    let cornersForRun = [];

    if (run) {
      cornersForRun = cornersWithFlips.filter((corner) => {
        return corner.points[run.id];
      });
    }

    const isEnd = cornersForRun.length === 1;

    let flips = {
      startFlip: false,
      endFlip: false,
    };

    let noFlips = {
      startNoFlip: false,
      endNoFlip: false,
    };

    cornersForRun.forEach((corner) => {
      if (corner.flip) {
        if (corner.points[run.id].type === "1") {
          flips.startFlip = true;
        } else if (corner.points[run.id].type === "2") {
          flips.endFlip = true;
        }
      }

      if (corner.noFlip) {
        if (corner.points[run.id].type === "1") {
          noFlips.startNoFlip = true;
        } else if (corner.points[run.id].type === "2") {
          noFlips.endNoFlip = true;
        }
      }
    });

    return {
      id: handrail.id,
      noFlips: noFlips,
      flips: flips,
      isEnd: isEnd,
      handrail: handrail,
      run: run,
      corners: cornersForRun,
    };
  });
}

export function generateHandrailAnchorPoints(
  runsWithFlips,
  runs,
  run,
  corners,
  side = 1
) {
  const { x1, y1, x2, y2 } = run;

  const dx = x2 - x1;
  const dy = y2 - y1;

  const fullAngle = Math.atan2(dy, dx);
  const perpendicularAngle = fullAngle - Math.PI / 2;

  const xComponent = Math.cos(perpendicularAngle) * side * 10;
  const yComponent = Math.sin(perpendicularAngle) * side * 10;

  const cornerXComponent = Math.cos(fullAngle) * cornerDistance();
  const cornerYComponent = Math.sin(fullAngle) * cornerDistance();

  let startingCorner = null;
  let endingCorner = null;

  let matchingStartRun = null;
  let matchingEndRun = null;

  let startCornerXComponent = 0;
  let startCornerYComponent = 0;

  let endCornerXComponent = 0;
  let endCornerYComponent = 0;

  if (corners && corners.length) {
    const potentialCorners = corners
      .map((corner) => corner.runs.has(run.id))
      .filter((included) => included);

    if (potentialCorners && potentialCorners.length) {
      startingCorner = corners.find((corner) => {
        return corner.runs.has(run.id) && corner.points[run.id].type === "1";
      });

      if (startingCorner) {
        matchingStartRun = Object.keys(startingCorner.points).reduce(
          (_acc, id) => {
            if (id !== run.id) {
              return id;
            }

            return _acc;
          },
          null
        );

        matchingStartRun = runs.get(matchingStartRun);
      }

      endingCorner = corners.find((corner) => {
        return corner.runs.has(run.id) && corner.points[run.id].type === "2";
      });

      if (endingCorner) {
        matchingEndRun = Object.keys(endingCorner.points).reduce((_acc, id) => {
          if (id !== run.id) {
            return id;
          }

          return _acc;
        }, null);

        matchingEndRun = runs.get(matchingEndRun);
      }
    }
  }

  if (startingCorner) {
    startCornerXComponent = cornerXComponent * 1;
    startCornerYComponent = cornerYComponent * 1;
  }

  if (endingCorner) {
    endCornerXComponent = cornerXComponent * -1;
    endCornerYComponent = cornerYComponent * -1;
  }

  let flip = 1;
  let otherFlip1 = 1;
  let otherFlip2 = 1;

  if (runsWithFlips.has(run.id)) {
    if (runsWithFlips.getIn([run.id, "flip"])) {
      flip = -1;
    }
  }

  if (matchingStartRun) {
    const matchingStartRail = runsWithFlips.get(
      runsWithFlips.getIn([run.id, "edges", "run-start-to-run", "to"])
    );
    if (matchingStartRail && matchingStartRail.flip) {
      otherFlip1 = -1;
    }
  }

  if (matchingEndRun) {
    const matchingEndRail = runsWithFlips.get(
      runsWithFlips.getIn([run.id, "edges", "run-end-to-run", "to"])
    );
    if (matchingEndRail && matchingEndRail.flip) {
      otherFlip2 = -1;
    }
  }

  const newPostX1 = x1 + xComponent * flip + startCornerXComponent;
  const newPostY1 = y1 + yComponent * flip + startCornerYComponent;
  const newPostX2 = x2 + xComponent * flip + endCornerXComponent;
  const newPostY2 = y2 + yComponent * flip + endCornerYComponent;

  let newLineX1 = newPostX1;
  let newLineY1 = newPostY1;
  let newLineX2 = newPostX2;
  let newLineY2 = newPostY2;

  if (startingCorner) {
    if (matchingStartRun) {
      const {
        x1: matchX1,
        y1: matchY1,
        x2: matchX2,
        y2: matchY2,
      } = matchingStartRun;

      const matchAngle =
        Math.atan2(matchY2 - matchY1, matchX2 - matchX1) - Math.PI / 2;

      const matchXComponent = Math.cos(matchAngle) * side * 10;
      const matchYComponent = Math.sin(matchAngle) * side * 10;

      const matchingPostX1 = matchX1 + matchXComponent * otherFlip1;
      const matchingPostY1 = matchY1 + matchYComponent * otherFlip1;
      const matchingPostX2 = matchX2 + matchXComponent * otherFlip1;
      const matchingPostY2 = matchY2 + matchYComponent * otherFlip1;

      const { x, y } = getIntersectionOfLineSegments(
        { x1: newPostX1, y1: newPostY1, x2: newPostX2, y2: newPostY2 },
        {
          x1: matchingPostX1,
          y1: matchingPostY1,
          x2: matchingPostX2,
          y2: matchingPostY2,
        }
      );

      newLineX1 = x;
      newLineY1 = y;
    }
  }

  if (endingCorner) {
    if (matchingEndRun) {
      const {
        x1: matchX1,
        y1: matchY1,
        x2: matchX2,
        y2: matchY2,
      } = matchingEndRun;

      const matchAngle =
        Math.atan2(matchY2 - matchY1, matchX2 - matchX1) - Math.PI / 2;

      const matchXComponent = Math.cos(matchAngle) * side * 10;
      const matchYComponent = Math.sin(matchAngle) * side * 10;

      const matchingPostX1 = matchX1 + matchXComponent * otherFlip2;
      const matchingPostY1 = matchY1 + matchYComponent * otherFlip2;
      const matchingPostX2 = matchX2 + matchXComponent * otherFlip2;
      const matchingPostY2 = matchY2 + matchYComponent * otherFlip2;
      const { x, y } = getIntersectionOfLineSegments(
        { x1: newPostX1, y1: newPostY1, x2: newPostX2, y2: newPostY2 },
        {
          x1: matchingPostX1,
          y1: matchingPostY1,
          x2: matchingPostX2,
          y2: matchingPostY2,
        }
      );

      newLineX2 = x;
      newLineY2 = y;
    }
  }

  return {
    startingCorner,
    endingCorner,
    newLineX1,
    newLineY1,
    newLineX2,
    newLineY2,
    newPostX1,
    newPostY1,
    newPostX2,
    newPostY2,
  };
}

export function getCornersWithFlips(corners) {
  return corners.map(getCornerFlips);
}

const getCornerFlips = (corner) => {
  const [type1, type2] = Object.values(corner.points).map(
    (point) => point.type
  );

  if (type1 === type2) {
    corner.flip = true;
  }

  if (type1 !== type2) {
    corner.noFlip = true;
  }

  return corner;
};

export function getIntersectionOfLineSegments(segment1, segment2) {
  const { slope: slope1, intercept: intercept1 } =
    segmentToSlopeIntercept(segment1);
  const { slope: slope2, intercept: intercept2 } =
    segmentToSlopeIntercept(segment2);

  if (slope1 === slope2) {
    return { x: NaN, y: NaN };
  }

  if (slope1 === Infinity || slope1 === -Infinity) {
    const x = segment1.x1;
    const y = slope2 * x + intercept2;

    return { x, y };
  }

  if (slope2 === Infinity || slope2 === -Infinity) {
    const x = segment2.x1;
    const y = slope1 * x + intercept1;

    return { x, y };
  }

  const x = (intercept2 - intercept1) / (slope1 - slope2);
  const y = slope1 * x + intercept1;

  return { x, y };
}

function segmentToSlopeIntercept({ x1, y1, x2, y2 }) {
  const slope = (y2 - y1) / (x2 - x1);
  const intercept = y1 - slope * x1;

  return { slope, intercept };
}

export function shapeIntersection(
  polygon,
  mousePoint,
  lineThreshold = lineIntersectionThreshold()
) {
  const { type, x1, y1, x2, y2 } = polygon;
  const { x: mouseX, y: mouseY } = mousePoint;

  if (type === "rectangle") {
    if (x2 > x1 && y2 > y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 > y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 > x1 && y2 < y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 < y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }
  }

  if (type === "circle") {
    return isPointInsideEllipse(polygon, mousePoint);
  }

  if (type === "triangle") {
    if (x2 > x1 && y2 > y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 > y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 > x1 && y2 < y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 < y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }
  }

  if (type === "line") {
    return isPointBetweenPoints(
      mousePoint,
      { x: polygon.x1, y: polygon.y1 },
      { x: polygon.x2, y: polygon.y2 },
      lineThreshold
    );
  }

  return false;
}

export function stairsIntersection(stairs, mousePoint) {
  const { x1, y1, x2, y2 } = stairs;
  const { x: mouseX, y: mouseY } = mousePoint;

  const angle = stairs.rotate.get("angle");
  const axis = stairs.rotate.get("axis");

  if (angle % 180 === 0) {
    if (x2 > x1 && y2 > y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 > y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY > y1 && mouseY < y2) {
        return true;
      }
    }

    if (x2 > x1 && y2 < y1) {
      if (mouseX > x1 && mouseX < x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }

    if (x2 < x1 && y2 < y1) {
      if (mouseX < x1 && mouseX > x2 && mouseY < y1 && mouseY > y2) {
        return true;
      }
    }
  } else {
    if (x2 > x1 && y2 > y1) {
      return stairsClickIntersection(
        [mouseX, mouseY],
        {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
        },
        angle,
        axis
      );
    }

    if (x2 < x1 && y2 > y1) {
      return stairsClickIntersection(
        [mouseX, mouseY],
        {
          x1: x2,
          y1: y1,
          x2: x1,
          y2: y2,
        },
        angle,
        axis
      );
    }

    if (x2 > x1 && y2 < y1) {
      return stairsClickIntersection(
        [mouseX, mouseY],
        {
          x1: x1,
          y1: y2,
          x2: x2,
          y2: y1,
        },
        angle,
        axis
      );
    }

    if (x2 < x1 && y2 < y1) {
      return stairsClickIntersection(
        [mouseX, mouseY],
        {
          x1: x2,
          y1: y2,
          x2: x1,
          y2: y1,
        },
        angle,
        axis
      );
    }
  }

  return false;
}

/**
 * Determine if a click hit a rotated rectangle
 *
 * @param {Array} click Click position [X,Y]
 * @param {Array} position Rect from left, top [X,Y]
 * @param {Array} size Rect size as lengths [X,Y]
 * @param {Number} degrees Degrees rotated around center
 * @return {Boolean} Returns true if hit, false if miss
 */
function stairsClickIntersection(click, position, degrees, axis) {
  const { x1, y1, x2, y2 } = position;
  // Find the area of the rectangle
  // Round to avoid small JS math differences
  var rectArea = Math.round(Math.abs(x2 - x1) * Math.abs(y2 - y1));

  // Find the vertices
  var vertices = findVerticesOfStairs(position, degrees, axis);

  // Create an array of the areas of the four triangles
  var triArea = [
    // Click, LT, RT
    triangleArea(
      distance(click, vertices.LT),
      distance(vertices.LT, vertices.RT),
      distance(vertices.RT, click)
    ),
    // Click, RT, RB
    triangleArea(
      distance(click, vertices.RT),
      distance(vertices.RT, vertices.RB),
      distance(vertices.RB, click)
    ),
    // Click, RB, LB
    triangleArea(
      distance(click, vertices.RB),
      distance(vertices.RB, vertices.LB),
      distance(vertices.LB, click)
    ),
    // Click, LB, LT
    triangleArea(
      distance(click, vertices.LB),
      distance(vertices.LB, vertices.LT),
      distance(vertices.LT, click)
    ),
  ];

  // Reduce this array with a sum function
  // Round to avoid small JS math differences
  triArea = Math.round(
    triArea.reduce(function (a, b) {
      return a + b;
    }, 0)
  );

  // Finally do that simple thing we visualized earlier
  if (triArea <= rectArea) {
    return true;
  }
  return false;
}

/**
 * Find point after rotation around another point by X degrees
 *
 * @param {Array} point The point to be rotated [X,Y]
 * @param {Array} rotationCenterPoint The point that should be rotated around [X,Y]
 * @param {Number} degrees The degrees to rotate the point
 * @return {Array} Returns point after rotation [X,Y]
 */
function rotatePoint(point, rotationCenterPoint, degrees) {
  // Using radians for this formula
  var radians = (degrees * Math.PI) / 180;

  // Translate the plane on which rotation is occurring.
  // We want to rotate around 0,0. We'll add these back later.
  point[0] -= rotationCenterPoint[0];
  point[1] -= rotationCenterPoint[1];

  // Perform the rotation
  var newPoint = [];
  newPoint[0] = point[0] * Math.cos(radians) - point[1] * Math.sin(radians);
  newPoint[1] = point[0] * Math.sin(radians) + point[1] * Math.cos(radians);

  // Translate the plane back to where it was.
  newPoint[0] += rotationCenterPoint[0];
  newPoint[1] += rotationCenterPoint[1];

  return newPoint;
}

export function findVerticesOfStairs(position, degrees, axis = "top-left") {
  const { x1, y1, x2, y2 } = position;

  const left = x1;
  const top = y1;
  const right = x2;
  const bottom = y2;

  let center = [left, top];

  if (axis === "top-left") {
    center = [left, top];
  }

  if (axis === "top-right") {
    center = [right, top];
  }

  if (axis === "bottom-left") {
    center = [left, bottom];
  }

  if (axis === "bottom-right") {
    center = [right, bottom];
  }

  const LT = [left, top];
  const RT = [right, top];
  const LB = [left, bottom];
  const RB = [right, bottom];

  return {
    LT: rotatePoint(LT, center, degrees),
    RT: rotatePoint(RT, center, degrees),
    RB: rotatePoint(RB, center, degrees),
    LB: rotatePoint(LB, center, degrees),
  };
}

function arrayPointToObjectPoint(arrayPoint) {
  return {
    x: arrayPoint[0],
    y: arrayPoint[1],
  };
}

export function stairsCornerIntersection(stairs, mousePoint, threshold = 10) {
  const { x1, y1, x2, y2 } = stairs;

  const angle = stairs.rotate.get("angle");
  const axis = stairs.rotate.get("axis");

  if (angle % 360 === 0) {
    if (x2 > x1 && y2 > y1) {
      if (isNearPoint({ x: x1, y: y1 }, mousePoint, threshold)) {
        return "LT";
      }

      if (isNearPoint({ x: x1, y: y2 }, mousePoint, threshold)) {
        return "LB";
      }

      if (isNearPoint({ x: x2, y: y1 }, mousePoint, threshold)) {
        return "RT";
      }

      if (isNearPoint({ x: x2, y: y2 }, mousePoint, threshold)) {
        return "RB";
      }
    }

    if (x2 < x1 && y2 > y1) {
      if (isNearPoint({ x: x2, y: y1 }, mousePoint, threshold)) {
        return "LT";
      }

      if (isNearPoint({ x: x2, y: y2 }, mousePoint, threshold)) {
        return "LB";
      }

      if (isNearPoint({ x: x1, y: y1 }, mousePoint, threshold)) {
        return "RT";
      }

      if (isNearPoint({ x: x1, y: y2 }, mousePoint, threshold)) {
        return "RB";
      }
    }

    if (x2 > x1 && y2 < y1) {
      if (isNearPoint({ x: x1, y: y2 }, mousePoint, threshold)) {
        return "LT";
      }

      if (isNearPoint({ x: x1, y: y1 }, mousePoint, threshold)) {
        return "LB";
      }

      if (isNearPoint({ x: x2, y: y2 }, mousePoint, threshold)) {
        return "RT";
      }

      if (isNearPoint({ x: x2, y: y1 }, mousePoint, threshold)) {
        return "RB";
      }
    }

    if (x2 < x1 && y2 < y1) {
      if (isNearPoint({ x: x2, y: y2 }, mousePoint, threshold)) {
        return "LT";
      }

      if (isNearPoint({ x: x2, y: y1 }, mousePoint, threshold)) {
        return "LB";
      }

      if (isNearPoint({ x: x1, y: y2 }, mousePoint, threshold)) {
        return "RT";
      }

      if (isNearPoint({ x: x1, y: y1 }, mousePoint, threshold)) {
        return "RB";
      }
    }
  } else {
    if (x2 > x1 && y2 > y1) {
      const vertices = findVerticesOfStairs(
        {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
        },
        angle,
        axis
      );

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LT), mousePoint, threshold)
      ) {
        return "LT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RT), mousePoint, threshold)
      ) {
        return "RT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RB), mousePoint, threshold)
      ) {
        return "RB";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LB), mousePoint, threshold)
      ) {
        return "LB";
      }
    }

    if (x2 < x1 && y2 > y1) {
      const vertices = findVerticesOfStairs(
        {
          x1: x2,
          y1: y1,
          x2: x1,
          y2: y2,
        },
        angle,
        axis
      );

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LT), mousePoint, threshold)
      ) {
        return "LT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RT), mousePoint, threshold)
      ) {
        return "RT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RB), mousePoint, threshold)
      ) {
        return "RB";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LB), mousePoint, threshold)
      ) {
        return "LB";
      }
    }

    if (x2 > x1 && y2 < y1) {
      const vertices = findVerticesOfStairs(
        {
          x1: x1,
          y1: y2,
          x2: x2,
          y2: y1,
        },
        angle,
        axis
      );

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LT), mousePoint, threshold)
      ) {
        return "LT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RT), mousePoint, threshold)
      ) {
        return "RT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RB), mousePoint, threshold)
      ) {
        return "RB";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LB), mousePoint, threshold)
      ) {
        return "LB";
      }
    }

    if (x2 < x1 && y2 < y1) {
      const vertices = findVerticesOfStairs(
        {
          x1: x2,
          y1: y2,
          x2: x1,
          y2: y1,
        },
        angle,
        axis
      );

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LT), mousePoint, threshold)
      ) {
        return "LT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RT), mousePoint, threshold)
      ) {
        return "RT";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.RB), mousePoint, threshold)
      ) {
        return "RB";
      }

      if (
        isNearPoint(arrayPointToObjectPoint(vertices.LB), mousePoint, threshold)
      ) {
        return "LB";
      }
    }
  }

  return false;
}

function getStairCornersForRuns(stairs) {
  const angle = stairs.getIn(["rotate", "angle"]);

  let { x1, y1, x2, y2 } = stairs;

  if (stairs.x1 < stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y1;
    x2 = stairs.x2;
    y2 = stairs.y2;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y1;
    x2 = stairs.x1;
    y2 = stairs.y2;
  }

  if (stairs.x1 < stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y2;
    x2 = stairs.x2;
    y2 = stairs.y1;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y2;
    x2 = stairs.x1;
    y2 = stairs.y1;
  }

  let pivot = [x1, y1];
  const rotationAxis = stairs.getIn(["rotate", "axis"]);

  if (rotationAxis === "top-right") {
    pivot = [x2, y1];
  }

  if (rotationAxis === "top-left") {
    pivot = [x1, y1];
  }

  if (rotationAxis === "bottom-left") {
    pivot = [x1, y2];
  }

  if (rotationAxis === "bottom-right") {
    pivot = [x2, y2];
  }

  if (stairs.orientation === "vertical") {
    return [
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y1 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y1 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y2 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y2 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y2 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y2 + 5], pivot, angle)),
    ];
  }

  if (stairs.orientation === "horizontal") {
    return [
      arrayPointToObjectPoint(rotatePoint([x1 - 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 + 5, y1 + 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 - 5, y2 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x1 + 5, y2 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 - 5, y2 - 5], pivot, angle)),
      arrayPointToObjectPoint(rotatePoint([x2 + 5, y2 - 5], pivot, angle)),
    ];
  }
}

export function getUnrotatedStairsCorners(stairs) {
  let { x1, y1, x2, y2 } = stairs;

  if (stairs.x1 < stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y1;
    x2 = stairs.x2;
    y2 = stairs.y2;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y1;
    x2 = stairs.x1;
    y2 = stairs.y2;
  }

  if (stairs.x1 < stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y2;
    x2 = stairs.x2;
    y2 = stairs.y1;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y2;
    x2 = stairs.x1;
    y2 = stairs.y1;
  }

  return {
    TL: [x1, y1],
    TR: [x2, y1],
    BL: [x1, y2],
    BR: [x2, y2],
  };
}

export function getRotatedStairsCorners(stairs) {
  const { TL, TR, BL, BR } = getUnrotatedStairsCorners(stairs);

  const angle = stairs.getIn(["rotate", "angle"]);
  const rotationAxis = stairs.getIn(["rotate", "axis"]);

  let pivot = TL.slice(0);

  if (rotationAxis === "top-right") {
    pivot = TR.slice(0);
  }

  if (rotationAxis === "top-left") {
    pivot = TL.slice(0);
  }

  if (rotationAxis === "bottom-left") {
    pivot = BL.slice(0);
  }

  if (rotationAxis === "bottom-right") {
    pivot = BR.slice(0);
  }

  return {
    TL: rotatePoint(TL.slice(0), pivot, angle),
    TR: rotatePoint(TR.slice(0), pivot, angle),
    BL: rotatePoint(BL.slice(0), pivot, angle),
    BR: rotatePoint(BR.slice(0), pivot, angle),
  };
}

function getStairCorners(stairs) {
  const angle = stairs.getIn(["rotate", "angle"]);

  let { x1, y1, x2, y2 } = stairs;

  if (stairs.x1 < stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y1;
    x2 = stairs.x2;
    y2 = stairs.y2;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y1;
    x2 = stairs.x1;
    y2 = stairs.y2;
  }

  if (stairs.x1 < stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y2;
    x2 = stairs.x2;
    y2 = stairs.y1;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y2;
    x2 = stairs.x1;
    y2 = stairs.y1;
  }

  let pivot = [x1, y1];
  const rotationAxis = stairs.getIn(["rotate", "axis"]);

  if (rotationAxis === "top-right") {
    pivot = [x2, y1];
  }

  if (rotationAxis === "top-left") {
    pivot = [x1, y1];
  }

  if (rotationAxis === "bottom-left") {
    pivot = [x1, y2];
  }

  if (rotationAxis === "bottom-right") {
    pivot = [x2, y2];
  }

  if (!stairs.orientation || stairs.orientation === "vertical") {
    return {
      TL: arrayPointToObjectPoint(rotatePoint([x1 + 5, y1 - 5], pivot, angle)),
      TR: arrayPointToObjectPoint(rotatePoint([x2 - 5, y1 - 5], pivot, angle)),
      BL: arrayPointToObjectPoint(rotatePoint([x1 + 5, y2 + 5], pivot, angle)),
      BR: arrayPointToObjectPoint(rotatePoint([x2 - 5, y2 + 5], pivot, angle)),
    };
  }

  if (stairs.orientation === "horizontal") {
    return {
      TL: arrayPointToObjectPoint(rotatePoint([x1 - 5, y1 + 5], pivot, angle)),
      TR: arrayPointToObjectPoint(rotatePoint([x2 + 5, y1 + 5], pivot, angle)),
      BL: arrayPointToObjectPoint(rotatePoint([x1 - 5, y2 - 5], pivot, angle)),
      BR: arrayPointToObjectPoint(rotatePoint([x2 + 5, y2 - 5], pivot, angle)),
    };
  }
}

export function getStairCornersForSnapLines(
  stairs,
  orientation: string = null
) {
  const angle = stairs.getIn(["rotate", "angle"]);

  let { x1, y1, x2, y2 } = stairs;

  if (stairs.x1 < stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y1;
    x2 = stairs.x2;
    y2 = stairs.y2;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 < stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y1;
    x2 = stairs.x1;
    y2 = stairs.y2;
  }

  if (stairs.x1 < stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x1;
    y1 = stairs.y2;
    x2 = stairs.x2;
    y2 = stairs.y1;
  }

  if (stairs.x1 > stairs.x2 && stairs.y1 > stairs.y2) {
    x1 = stairs.x2;
    y1 = stairs.y2;
    x2 = stairs.x1;
    y2 = stairs.y1;
  }

  let pivot = [x1, y1];
  const rotationAxis = stairs.getIn(["rotate", "axis"]);

  if (rotationAxis === "top-right") {
    pivot = [x2, y1];
  }

  if (rotationAxis === "top-left") {
    pivot = [x1, y1];
  }

  if (rotationAxis === "bottom-left") {
    pivot = [x1, y2];
  }

  if (rotationAxis === "bottom-right") {
    pivot = [x2, y2];
  }

  let stairsOrientation = stairs.orientation;

  if (orientation) {
    stairsOrientation = orientation;
  }

  if (!stairsOrientation || stairsOrientation === "vertical") {
    return {
      TL: arrayPointToObjectPoint(rotatePoint([x1 + 5, y1], pivot, angle)),
      TR: arrayPointToObjectPoint(rotatePoint([x2 - 5, y1], pivot, angle)),
      BL: arrayPointToObjectPoint(rotatePoint([x1 + 5, y2], pivot, angle)),
      BR: arrayPointToObjectPoint(rotatePoint([x2 - 5, y2], pivot, angle)),
    };
  }

  if (stairsOrientation === "horizontal") {
    return {
      TL: arrayPointToObjectPoint(rotatePoint([x1, y1 + 5], pivot, angle)),
      TR: arrayPointToObjectPoint(rotatePoint([x2, y1 + 5], pivot, angle)),
      BL: arrayPointToObjectPoint(rotatePoint([x1, y2 - 5], pivot, angle)),
      BR: arrayPointToObjectPoint(rotatePoint([x2, y2 - 5], pivot, angle)),
    };
  }
}

export function nearestStairCornerForRun(stairs, mousePoint) {
  const stairsCorners = getStairCornersForRuns(stairs);

  const cornerDistance = stairsCorners.map((point) =>
    getDistance(point, mousePoint)
  );

  let min = 1000;
  let index = 0;

  for (let i = 0, len = cornerDistance.length; i < len; i++) {
    if (cornerDistance[i] < min) {
      min = cornerDistance[i];
      index = i;
    }
  }

  return stairsCorners[index];
}

export function findNearestStairCorner(stairs, mousePoint, snapLine) {
  const stairCorners = getStairCorners(stairs);

  let min = Infinity;
  let key = "TL";

  Object.entries(stairCorners).forEach(([type, point]) => {
    const cornerDistance = getDistance(point, mousePoint);
    if (cornerDistance < min) {
      min = cornerDistance;
      key = type;
    }
  });

  let keys = { start: "LT", end: "LB" };

  if (!stairs.orientation || stairs.orientation === "vertical") {
    if (key === "TL") {
      keys.start = "TL";
      keys.end = "BL";
    }

    if (key === "BL") {
      keys.start = "BL";
      keys.end = "TL";
    }

    if (key === "TR") {
      keys.start = "TR";
      keys.end = "BR";
    }

    if (key === "BR") {
      keys.start = "BR";
      keys.end = "TR";
    }
  }

  if (stairs.orientation === "horizontal") {
    if (key === "TL") {
      keys.start = "TL";
      keys.end = "TR";
    }

    if (key === "BL") {
      keys.start = "BL";
      keys.end = "BR";
    }

    if (key === "TR") {
      keys.start = "TR";
      keys.end = "TL";
    }

    if (key === "BR") {
      keys.start = "BR";
      keys.end = "BL";
    }
  }

  if (stairs.type === "landing" && snapLine) {
    const slope = (snapLine.y2 - snapLine.y1) / (snapLine.x2 - snapLine.x1);

    // If snap line is horizontal or vertical change which corners we are snapping to.
    if (key === "TL") {
      if (isCloseToValue(slope, 0, 0.1)) {
        keys.start = "TL";
        keys.end = "TR";
      } else {
        keys.start = "TL";
        keys.end = "BL";
      }
    }

    if (key === "BL") {
      if (isCloseToValue(slope, 0, 0.1)) {
        keys.start = "BL";
        keys.end = "BR";
      } else {
        keys.start = "BL";
        keys.end = "TL";
      }
    }

    if (key === "TR") {
      if (isCloseToValue(slope, 0, 0.1)) {
        keys.start = "TR";
        keys.end = "TL";
      } else {
        keys.start = "TR";
        keys.end = "BR";
      }
    }

    if (key === "BR") {
      if (isCloseToValue(slope, 0, 0.1)) {
        keys.start = "BR";
        keys.end = "BL";
      } else {
        keys.start = "BR";
        keys.end = "TR";
      }
    }
  }

  return {
    keys: keys,
    corners: stairCorners,
  };
}

/**
 * Distance formula
 *
 * @param {Array} p1 First point [X,Y]
 * @param {Array} p2 Second point [X,Y]
 * @return {Number} Returns distance between points
 */
function distance(p1, p2) {
  return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
}

/**
 * Heron's formula (triangle area)
 *
 * @param {Number} d1 Distance, side 1
 * @param {Number} d2 Distance, side 2
 * @param {Number} d3 Distance, side 3
 * @return {Number} Returns area of triangle
 */
function triangleArea(d1, d2, d3) {
  // See https://en.wikipedia.org/wiki/Heron's_formula
  var s = (d1 + d2 + d3) / 2;
  return Math.sqrt(s * (s - d1) * (s - d2) * (s - d3));
}

export function isPointBetweenPoints(
  currPoint,
  point1,
  point2,
  threshold = lineIntersectionThreshold()
) {
  if (isPointOnLine(currPoint, point1, point2, threshold)) {
    return true;
  } else {
    return false;
  }
}

export function isPointOnLine(
  currPoint,
  point1,
  point2,
  threshold = lineIntersectionThreshold()
) {
  const d1 = distance([currPoint.x, currPoint.y], [point1.x, point1.y]);
  const d2 = distance([currPoint.x, currPoint.y], [point2.x, point2.y]);
  const d3 = distance([point1.x, point1.y], [point2.x, point2.y]);

  const diff = d3 - (d1 + d2);

  if (Math.abs(diff) > threshold) {
    return false;
  } else {
    return true;
  }
}

export function runIntersection(
  run,
  mousePoint,
  threshold = lineIntersectionThreshold()
) {
  return isPointBetweenPoints(
    mousePoint,
    { x: run.x1, y: run.y1 },
    { x: run.x2, y: run.y2 },
    threshold
  );
}

export function handrailIntersection(
  handrail,
  mousePoint,
  runs,
  threshold = lineIntersectionThreshold()
) {
  if (handrail.run) {
    const run = runs.get(handrail.run);

    if (!run) {
      return false;
    }

    const angle = Math.atan((run.y2 - run.y1) / (run.x2 - run.x1));

    const perpendicularAngle = angle - Math.PI / 2;

    let side = 1;

    if (handrail.side === "default") {
      side = 1;
    }

    if (handrail.side === "flip") {
      side = -1;
    }

    const xComponent =
      roundToHundreth(Math.cos(perpendicularAngle)) * side * 10;
    const yComponent =
      roundToHundreth(Math.sin(perpendicularAngle)) * side * 10;

    return isPointBetweenPoints(
      mousePoint,
      { x: xComponent + run.x1, y: yComponent + run.y1 },
      { x: xComponent + run.x2, y: yComponent + run.y2 },
      threshold
    );
  }

  if (handrail.p1.run && handrail.p2.run) {
    if (handrail.side === "flip") {
      if (handrail.p1.matchingPoint && handrail.p2.matchingPoint) {
        return isPointBetweenPoints(
          mousePoint,
          { x: handrail.p1.matchingPoint.x, y: handrail.p1.matchingPoint.y },
          { x: handrail.p2.matchingPoint.x, y: handrail.p2.matchingPoint.y },
          threshold
        );
      }
    }
  }

  return isPointBetweenPoints(
    mousePoint,
    { x: handrail.x1, y: handrail.y1 },
    { x: handrail.x2, y: handrail.y2 },
    threshold
  );
}

function gateIntersectionWithNoRunAttachment(gate, mousePoint, runs, settings) {
  let startPost = { x: gate.x1, y: gate.y1 };
  let endPost = { x: gate.x2, y: gate.y2 };

  let theRun1 = null;
  let theRun2 = null;

  if (gate.p1.runIndex !== "no-run") {
    const runIndex1 = gate.p1.runIndex;

    theRun1 = runs.get(runIndex1);

    if (!theRun1) {
      return;
    }

    const posts1 = getPosts(theRun1, settings);

    startPost = posts1[gate.p1.postIndex]
      ? posts1[gate.p1.postIndex]
      : startPost;
  }

  if (gate.p2.runIndex !== "no-run") {
    const runIndex2 = gate.p2.runIndex;

    theRun2 = runs.get(runIndex2);

    if (!theRun2) {
      return;
    }

    const posts2 = getPosts(theRun2, settings);

    endPost = posts2[gate.p2.postIndex] ? posts2[gate.p2.postIndex] : endPost;
  }

  if (!startPost || !endPost) {
    return false;
  }

  let x1, y1, x2, y2;

  if (endPost.x < startPost.x) {
    x1 = endPost.x;
    y1 = endPost.y;
    x2 = startPost.x;
    y2 = startPost.y;
  } else {
    x1 = startPost.x;
    y1 = startPost.y;
    x2 = endPost.x;
    y2 = endPost.y;
  }

  let x3 = x1,
    y3 = y1,
    x4 = x2,
    y4 = y2;

  if (gate.opening !== "normal") {
    x3 = x2;
    y3 = y2;
    x4 = x1;
    y4 = y1;
  }

  const distance = getDistance({ x: x3, y: y3 }, { x: x4, y: y4 });
  const mouseDistance = getDistance({ x: x3, y: y3 }, mousePoint);

  const v1 = { x: x4 - x3, y: y4 - y3 };
  const v = { x: mousePoint.x - x3, y: mousePoint.y - y3 };

  let angle = Math.atan2(y4 - y3, x4 - x3);

  const gte = angle >= 0;
  const add = gte && gate.opening === "normal";

  let perpendicularAngle = add ? Math.PI / 2 : (-1 * Math.PI) / 2;

  if (gate.side !== "normal") {
    perpendicularAngle = perpendicularAngle + (add ? Math.PI : -1 * Math.PI);
  }

  const v2Point = rotatePoint(
    [x4, y4],
    [x3, y3],
    radiansToDegrees(perpendicularAngle)
  );

  const v2 = { x: v2Point[0] - x3, y: v2Point[1] - y3 };

  const isWithin = crossProduct(v1, v) * crossProduct(v, v2) >= 0;
  let crossProduct1 = crossProduct(v1, v) > 0;
  let crossProduct2 = crossProduct(v, v2) > 0;

  if (gate.opening !== "normal") {
    crossProduct1 = !crossProduct1;
    crossProduct2 = !crossProduct2;
  }

  if (gate.side !== "normal") {
    crossProduct1 = !crossProduct1;
    crossProduct2 = !crossProduct2;
  }

  if (mouseDistance < distance && isWithin && crossProduct1 && crossProduct2) {
    return true;
  } else {
    return false;
  }
}

export function gateIntersection(gate, mousePoint, runs, posts, settings) {
  if (gate.p1.runIndex === "no-run" || gate.p2.runIndex === "no-run") {
    return gateIntersectionWithNoRunAttachment(
      gate,
      mousePoint,
      runs,
      settings
    );
  }

  let x1, y1, x2, y2;

  const startPost = { x: gate.x1, y: gate.y1 };
  const endPost = { x: gate.x2, y: gate.y2 };

  if (endPost.x < startPost.x) {
    x1 = endPost.x;
    y1 = endPost.y;
    x2 = startPost.x;
    y2 = startPost.y;
  } else {
    x1 = startPost.x;
    y1 = startPost.y;
    x2 = endPost.x;
    y2 = endPost.y;
  }

  let x3 = x1,
    y3 = y1,
    x4 = x2,
    y4 = y2;

  if (gate.opening !== "normal") {
    x3 = x2;
    y3 = y2;
    x4 = x1;
    y4 = y1;
  }

  const distance = getDistance({ x: x3, y: y3 }, { x: x4, y: y4 });
  const mouseDistance = getDistance({ x: x3, y: y3 }, mousePoint);

  const v1 = { x: x4 - x3, y: y4 - y3 };
  const v = { x: mousePoint.x - x3, y: mousePoint.y - y3 };

  const add = gate.opening === "normal";

  let perpendicularAngle = add ? Math.PI / 2 : (-1 * Math.PI) / 2;

  if (gate.side !== "normal") {
    perpendicularAngle = perpendicularAngle + (add ? Math.PI : -1 * Math.PI);
  }

  const v2Point = rotatePoint(
    [x4, y4],
    [x3, y3],
    radiansToDegrees(perpendicularAngle)
  );

  const v2 = { x: v2Point[0] - x3, y: v2Point[1] - y3 };

  const isWithin = crossProduct(v1, v) * crossProduct(v, v2) >= 0;
  let crossProduct1 = crossProduct(v1, v) > 0;
  let crossProduct2 = crossProduct(v, v2) > 0;

  if (gate.opening !== "normal") {
    crossProduct1 = !crossProduct1;
    crossProduct2 = !crossProduct2;
  }

  if (gate.side !== "normal") {
    crossProduct1 = !crossProduct1;
    crossProduct2 = !crossProduct2;
  }

  if (mouseDistance < distance && isWithin && crossProduct1 && crossProduct2) {
    return true;
  } else {
    return false;
  }
}

export function crossProduct(A, B) {
  return A.x * B.y - A.y * B.x;
}

export function distanceBetweenPoints(point1, point2) {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
  );
}

export function imageIntersection(image, mousePoint, images) {
  let clicked = false;

  const x = Math.floor(image.width / 2);
  const y = Math.floor(image.height / 2);

  const bounds = {
    x1: image.point.x - x,
    y1: image.point.y - y,
    x2: image.point.x + x,
    y2: image.point.y + y,
  };

  if (
    mousePoint.x >= bounds.x1 &&
    mousePoint.x <= bounds.x2 &&
    mousePoint.y >= bounds.y1 &&
    mousePoint.y <= bounds.y2
  ) {
    clicked = true;
  }

  return clicked;
}

export function imageCornerIntersection(image, mousePoint) {}

export function getDistance(point1, point2) {
  const distance = Math.sqrt(
    (point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2
  );

  return distance;
}

export function getNormalDistance(linePoint1, linePoint2, checkPoint) {
  return (
    Math.abs(
      (linePoint2.x - linePoint1.x) * (linePoint1.y - checkPoint.y) -
        (linePoint1.x - checkPoint.x) * (linePoint2.y - linePoint1.y)
    ) / getDistance(linePoint1, linePoint2)
  );
}

export function snapDistanceThreshold(threshold = 16) {
  return threshold;
}

export function getClosestPointOnLine(linePoint1, linePoint2, checkPoint) {
  const aToP = [checkPoint.x - linePoint1.x, checkPoint.y - linePoint1.y];
  const aToB = [linePoint2.x - linePoint1.x, linePoint2.y - linePoint1.y];

  const aToBSquared = aToB[0] ** 2 + aToB[1] ** 2;

  // Dot product of two vectors.
  const dotProduct = aToP[0] * aToB[0] + aToP[1] * aToB[1];

  // Calculate constant of distance.
  const t = dotProduct / aToBSquared;

  return { x: linePoint1.x + aToB[0] * t, y: linePoint1.y + aToB[1] * t };
}

export function isNearPoint(point1, point2, threshold = 10) {
  if (!point1 || !point2 || !point1.x || !point1.y || !point2.x || !point2.y) {
    return false;
  }

  const distance = getDistance(point1, point2);

  return distance < threshold;
}

export function isNearPointArrayPoints(point1, point2, threshold = 10) {
  if (
    !point1 ||
    !point2 ||
    !point1[0] ||
    !point1[1] ||
    !point2[0] ||
    !point2[1]
  ) {
    return false;
  }

  const distance = getDistance(
    { x: point1[0], y: point1[1] },
    { x: point2[0], y: point2[1] }
  );

  return distance < threshold;
}

export function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians) {
  return (radians * 180) / Math.PI;
}

function minimumDistnaceOfRotatePoints(point1, stairPoints) {
  const distances = Object.values(stairPoints).map((point) => {
    return getDistance(point1, point);
  });

  return Math.min(...distances);
}

function getPostsWithChainOfStairsFunc(alreadyCalculated = Map()) {
  return function (run, settings, state) {
    // const hash =
    //   run.hashCode().toString() +
    //   settings.hashCode().toString() +
    //   state.stairs.hashCode().toString() +
    //   state.runs.hashCode().toString();

    // if (!state.runs.has(run.id)) {
    //   console.log(hash);
    // }

    // if (alreadyCalculated.has(hash)) {
    //   return alreadyCalculated.get(hash);
    // }

    const posts = getPostsWithChainOfStairsCalc(run, settings, state);

    // alreadyCalculated = alreadyCalculated.set(hash, posts);

    return posts;
  };
}

export const getPostsWithChainOfStairs = getPostsWithChainOfStairsFunc();

function getPostsWithChainOfStairsCalc(run, settings, state) {
  let posts = [];

  const { start, end } = run.stairs.keys;

  let { continuousStairs } = run.stairs;

  continuousStairs = OrderedMap(continuousStairs).map((stair) =>
    Stairs(stair).set("rotate", Map(stair?.rotate))
  );

  const startVertical = start[0];
  const startHorizontal = start[1];
  const endVertical = end[0];
  const endHorizontal = end[1];

  let orientation = "vertical";

  if (startVertical === endVertical) {
    orientation = "horizontal";
  }

  if (startHorizontal === endHorizontal) {
    orientation = "vertical";
  }

  const points = continuousStairs.map((stair) => {
    const rotated = getStairCornersForSnapLines(stair, orientation);

    return rotated;
  });

  const point1 = { x: run.x1, y: run.y1 };
  const point2 = { x: run.x2, y: run.y2 };

  continuousStairs = continuousStairs.sort((stairA, stairB) => {
    const stairAPoints = points.get(stairA.id);
    const stairBPoints = points.get(stairB.id);

    const distanceA = minimumDistnaceOfRotatePoints(point1, stairAPoints);
    const distanceB = minimumDistnaceOfRotatePoints(point1, stairBPoints);

    if (isCloseToValue(distanceA, distanceB, 0.01)) {
      if (stairsIntersection(stairA, point1)) {
        return -1;
      }

      if (stairsIntersection(stairB, point1)) {
        return 1;
      }
    }

    if (distanceA < distanceB) {
      return -1;
    } else {
      return 1;
    }
  });

  // Calculate any inner posts not on stairs.
  const { max, min } = continuousStairs.reduce(
    (acc, stair) => {
      const stairPoints = points.get(stair.id);

      if (
        (stairPoints[start].x <= acc.min.x ||
          isCloseToValue(stairPoints[start].x, acc.min.x, 1)) &&
        (stairPoints[start].y <= acc.min.y ||
          isCloseToValue(stairPoints[start].y, acc.min.y, 1))
      ) {
        acc.min = stairPoints[start];
      }

      if (
        (stairPoints[end].x <= acc.min.x ||
          isCloseToValue(stairPoints[end].x, acc.min.x, 1)) &&
        (stairPoints[end].y <= acc.min.y ||
          isCloseToValue(stairPoints[end].y, acc.min.y, 1))
      ) {
        acc.min = stairPoints[end];
      }

      if (
        (stairPoints[end].x >= acc.max.x ||
          isCloseToValue(stairPoints[end].x, acc.max.x, 1)) &&
        (stairPoints[end].y >= acc.max.y ||
          isCloseToValue(stairPoints[end].y, acc.max.y, 1))
      ) {
        acc.max = stairPoints[end];
      }

      if (
        (stairPoints[start].x >= acc.max.x ||
          isCloseToValue(stairPoints[start].x, acc.max.x, 1)) &&
        (stairPoints[start].y >= acc.max.y ||
          isCloseToValue(stairPoints[start].y, acc.max.y, 1))
      ) {
        acc.max = stairPoints[start];
      }

      return acc;
    },
    {
      max: { x: -Infinity, y: -Infinity },
      min: { x: Infinity, y: Infinity },
    }
  );

  let point1Min = false;
  let point1Max = false;
  let point2Min = false;
  let point2Max = false;

  if (
    (point1.x < min.x || isCloseToValue(point1.x, min.x, 1)) &&
    (point1.y < min.y || isCloseToValue(point1.y, min.y, 1))
  ) {
    point1Min = true;
  }

  if (
    (point1.x > max.x || isCloseToValue(point1.x, max.x, 1)) &&
    (point1.y > max.y || isCloseToValue(point1.y, max.y, 1))
  ) {
    point1Max = true;
  }

  if (
    (point2.x < min.x || isCloseToValue(point2.x, min.x, 1)) &&
    (point2.y < min.y || isCloseToValue(point2.y, min.y, 1))
  ) {
    point2Min = true;
  }

  if (
    (point2.x > max.x || isCloseToValue(point2.x, max.x, 1)) &&
    (point2.y > max.y || isCloseToValue(point2.y, max.y, 1))
  ) {
    point2Max = true;
  }

  // If run is not within stairs at all.
  if (
    !isPointBetweenPoints(point1, max, min) &&
    !isPointBetweenPoints(point2, max, min) &&
    ((point1Min && point2Min) || (point1Max && point2Max))
  ) {
    // If run is not within stairs at all.

    let newRun = run;

    let index = 0;

    const newStartPost = {
      x: newRun.x1,
      y: newRun.y1,
      type: "terminal",
      index: index++,
      matchingStair: ["not-connected"],
    };

    posts.push(newStartPost);

    const {
      posts: thePosts,
      xDistance,
      yDistance,
    } = calculateNumPosts(newRun, settings);

    for (let i = 1; i <= thePosts; i++) {
      const newPost = {
        x: newRun.x1 + i * xDistance,
        y: newRun.y1 + i * yDistance,
        type: "intermediate",
        index: index++,
        matchingStair: ["not-connected"],
      };

      posts.push(newPost);
    }

    const newEndPost = {
      x: newRun.x2,
      y: newRun.y2,
      type: "terminal",
      index: index++,
      matchingStair: ["not-connected"],
    };

    posts.push(newEndPost);

    // Exit early as there is no more calculating to do.
    return posts;
  }

  // If run start is outside of stairs calculate intermediate posts.
  if (point1Min || point1Max) {
    let newRun = run;

    if (point1Min) {
      newRun = newRun.set("x2", min.x).set("y2", min.y);
    } else if (point1Max) {
      newRun = newRun.set("x2", max.x).set("y2", max.y);
    }

    const terminalStartPost = {
      x: newRun.x1,
      y: newRun.y1,
      type: "terminal",
      matchingStair: ["start"],
    };

    posts.push(terminalStartPost);

    const {
      posts: thePosts,
      xDistance,
      yDistance,
    } = calculateNumPosts(newRun, settings);

    for (let i = 1; i <= thePosts; i++) {
      const newPost = {
        x: newRun.x1 + i * xDistance,
        y: newRun.y1 + i * yDistance,
        type: "intermediate",
        matchingStair: ["start"],
      };

      posts.push(newPost);
    }
  }

  // Calculate all inner posts on stairs.
  continuousStairs.forEach((stair, index) => {
    // Run within stairs.
    const stairPoints = points.get(index);

    const angleRadians = degreesToRadians(stair.angle);

    // Calculate posts for run spanning all stairs.
    if (
      // Run spans stairs.
      isPointBetweenPoints(
        stairPoints[start],
        { x: run.x1, y: run.y1 },
        { x: run.x2, y: run.y2 }
      ) &&
      isPointBetweenPoints(
        stairPoints[end],
        { x: run.x1, y: run.y1 },
        { x: run.x2, y: run.y2 }
      )
    ) {
      const newRun = run
        .set("x1", stairPoints[start].x)
        .set("y1", stairPoints[start].y)
        .set("x2", stairPoints[end].x)
        .set("y2", stairPoints[end].y);

      const {
        posts: thePosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(newRun, settings, angleRadians);

      if (posts.length === 0) {
        const startTransition = {
          x: stairPoints[start].x,
          y: stairPoints[start].y,
          type: "stairPostTerminal",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: start },
        };

        if (stair.type === "landing") {
          startTransition.type = "terminal";
        }

        posts.push(startTransition);
      } else {
        const startTransition = {
          x: stairPoints[start].x,
          y: stairPoints[start].y,
          type: "stairPostTransition",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: start },
        };

        posts.push(startTransition);
      }

      for (let i = 1; i <= thePosts; i++) {
        const newPost = {
          x: newRun.x1 + i * xDistance,
          y: newRun.y1 + i * yDistance,
          type: "stairPostIntermediate",
          matchingStair: [stair.id],
        };

        if (stair.type === "landing") {
          newPost.type = "intermediate";
        }

        posts.push(newPost);
      }

      const endTransition = {
        x: stairPoints[end].x,
        y: stairPoints[end].y,
        type: "stairPostTransition",
        matchingStair: [stair.id],
        cornerType: { [stair.id]: end },
      };

      posts.push(endTransition);
    } else if (
      // Run within stairs.
      isPointBetweenPoints(
        { x: run.x1, y: run.y1 },
        stairPoints[start],
        stairPoints[end]
      ) &&
      isPointBetweenPoints(
        { x: run.x2, y: run.y2 },
        stairPoints[start],
        stairPoints[end]
      )
    ) {
      let newRun = run;

      const newStartPost = {
        x: newRun.x1,
        y: newRun.y1,
        type: "stairPostTerminal",
        matchingStair: [stair.id],
      };

      if (stair.type === "landing") {
        newStartPost.type = "terminal";
      }

      if (isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[start], 6)) {
        newStartPost.cornerType = { [stair.id]: start };
      }

      if (isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[end], 6)) {
        newStartPost.cornerType = { [stair.id]: end };
      }

      posts.push(newStartPost);

      const {
        posts: thePosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(newRun, settings, angleRadians);

      for (let i = 1; i <= thePosts; i++) {
        const newPost = {
          x: newRun.x1 + i * xDistance,
          y: newRun.y1 + i * yDistance,
          type: "stairPostIntermediate",
          matchingStair: [stair.id],
        };

        if (stair.type === "landing") {
          newPost.type = "intermediate";
        }

        posts.push(newPost);
      }

      const newEndPost = {
        x: newRun.x2,
        y: newRun.y2,
        type: "stairPostTerminal",
        matchingStair: [stair.id],
      };

      if (isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[start], 6)) {
        newEndPost.cornerType = { [stair.id]: start };
      }

      if (isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[end], 6)) {
        newEndPost.cornerType = { [stair.id]: end };
      }

      if (stair.type === "landing") {
        newEndPost.type = "terminal";
      }

      posts.push(newEndPost);
    } else if (
      // Run start in between stairs.
      isPointBetweenPoints(
        { x: run.x1, y: run.y1 },
        stairPoints[start],
        stairPoints[end]
      )
    ) {
      // Check start is in between section.
      let newRun = run;

      let endTransition = null;

      // Check configuration of run.
      if (
        // Run extends past start within stairs.
        isPointBetweenPoints(
          stairPoints[start],
          { x: run.x1, y: run.y1 },
          { x: run.x2, y: run.y2 }
        )
      ) {
        newRun = run
          .set("x2", stairPoints[start].x)
          .set("y2", stairPoints[start].y);

        endTransition = {
          x: stairPoints[start].x,
          y: stairPoints[start].y,
          type: "stairPostTransition",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: start },
        };

        const newStartPost = {
          x: newRun.x1,
          y: newRun.y1,
          type: "stairPostTerminal",
          matchingStair: [stair.id],
        };

        if (
          isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[start], 1)
        ) {
          newStartPost.cornerType = { [stair.id]: start };
        }

        if (isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[end], 1)) {
          newStartPost.cornerType = { [stair.id]: end };
        }

        if (stair.type === "landing") {
          newStartPost.type = "terminal";
        }

        posts.push(newStartPost);
      } else if (
        // Run extends past end within stairs.
        isPointBetweenPoints(
          stairPoints[end],
          { x: run.x1, y: run.y1 },
          { x: run.x2, y: run.y2 }
        )
      ) {
        newRun = run
          .set("x2", stairPoints[end].x)
          .set("y2", stairPoints[end].y);

        endTransition = {
          x: stairPoints[end].x,
          y: stairPoints[end].y,
          type: "stairPostTransition",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: end },
        };

        const newStartPost = {
          x: newRun.x1,
          y: newRun.y1,
          type: "stairPostTerminal",
          matchingStair: [stair.id],
        };

        if (
          isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[start], 1)
        ) {
          newStartPost.cornerType = { [stair.id]: start };
        }

        if (isNearPoint({ x: newRun.x1, y: newRun.y1 }, stairPoints[end], 1)) {
          newStartPost.cornerType = { [stair.id]: end };
        }

        if (stair.type === "landing") {
          newStartPost.type = "terminal";
        }

        posts.push(newStartPost);
      }

      const {
        posts: thePosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(newRun, settings, angleRadians);

      for (let i = 1; i <= thePosts; i++) {
        const newPost = {
          x: newRun.x1 + i * xDistance,
          y: newRun.y1 + i * yDistance,
          type: "stairPostIntermediate",
          matchingStair: [stair.id],
        };

        if (stair.type === "landing") {
          newPost.type = "intermediate";
        }

        posts.push(newPost);
      }

      if (endTransition) {
        posts.push(endTransition);
      }
    } else if (
      // Run end in between stairs.
      isPointBetweenPoints(
        { x: run.x2, y: run.y2 },
        stairPoints[start],
        stairPoints[end]
      )
    ) {
      // Check start is in between section.
      let newRun = run;

      // Check configuration of run.
      if (
        // Run extends past start within stairs.
        isPointBetweenPoints(
          stairPoints[start],
          { x: run.x1, y: run.y1 },
          { x: run.x2, y: run.y2 }
        )
      ) {
        newRun = run
          .set("x1", stairPoints[start].x)
          .set("y1", stairPoints[start].y);

        const startTransition = {
          x: stairPoints[start].x,
          y: stairPoints[start].y,
          type: "stairPostTransition",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: start },
        };

        posts.push(startTransition);

        const newEndPost = {
          x: newRun.x2,
          y: newRun.y2,
          type: "stairPostTerminal",
          matchingStair: [stair.id],
        };

        if (
          isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[start], 1)
        ) {
          newEndPost.cornerType = { [stair.id]: start };
        }

        if (isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[end], 1)) {
          newEndPost.cornerType = { [stair.id]: end };
        }

        if (stair.type === "landing") {
          newEndPost.type = "terminal";
        }

        posts.push(newEndPost);
      } else if (
        // Run extends past end within stairs.
        isPointBetweenPoints(
          stairPoints[end],
          { x: run.x1, y: run.y1 },
          { x: run.x2, y: run.y2 }
        )
      ) {
        newRun = run
          .set("x1", stairPoints[end].x)
          .set("y1", stairPoints[end].y);

        const startTransition = {
          x: stairPoints[end].x,
          y: stairPoints[end].y,
          type: "stairPostTransition",
          matchingStair: [stair.id],
          cornerType: { [stair.id]: end },
        };

        posts.push(startTransition);

        const newEndPost = {
          x: newRun.x2,
          y: newRun.y2,
          type: "stairPostTerminal",
          matchingStair: [stair.id],
        };

        if (
          isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[start], 1)
        ) {
          newEndPost.cornerType = { [stair.id]: start };
        }

        if (isNearPoint({ x: newRun.x2, y: newRun.y2 }, stairPoints[end], 1)) {
          newEndPost.cornerType = { [stair.id]: end };
        }

        if (stair.type === "landing") {
          newEndPost.type = "terminal";
        }

        posts.push(newEndPost);
      }

      const {
        posts: thePosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(newRun, settings, angleRadians);

      for (let i = 1; i <= thePosts; i++) {
        const newPost = {
          x: newRun.x1 + i * xDistance,
          y: newRun.y1 + i * yDistance,
          type: "stairPostIntermediate",
          matchingStair: [stair.id],
        };

        if (stair.type === "landing") {
          newPost.type = "intermediate";
        }

        posts.push(newPost);
      }
    }
  });

  // If run end is outside of stairs calculate intermediate posts.
  if (point2Min || point2Max) {
    let newRun = run;

    if (point2Min) {
      newRun = newRun.set("x1", min.x).set("y1", min.y);
    } else if (point2Max) {
      newRun = newRun.set("x1", max.x).set("y1", max.y);
    }

    const {
      posts: thePosts,
      xDistance,
      yDistance,
    } = calculateNumPosts(newRun, settings);

    for (let i = 1; i <= thePosts; i++) {
      const newPost = {
        x: newRun.x1 + i * xDistance,
        y: newRun.y1 + i * yDistance,
        type: "intermediate",
        matchingStair: ["end"],
      };

      posts.push(newPost);
    }

    const terminalEndPost = {
      x: newRun.x2,
      y: newRun.y2,
      type: "terminal",
      matchingStair: ["end"],
    };

    posts.push(terminalEndPost);
  }

  // Remove any duplicate transition posts.
  let seen = Map();

  let count = 0;

  posts = posts.reduce((acc, post, index) => {
    const { x, y } = post;
    const hash = Map({ x: x.toString(), y: y.toString() })
      .hashCode()
      .toString();

    let matchingPost = null;

    if (
      post.type === "stairPostTransition" &&
      acc[count - 1]?.type === "stairPostTransition"
    ) {
      if (post.matchingStair[0] !== acc[count - 1].matchingStair[0]) {
        matchingPost = true;
      }
    }

    if (!seen.has(hash) && !matchingPost) {
      post.index = count;
      acc.push(post);

      seen = seen.set(hash, post);
      count++;
    } else {
      if (!seen.has(hash) && matchingPost) {
        acc[count - 1].matchingStair = acc[count - 1].matchingStair.concat(
          post.matchingStair
        );

        acc[count - 1].cornerType = {
          ...acc[count - 1].cornerType,
          ...post.cornerType,
        };

        acc[count - 1] = acc[count - 1];
      } else {
        const existingPost = seen.get(hash);

        if (existingPost.type === "stairPostTransition") {
          existingPost.matchingStair = existingPost.matchingStair.concat(
            post.matchingStair
          );

          existingPost.cornerType = {
            ...existingPost.cornerType,
            ...post.cornerType,
          };

          acc[existingPost.index] = existingPost;
        }
      }
    }

    return acc;
  }, []);

  // Reposition transition posts and eliminate transitions too close to end posts.
  let transition = "start";

  if (!point1Max && !point1Min) {
    transition = "end";
  }

  let seenEnd = false;
  let seenStart = false;

  posts = posts.reduce((acc, post, index) => {
    post.index = index;

    if (post.type === "stairPostTransition") {
      if (isNearPoint(post, point1) || isNearPoint(post, point2)) {
        let matchingStair = post.matchingStair;

        if (matchingStair.length) {
          if (matchingStair.length > 1) {
            matchingStair = matchingStair.filter((stairIndex) => {
              const stair = continuousStairs.get(stairIndex);
              return (
                stair && (stair.type === "stairs" || stair.type === "landing")
              );
            });
          }

          if (isNearPoint(post, point1)) {
            if (acc[0]) {
              acc[0].matchingStair = acc[0].matchingStair.concat(matchingStair);
              acc[0].cornerType = post.cornerType;
              acc[0].type = "stairPostTerminal";

              transition = "end";
            }
          }

          if (isNearPoint(post, point2)) {
            posts[posts.length - 1].matchingStair =
              posts[posts.length - 1].matchingStair.concat(matchingStair);

            posts[posts.length - 1].cornerType = post.cornerType;
            posts[posts.length - 1].type = "stairPostTerminal";

            acc.push(posts[posts.length - 1]);

            seenEnd = true;
          }
        }
        return acc;
      }

      const matchingStair = post.matchingStair
        .map((stairIndex) => continuousStairs.get(stairIndex))
        .filter(
          (stair) =>
            stair && (stair.type === "stairs" || stair.type === "landing")
        );

      if (matchingStair.length) {
        const match = matchingStair[0];

        const rotatedMatchPoints = points.get(match.id);

        const cornerType = post.cornerType[match.id];

        const point = rotatedMatchPoints[cornerType];

        // post.matchingStair = [match.id];
        if (post.matchingStair.length === 1) {
          post.matchingStair = post.matchingStair.concat(transition);
          transition = "end";
        }

        if (!seenStart) {
          seenStart = true;
          transition = "end";
        }

        post.cornerType = cornerType;

        if (orientation === "horizontal") {
          const r1 = rotatedMatchPoints["TL"];
          const r2 = rotatedMatchPoints["TR"];
          const angle = Math.atan2(r2.y - r1.y, r2.x - r1.x);

          const xComponent = roundToHundreth(Math.cos(angle) * 5);
          const yComponent = roundToHundreth(Math.sin(angle) * 5);

          if (cornerType === "TR" || cornerType === "BR") {
            post.x = point.x - xComponent;
            post.y = point.y - yComponent;
          }

          if (cornerType === "TL" || cornerType === "BL") {
            post.x = point.x + xComponent;
            post.y = point.y + yComponent;
          }
        }

        if (orientation === "vertical") {
          const r1 = rotatedMatchPoints["TL"];
          const r2 = rotatedMatchPoints["BL"];
          const angle = Math.atan2(r2.y - r1.y, r2.x - r1.x);

          const xComponent = roundToHundreth(Math.cos(angle) * 5);
          const yComponent = roundToHundreth(Math.sin(angle) * 5);

          if (cornerType === "TL" || cornerType === "TR") {
            post.x = point.x - xComponent;
            post.y = point.y - yComponent;
          }

          if (cornerType === "BL" || cornerType === "BR") {
            post.x = point.x + xComponent;
            post.y = point.y + yComponent;
          }
        }
      }

      acc.push(post);
    } else {
      if (!seenEnd) {
        acc.push(post);
      }
    }

    return acc;
  }, []);

  return posts;
}

export function isNumber(maybeNumber) {
  return typeof maybeNumber === "number" && !isNaN(maybeNumber);
}

export function getPostInches(settings: ProjectSettings) {
  let inches = 36;

  if (!settings) {
    return 36;
  }

  if (settings) {
    const railHeight = settings.railHeight;
    if (railHeight === "custom") {
      const heightInches = settings.customRailHeight.inches;
      const heightFeet = settings.customRailHeight.feet;

      return heightInches + heightFeet * 12;
    } else {
      return parseInt(railHeight, 10);
    }
  }

  return inches;
}

export function getNumberOfCableRuns(settings: ProjectSettings) {
  const factors = railingHeightFactors();

  if (settings.railHeight === "custom") {
    const inches = getPostInches(settings);
    return calculateNumberOfSegmentsForCustomRailHeight(inches);
  } else {
    return factors[settings.railHeight];
  }
}

export function calculateNumberOfSegmentsForCustomRailHeight(inches: number) {
  // Subtract the total space taken by the 3.5 inch starting points from the distance
  const availableSpace = Math.max(inches - 7, 0);

  // Calculate the maximum number of segments that can fit within the available space
  const maxNumberOfSegments = Math.floor(availableSpace / 2);

  // Calculate the number of segments that can fit within the available space with a maximum spacing of 3"
  let numberOfSegments = Math.min(
    maxNumberOfSegments,
    Math.floor(availableSpace / 2.6)
  );

  if (inches <= 12 && inches > 10) {
    numberOfSegments = 2;
  } else if (inches > 7 && inches <= 10) {
    numberOfSegments = 1;
  }

  return numberOfSegments;
}

export function getHypotenuseInFeetRaw(run: Run, stairs: Stairs, stairsRun) {
  if (!stairsRun) {
    return 0;
  }

  let side = 0;

  const { keys } = stairsRun;

  if (stairsRun && stairsRun.snapLineCorners) {
    if (
      isPointBetweenPoints(
        { x: run.x1, y: run.y1 },
        stairsRun.snapLineCorners[keys.start],
        stairsRun.snapLineCorners[keys.end]
      )
    ) {
      // Handle stairs where run starts inside of stairs.
      if (
        isPointBetweenPoints(
          stairsRun.snapLineCorners[keys.start],
          { x: run.x2, y: run.y2 },
          { x: run.x1, y: run.y1 }
        )
      ) {
        // Start post is inbetween point one and point2.
        const distance = getDistance(stairsRun.snapLineCorners[keys.start], {
          x: run.x1,
          y: run.y1,
        });
        side = distance;
      } else if (
        isPointBetweenPoints(
          stairsRun.snapLineCorners[keys.end],
          { x: run.x2, y: run.y2 },
          { x: run.x1, y: run.y1 }
        )
      ) {
        // Start post is inbetween point one and point2.
        const distance = getDistance(stairsRun.snapLineCorners[keys.end], {
          x: run.x1,
          y: run.y1,
        });
        side = distance;
      } else {
        if (stairs.orientation === "vertical") {
          side = getDistance(
            { x: run.x1, y: run.y1 },
            { x: run.x2, y: run.y2 }
          );
        } else {
          side = getDistance(
            { x: run.x1, y: run.y1 },
            { x: run.x2, y: run.y2 }
          );
        }
      }
    } else if (
      !isPointBetweenPoints(
        { x: run.x1, y: run.y1 },
        stairsRun.snapLineCorners[keys.start],
        stairsRun.snapLineCorners[keys.end]
      ) &&
      !isPointBetweenPoints(
        { x: run.x2, y: run.y2 },
        stairsRun.snapLineCorners[keys.start],
        stairsRun.snapLineCorners[keys.end]
      ) &&
      !isPointBetweenPoints(
        stairsRun.snapLineCorners[keys.end],
        { x: run.x1, y: run.y1 },
        { x: run.x2, y: run.y2 }
      ) &&
      !isPointBetweenPoints(
        stairsRun.snapLineCorners[keys.start],
        { x: run.x1, y: run.y1 },
        { x: run.x2, y: run.y2 }
      )
    ) {
      side = 0;
    } else {
      // Handle normal stairs where run starts outside of stairs.
      if (
        isPointBetweenPoints(
          { x: run.x2, y: run.y2 },
          stairsRun.snapLineCorners[keys.start],
          stairsRun.snapLineCorners[keys.end]
        )
      ) {
        const distance = getDistance(stairsRun.snapLineCorners[keys.start], {
          x: run.x2,
          y: run.y2,
        });
        side = distance;
      } else {
        if (stairs.orientation === "vertical") {
          side = Math.abs(stairs.y2 - stairs.y1);
        } else {
          side = Math.abs(stairs.x2 - stairs.x1);
        }
      }
    }
  }

  return side;
}

export function getHypotenuseInFeetObject(run, stairs, stairsRun) {
  if (!stairsRun) {
    return { feet: 0, inches: 0 };
  }

  const side = getHypotenuseInFeetRaw(run, stairs, stairsRun);

  const angle = toRadians(stairs.angle);

  const distance = distanceOfHypotenuse(angle, side);

  return distance;
}

export function toRadians(angle: number) {
  return angle * (Math.PI / 180);
}

export const rawDistanceOfHypotenuse = function (
  angle: number,
  distanceOfSide: number
) {
  return distanceOfSide / Math.cos(angle);
};

export const distanceOfHypotenuse = function (
  angle: number,
  distanceOfSide: number
) {
  const distance = rawDistanceOfHypotenuse(angle, distanceOfSide);

  const distanceInFeet = distance / pixelsPerFoot();
  const remainder = (distanceInFeet % 1).toFixed(4);

  const inches = Math.floor(remainder * 12);
  const feet = Math.floor(distanceInFeet);

  return { feet: feet, inches: inches };
};
