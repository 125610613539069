import { useEffect, useState } from "react";
import { serviceUrl } from "../environment";
import { projectJsonToImmutable } from "../data/fetch-projects";
import { Project } from "../entities";

let fetching = false;

function useProjectsLoader() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const PAGE_SIZE = 50;

  useEffect(() => {
    const fetchPage = (pageUrl: string) => {
      return new Promise((resolve, reject) => {
        requestIdleCallback(async function () {
          try {
            const response = await fetch(pageUrl);
            const json = await response.json();

            requestIdleCallback(function () {
              setProjects((prevProjects: Project[]) => {
                const newProjects = [
                  ...prevProjects,
                  ...json.reverse().map(projectJsonToImmutable),
                ];

                resolve(newProjects);
                return newProjects;
              });
            });
          } catch (error) {
            console.error(error);
            setError(error);
            reject(error);
          }
        });
      });
    };

    const fetchPageCount = async () => {
      try {
        const response = await fetch(countUrl);
        const data = await response.json();

        if (typeof data === "number") {
          return Math.ceil(data / PAGE_SIZE);
        } else {
          return 1;
        }
      } catch (error) {
        setError(error);
        return 1;
      }
    };

    const countUrl = serviceUrl("getProjectsCount");
    const url = serviceUrl("getProjects");

    (async () => {
      if (fetching) return;

      fetching = true;

      const pageCount = await fetchPageCount();

      const requests = Array.from(Array(pageCount).keys())
        .reverse()
        .map((val) => `${url}?pageNumber=${val + 1}`)
        .map((url) => fetchPage(url));

      Promise.all(requests)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
        });
    })();
  }, [setProjects]);

  return { projects, setProjects, loading, error };
}

export default useProjectsLoader;
