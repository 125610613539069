// @ts-nocheck
import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { serviceUrl } from "../../environment";
import { getTotal } from "../../partsList";
import getPickListItems from "./getPickListItems";
import {
  getDateValues,
  projectEstimateNumber,
  roundToHundreth,
} from "../../utils";
import {
  formatItemListDescriptionsForPDF,
  formatStainlessTopRailQuantity,
  getItemLists,
  getMasterItemList,
} from "../../utils/partsList";
import hashCode from "../../utils/hash";
import { ItemList } from "../../partsList/RunItemList";
import {
  CustomersContext,
  EstimatorContext,
  InventoryContext,
} from "../../App";
import { getCustomerName } from "../../entities/utils";

const ExportSalesOrderPdf = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state.present);
  const project = state;

  const { customers } = useContext(CustomersContext);
  const { estimators } = useContext(EstimatorContext);
  const inventoryList = useContext(InventoryContext);

  return (
    <button
      onClick={() => {
        if (!state.settings.nearSaltwater) {
          dispatch({ type: "settings/toggle" });
          dispatch({ type: "fields/require-saltwater" });
          return;
        }

        if (!project.settings.nearPoolwater) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-poolwater" });
          return;
        }

        const customer = state.customer
          ? customers.has(state.customer)
            ? customers.get(state.customer).toJS()
            : ""
          : "";
        const projectEstimators = state.estimators.size
          ? state.estimators.reduce((map, id) => {
              if (estimators.has(id)) {
                map[id] = estimators.get(id).toJS();
              }

              return map;
            }, {})
          : "";

        if (!customer) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-field", field: "customer" });
          dispatch({
            type: "window/error-message-open",
            message:
              "Customer not set. Try refreshing the browser and reopening the project.",
          });
          return;
        }

        if (!state.estimators.size) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-field", field: "estimator" });
          dispatch({
            type: "window/error-message-open",
            message: "Estimator is not set.",
          });
          return;
        }

        const estimateNumber = projectEstimateNumber(state).replace("#", "");

        const canvases = state.canvases;

        const itemLists = getItemLists(canvases, inventoryList, state);
        const getTotals = itemLists.map((itemList) => getTotal(itemList));
        const total = getTotals.reduce(
          (acc, total) => roundToHundreth(acc + total),
          0
        );

        const itemListsByRun = ItemList(
          state,
          inventoryList
        ).getItemListByRun();

        const masterItemList = formatStainlessTopRailQuantity(
          formatItemListDescriptionsForPDF(getMasterItemList(itemLists))
        );

        const date = getDateValues();

        const pickListItems = getPickListItems(masterItemList, inventoryList);

        const canvasData = state.canvases
          .map((canvas) => ({
            id: canvas.id,
            name: canvas.name,
          }))
          .toJS();

        const currentRevision = project.revisions.get(project.currentRevision);

        const pdfRequestData = {
          itemList: masterItemList,
          estimateNumber: estimateNumber,
          customer: customer,
          customerName: getCustomerName(customer),
          pickListItems: pickListItems,
          estimators: projectEstimators,
          date: date,
          shipTo: project.settings.shipToAddress,
          total: total.toString(),
          canvasData: canvasData,
          images: state.images.toJS(),
          revision: currentRevision ? currentRevision.description : "",
          nearSaltwater: project.settings.nearSaltwater,
          nearPoolwater: project.settings.nearPoolwater,
          settings: project.settings.toJS(),
          itemListsByRun: itemListsByRun.toJS(),
          tax: project.tax,
        };

        const resellerPermit = state.settings.resellerPermit;
        
        if (resellerPermit) {
          pdfRequestData.tax.set = false;
        }

        pdfRequestData.hash = hashCode(JSON.stringify(pdfRequestData));

        dispatch({
          type: "window/generate-pdf-open",
          message: "Sales Order PDF Generating...",
        });

        // Canvas.
        const images = {};

        const processes = Object.values(state.canvases.toJS()).map((canvas) => {
          return new Promise((resolve) => {
            dispatch({
              type: "render/canvas",
              canvas: canvas,
              resolve: resolve,
            });
          });
        });

        Promise.all(processes).then((imagesData) => {
          imagesData.forEach((imageData) => {
            images[imageData.canvas.id] = imageData.image;
            pdfRequestData.images = images;
          });
          pdfSalesOrderExportRequest(pdfRequestData, dispatch);
        });
      }}
      className="options__link"
    >
      Export to Sales Order PDF
    </button>
  );
};

function pdfSalesOrderExportRequest(requestData, dispatch) {
  const url = serviceUrl("salesOrderGeneratePdf");

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData), // body data type must match "Content-Type" header
  })
    .then((res) => {
      if (!res || !res.ok) {
        throw new Error("Error generating PDF");
      } else {
        return res.json();
      }
    })
    .then((data) => {
      dispatch({
        type: "window/generate-pdf-ready",
        message: "PDF Ready",
        download: data.download,
        pdfType: "SO",
      });
    })
    .catch((error) => {
      dispatch({ type: "window/generate-pdf-close" });
      dispatch({
        type: "window/error-message-open",
        message: "An error occurred while processing the PDF. Try again.",
      });
      console.error(error);
    });
}

export default ExportSalesOrderPdf;
