import { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Icon from "../Icon";
import LazyList from "../LazyList";
import { Customer, Estimator, Project } from "../../entities";
import { Map } from "immutable";
import CustomerSelectItem from "./CustomerSelectItem";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { getCustomerName } from "../../entities/utils";

interface PropType {
  setAddNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
  listPopupOpen: boolean;
  setListPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditShipToWindow: React.Dispatch<React.SetStateAction<boolean>>;
  customers: Map<string, Customer>;
  setCustomers: React.Dispatch<React.SetStateAction<Map<string, Customer>>>;
  project: Project;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  estimators: Map<string, Estimator>;
}

const CustomersDropdown = ({
  setAddNewWindow,
  listPopupOpen,
  setListPopupOpen,
  setEditShipToWindow,
  customers,
  setCustomers,
  project,
  setOpen,
  search,
  setSearch,
  estimators,
}: PropType) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);
  const clickOutside = useCallback(() => {
    setSearch("");
    setOpen(false);
  }, [setOpen, setSearch]);

  const [sortedCustomers, setSortedCustomers] = useState<Customer[]>([]);

  useOnClickOutside(ref, clickOutside);

  let listClass = "estimators__list";

  if (listPopupOpen) {
    listClass += " esimators__list--popup-open";
  }

  // const filteredCustomers = filterCustomers(customers, search);

  useEffect(() => {
    const sortedCustomers = customers
      .toArray()
      .map(([_id, customer]) => customer)
      .sort((a: Customer, b: Customer) => {
        const nameA = getCustomerName(a);
        const nameB = getCustomerName(b);
        return nameA.localeCompare(nameB);
      });
    setSortedCustomers(sortedCustomers);
  }, [customers]);

  return (
    <div ref={ref} className="estimators__dropdown">
      <div className="estimators__search">
        <Icon className="estimators__search-icon" icon="search" />
        <input
          className="estimators__search-input"
          type="text"
          placeholder="Search for a customer..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div className="estimators__new">
        <button
          onClick={() => {
            // Unset customer.
            dispatch({
              type: "customer/select",
              checked: true,
            });
            setAddNewWindow(true);
          }}
          className="estimators__add-new"
        >
          <Icon className="estimators__add-icon" icon="plus" />
          <span className="estimators__add-text">Add New Customer</span>
        </button>
      </div>
      <ul className={listClass}>
        <LazyList
          items={sortedCustomers}
          searchTerm={search}
          defaultOpen={true}
          isMatchingSearchTerm={(customer, searchTerm) => {
            if (!searchTerm.length) {
              return true;
            }

            const name = getCustomerName(customer);
            if (!name) {
              return false;
            }

            if (name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            }

            return false;
          }}
          noResultsMessage={() => <div>No results found</div>}
          renderListItem={(customer, index, setThirdToLastElement) => {
            const id = customer.id;

            const checked = project.customer === customer.id;

            return (
              <CustomerSelectItem
                key={id}
                customer={customer}
                checked={checked}
                setListPopupOpen={setListPopupOpen}
                setEditShipToWindow={setEditShipToWindow}
                // todo: do i use customers or estimators here?
                setCustomers={setCustomers}
                estimators={estimators}
              />
            );
          }}
        />
        {/* {filteredCustomers
          .entrySeq()
          .sort(([keyA, a], [keyB, b]) => {
            if (typeof a.name !== "string") {
              a = a.set("name", "");
            }

            return a.name.localeCompare(b.name);
          })
          .slice(0, 200)

          .map(([id, customer]) => {
            const checked = project.customer === customer.id;

            return (
              <CustomerSelectItem
                key={id}
                customer={customer}
                checked={checked}
                setListPopupOpen={setListPopupOpen}
                setEditShipToWindow={setEditShipToWindow}
                setCustomers={setCustomers}
                estimators={estimators}
              />
            );
          })} */}
      </ul>
    </div>
  );
};

export default CustomersDropdown;
