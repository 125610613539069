import { addItem } from "..";
import { roundToHundreth } from "../..";
import { AllInventoryTypes } from "../../../data/fetch-inventory";
import { ItemListType } from "../itemListTypes";
import { aluminumCustomPostDescriptions } from "./aluminumCustomPostDescriptions";

export function addAluminumCustomPosts(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  upc: string,
  quantity: number,
  measure: string,
  inchesPerPost: number
) {
  upc = upc.toString();
  if (!itemList[upc]) {
    itemList = addItem(itemList, inventory, upc, quantity, measure);
    if (itemList[upc]) {
      itemList[upc].quantity = 0;
    }
  }

  if (itemList[upc]) {
    if (typeof itemList[upc].counts?.[inchesPerPost] === "number") {
      itemList[upc].counts![inchesPerPost] += quantity;
    } else {
      if (itemList[upc].counts) {
        itemList[upc].counts![inchesPerPost] = quantity;
      } else {
        itemList[upc].counts = {};
        itemList[upc].counts![inchesPerPost] = quantity;
      }
    }
  }

  const item = aluminumCustomPostDescriptions(upc, itemList[upc]);

  if (itemList[upc]) {
    itemList[upc].description = item.description;
    itemList[upc].price = item.price;
    itemList[upc].quantity =
      (itemList[upc].quantity || 0) +
      Object.entries(itemList[upc].counts || {}).reduce(
        (acc, [inches, count]) =>
          acc + count * roundToHundreth(parseFloat(inches) / 12),
        0
      );
    itemList[upc].total = roundToHundreth(item.price * itemList[upc].quantity);
  }

  return itemList;
}
