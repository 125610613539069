import { useState } from "react";
import { useDispatch } from "react-redux";
import { Customer, Estimator } from "../../entities";
import Icon from "../Icon";
import { Map } from "immutable";
import { getCustomerName } from "../../entities/utils";

interface PropType {
  customer: Customer;
  checked: boolean;
  setListPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditShipToWindow: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomers: React.Dispatch<React.SetStateAction<Map<string, Customer>>>;
  estimators: Map<string, Estimator>;
}

const CustomerSelectItem = ({
  customer,
  checked,
  setListPopupOpen,
  setEditShipToWindow,
  setCustomers,
  estimators,
}: PropType) => {
  const dispatch = useDispatch();

  const [popupOpen, setPopupOpen] = useState(false);

  let estimatorClass = "estimators__estimator";

  if (checked) {
    estimatorClass += " estimators__estimator--checked";
  }

  if (customer.status === "archived") {
    estimatorClass += " estimators__estimator--archived";
  }

  if (!customer.id) {
    return null;
  }

  return (
    <li className={estimatorClass}>
      <span
        onClick={() => {
          dispatch({
            type: "customer/select",
            checked: checked,
            customer: customer,
          });

          if (customer.quickbooksData && customer.quickbooksData.SalesRepRef) {
            const estimator = estimators.get(
              customer.quickbooksData.SalesRepRef.ListID
            );

            if (estimator) {
              dispatch({
                type: "estimators/select",
                checked: false,
                estimator: estimator,
              });
            }
          }

          dispatch({ type: "tax/reset" });
        }}
        className="estimator__select"
      >
        <label className="estimator__name customer__name">
          {getCustomerName(customer)}
        </label>
      </span>
      {customer.quickbooksId.length === 0 && (
        <button
          onClick={() => {
            setPopupOpen((open) => !open);
            setListPopupOpen((open) => !open);
          }}
          className="estimator__more"
        >
          <Icon className="estimator__more-icon" icon="more" />
        </button>
      )}
      {popupOpen && (
        <div className="estimator__popup-window">
          <button
            onClick={() => {
              setPopupOpen(false);
              setListPopupOpen((open) => !open);
              setEditShipToWindow(true);
            }}
            className="estimator__popup-window-action"
          >
            Edit details
          </button>
          {customer.status !== "archived" && (
            <button
              onClick={() => {
                setPopupOpen(false);
                setListPopupOpen((open) => !open);
                dispatch({
                  type: "customers/edit",
                  customer: customer.set("status", "archived"),
                });
                setCustomers((state) => {
                  return state.set(
                    customer.id,
                    customer.set("status", "archived")
                  );
                });
              }}
              className="estimator__popup-window-action"
            >
              Archive customer
            </button>
          )}
          {customer.status !== "active" && (
            <button
              onClick={() => {
                setPopupOpen(false);
                setListPopupOpen((open) => !open);
                dispatch({
                  type: "customers/edit",
                  customer: customer.set("status", "active"),
                });
                setCustomers((state) => {
                  return state.set(
                    customer.id,
                    customer.set("status", "active")
                  );
                });
              }}
              className="estimator__popup-window-action"
            >
              Activate customer
            </button>
          )}
          <button
            onClick={() => {
              setPopupOpen(false);
              setListPopupOpen((open) => !open);
              dispatch({
                type: "customers/delete",
                customer: customer,
              });
            }}
            className="estimator__popup-window-action"
          >
            Delete customer
          </button>
        </div>
      )}
    </li>
  );
};

export default CustomerSelectItem;
