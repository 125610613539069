const salesReps = {
  "?xml": { "@_version": "1.0" },
  QBXML: {
    QBXMLMsgsRs: {
      SalesRepQueryRs: {
        SalesRepRet: [
          {
            ListID: "8000001D-1639162253",
            TimeCreated: "2021-12-10T10:50:53-08:00",
            TimeModified: "2021-12-13T13:19:31-08:00",
            EditSequence: 1639430371,
            Initial: "AM",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000C4EF-1639430364",
              FullName: "McCallum, Alex",
            },
          },
          {
            ListID: "8000000F-1513783825",
            TimeCreated: "2017-12-20T07:30:25-08:00",
            TimeModified: "2017-12-20T07:30:25-08:00",
            EditSequence: 1513783825,
            Initial: "AMF",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000049-1513783823",
              FullName: "Frieberg, Allison M",
            },
          },
          {
            ListID: "80000002-1513783379",
            TimeCreated: "2017-12-20T07:22:59-08:00",
            TimeModified: "2017-12-20T07:22:59-08:00",
            EditSequence: 1513783379,
            Initial: "AMZN",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000006-1510598977",
              FullName: "Amazon",
            },
          },
          {
            ListID: "80000003-1513783404",
            TimeCreated: "2017-12-20T07:23:24-08:00",
            TimeModified: "2017-12-20T07:23:24-08:00",
            EditSequence: 1513783404,
            Initial: "ASC",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000040-1513783401",
              FullName: "Cox, Aaron S",
            },
          },
          {
            ListID: "80000004-1513783443",
            TimeCreated: "2017-12-20T07:24:03-08:00",
            TimeModified: "2017-12-20T07:24:03-08:00",
            EditSequence: 1513783443,
            Initial: "BAM",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000041-1513783441",
              FullName: "Malcom, Boyd A",
            },
          },
          {
            ListID: "80000005-1513783459",
            TimeCreated: "2017-12-20T07:24:19-08:00",
            TimeModified: "2019-11-04T13:52:45-08:00",
            EditSequence: 1572904365,
            Initial: "CRD",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000003B-1513783134",
              FullName: "Cable Railing Direct",
            },
          },
          {
            ListID: "80000006-1513783475",
            TimeCreated: "2017-12-20T07:24:35-08:00",
            TimeModified: "2021-05-24T11:15:50-08:00",
            EditSequence: 1621880150,
            Initial: "E-Bay",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000003C-1513783158",
              FullName: "E-Bay",
            },
          },
          {
            ListID: "80000021-1673981716",
            TimeCreated: "2023-01-17T10:55:16-08:00",
            TimeModified: "2023-01-17T10:55:16-08:00",
            EditSequence: 1673981716,
            Initial: "ERI",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000A3DC-1603484188",
              FullName: "Eri Design, Inc.",
            },
          },
          {
            ListID: "80000007-1513783499",
            TimeCreated: "2017-12-20T07:24:59-08:00",
            TimeModified: "2017-12-20T07:24:59-08:00",
            EditSequence: 1513783499,
            Initial: "EWR",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000042-1513783495",
              FullName: "Reimer, Eric W",
            },
          },
          {
            ListID: "8000001A-1575938483",
            TimeCreated: "2019-12-09T16:41:23-08:00",
            TimeModified: "2023-04-10T08:23:11-08:00",
            EditSequence: 1681140191,
            Initial: "GE",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80008501-1575938472",
              FullName: "Edwards, Grace E",
            },
          },
          {
            ListID: "80000008-1513783530",
            TimeCreated: "2017-12-20T07:25:30-08:00",
            TimeModified: "2017-12-20T07:25:30-08:00",
            EditSequence: 1513783530,
            Initial: "H",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000043-1513783522",
              FullName: "Houzz",
            },
          },
          {
            ListID: "8000001F-1642783394",
            TimeCreated: "2022-01-21T08:43:14-08:00",
            TimeModified: "2022-01-21T08:43:14-08:00",
            EditSequence: 1642783394,
            Initial: "HC",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000C70B-1642783386",
              FullName: "Couch, Hayden",
            },
          },
          {
            ListID: "80000022-1675978679",
            TimeCreated: "2023-02-09T13:37:59-08:00",
            TimeModified: "2023-02-09T13:37:59-08:00",
            EditSequence: 1675978679,
            Initial: "IT",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000E983-1675978669",
              FullName: "Inventory Transfer",
            },
          },
          {
            ListID: "8000001E-1642783318",
            TimeCreated: "2022-01-21T08:41:58-08:00",
            TimeModified: "2022-01-21T08:41:58-08:00",
            EditSequence: 1642783318,
            Initial: "JA",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000C70A-1642783312",
              FullName: "Andrews, James",
            },
          },
          {
            ListID: "80000020-1648579912",
            TimeCreated: "2022-03-29T11:51:52-08:00",
            TimeModified: "2022-03-29T11:51:52-08:00",
            EditSequence: 1648579912,
            Initial: "JC",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "800083BA-1574280843",
              FullName: "Corson*, Jeremiah D",
            },
          },
          {
            ListID: "80000023-1677862722",
            TimeCreated: "2023-03-03T08:58:42-08:00",
            TimeModified: "2023-03-03T08:58:42-08:00",
            EditSequence: 1677862722,
            Initial: "KDR",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80009467-1591498726",
              FullName: "Redula, Kyle D",
            },
          },
          {
            ListID: "80000009-1513783601",
            TimeCreated: "2017-12-20T07:26:41-08:00",
            TimeModified: "2019-04-05T14:41:58-08:00",
            EditSequence: 1554500518,
            Initial: "KMK",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000045-1513783598",
              FullName: "Kozber, Katja M",
            },
          },
          {
            ListID: "8000000A-1513783622",
            TimeCreated: "2017-12-20T07:27:02-08:00",
            TimeModified: "2023-03-30T07:55:53-08:00",
            EditSequence: 1680188153,
            Initial: "LRT",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000046-1513783620",
              FullName: "Towell, Larry R",
            },
          },
          {
            ListID: "8000000C-1513783681",
            TimeCreated: "2017-12-20T07:28:01-08:00",
            TimeModified: "2017-12-20T07:28:01-08:00",
            EditSequence: 1513783681,
            Initial: "SAH",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000047-1513783677",
              FullName: "Hibler, Seth A",
            },
          },
          {
            ListID: "8000001B-1589994256",
            TimeCreated: "2020-05-20T10:04:16-08:00",
            TimeModified: "2020-05-20T10:04:16-08:00",
            EditSequence: 1589994256,
            Initial: "TMH",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000927A-1589991030",
              FullName: "Holladay, Travis M",
            },
          },
          {
            ListID: "8000000D-1513783714",
            TimeCreated: "2017-12-20T07:28:34-08:00",
            TimeModified: "2019-04-05T14:02:43-08:00",
            EditSequence: 1554498163,
            Initial: "VV",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000048-1513783710",
              FullName: "Vuong, Vivian",
            },
          },
          {
            ListID: "8000000E-1513783740",
            TimeCreated: "2017-12-20T07:29:00-08:00",
            TimeModified: "2019-10-21T14:20:30-08:00",
            EditSequence: 1571692830,
            Initial: "Yahoo",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000003E-1513783253",
              FullName: "Y!",
            },
          },
        ],
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default salesReps;
